import { filter } from 'rxjs/operators';
// Angular
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
// CRUD
import { QueryResultsModel } from '../models/query-models/query-results.model';
import { QueryParamsModel, HttpParamsOption } from '../models/query-models/query-params.model';
import { HttpExtenstionsModel } from '../../crud/models/http-extentsions-model';
import { isDefined } from './helper.function';


@Injectable()
export class HttpUtilsService {
	/**
	 * Prepare query http params
	 * @param queryParams: QueryParamsModel
	 */
	getFindHTTPParams(queryParams: QueryParamsModel): HttpParams {
		let params = new HttpParams();

		// Pagination filters
		if (!!isDefined(queryParams.start) && isDefined(queryParams.limit)) {
			params = params
				.set('_start', queryParams.start.toString())
				.set('_limit', queryParams.limit.toString());
		}
		// Sort filter
		if (!!queryParams.sortOrder && !!queryParams.sortField && queryParams.sortField !== '') {
			params = params.set('_sort', `${queryParams.sortField}:${queryParams.sortOrder}`);
		}
		// Other Filters
		if (!!queryParams && !!queryParams.filter && Object.keys(queryParams.filter).length > 0) {
			Object.keys(queryParams.filter).forEach( keyFilter => {
				if (isDefined(queryParams.filter[keyFilter]) && queryParams.filter[keyFilter] !== '' ) {
					params = params.set(keyFilter, queryParams.filter[keyFilter].toString());
				}
			});
		}
		return params;
	}
	/**
	 * Prepare query http params
	 * @param queryParams: QueryParamsModel
	 */
	getFilterHTTPParams(filters: object): HttpParams {
		let params = new HttpParams();

		// Other Filters
		if (!!filters && Object.keys(filters).length > 0) {
			Object.keys(filters).forEach( keyFilter => {
				if (isDefined(filters[keyFilter]) && filters[keyFilter] !== '' ) {
					params = params.set(keyFilter, filters[keyFilter].toString());
				}
			});
		}
		return params;
	}

	createHttpParams(options: HttpParamsOption) {
		let params = new HttpParams();
		Object.keys(options).forEach(key => params = params.set(key, options[key]));
		return params;
	}

	/**
	 * get standard content-type
	 */
	getHTTPHeaders(): HttpHeaders {
		const result = new HttpHeaders();
		result.set('Content-Type', 'application/json');
		return result;
	}

	baseFilter(_entities: any[], _queryParams: QueryParamsModel, _filtrationFields: string[] = []): QueryResultsModel {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.baseFilter(_entities, _queryParams, _filtrationFields);
	}

	sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.sortArray(_incomingArray, _sortField, _sortOrder);
	}

	searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
		const httpExtention = new HttpExtenstionsModel();
		return httpExtention.searchInArray(_incomingArray, _queryObj, _filtrationFields);
	}
}
