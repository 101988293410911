import gql from 'graphql-tag';
import orderCommonFragment from '../fragments/orderCommon';

const orderByReferenceIdQuery = gql`
	query orderByReferenceIdQuery($id: ID!, $shopId: ID!) {
		orderByReferenceId(id: $id, shopId: $shopId) {
			...OrderCommon
			__typename
		}
	}
	${orderCommonFragment}
`;

export default orderByReferenceIdQuery;
