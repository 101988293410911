import { GraphQLModule } from './../../../core/graphql/graphql.module';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { LayoutUtilsService } from '../../../core/_base/crud/utils/layout-utils.service';
import { TypesUtilsService } from '../../../core/_base/crud/utils/types-utils.service';
import { HttpUtilsService } from '../../../core/_base/crud/utils/http-utils.service';

import { TranslateModule } from '@ngx-translate/core';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import {
	MatDialogModule,
	MatSortModule,
	MatTableModule,
	MatCheckboxModule,
	MatProgressSpinnerModule,
	MatPaginatorModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSelectModule,
	MatIconModule,
	MatButtonModule
} from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent,
	ConfirmationDialogComponent,
	PublishProductDialogComponent,
	CreateTagDialogComponent,
	AlertDialogComponent
} from '../../partials/content/crud';
import { OrdersComponent } from './orders.component';
import { OrderViewDialogComponent } from './order-view/order-view.dialog.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ordersReducer } from './../../../core/point-of-interest';
import { OrderEffects } from './../../../core/point-of-interest/_effects/order.effects';

// Apollo

// tslint:disable-next-line:class-name
const routes: Routes = [
	{
		path: '',
		component: OrdersComponent
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgbModule,
		GraphQLModule,
		RouterModule.forChild(routes),
		StoreModule.forFeature('orders', ordersReducer),
		EffectsModule.forFeature([OrderEffects]),
		CoreModule,
		TranslateModule.forChild(),
		MatProgressSpinnerModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatSelectModule,
		MatIconModule,
		MatButtonModule
	],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
	],
	entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		ConfirmationDialogComponent,
		AlertDialogComponent,
		PublishProductDialogComponent,
		CreateTagDialogComponent,
		OrderViewDialogComponent
	],
	declarations: [OrdersComponent, OrdersListComponent, OrderViewDialogComponent]
})
export class OrdersModule {}
