import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	ViewChild,
	ChangeDetectorRef
} from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	FormControl
} from '@angular/forms';

export const MAX_IMAGE_SIZE = 2.097e6; // 2mb
@Component({
	selector: 'kt-avatar-file-upload',
	templateUrl: './avatar-file-upload.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: AvatarFileUploadComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: AvatarFileUploadComponent,
			multi: true
		}
	],
	styleUrls: ['./avatar-file-upload.component.scss']
})
export class AvatarFileUploadComponent implements OnInit, ControlValueAccessor {
	//   @Input() progress;
	@Input() defaultBackground = './assets/media/users/default.jpg';
	@Input() filesAccepted = '.png, .jpg, .jpeg';
	@Input() maxSize = MAX_IMAGE_SIZE;
	disabled = false;

	holder = null;
	cancel = null;
	@ViewChild('kt_avatar', { static: true }) element: ElementRef;
	onChange: Function;
	onTouched: Function;
	private file: File | null = null;

	constructor(
		private host: ElementRef<HTMLInputElement>,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.holder = KTUtil.find(this.element.nativeElement, '.kt-avatar__holder');
		KTUtil.css(
			this.holder,
			'background-image',
			'url(' + this.defaultBackground + ')'
		);
		this.cancel = KTUtil.find(this.element.nativeElement, '.kt-avatar__cancel');

		// Handle avatar cancel
		KTUtil.addEvent(this.cancel, 'click', (e) => {
			e.preventDefault();
			this.clear();
			this.onChange(this.file);
		});
	}

	@HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
		const file = event && event.item(0);
		this.onChange(file);
		this.onTouched(true);
	}
	validate({ value }: FormControl) {
		let result = {};
		if (!value || !value.size) {
			result = {};
		} else if (value.size > MAX_IMAGE_SIZE) {
			result = {
				invalidFileSize: {
					fileSize: (value.size / Math.pow(1024, 2)).toFixed(2),
					maxFileSize: (MAX_IMAGE_SIZE / Math.pow(1024, 2)).toFixed(2)
				}
			};
		}
		this.updateFile(value);
		this.cdr.detectChanges();
		return result;
	}

	updateFile(file: File) {
		if (!!file && file.size > 0) {
			this.file = file;
			const reader = new FileReader();
			reader.onload = (e) => {
				KTUtil.css(this.holder, 'background-image', 'url(' + reader.result + ')');
			};
			reader.readAsDataURL(this.file);
			KTUtil.addClass(this.element.nativeElement, 'kt-avatar--changed');
		}
	}

	writeValue(value: null) {
		// clear file input
		this.clear();
	}

	clear() {
		KTUtil.removeClass(this.element.nativeElement, 'kt-avatar--changed');
		KTUtil.css(
			this.holder,
			'background-image',
			'url(' + this.defaultBackground + ')'
		);
		// clear file input
		this.host.nativeElement.value = '';
		this.file = null;
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}
}
