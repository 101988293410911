import { TranslateService } from '@ngx-translate/core';
import {
	LayoutUtilsService,
	MessageType
} from './../../../../core/_base/crud/utils/layout-utils.service';
import {
	PointofinterestShopInfoComponent,
	PointOfInterestAndShopId
} from './../../shared/pointofinterest-shop-info/pointofinterest-shop-info.component';
import { User } from './../../../../core/auth/_models/user.model';
import { currentUser } from './../../../../core/auth/_selectors/auth.selectors';
import { NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
// Angular
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	OnDestroy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';

// Services and Models
import { ProductModel, ProductsService } from '../../../../core/point-of-interest';
import { objectPathExists } from '../../../../core/_base/crud/utils/helper.function';

import { VariantLine } from './variant-list/variant-line.model';
import { GetProductByIdReactionResponse } from '../../../../core/point-of-interest/_models/product.model';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-product-form',
	templateUrl: './product-form.component.html',
	styleUrls: ['./product-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductFormComponent extends PointofinterestShopInfoComponent
	implements OnInit, OnDestroy {
	// Alert panel
	@ViewChild('alert', { static: false }) set content(content: ElementRef) {
		if (!!content && !!content.nativeElement) {
			content.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.alertElement = content;
	}
	alertElement: ElementRef;
	alertMessage: {
		message?: string;
		type?: 'primary' | 'accent' | 'warn' | 'info';
		duration?: number;
	} = {};

	// Public properties
	productForm: FormGroup;
	canBackorder = true;

	product: ProductModel;

	variantsLabel: string = null;
	optionsLabel: string = null;

	// Variant lines
	variantLines: VariantLine[];
	// Current User (used to get the user limits)
	user$: Observable<User>;
	currentUser: User;

	// ---------------------------------------------------------------------------------------------------------

	loadingSubject = new BehaviorSubject<boolean>(true);
	isPublishing$ = new BehaviorSubject<boolean>(false);
	loading$: Observable<boolean>;

	pointOfInterestId: number;
	shopId: string;

	allowedToUpdateVariants = true;

	/**
	 * Component constructor
	 *
	 */
	constructor(
		public store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		public dialog: MatDialog,
		private productService: ProductsService,
		private cdr: ChangeDetectorRef,
		public formatter: NgbDateParserFormatter,
		protected calendar: NgbCalendar,
		private layoutUtilsSrvc: LayoutUtilsService,
		private translate: TranslateService
	) {
		super(store);
	}

	/**
	 * On init
	 */
	async ngOnInit() {
		this.getPoiAndShopInfo();

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.user$ = this.store.pipe(select(currentUser));
		this.user$.pipe(takeWhile(() => this.inView)).subscribe((user) => {
			if (!!user) {
				this.currentUser = user;
			}
		});

		this.pointOfInterestAndShopIds$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterestShopInfo: PointOfInterestAndShopId) => {
				if (!!pointOfInterestShopInfo) {
					if (
						!!this.pointOfInterestId &&
						this.pointOfInterestId !== pointOfInterestShopInfo.poiId
					) {
						// If the point of interest changed need redirect to the Product List dash.
						this.goBackWithoutId();
						return;
					}

					this.pointOfInterestId = pointOfInterestShopInfo.poiId;
					this.shopId = pointOfInterestShopInfo.shopId;

					const productId = this.activatedRoute.snapshot.paramMap.get('id');
					this.loadProductFromService(productId);
				}
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		this.inView = false;
	}

	get doesProductExist() {
		return !!this.product;
	}

	// Get the product from the server
	async loadProductFromService(productId) {
		if (!this.pointOfInterestId || !productId) {
			this.loadingSubject.next(false);
			return;
		}
		this.productService
			.getProductByIdWatch(this.shopId, productId)
			.pipe(takeWhile(() => this.inView))
			.subscribe((p: GetProductByIdReactionResponse) => {
				const product = objectPathExists(p, 'data.product', true);
				if (product) {
					this.product = product;
					this.loadProductInfo(product);
				}
				this.allowedToUpdateVariants = true;
				this.loadingSubject.next(false);
				this.isPublishing$.next(false);
				this.cdr.detectChanges();
			});
	}

	loadProductInfo(product: ProductModel) {
		const [variantLabel, optionLabel] = ProductModel.getAttrsLabeslInfo(
			this.product
		);

		this.variantsLabel = variantLabel || '';
		this.optionsLabel = optionLabel || '';
	}

	async publishProduct() {
		if (!this.canBePublished) return;

		try {
			this.isPublishing$.next(true);

			const variantsWithoutAttrLabel = ProductModel.getVariantsWithoutAttributeLabelsUpdated(
				this.product,
				this.variantsLabel,
				this.optionsLabel
			);

			if (!!variantsWithoutAttrLabel.length) {
				const updates = variantsWithoutAttrLabel.map(({ type, entity }) =>
					this.productService.updateProductVariant(
						this.shopId,
						this.product._id,
						entity._id,
						{
							attributeLabel:
								type === 'variant' ? this.variantsLabel : this.optionsLabel
						}
					)
				);

				const updatesResult = await Promise.all(updates);

				if (updatesResult.some((v) => !v)) throw new Error('Error updating labels');
			}

			const published = await this.productService.publishProduct(
				this.shopId,
				this.product._id,
				true
			);

			if (!published) throw new Error('Request ok, but not published');

			this.layoutUtilsSrvc.showActionNotification(
				this.translate.instant('PRODUCTS.FORM.MESSAGES.PUBLISHED_SUCCESSFULLY'),
				MessageType.Create
			);
		} catch (error) {
			this.isPublishing$.next(false);
			this.layoutUtilsSrvc.showSimpleAlertDialog(
				'warning',
				'PRODUCTS.FORM.VARIANTS_AND_OPTIONS.ADD_VARIANT',
				'PRODUCTS.FORM.ERROR.ERROR_PUBLISHING',
				true
			);
		}
	}

	onStartUpdatingProduct(value: boolean) {
		this.allowedToUpdateVariants = !value;
	}

	// Just used by product-basic-info
	forceProductQuery(value: true) {
		this.productService
			.getProductById(this.shopId, this.product._id)
			.then((p: ProductModel) => {
				if (p) {
					this.product = p;
					this.loadProductInfo(p);
				}
				this.cdr.detectChanges();
			});
	}

	/**
	 * Go back to the list
	 */
	goBackWithoutId() {
		this.router.navigate(['/products']);
	}

	/**
	 * UI functions
	 */
	getPortletTitle() {
		if (!this.product || (!!this.product && !this.product.title)) {
			return '';
		}
		return this.product.title;
	}

	get canBePublished() {
		if (!this.product) return;
		if (
			this.product.currentProductHash === this.product.publishedProductHash &&
			!!this.product.currentProductHash
		)
			return false;

		if (!ProductModel.hasVariants(this.product)) return false;

		return true;
	}
}
