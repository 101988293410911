// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { NotificationsState } from '../_reducers/notification.reducers';
import { NotificationModel } from '../_models/notification.model';

export const selectNotificationsState = createFeatureSelector<NotificationsState>(
	'notifications'
);

export const selectNotificationById = (notificationId: number) =>
	createSelector(
		selectNotificationsState,
		(notificationsState) => notificationsState.entities[notificationId]
	);

export const selectNotificationsPageLoading = createSelector(
	selectNotificationsState,
	(notificationsState) =>
		(!!notificationsState && notificationsState.listLoading) || null
);

export const selectNotificationsActionLoading = createSelector(
	selectNotificationsState,
	(notificationsState) => notificationsState.actionsloading
);

export const selectNotificationsPageLastQuery = createSelector(
	selectNotificationsState,
	(notificationsState) =>
		(!!notificationsState && notificationsState.lastQuery) || null
);

export const selectLastCreatedNotificationId = createSelector(
	selectNotificationsState,
	(notificationsState) =>
		(!!notificationsState && notificationsState.lastCreatedNotificationId) || null
);

export const selectNotificationsInitWaitingMessage = createSelector(
	selectNotificationsState,
	(notificationsState) =>
		(!!notificationsState && notificationsState.showInitWaitingMessage) || null
);

export const selectNotificationsInStore = createSelector(
	selectNotificationsState,
	(notificationsState) => {
		const items: NotificationModel[] = [];
		if (!notificationsState) return;

		each(notificationsState.entities, (element) => {
			items.push(element);
		});
		const httpExtension = new HttpExtenstionsModel();
		const result: NotificationModel[] = httpExtension.sortArray(
			items,
			notificationsState.lastQuery.sortField,
			notificationsState.lastQuery.sortOrder
		);
		return new QueryResultsModel(result, notificationsState.totalCount, '');
	}
);

export const selectHasNotificationsInStore = createSelector(
	selectNotificationsInStore,
	(queryResult) => {
		if (!queryResult.totalCount) {
			return false;
		}

		return true;
	}
);
