import { BaseDataSource } from '../../../../../../core/_base/crud/models/_base.datasource';
// Angular
import {
	Component,
	Input,
	ChangeDetectionStrategy,
	OnInit,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnDestroy
} from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';

// CRUD
import {
	TypesUtilsService,
	LayoutUtilsService,
	MessageType
} from '../../../../../../core/_base/crud';
import { VariantLine } from './../variant-line.model';
import {
	markAllFormAs,
	isDefined,
	toInteger,
	isNumber,
	objectPathExists
} from '../../../../../../core/_base/crud/utils/helper.function';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'kt-options-list',
	templateUrl: './options-list.component.html',
	styleUrls: ['./options-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition(
				'expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			)
		])
	]
})
export class OptionsListComponent implements OnInit, OnDestroy {
	// Incoming data
	@Input() optionsLines: VariantLine[];
	@Input() parentLine: VariantLine;
	_inventoryFlags = {
		canBackorder: false,
		isEnabled: false
	};
	@Input() set inventoryFlags(newValue: {
		canBackorder: boolean;
		isEnabled: boolean;
	}) {
		if (this.optionsLines && this.optionsLines.length > 0) {
			this.optionsLines.forEach((variant) => {
				variant.setInventoryFlags(newValue);
			});
			this.cd.detectChanges();
		}
		this._inventoryFlags = newValue;
	}
	// Edit mode
	@Input() set isSwitchedToEditMode(value: boolean) {
		this._isSwitchedToEditMode = value;
	}
	@Output() editModeChange = new EventEmitter<boolean>();

	// Table fields
	dataSource: BaseDataSource;
	displayedColumns = [
		'attribute',
		'label',
		'tag',
		'price',
		'saleprice',
		'quantity',
		'state',
		'actions'
	];
	// Add and Edit
	loadingAfterSubmit = false;
	formGroup: FormGroup;

	_isSwitchedToEditMode = false;
	switchToEditMode(newState: boolean) {
		this._isSwitchedToEditMode = newState;
		this.editModeChange.next(newState);
		this.cd.detectChanges();
	}

	/**
	 * Component constructor
	 *
	 * @param fb: FormBuilder
	 * @param dialog: MatDialog
	 * @param typesUtilsService: TypeUtilsService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param cd: ChangeDetectorRef
	 */
	constructor(
		private fb: FormBuilder,
		public dialog: MatDialog,
		public typesUtilsService: TypesUtilsService,
		private layoutUtilsService: LayoutUtilsService,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		if (!this.optionsLines) {
			return;
		}

		this.clearForm();
		// Init DataSource
		this.dataSource = new BaseDataSource();
		this.dataSource.entitySubject.next(this.optionsLines);
	}
	ngOnDestroy() {}

	/**
	 * Update the dom datasource list when add/remove items from list
	 */
	updateDataSource() {
		this.switchToEditMode(false);
		this.dataSource.entitySubject.next(this.optionsLines);
		this.cd.detectChanges();
	}

	// Form FUNCTIONS: createFormGroup | clearForm | checkForm
	/**
	 * Create Reactive Form
	 * @param _item: VariantLine
	 */

	createFormGroup(item: VariantLine) {
		this.formGroup = this.fb.group({
			attribute: [item.attribute, Validators.compose([Validators.required])],
			label: [item.label, Validators.compose([Validators.required])],
			price: [
				item.price,
				Validators.compose([item.hasOptions ? null : Validators.required])
			],
			saleprice: [item.saleprice],
			quantity: [
				isDefined(item.quantity) ? item.quantity : null,
				objectPathExists(item, 'inventoryInfo.isEnabled', true) && !item.hasOptions
					? Validators.compose([Validators.required])
					: null
			],
			state: [item.state, Validators.compose([Validators.required])]
		});
	}

	/**
	 * Clear the Reactive Form
	 */
	clearForm() {
		const clearOption = new VariantLine();
		clearOption._isEditMode = false;
		clearOption.currencySymbol =
			(this.parentLine && this.parentLine.currencySymbol) || '';
		clearOption._parent = this.parentLine;
		this.createFormGroup(clearOption);
	}

	/**
	 * Check if Form is Valid
	 */
	checkForm() {
		markAllFormAs(this.formGroup, 'touched');
		return !this.formGroup.invalid;
	}

	// ADD OPTION FUNCTIONS: addOptionButtonOnClick

	/**
	 * Open Option Add Form
	 */
	addOptionButtonOnClick() {
		if (this._isSwitchedToEditMode) {
			return;
		}
		const newOptionLine = new VariantLine();
		newOptionLine._isEditMode = true;
		newOptionLine._isNew = true;
		newOptionLine.currencySymbol =
			(this.parentLine && this.parentLine.currencySymbol) || '';
		newOptionLine._parent = this.parentLine;
		newOptionLine.inventoryInfo =
			(this.parentLine && this.parentLine.inventoryInfo) || null;
		newOptionLine.productId = this.parentLine.productId;

		// All variants at same level shoud be have the same attributeLabel.
		// If ins't the first variation get the attribute of first not deleted line.
		if (this.optionsLines && this.optionsLines.length !== 0) {
			const mainOption = this.optionsLines.find((variant) => !variant._isDeleted);
			if (!!mainOption) {
				newOptionLine.attribute = mainOption.attribute;
			}
		}

		this.createFormGroup(newOptionLine);
		this.optionsLines.push(newOptionLine);

		this.updateDataSource();
		this.switchToEditMode(true);
	}

	isTheMainOption(index) {
		if (this.optionsLines && this.optionsLines.length !== 0) {
			const mainOptionIndex = this.optionsLines.findIndex(
				(variant) => !variant._isDeleted
			);
			return mainOptionIndex === index;
		}
		return true;
	}
	getMainOption() {
		if (this.optionsLines && this.optionsLines.length !== 0) {
			return this.optionsLines.find((variant) => !variant._isDeleted);
		}
		return null;
	}

	// EDIT Option FUNCTIONS:  editOptionButtonOnClick | cancelEditButtonOnClick |

	/**
	 * Update Option
	 *
	 * @param item: VariantLine
	 */
	editOptionButtonOnClick(item: VariantLine) {
		this.createFormGroup(item);
		item._isEditMode = true;
		this.switchToEditMode(true);
	}

	/**
	 * Cancel Option edit
	 *
	 * @param item: VariantLine
	 */
	cancelEditButtonOnClick(item: VariantLine) {
		this.clearForm();
		item._isEditMode = false;
		this.switchToEditMode(false);
	}

	/**
	 * Save Variant
	 *
	 * @param index: number
	 */
	saveOption(index: number) {
		if (!this.checkForm()) {
			return;
		}

		this.loadingAfterSubmit = true;
		const controls = this.formGroup.controls;
		this.loadingAfterSubmit = false;
		const objectForUpdate: VariantLine = this.optionsLines[index];
		if (!!objectForUpdate) {
			// Any change on the attribute value should be applied to all options
			// (Note: Options at the same level should have the same attribute value )
			this.optionsLines.forEach(
				(v: VariantLine) => (v.attribute = controls.attribute.value)
			);

			objectForUpdate.label = controls.label.value;
			if (isDefined(controls.price.value)) {
				const intPrice = toInteger(controls.price.value);
				if (isNumber(intPrice)) {
					objectForUpdate.setPrice(intPrice);
				}
			}
			if (isDefined(controls.saleprice.value)) {
				const intSalePrice = toInteger(controls.saleprice.value);
				if (isNumber(intSalePrice)) {
					objectForUpdate.setSalePrice(intSalePrice);
				}
			}
			if (
				objectPathExists(objectForUpdate, 'inventoryInfo.isEnabled', true) &&
				isDefined(controls.quantity.value)
			) {
				const intQtd = toInteger(controls.quantity.value);
				if (isNumber(intQtd)) {
					objectForUpdate.setQuantity(intQtd);
				}
			}
			objectForUpdate.state = controls.state.value;
			objectForUpdate._isEditMode = false;

			this.switchToEditMode(false);
			this.layoutUtilsService.showActionNotification(
				'PRODUCTS.FORM.MESSAGES.SAVE_OPTION_SUCCESS',
				MessageType.Update,
				1000,
				true,
				true
			);
		}
	}

	/** ACTIONS */
	/**
	 * Delete variant
	 *
	 * @param index: number
	 */
	deleteOption(index: number) {
		const _title = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.TITLE';
		const _description = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.DESCRIPTION';
		const _waitDesciption = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.WAIT_DESCRIPTION';
		const _deleteMessage = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.DELETE_MESSAGE';

		const dialogRef = this.layoutUtilsService.deleteElement(
			_title,
			_description,
			_waitDesciption
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			const option = this.optionsLines[index];
			if (!!option) {
				if (option._isNew) {
					this.optionsLines.splice(index, 1);
				} else {
					option._isDeleted = true;
					option._isEditMode = false;
				}
				this.updateDataSource();

				this.layoutUtilsService.showActionNotification(
					_deleteMessage,
					MessageType.Delete
				);
			}
		});
	}

	/* UI **/
	/**
	 * Returns CSS Class by state
	 *
	 * @param state: boolean
	 */
	getItemCssClassByStatus(state: boolean): string {
		return state ? 'success' : 'danger';
	}
	/**
	 * Returns translate key for state
	 * @param state: boolean
	 */
	getItemStatusTranslateKey(state: boolean): string {
		return state ? 'PRODUCTS.STATE.ACTIVE' : 'PRODUCTS.STATE.INACTIVE';
	}
}
