// Angular
import { Component, Input } from '@angular/core';

@Component({
	selector: 'kt-widget-1-value-kpi',
	templateUrl: './widget-1-value-kpi.component.html',
	styleUrls: ['./widget-1-value-kpi.component.scss']
})
export class Widget1ValueKpiComponent {
	// Public properties
	@Input() data: {
		label: string;
		value: number;
		kpi: { label: string; value: number };
	};

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor() {}
}
