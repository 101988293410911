// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User } from '../_models/user.model';
import { PointOfInterest } from '../../point-of-interest';

export interface AuthState {
	loggedIn: boolean;
	authToken: string;
	user: User;
	selectedPOI: PointOfInterest;
	isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
	loggedIn: false,
	authToken: undefined,
	user: undefined,
	selectedPOI: undefined,
	isUserLoaded: false
};

export function authReducer(
	state = initialAuthState,
	action: AuthActions
): AuthState {
	switch (action.type) {
		case AuthActionTypes.Login: {
			const token: string = action.payload.authToken;
			return {
				loggedIn: true,
				authToken: token,
				user: undefined,
				selectedPOI: undefined,
				isUserLoaded: false
			};
		}

		case AuthActionTypes.Register: {
			const token: string = action.payload.authToken;
			return {
				loggedIn: true,
				authToken: token,
				user: undefined,
				selectedPOI: undefined,
				isUserLoaded: false
			};
		}

		case AuthActionTypes.Logout:
			return initialAuthState;

		case AuthActionTypes.UserLoaded: {
			const user: User = action.payload.user;

			return {
				...state,
				user,
				isUserLoaded: true
			};
		}
		case AuthActionTypes.PointOfInterestSelected: {
			const selectedPOI: PointOfInterest = action.payload.pointOfInterest;
			// Save in session storage the selected POI_ID
			sessionStorage.setItem(
				'selectedPOI_ID',
				selectedPOI ? '' + selectedPOI.id : ''
			);

			return {
				...state,
				selectedPOI: selectedPOI || undefined
			};
		}

		default:
			return state;
	}
}
