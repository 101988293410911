import { NUMBER_REGEX } from './../../../../../core/shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService } from './../../../../../core/_base/crud/utils/layout-utils.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
	objectPathExists,
	isControlInvalidAndDirty
} from './../../../../../core/_base/crud/utils/helper.function';
import { ProductsService } from './../../../../../core/point-of-interest/_services/products.service';
import { ProductVariantsInfoComponent } from '../shared/product-variants-info/product-variants-info.component';
import {
	ProductModel,
	GetProductByIdReactionResponse,
	ProductVariantModel
} from './../../../../../core/point-of-interest/_models/product.model';
import {
	Component,
	OnInit,
	Input,
	OnDestroy,
	EventEmitter,
	Output
} from '@angular/core';
import { skip, takeWhile } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

interface VariantPriceEditableRepresentation {
	_id: string;
	variantTitle: string;
	optionTitle: string;
	form: FormGroup;
}

@Component({
	selector: 'kt-product-store-position',
	templateUrl: './product-store-position.component.html',
	styleUrls: ['./product-store-position.component.scss']
})
export class ProductStorePositionComponent extends ProductVariantsInfoComponent
	implements OnInit, OnDestroy {
	@Input() product: ProductModel;
	@Input() shopId: string;

	private isComponentDisabled = false;
	@Input() set disabled(value: boolean) {
		this.isComponentDisabled = value;

		if (this.editionStructure)
			this.walkThroughStructureAndDo(({ form }) =>
				value ? form.disable() : form.enable()
			);
	}
	@Output() startUpdating = new EventEmitter<boolean>();

	editionStructure: VariantPriceEditableRepresentation[][];

	loadingBuildingStructure$ = new BehaviorSubject<boolean>(false);
	actionLoadingUpdatePricing$ = new BehaviorSubject<boolean>(false);

	inView = true;
	isProductValid = true;

	constructor(
		private productSrvc: ProductsService,
		private layoutUtilsSrvc: LayoutUtilsService,
		private translate: TranslateService,
		private fb: FormBuilder
	) {
		super();
	}

	ngOnInit() {
		this.loadProduct(this.product);

		this.productSrvc
			.getProductByIdWatch(this.shopId, this.product._id)
			.pipe(
				skip(1),
				takeWhile(() => this.inView)
			)
			.subscribe((p: GetProductByIdReactionResponse) => {
				const product = objectPathExists(p, 'data.product', true);
				if (!product) return;
				this.loadProduct(product);
				this.setLoadingStateOnUpdate(false);
			});
	}

	loadProduct(product: ProductModel) {
		this.loadVariantsAttrs(product);
		this.isProductValid = this.getLightProductValidation(product);

		if (!this.isProductValid) return;

		this.prepareProductForEdition(product);
	}

	/**
	 *
	 *  Assumes product has a correct structure
	 *
	 * If there are only variants (without options)
	 * 	returns [[v][v][v][v][v]]
	 *
	 * If there are only one option per variant
	 * 	returns [[o][o][o][o][o]]
	 *
	 * If there are 3 options per variant
	 * 	returns [[o,o,o][o,o,o][o,o,o][o,o,o][o,o,o]]
	 */
	prepareProductForEdition(product: ProductModel): void {
		// Aux function

		const genProductEditableRepresentation = (
			variantTitle: string = '',
			optionTitle: string = '',
			variant: ProductVariantModel
		) => {
			const [originalPrice, salePrice] = ProductVariantModel.translatePricing(
				variant
			);
			return {
				_id: variant._id,
				variantTitle,
				optionTitle,
				form: this.fb.group({
					originalPrice: [
						{
							value: originalPrice,
							disabled: this.isComponentDisabled
						},
						[Validators.required, Validators.pattern(NUMBER_REGEX)]
					],
					salePrice: [
						{
							value: salePrice,
							disabled: this.isComponentDisabled
						},
						[Validators.pattern(NUMBER_REGEX)]
					]
				})
			};
		};

		if (!ProductModel.hasVariants(product)) return;

		this.loadingBuildingStructure$.next(true);
		this.editionStructure = product.variants.map((vAr) => {
			if (!ProductModel.variantHasOptions(vAr))
				return [genProductEditableRepresentation(vAr.optionTitle, null, vAr)];

			return vAr.options.map((op, i) =>
				genProductEditableRepresentation(
					i === 0 ? `${vAr.optionTitle}` : null,
					op.optionTitle,
					op
				)
			);
		});

		this.loadingBuildingStructure$.next(false);
	}

	async updatePricing() {
		if (!this.isFormValidAndWithChanges) {
			console.log('Data not valid');
			return;
		}

		const representationsChanged: VariantPriceEditableRepresentation[] = [];
		this.editionStructure.forEach((variationsRepresentationList) =>
			variationsRepresentationList.forEach((represenatation) => {
				const { form } = represenatation;
				if (form.valid && form.dirty) representationsChanged.push(represenatation);
			})
		);

		this.setLoadingStateOnUpdate(true);

		const priceChangeRequests = representationsChanged.map(
			({
				_id,
				form: {
					controls: { originalPrice, salePrice }
				}
			}) => {
				const [price, compareAt] = ProductVariantModel.getPricing(
					originalPrice.value || null,
					salePrice.value || null
				);

				return this.productSrvc.updateProductVariantPrices(
					this.shopId,
					this.product._id,
					_id,
					price,
					compareAt,
					true
				);
			}
		);

		try {
			const updates = await Promise.all(priceChangeRequests);
			if (updates.some((u) => !u))
				throw new Error('PRODUCTS.FORM.ERRORS.PRICING_NOT_UPDATED_ERROR');

			this.layoutUtilsSrvc.showActionNotification(
				this.translate.instant('PRODUCTS.FORM.PRICING.SAVED_SUCCESSFULLY')
			);
		} catch (error) {
			this.layoutUtilsSrvc.showSimpleAlertDialog(
				'warning',
				'PRODUCTS.FORM.PRICING.UPDATING_PRICING',
				error.message || 'PRODUCTS.FORM.ERRORS.PRICING_NOT_UPDATED_ERROR',
				true
			);
			this.setLoadingStateOnUpdate(false);
		}
	}

	setLoadingStateOnUpdate(state: boolean) {
		this.actionLoadingUpdatePricing$.next(state);

		if (!this.editionStructure) return;

		this.editionStructure.forEach((variationsRepresentationList) =>
			variationsRepresentationList.forEach((represenatation) =>
				state ? represenatation.form.disable() : represenatation.form.enable()
			)
		);
	}

	get tableContetLabel() {
		return `${this.variationsLabel || ''} ${
			this.optionsLabel ? `- ${this.optionsLabel}` : ''
		}`;
	}

	get isFormValidAndWithChanges() {
		if (!this.editionStructure || !this.editionStructure.length) return false;

		return this.editionStructure.some((arr) =>
			arr.some(({ form }) => {
				return form.valid && form.dirty;
			})
		);
	}

	walkThroughStructureAndDo(fn: (rep: VariantPriceEditableRepresentation) => void) {
		this.editionStructure.forEach((variationsRepresentationList) =>
			variationsRepresentationList.forEach((represenatation) => fn(represenatation))
		);
	}

	isControlInvalidAndDirty(form: FormGroup, controlName: string) {
		return isControlInvalidAndDirty(form.controls[controlName]);
	}

	ngOnDestroy(): void {
		this.inView = false;
	}
}
