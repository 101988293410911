/* tslint:disable variable-name */

import { CircularFence } from './components/circular-fence.model';
import { POI_STATE } from './../_consts/specification.dictionary';
import { MediaFile } from './../../_base/crud/models/media-file.model';
import { TranslationField } from './../../_base/crud/models/translation-field.model';
import { BaseModel } from '../../_base/crud';
import { Feature } from './feature.model';
import { Category } from './category.model';
import { PolygonFence } from './components/polygon-fence.model';
import { Address } from './components/address.model';
import { OpeningHours } from './components/opening-hours.model';
import { Contacts } from './components/contacts.model';
import { Payment } from './components/payment.model';
import { SocialNetworks } from './components/social-networks.model';

export class PointOfInterest extends BaseModel {
	title: string | TranslationField;
	description?: string | TranslationField;
	mainImage?: MediaFile;
	image?: MediaFile[];
	geofence?: (CircularFence | PolygonFence)[];
	address?: Address;
	opening_hours?: OpeningHours[];
	contacts?: Contacts;
	web_site?: string;
	available_payments?: Payment[];
	state: POI_STATE;
	social_networks?: SocialNetworks;
	demo: boolean;
	user: number; // <User> ID of Owner/Manager of PointOfInterest
	features?: Feature[] | number[];
	categories?: Category[] | number[];

	clear() {
		this.id = undefined;
		this.title = new TranslationField();
		this.title.clear();
		this.description = new TranslationField();
		this.description.clear();
		this.mainImage = new MediaFile();
		this.mainImage.clear();
		this.image = [];
		this.geofence = [];
		this.address = new Address();
		this.address.clear();
		this.opening_hours = [];
		this.contacts = new Contacts();
		this.contacts.clear();
		this.web_site = '';
		this.available_payments = [];
		this.state = undefined;
		this.social_networks = new SocialNetworks();
		this.social_networks.clear();
		this.user = undefined;
		this.features = [];
		this.categories = [];
		this.demo = false;
	}
}

export class PoiECommerceInfoModel {
	id: number;
	shop_id: string;
	poi_id: number;
	created_at: Date;
	updated_at: Date;
	created_by: string;
	updated_by: string;
}
