import { OrderRequest } from './../../../../core/point-of-interest/_actions/order.actions';
import { selectOrderById } from './../../../../core/point-of-interest/_selectors/order.selectors';
import { OrderUtils } from './../share/orders.utils';
import { AppState } from './../../../../core/reducers/index';
import {
	OrderModel,
	AddressModel,
	OrderItemModel
} from './../../../../core/point-of-interest/_models/order.model';
// Angular
import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
// State

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-order-view-dialog',
	templateUrl: './order-view.dialog.component.html'
})
export class OrderViewDialogComponent implements OnInit, OnDestroy {
	// Public properties
	order: OrderModel;
	shippingAddress: AddressModel;
	orderItems: OrderItemModel[];

	ORDER_UTILS = OrderUtils;

	displayedColumns1 = ['title', 'attributes', 'quantity', 'price.displayAmount'];

	// Private properties
	private componentSubscriptions: Subscription;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<CustomerEditDialogComponent>
	 * @param data: any
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(
		public dialogRef: MatDialogRef<OrderViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private store: Store<AppState> // private typesUtilsService: TypesUtilsService
	) {}

	ngOnInit() {
		const id = this.data.orderId;

		this.store.pipe(select(selectOrderById(id))).subscribe((result) => {
			if (!result) {
				// this.loadProductFromService(id);
				return;
			}

			const order: OrderModel = result as OrderModel;
			this.fillOrderInfo(result);
			// A simple way to say that we don't have all the order data
			if (!this.hasOrderFullInfo)
				setTimeout(() => {
					this.store.dispatch(
						new OrderRequest({
							shopId: this.data.shopId,
							referenceId: order.referenceId
						})
					);
				});
		});
	}

	fillOrderInfo(order: OrderModel) {
		this.order = order;
		const fulfillmentGroup =
			(order &&
				order.fulfillmentGroups &&
				order.fulfillmentGroups.length &&
				order.fulfillmentGroups[0]) ||
			null;

		if (!fulfillmentGroup) return;

		this.shippingAddress =
			!!fulfillmentGroup.data && fulfillmentGroup.data.shippingAddress;

		this.orderItems = !!fulfillmentGroup.items && fulfillmentGroup.items.nodes;
	}

	get hasOrderFullInfo() {
		return !!this.orderItems;
	}
	get hasShippingAddress() {
		return !!this.shippingAddress;
	}

	get hasOrderItems() {
		return !!this.orderItems;
	}

	getAttributes(orderItem: OrderItemModel) {
		const attrs = orderItem.attributes.map((att) => att.value);
		return attrs.join(', ');
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}
}
