/**
 * Model used to represent the interval of week days.
 * :from -> day of the week: 0 for Sunday, 1 for Monday, 2 for Tuesday, ..., OR 0 to represent the day off
 * :to -> day of the week: 0 for Sunday, 1 for Monday, 2 for Tuesday, ..., OR 0 to represent the day off
 */
import { isDefined } from '../utils/helper.function';
export const WEEK_DAY_OPTIONS = [
	{ value: 1, name: 'TIME.WEEK_DAYS.MONDAY.FULL' },
	{ value: 2, name: 'TIME.WEEK_DAYS.TUESDAY.FULL' },
	{ value: 3, name: 'TIME.WEEK_DAYS.WEDNESDAY.FULL' },
	{ value: 4, name: 'TIME.WEEK_DAYS.THURSDAY.FULL' },
	{ value: 5, name: 'TIME.WEEK_DAYS.FRIDAY.FULL' },
	{ value: 6, name: 'TIME.WEEK_DAYS.SATURDAY.FULL' },
	{ value: 7, name: 'TIME.WEEK_DAYS.SUNDAY.FULL' },
	{ value: 0, name: 'TIME.WEEK_DAYS.HOLIDAY.FULL' }
];

export class DayInterval {
	id: number;
	from: number; // (min: 0, max:7)
	to?: number; // (min: 0, max:7)

	public static validator(data: DayInterval) {
		let res = {};
		if (!!data) {
			if (!isDefined(data.from)) {
				res = {
					...res,
					day_interval: 'INPUT.VALIDATIONS.DAY_INTERVAL.MANDATORY'
				};
			} else if (!!data.from && !!data.to && data.from >= data.to) {
				res = {
					...res,
					day_interval: 'INPUT.VALIDATIONS.DAY_INTERVAL.END_AFTER_START'
				};
			}
		}
		return res;
	}

	clear() {
		this.id = undefined;
		this.from = undefined;
		this.to = undefined;
	}
}
