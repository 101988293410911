import { PointOfInterest } from './../../point-of-interest/_models/point-of-interest.model';
import { POIeCommerceInfoRequest } from './../../point-of-interest/_actions/e-commerce.actions';
import { PointOfInterestSelected } from './../_actions/auth.actions';
import { switchMap, catchError, map } from 'rxjs/operators';
// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import {
	AuthActionTypes,
	Login,
	Logout,
	Register,
	UserLoaded,
	UserRequested
} from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { User } from '../_models/user.model';

@Injectable()
export class AuthEffects {
	@Effect({ dispatch: false })
	login$ = this.actions$.pipe(
		ofType<Login>(AuthActionTypes.Login),
		tap((action) => {
			localStorage.setItem(environment.authTokenKey, action.payload.authToken);
			this.store.dispatch(new UserRequested());
		})
	);

	@Effect({ dispatch: false })
	logout$ = this.actions$.pipe(
		ofType<Logout>(AuthActionTypes.Logout),
		tap(() => {
			localStorage.removeItem(environment.authTokenKey);
			this.router.navigate(['/auth/login'], {
				queryParams: { returnUrl: this.returnUrl }
			});
		})
	);

	@Effect({ dispatch: false })
	register$ = this.actions$.pipe(
		ofType<Register>(AuthActionTypes.Register),
		tap((action) => {
			localStorage.setItem(environment.authTokenKey, action.payload.authToken);
		})
	);

	@Effect({ dispatch: false })
	loadUser$ = this.actions$.pipe(
		ofType<UserRequested>(AuthActionTypes.UserRequested),
		withLatestFrom(this.store.pipe(select(isUserLoaded))),
		switchMap(([action, _isUserLoaded]) =>
			this.auth.getLoggedUserData().pipe(
				catchError((error) => {
					console.error('Caught get user data!', error);
					return of(false);
				})
			)
		),
		tap((user) => {
			if (user) {
				this.store.dispatch(new UserLoaded({ user: user as User }));
			} else {
				this.store.dispatch(new Logout());
			}
		})
	);

	@Effect()
	requestECommerceInfo$ = this.actions$.pipe(
		ofType<UserLoaded>(AuthActionTypes.UserLoaded),
		map(({ payload }) => {
			const { user } = payload;

			let selectedPOI: PointOfInterest = null;

			const sessionSelectedID = sessionStorage.getItem('selectedPOI_ID');
			if (!!sessionSelectedID) {
				selectedPOI = user.point_of_interests.find(
					(poi) => poi.id === +sessionSelectedID
				);
			}
			if (!selectedPOI) {
				// Select the first if don't have any previous Point of Interest selected
				selectedPOI =
					!!user &&
					!!user.point_of_interests &&
					!!user.point_of_interests[0] &&
					user.point_of_interests[0];
			}

			if (selectedPOI) {
				return new PointOfInterestSelected({ pointOfInterest: selectedPOI });
			}

			return of(null);
		})
	);

	@Effect()
	loadECommerceInfo$ = this.actions$.pipe(
		ofType<PointOfInterestSelected>(AuthActionTypes.PointOfInterestSelected),
		map(({ payload }) => {
			if (payload && payload.pointOfInterest && payload.pointOfInterest.id) {
				return new POIeCommerceInfoRequest({
					pointOfInterestId: payload.pointOfInterest.id
				});
			}

			return of(null);
		})
	);

	@Effect()
	init$: Observable<Action> = defer(() => {
		const userToken = localStorage.getItem(environment.authTokenKey);
		let observableResult = of({ type: 'NO_ACTION' });
		if (userToken) {
			observableResult = of(new Login({ authToken: userToken }));
		}
		return observableResult;
	});

	private returnUrl: string;

	constructor(
		private actions$: Actions,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
	}
}
