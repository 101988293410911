import { GQLImageSizes } from './../../../../../generated/graphql';
import { environment } from './../../../../../environments/environment';
import { BaseModel } from './_base.model';
import { isDefined } from '../utils/helper.function';
export enum ImageSizes {
	large,
	medium,
	original,
	small,
	thumbnail
}

export class MediaFile extends BaseModel {
	name: string;
	hash: string;
	sha256: string;
	ext: string;
	mime: string;
	size: number;
	url: string;
	provider: string;
	'provider_metadata'?: string;

	public static getFilePath(file: MediaFile) {
		// TODO: adapt to acces to S3 files
		return !!file ? `${file.url}` : '';
	}

	public static getGQLImagePathSize(
		imgUrls: GQLImageSizes,
		size: ImageSizes,
		host: string = ''
	) {
		if (!!imgUrls) {
			for (let i = size; i < Object.keys(ImageSizes).length; i++) {
				if (
					isDefined(imgUrls[ImageSizes[i]]) &&
					imgUrls[ImageSizes[i]] !== String(null)
				) {
					return `${host}${imgUrls[ImageSizes[i]]}`;
					break;
				}
			}
		}
		return null;
	}

	clear(): void {
		this.id = undefined;
		this.name = '';
		this.hash = '';
		this.sha256 = '';
		this.ext = '';
		this.mime = '';
		this.size = undefined;
		this.url = '';
		this.provider = '';
		this.provider_metadata = '';
	}
}
