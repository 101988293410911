import { TranslateService } from '@ngx-translate/core';
import {
	PointofinterestShopInfoComponent,
	PointOfInterestAndShopId
} from './../shared/pointofinterest-shop-info/pointofinterest-shop-info.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsService } from './../../../core/point-of-interest/_services/products.service';
import {
	LayoutUtilsService,
	MessageType
} from './../../../core/_base/crud/utils/layout-utils.service';
import { PointOfInterestService } from './../../../core/point-of-interest/_services/point-of-interest.service';
import { PointOfInterest } from './../../../core/point-of-interest/_models/point-of-interest.model';
import { takeWhile } from 'rxjs/operators';
import { AppState } from './../../../core/reducers/index';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { POI_STATE } from './../../../core/point-of-interest';
import { objectPathExists } from './../../../core/_base/crud/utils/helper.function';

@Component({
	selector: 'kt-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends PointofinterestShopInfoComponent
	implements OnInit, OnDestroy {
	inView = true;
	pointOfInterest$: Observable<PointOfInterest>;
	poiId: number;
	shopId: string;

	constructor(
		public store: Store<AppState>,
		private productsService: ProductsService,
		private layoutUtilsService: LayoutUtilsService,
		private cd: ChangeDetectorRef,
		private router: Router,
		private translate: TranslateService,
		private activatedRoute: ActivatedRoute
	) {
		super(store);
	}

	ngOnInit() {
		this.getPoiAndShopInfo();
		this.pointOfInterestAndShopIds$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterestShopInfo: PointOfInterestAndShopId) => {
				if (!!pointOfInterestShopInfo) {
					if (
						pointOfInterestShopInfo &&
						objectPathExists(
							pointOfInterestShopInfo,
							'data.pointOfInterest.state',
							true
						) === POI_STATE.Active
					) {
						this.poiId = pointOfInterestShopInfo.poiId;
						this.shopId = pointOfInterestShopInfo.shopId;
					} else {
						this.poiId = null;
					}
					this.cd.detectChanges();
				}
			});
	}
	ngOnDestroy() {
		this.inView = false;
	}

	async createProduct() {
		try {
			const product = await this.productsService.createProduct(this.shopId);

			const productId = objectPathExists(
				product,
				'data.createProduct.product._id',
				true
			);

			if (!product || !productId)
				throw new Error('Something wrong creating product');

			this.router.navigate(
				['/products/edit', product.data.createProduct.product._id],
				{
					relativeTo: this.activatedRoute
				}
			);
		} catch (error) {
			this.layoutUtilsService.showActionNotification(
				this.translate.instant('PRODUCTS.FORM.ERRORS.ERROR_CREATING_PRODUCT'),
				MessageType.Create
			);
		}
	}
}
