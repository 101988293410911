import {
	TagsReactionResponse,
	TagModel,
	CreateTagReactionResponse,
	UpdateTagReactionResponse,
	RemoveTagReactionResponse
} from './../_models/tag.model';
// Angular
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ReactionQueryResultsModel } from '../../_base/crud/models/query-models/reaction-query-results.model';
import tagListingQuery from '../../graphql/e-commerce/queries/getTags';
import addTagMutation from '../../graphql/e-commerce/mutations/addTagMutation';
import { objectPathExists, isDefined } from '../../_base/crud/utils/helper.function';
import updateTagMutation from '../../graphql/e-commerce/mutations/updateTagMutation';
import removeTagMutation from '../../graphql/e-commerce/mutations/removeTagMutation';

const getTagsQuery = (shopId: string) => [
	{
		query: tagListingQuery,
		variables: {
			shopId
		}
	}
];

@Injectable({
	providedIn: 'root'
})
export class TagsService {
	constructor(private apollo: Apollo) {}

	getTags(shopId: string, force: boolean = false): Observable<TagsReactionResponse> {
		return this.apollo.query<{
			tags: ReactionQueryResultsModel<TagModel>;
		}>({
			query: tagListingQuery,
			variables: {
				shopId
			},
			fetchPolicy: force ? 'network-only' : undefined
		});
	}

	watchTags(
		shopId: string,
		force: boolean = false
	): Observable<TagsReactionResponse> {
		return this.apollo.watchQuery<{
			tags: ReactionQueryResultsModel<TagModel>;
		}>({
			query: tagListingQuery,
			variables: {
				shopId
			},
			fetchPolicy: force ? 'network-only' : undefined
		}).valueChanges;
	}

	createTag(
		shopId: string,
		poiId: number,
		displayTitle: string,
		position: string,
		isVisible: boolean = true,
		refetchTags = false
	): Promise<TagModel> {
		const name = `${displayTitle.toLowerCase()}_${poiId}`;

		const refetchQueries = refetchTags ? getTagsQuery(shopId) : undefined;

		const metafields = isDefined(position)
			? TagModel.getPositionMetafield(position)
			: [];
		return this.apollo
			.mutate({
				mutation: addTagMutation,
				variables: {
					displayTitle,
					name,
					shopId,
					metafields,
					isVisible
				},
				refetchQueries
			})
			.toPromise()
			.then(
				(v: CreateTagReactionResponse) =>
					objectPathExists(v, 'data.addTag.tag', true) || null
			)
			.catch(() => null);
	}

	updateTag(
		shopId: string,
		poiId: number,
		tagId: string,
		displayTitle: string,
		position: string,
		isVisible: boolean = true
	): Promise<TagModel> {
		const name = `${displayTitle.toLowerCase()}_${poiId}`;

		const metafields = isDefined(position)
			? TagModel.getPositionMetafield(position)
			: [];

		return this.apollo
			.mutate({
				mutation: updateTagMutation,
				variables: {
					input: { id: tagId, displayTitle, name, shopId, metafields, isVisible }
				}
			})
			.toPromise()
			.then(
				(v: UpdateTagReactionResponse) =>
					objectPathExists(v, 'data.updateTag.tag', true) || null
			)
			.catch(() => null);
	}

	removeTag(shopId: string, tagId: string): Promise<TagModel> {
		return this.apollo
			.mutate({
				mutation: removeTagMutation,
				variables: {
					input: { id: tagId, shopId }
				}
			})
			.toPromise()
			.then(
				(v: RemoveTagReactionResponse) =>
					objectPathExists(v, 'data.removeTag.tag', true) || null
			)
			.catch(() => null);
	}
}
