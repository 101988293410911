import gql from 'graphql-tag';

export default gql`
	fragment TagInfo on Tag {
		_id
		displayTitle
		position
		name
		slug
		isVisible
		heroMediaUrl
		metafields {
			description
			key
			namespace
			scope
			value
			valueType
		}
		createdAt
		updatedAt
	}
`;
