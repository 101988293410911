import { MediaFile } from './../../../../core/_base/crud/models/media-file.model';
import { NotificationsService } from './../../../../core/point-of-interest/_services/notifications.service';
import { environment } from './../../../../../environments/environment';
import { NOTIFICATION_STATE } from './../../../../core/point-of-interest/_consts/specification.dictionary';
import { QueryParamsModel } from './../../../../core/_base/crud/models/query-models/query-params.model';
import {
	MessageType,
	LayoutUtilsService
} from './../../../../core/_base/crud/utils/layout-utils.service';
// Angular
import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
	OnDestroy,
	Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { distinctUntilChanged, tap, skip, debounceTime } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { AppState } from './../../../../core/reducers/index';
import { Store, select } from '@ngrx/store';

// Services and Models
import {
	NotificationModel,
	NotificationsDataSource,
	NotificationsPageRequested,
	selectNotificationsPageLastQuery
} from './../../../../core/point-of-interest/';
import { dateToEnvironmentFormat } from './../../../../core/_base/crud/utils/helper.function';

// Table with EDIT item in new page
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-notifications-list',
	templateUrl: './notifications-list.component.html',
	styleUrls: ['./notifications-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent implements OnInit, OnDestroy {
	_activeNotification: NotificationModel;
	_selectedPointOfInterestId = null;
	@Input() set activeNotification(notification: NotificationModel) {
		this._activeNotification = notification;
		this.resetSort();
		this.loadNotificationsList();
	}
	@Input() set selectedPointOfInterestId(poiId: number) {
		if (!!poiId && poiId !== this._selectedPointOfInterestId) {
			this._selectedPointOfInterestId = poiId;
			this.resetSort();
			this.loadNotificationsList();
		}
	}
	dateFormat = environment.dateFormat;
	// Table fields
	dataSource: NotificationsDataSource;
	displayedColumns = [
		'select',
		'title',
		'valid_from',
		'valid_to',
		'state',
		'actions'
	];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
	filterStatus = '';
	lastQuery: QueryParamsModel;
	// Selection
	selection = new SelectionModel<NotificationModel>(true, []);
	notificationsResult: NotificationModel[] = [];
	private subscriptions: Subscription[] = [];
	MediaFile = MediaFile;
	dateToEnvironmentFormat = dateToEnvironmentFormat;
	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private layoutUtilsService: LayoutUtilsService,
		private store: Store<AppState>,
		private notificationsService: NotificationsService
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => {
			this.paginator.pageIndex = 0;
			this.loadNotificationsList();
		});
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered:
		- when a pagination event occurs => this.paginator.page
		**/
		const paginatorSubscriptions = merge(this.paginator.page)
			.pipe(
				tap((pageEvent: PageEvent) => {
					// PAGINATOR BUG:
					// The paginator doesn't reset automatically the pageIndex when user changes the pageSize.
					// Need check with previous value if exists any change and reset the pageIndex
					if (this.lastQuery.limit !== pageEvent.pageSize) {
						this.paginator.pageIndex = 0;
					}
					this.loadNotificationsList();
				})
			)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadNotificationsList();
				})
			)
			.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init DataSource
		this.dataSource = new NotificationsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject
			.pipe(skip(1), distinctUntilChanged())
			.subscribe((res) => {
				this.notificationsResult = res;
			});
		this.subscriptions.push(entitiesSubscription);
		const lastQuerySubscription = this.store
			.pipe(select(selectNotificationsPageLastQuery))
			.subscribe((res) => (this.lastQuery = res));
		// Load last query from store
		this.subscriptions.push(lastQuerySubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((el) => el.unsubscribe());
	}

	resetSort() {
		this.sort.active = 'state';
		this.sort.direction = 'asc';
	}
	/**
	 * Load Notifications List
	 */
	loadNotificationsList() {
		if (!this._selectedPointOfInterestId) {
			return;
		}
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex * this.paginator.pageSize || 0,
			this.paginator.pageSize
		);
		// Call request from server
		this.store.dispatch(new NotificationsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Returns object for filter
	 */
	filterConfiguration(): any {
		const filter: any = {};

		if (this.filterStatus && this.filterStatus.length > 0) {
			filter.state = this.filterStatus;
		}
		if (!!this._selectedPointOfInterestId) {
			filter.point_of_interest = this._selectedPointOfInterestId;
		}

		if (!!this._activeNotification) {
			filter.id_nin = this._activeNotification.id;
		} else {
			filter.id_nin = null;
		}

		const searchText: string = this.searchInput.nativeElement.value;
		filter._q = searchText;

		return filter;
	}

	/**
	 * Restore state
	 *
	 * @param queryParams: QueryParamsModel
	 * @param id: number
	 */
	restoreState(queryParams: QueryParamsModel, id: number) {
		if (!queryParams.filter) {
			return;
		}

		if ('status' in queryParams.filter) {
			this.filterStatus = queryParams.filter.status.toString();
		}

		if (queryParams.filter.model) {
			this.searchInput.nativeElement.value = queryParams.filter.model;
		}
	}

	/** ACTIONS */
	/**
	 * Delete notification
	 *
	 * @param item: NotificationModel
	 */
	deleteNotification(item: NotificationModel) {
		const title = 'NOTIFICATIONS.DELETE_MODAL.TITLE';
		const description = 'NOTIFICATIONS.DELETE_MODAL.DESCRIPTION';
		const waitDescription = 'NOTIFICATIONS.DELETE_MODAL.WAIT_DESCRIPTION';
		const deleteMessage = 'NOTIFICATIONS.DELETE_MODAL.DELETE_MESSAGE';

		const dialogRef = this.layoutUtilsService.deleteElement(
			title,
			description,
			waitDescription
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			this.notificationsService
				.deleteNotification(item.id)
				.toPromise()
				.then((res) => {
					if (!!res) {
						this.layoutUtilsService.showActionNotification(
							deleteMessage,
							MessageType.Delete
						);
					}
					this.loadNotificationsList();
				})
				.catch((error) => {
					console.error("Can't delete the notification!", error);
				});
		});
	}

	/**
	 * Delete notifications
	 */
	deleteNotifications() {
		const title = 'NOTIFICATIONS.DELETE_MODAL.TITLE';
		const description = 'NOTIFICATIONS.DELETE_MODAL.DESCRIPTION';
		const waitDescription = 'NOTIFICATIONS.DELETE_MODAL.WAIT_DESCRIPTION';
		const deleteMessage = 'NOTIFICATIONS.DELETE_MODAL.DELETE_MESSAGE';

		const dialogRef = this.layoutUtilsService.deleteElement(
			title,
			description,
			waitDescription
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			const idsForDeletion: number[] = [];
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < this.selection.selected.length; i++) {
				idsForDeletion.push(this.selection.selected[i].id);
			}

			this.notificationsService
				.deleteNotifications(idsForDeletion)
				.then((results) => {
					if (results.every((r) => !!r)) {
						this.layoutUtilsService.showActionNotification(
							deleteMessage,
							MessageType.Delete
						);
					} else {
						console.error("Can't delete the all notifications");
					}
					this.loadNotificationsList();
				})
				.catch((error) => {
					console.error("Can't delete the notifications", error);
				});
		});
	}

	/**
	 * Update status dialog
	 */
	updateStatusForNotifications() {
		const title = 'NOTIFICATIONS.UPDATE_STATE_DIALOG.TITLE';
		const updateMessage = 'NOTIFICATIONS.UPDATE_STATE_DIALOG.MESSAGE';
		const key = 'GENERIC.STATE';
		const statuses = [
			{
				value: NOTIFICATION_STATE.Active,
				text: this.getItemStatusTranslateKey(NOTIFICATION_STATE.Active)
			},
			{
				value: NOTIFICATION_STATE.Inactive,
				text: this.getItemStatusTranslateKey(NOTIFICATION_STATE.Inactive)
			}
		];
		const messages = [];

		this.selection.selected.forEach((elem) => {
			messages.push({
				text: `${elem.title}`,
				id: elem.id,
				status: elem.state,
				statusTitle: this.getItemStatusTranslateKey(elem.state),
				statusCssClass: this.getItemCssClassByStatus(elem.state)
			});
		});

		const dialogRef = this.layoutUtilsService.updateKeyForEntities(
			title,
			key,
			statuses,
			messages
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				this.selection.clear();
				return;
			}

			this.notificationsService
				.updateStatusForNotification(this.selection.selected, res)
				.then((results) => {
					if (results.every((r) => !!r)) {
						this.layoutUtilsService.showActionNotification(
							updateMessage,
							MessageType.Update
						);
					} else {
						console.error("Can't upadte the all notifications");
					}
					this.loadNotificationsList();
				})
				.catch((error) => {
					console.error("Can't update the notifications", error);
				});
		});
	}

	/**
	 * Redirect to edit page
	 *
	 * @param id: any
	 */
	editNotification(id) {
		this.router.navigate(['/notifications/edit', id], {
			relativeTo: this.activatedRoute
		});
	}

	createNotification() {
		this.router.navigateByUrl('/notifications/add');
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.notificationsResult.length;
		return numSelected === numRows;
	}

	/**
	 * Selects all rows if they are not all selected; otherwise clear selection
	 */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.notificationsResult.forEach((row) => this.selection.select(row));
		}
	}

	/* UI */
	/**
	 * Returns translate key for state
	 * @param state: NOTIFICATION_STATE
	 */
	getItemStatusTranslateKey(state: string): string {
		switch (state) {
			case NOTIFICATION_STATE.Active:
				return 'NOTIFICATIONS.STATE.ACTIVE';
			case NOTIFICATION_STATE.Inactive:
				return 'NOTIFICATIONS.STATE.INACTIVE';
			default:
				return '';
		}
	}

	/**
	 * Returns CSS Class by state
	 *
	 * @param state: NOTIFICATION_STATE
	 */
	getItemCssClassByStatus(state: string): string {
		switch (state) {
			case NOTIFICATION_STATE.Active:
				return 'success';
			case NOTIFICATION_STATE.Inactive:
				return 'danger';
			default:
				return '';
		}
	}
}
