import { HttpUtilsService } from './../../_base/crud/utils/http-utils.service';
import { environment } from './../../../../environments/environment';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Feature } from '../_models';
// RxJS

// CRUD

// Models

const API_CATEGORY_URL = `${environment.apiEndpoint}/features`;

// Real REST API
@Injectable({
	providedIn: 'root'
})
export class FeaturesService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

	// READ
	// Server should return filtered result
	findFeatures(filterParams: object): Promise<Feature[]> {
		const httpParams = this.httpUtils.getFilterHTTPParams(filterParams);

		const url = API_CATEGORY_URL;
		return this.http
			.get<Feature[]>(url, { params: httpParams })
			.toPromise()
			.then((res) => res)
			.catch(() => []);
	}
}
