import { TagModel } from './tag.model';
import { environment } from './../../../../environments/environment';
import { MediaFile, ImageSizes } from './../../_base/crud/models/media-file.model';
import {
	GQLProduct,
	GQLImageInfo,
	GQLProductVariant,
	GQLMetafield
} from './../../../../generated/graphql';
import { objectPathExists, isDefined } from '../../_base/crud/utils/helper.function';
import { ApolloQueryResult } from 'apollo-client';

export enum PRODUCT_METAFIELDS_NAMESPACES {
	ATTRIBUTES_LABELS = 'attributes-labels'
}

export enum PRODUCT_METAFIELDS_KEYS {
	VARIATIONS_LABEL = 'variations-label',
	OPTIONS_LABEL = 'options-label'
}

export interface GetProductByIdReactionResponse
	extends ApolloQueryResult<{
		product: ProductModel;
	}> {}
export interface CreateProductReactionResponse
	extends ApolloQueryResult<{
		createProduct: { product: ProductModel };
	}> {}

export interface CreateProductVariantReactionResponse
	extends ApolloQueryResult<{
		createProductVariant: { variant: ProductVariantModel };
	}> {}
export interface ArchiveProductVariantsReactionResponse
	extends ApolloQueryResult<{
		archiveProductVariants: { variants: ProductVariantModel[] };
	}> {}
export interface UpdateProductVariantReactionResponse
	extends ApolloQueryResult<{
		updateProductVariant: { variant: ProductVariantModel };
	}> {}
export interface UpdateProductVariantPriceReactionResponse
	extends ApolloQueryResult<{
		updateProductVariantPrices: { variant: ProductVariantModel };
	}> {}

export interface PublishProductReactionResponse
	extends ApolloQueryResult<{
		publishProductsToCatalog: ProductModel[];
	}> {}
export interface UpdateProductProductReactionResponse
	extends ApolloQueryResult<{
		updateProduct: { product: ProductModel };
	}> {}

// tslint:disable-next-line:no-empty-interface
export interface ProductMetafields extends GQLMetafield {}

export class ProductModel extends GQLProduct {
	public static getDisplayPrice(product: ProductModel) {
		const priceMin = objectPathExists(product, 'pricing.minPrice', true) || null;
		const priceMax = objectPathExists(product, 'pricing.maxPrice', true) || null;
		const symb = objectPathExists(product, 'shop.currency.symbol', true) || '';
		return priceMin !== priceMax
			? `${priceMin} ${symb} -  ${priceMax} ${symb}`
			: `${priceMin} ${symb}`;
	}

	public static getDisplaySalePrice(product: ProductModel) {
		const allSaleValues: number[] = [];
		if (product.variants) {
			product.variants.forEach((variant) => {
				const salePrice = objectPathExists(variant, 'pricing.compareAtPrice');
				if (!!variant.pricing && !!variant.pricing.compareAtPrice)
					allSaleValues.push(
						objectPathExists(variant, 'pricing.compareAtPrice.amount', true)
					);

				if (variant.options)
					variant.options.forEach((option) => {
						if (!!option.pricing && !!option.pricing.compareAtPrice)
							allSaleValues.push(
								objectPathExists(option, 'pricing.compareAtPrice.amount', true)
							);
					});
			});
		}

		if (!allSaleValues.length) return '-';

		const priceMin = Math.min(...allSaleValues);
		const priceMax = Math.max(...allSaleValues);
		const symb = objectPathExists(product, 'shop.currency.symbol', true) || '';

		return priceMin !== priceMax
			? `${priceMin} ${symb} -  ${priceMax} ${symb}`
			: `${priceMin} ${symb}`;
	}
	public static getProductCategory(product: ProductModel): TagModel {
		const categories = objectPathExists(product, 'tags.nodes', true);
		return categories && categories.length > 0 ? categories[0] : null;
	}

	public static isPublished(product: ProductModel) {
		return (
			!!product.currentProductHash &&
			product.currentProductHash === product.publishedProductHash
		);
	}

	public static getProductImagePath(
		product: ProductModel,
		size: ImageSizes = ImageSizes.large
	) {
		const images = objectPathExists(product, 'media', true);
		const firstImgURLs =
			images && images.length > 0 && objectPathExists(images[0], 'URLs', true);
		return MediaFile.getGQLImagePathSize(
			firstImgURLs,
			size,
			environment.ecommerceEndpoint
		);
	}

	public static allMediasProcessed(product: ProductModel) {
		let processed = true;
		const medias: GQLImageInfo[] = objectPathExists(product, 'media', true);
		if (medias) {
			// All media have all URL's defined ?
			processed = medias.every((img) => {
				if (!isDefined(img.URLs)) {
					return false;
				}
				return Object.keys(img.URLs).every(
					(imgKey) => !!img.URLs[imgKey] && img.URLs[imgKey] !== String(null)
				);
			});
		}
		return processed;
	}

	public static getProductFirstVariant(product: ProductModel): ProductVariantModel {
		if (!product) return null;
		return (
			(!!objectPathExists(product, 'variants.length', true) &&
				(product.variants[0] as ProductVariantModel)) ||
			null
		);
	}

	public static hasVariants(product: ProductModel): boolean {
		return !!(product.variants || []).length;
	}

	public static variantHasOptions(productVariant: ProductVariantModel): boolean {
		return !!(productVariant.options || []).length;
	}

	public static getVariantsWithoutAttributeLabelsUpdated(
		product: ProductModel,
		variantLabel: string,
		optionLabel: string
	): { type: 'variant' | 'option'; entity: ProductVariantModel }[] {
		if (!product.variants) return [];

		const variantsWithoutAttrLabel: {
			type: 'variant' | 'option';
			entity: ProductVariantModel;
		}[] = [];
		product.variants.forEach((v) => {
			if (!v.attributeLabel || v.attributeLabel !== variantLabel)
				variantsWithoutAttrLabel.push({ type: 'variant', entity: v });

			if (!!v.options)
				v.options.forEach((o) => {
					if (!o.attributeLabel || o.attributeLabel !== optionLabel)
						variantsWithoutAttrLabel.push({ type: 'option', entity: o });
				});
		});

		return variantsWithoutAttrLabel;
	}

	// returns, if exist, ["variant label", "option label"]
	public static getAttrsLabeslInfo(product: ProductModel): [string, string] {
		const variationsLabelMetafield = (product.metafields || []).find(
			(meta) =>
				meta.namespace === PRODUCT_METAFIELDS_NAMESPACES.ATTRIBUTES_LABELS &&
				meta.key === PRODUCT_METAFIELDS_KEYS.VARIATIONS_LABEL
		);
		const optionsLabelMetafield = (product.metafields || []).find(
			(meta) =>
				meta.namespace === PRODUCT_METAFIELDS_NAMESPACES.ATTRIBUTES_LABELS &&
				meta.key === PRODUCT_METAFIELDS_KEYS.OPTIONS_LABEL
		);
		return [
			variationsLabelMetafield ? variationsLabelMetafield.value : null,
			optionsLabelMetafield ? optionsLabelMetafield.value : null
		];
	}
}

export class ProductVariantModel extends GQLProductVariant {
	static getFirstOption(productVariant: ProductVariantModel) {
		return (
			(!!objectPathExists(productVariant, 'options.length', true) &&
				productVariant.options[0]) ||
			null
		);
	}

	// UI ::: return [price, compareAt]
	static getPricing(originalPrice: string, salePrice: string): [number, number] {
		if (!isDefined(originalPrice))
			// if does not exist original price but exist sale price, sale price is ignored.
			return [null, null];

		// Just price defined -> Product Current Price:originalPrice, CompareAt: null
		if (!isDefined(salePrice)) return [Number(originalPrice), null];

		// Both defined -> Product Current Price: salePrice, CompareAt: originalPrice
		return [Number(salePrice), Number(originalPrice)];
	}

	// UI  ::: return originalPrice salePrice
	static translatePricing(variant: ProductVariantModel) {
		const price = objectPathExists(variant, 'pricing.price', true, null);
		const compareAt = objectPathExists(
			variant,
			'pricing.compareAtPrice.amount',
			true,
			null
		);

		if (!isDefined(price)) return [null, null];
		if (!isDefined(compareAt)) return [price, null];

		return [compareAt, price];
	}
}
