import { TranslationField } from './../../_base/crud/models/translation-field.model';
import { BaseModel } from './../../_base/crud/models/_base.model';

export const DIGITAL_FEATURE_ID = 3;

export class Feature extends BaseModel {
	name: string | TranslationField;
	description?: string | TranslationField;
	icon?: string;
	order?: number;
	active: boolean;

	clear() {
		this.id = null;
		this.name = '';
		this.description = '';
		this.icon = '';
		this.order = null;
		this.active = true;
	}
}
