import { MediaFile } from './../../../../../core/_base/crud/models/media-file.model';
import { NotificationModel } from './../../../../../core/point-of-interest/_models/notification.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kt-active-notification-widget',
  templateUrl: './active-notification-widget.component.html',
  styleUrls: ['./active-notification-widget.component.scss']
})
export class ActiveNotificationWidgetComponent implements OnInit {

	// Public properties
	@Input() title: string;
	@Input() notification: NotificationModel;
	@Output() deleteNotification = new EventEmitter<NotificationModel>();

	constructor() { }

	ngOnInit() {
	}

	get notificationValidString() {
		return this.notification ? NotificationModel.validToString(this.notification) : '';
	}
	get notificationImagePath() {
		return this.notification.image ?  MediaFile.getFilePath(this.notification.image) : './assets/media/bg/no-image.png';
	}

	/**
	 * Delete notification
	 *
	 * @param item: NotificationModel
	 */
	deleteActiveNotification(item: NotificationModel) {
		this.deleteNotification.emit(item);
	}
}
