import gql from 'graphql-tag';

const updateFlatRateFulfillmentMethodMutation = gql`
	mutation updateFlatRateFulfillmentMethod(
		$input: UpdateFlatRateFulfillmentMethodInput!
	) {
		updateFlatRateFulfillmentMethod(input: $input) {
			method {
				name
			}
		}
	}
`;

export default updateFlatRateFulfillmentMethodMutation;
