// NGRX
import { createFeatureSelector } from '@ngrx/store';
import {
	EntityState,
	EntityAdapter,
	createEntityAdapter,
	Update
} from '@ngrx/entity';
// Actions
import {
	NotificationActions,
	NotificationActionTypes
} from '../_actions/notification.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { NotificationModel } from '../_models/notification.model';

export interface NotificationsState extends EntityState<NotificationModel> {
	listLoading: boolean;
	actionsloading: boolean;
	totalCount: number;
	lastQuery: QueryParamsModel;
	lastCreatedNotificationId: number;
	showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<NotificationModel> = createEntityAdapter<
	NotificationModel
>();

export const initialNotificationsState: NotificationsState = adapter.getInitialState(
	{
		listLoading: false,
		actionsloading: false,
		totalCount: 0,
		lastQuery: new QueryParamsModel({}),
		lastCreatedNotificationId: undefined,
		showInitWaitingMessage: true
	}
);

export function notificationsReducer(
	state = initialNotificationsState,
	action: NotificationActions
): NotificationsState {
	switch (action.type) {
		case NotificationActionTypes.NotificationsPageToggleLoading:
			return {
				...state,
				listLoading: action.payload.isLoading,
				lastCreatedNotificationId: undefined
			};
		case NotificationActionTypes.NotificationsActionToggleLoading:
			return {
				...state,
				actionsloading: action.payload.isLoading
			};
		case NotificationActionTypes.NotificationOnServerCreated:
			return {
				...state
			};
		case NotificationActionTypes.NotificationCreated:
			return adapter.addOne(action.payload.notification, {
				...state,
				lastCreatedNotificationId: action.payload.notification.id
			});
		case NotificationActionTypes.NotificationUpdated:
			return adapter.updateOne(action.payload.partialNotification, state);
		case NotificationActionTypes.NotificationsStateUpdated: {
			const _partialNotifications: Update<NotificationModel>[] = [];
			for (let i = 0; i < action.payload.notifications.length; i++) {
				_partialNotifications.push({
					id: action.payload.notifications[i].id,
					changes: {
						state: action.payload.state
					}
				});
			}
			return adapter.updateMany(_partialNotifications, state);
		}
		case NotificationActionTypes.OneNotificationDeleted:
			return adapter.removeOne(action.payload.id, state);
		case NotificationActionTypes.ManyNotificationsDeleted:
			return adapter.removeMany(action.payload.ids, state);
		case NotificationActionTypes.NotificationsPageCancelled:
			return {
				...state,
				listLoading: false,
				lastQuery: new QueryParamsModel({})
			};
		case NotificationActionTypes.NotificationsPageLoaded:
			return adapter.addMany(action.payload.notifications, {
				...initialNotificationsState,
				totalCount: action.payload.totalCount,
				listLoading: false,
				lastQuery: action.payload.page,
				showInitWaitingMessage: false
			});
		default:
			return state;
	}
}

export const getNotificationState = createFeatureSelector<NotificationModel>(
	'notifications'
);

export const {
	selectAll,
	selectEntities,
	selectIds,
	selectTotal
} = adapter.getSelectors();
