import { TranslateService } from '@ngx-translate/core';
// Angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';

@Component({
	selector: 'kt-widget15',
	templateUrl: './widget15.component.html',
	styleUrls: ['./widget15.component.scss'],
})
export class Widget15Component implements OnInit {
	_data: { labels: string[]; datasets: any[] } = null;
	// Public properties
	@Input() title: string;
	@Input() desc: string;
	@Input() set data( d: { labels: string[]; datasets: any[] }) {
		this._data = d;
		this.initChartJS();
	}
	@ViewChild('chart', {static: true}) chart: ElementRef;
	@ViewChild('legends', {static: true}) legends: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
		private translate: TranslateService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (!this._data) {
			this._data = {
				datasets: [{
					data: [10, 20, 30],
					backgroundColor: [
						this.layoutConfigService.getConfig('colors.state.success'),
						this.layoutConfigService.getConfig('colors.state.primary'),
						this.layoutConfigService.getConfig('colors.state.warning'),
					]
				}],
				// These labels appear in the legend and in the tooltips when hovering different arcs
				labels: [
					this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.OPENED'),
					this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.SAW'),
					this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.RECEIVED')
				]
			};
		}

		this.initChartJS();
	}

	/** Init chart */
	initChartJS() {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html
		const that = this;
		const chart = new Chart(this.chart.nativeElement, {
			type: 'doughnut',
			data: this._data,
			options: {
				cutoutPercentage: 70,
				title: {
					display: false,
				},
				tooltips: {
					intersect: false,
					mode: 'nearest',
					xPadding: 10,
					yPadding: 10,
					caretPadding: 10
				},
				legendCallback: function(chart) {
					const legendHtml = [];
					const item = chart.data.datasets[0];
					for (let i = 0; i < item.data.length; i++) {
						legendHtml.push('<div class="kt-widget14__legend">');
						legendHtml.push('<span class="kt-widget14__bullet" style="background-color:' + item.backgroundColor[i] + '"></span>');
						legendHtml.push('<span class="kt-widget14__stats">' + item.data[i] +' '+ that.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.PERSONS') +' - ' + chart.data.labels[i] + '</span>');
						legendHtml.push('</div>');
					}
					return legendHtml.join('');
				},
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: false,
				layout: {
					padding: {
						left: 10,
						right: 10,
						top: 10,
						bottom: 10
					}
				}
			}
		});
		this.legends.nativeElement.insertAdjacentHTML('beforeend', chart.generateLegend()); chart.generateLegend();
	}
}
