import { Apollo } from 'apollo-angular';
import { environment } from './../../../../environments/environment';
import { PoiECommerceInfoModel } from './../_models/point-of-interest.model';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// CRUD
import { of } from 'rxjs';
import updateShopInfoMutation from '../../graphql/e-commerce/mutations/updateShopInfo';

const API_POINT_OF_INTEREST_E_COMMERCE_URL = `${environment.apiEndpoint}/e-commerce/shops`;

@Injectable({
	providedIn: 'root'
})
export class ECommerceService {
	constructor(private http: HttpClient, private apollo: Apollo) {}

	getEcommerceInfoOfPOI(pointOfInterestId: number): Promise<PoiECommerceInfoModel> {
		if (!pointOfInterestId) {
			return Promise.reject();
		}
		return this.http
			.get<PoiECommerceInfoModel>(
				`${API_POINT_OF_INTEREST_E_COMMERCE_URL}/${pointOfInterestId}`
			)
			.toPromise()
			.then((res) => res)
			.catch(() => null);
	}

	updateShopInfo(
		shopId: string,
		name: string,
		description: string = '',
		email: string
	) {
		return this.apollo.mutate({
			mutation: updateShopInfoMutation,
			variables: {
				input: {
					allowGuestCheckout: null,
					description,
					emails: [{ address: email }],
					name,
					shopId,
					slug: name && name.toLowerCase()
				}
			}
		});
	}
}
