/* tslint:disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A string email address */
  Email: any;
  /**
   * An opaque string that identifies a particular result within a connection,
   * allowing you to request a subset of results before or after that result.
   */
  ConnectionCursor: any;
  /**
   * An integer between 1 and 50, inclusive. Values less than 1 become 1 and
   * values greater than 50 become 50.
   */
  ConnectionLimitInt: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** An object with any fields */
  JSONObject: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
};



/** Queries return all requested data, without any side effects */
export class GQLQuery {
  __typename?: 'Query';
  /** A test query */
  ping: Scalars['String'];
  /** Returns the primary shop for the domain */
  primaryShop?: Maybe<GQLShop>;
  /** Returns the ID of the primary shop for the domain */
  primaryShopId?: Maybe<Scalars['ID']>;
  /** Returns a shop by ID */
  shop?: Maybe<GQLShop>;
  /** Returns a shop by slug */
  shopBySlug?: Maybe<GQLShop>;
  /**
   * Returns app settings that are not shop specific. Plugins extend the GlobalSettings type to support
   * whatever settings they need.
   */
  globalSettings: GQLGlobalSettings;
  /**
   * Returns app settings for a specific shop. Plugins extend the ShopSettings type to support
   * whatever settings they need.
   */
  shopSettings: GQLShopSettings;
  /**
   * Get a list of errors and suggested properly formatted addresses for an address. If no address
   * validation service is active for the shop, this will return as if the address is valid even
   * though no check actually occurred.
   */
  addressValidation: GQLAddressValidationResults;
  /** Get a full list of all registered address validation services */
  addressValidationServices: Array<Maybe<GQLAddressValidationService>>;
  /** Returns a list of defined address validation rules for a shop */
  addressValidationRules: GQLAddressValidationRuleConnection;
  /** SystemInformation object */
  systemInformation: GQLSystemInformation;
  /** Retrieves a list of email templates */
  emailTemplates?: Maybe<GQLTemplateConnection>;
  /** Returns the account with the provided ID */
  account?: Maybe<GQLAccount>;
  /** Returns accounts optionally filtered by account groups */
  accounts: GQLAccountConnection;
  /** Returns the account for the authenticated user */
  viewer?: Maybe<GQLAccount>;
  /** Returns a single group by ID. */
  group?: Maybe<GQLGroup>;
  /** Returns a list of groups for the shop with ID `shopId`, as a Relay-compatible connection. */
  groups?: Maybe<GQLGroupConnection>;
  /** Returns a paged list of all roles associated with a shop */
  roles?: Maybe<GQLRoleConnection>;
  /** Query for a single Product */
  product?: Maybe<GQLProduct>;
  /** Query for a list of Products */
  products?: Maybe<GQLProductConnection>;
  /** Gets items from a shop catalog */
  catalogItems?: Maybe<GQLCatalogItemConnection>;
  /** Gets product from catalog */
  catalogItemProduct?: Maybe<GQLCatalogItemProduct>;
  /** Returns a list of product in a tag */
  productsByTagId: GQLTagProductConnection;
  /** Returns a tag from a provided tag ID or slug. Tags with isVisible set to false are excluded by default. */
  tag?: Maybe<GQLTag>;
  /** Returns a paged list of tags for a shop. You must include a shopId when querying. */
  tags?: Maybe<GQLTagConnection>;
  /**
   * Get the SimpleInventory info for a product configuration. Returns `null` if `updateSimpleInventory`
   * has never been called for this product configuration.
   */
  simpleInventory?: Maybe<GQLSimpleInventoryInfo>;
  /** Finds a cart by the cart ID and anonymous cart token. */
  anonymousCartByCartId?: Maybe<GQLCart>;
  /** Find a cart for a given account ID. */
  accountCartByAccountId?: Maybe<GQLCart>;
  /** Get an order by its ID */
  orderById?: Maybe<GQLOrder>;
  /** Get all orders for a single account, optionally limited to certain shop IDs and certain orderStatus */
  orders: GQLOrderConnection;
  /** Get all orders for a single account, optionally limited to certain shop IDs and certain orderStatus */
  ordersByAccountId: GQLOrdersByAccountIdConnection;
  /** Get an order by its reference ID (the ID shown to customers) */
  orderByReferenceId?: Maybe<GQLOrder>;
  /** Get refunds applied to an order by order ID */
  refunds?: Maybe<Array<Maybe<GQLRefund>>>;
  /** Get refunds applied to a specific payment by payment ID */
  refundsByPaymentId?: Maybe<Array<Maybe<GQLRefund>>>;
  /**
   * Get a list of all payment methods available during a checkout. This may filter by auth,
   * active/inactive, IP/region, shop, etc. To get the full list, use the `paymentMethods`
   * query with proper authorization.
   */
  availablePaymentMethods: Array<Maybe<GQLPaymentMethod>>;
  /** Get a full list of all payment methods */
  paymentMethods: Array<Maybe<GQLPaymentMethod>>;
  /** Gets discount codes */
  discountCodes?: Maybe<GQLDiscountCodeConnection>;
  /** Get the full list of surcharges. */
  surcharges: GQLSurchargeConnection;
  /** Get a single surcharge definition by its ID */
  surchargeById?: Maybe<GQLSurcharge>;
  /** Get a flat rate fulfillment method */
  flatRateFulfillmentMethod: GQLFlatRateFulfillmentMethod;
  /** Get a flat rate fulfillment methods */
  flatRateFulfillmentMethods: GQLFlatRateFulfillmentMethodConnection;
  /** Get the full list of flat rate fulfillment method restrictions. */
  getFlatRateFulfillmentRestrictions: GQLFlatRateFulfillmentRestrictionConnection;
  /** Get a single flat rate fulfillment method restriction. */
  getFlatRateFulfillmentRestriction?: Maybe<GQLFlatRateFulfillmentRestriction>;
  /** List all tax codes supported by the current active tax service for the shop */
  taxCodes: Array<Maybe<GQLTaxCode>>;
  /** Get a full list of all tax services for the shop */
  taxServices: Array<Maybe<GQLTaxService>>;
  /** Gets tax rates */
  taxRates?: Maybe<GQLTaxRateConnection>;
  /** Returns a navigation tree by its ID in the specified language */
  navigationTreeById?: Maybe<GQLNavigationTree>;
  /** Returns the navigation items for a shop */
  navigationItemsByShopId?: Maybe<GQLNavigationItemConnection>;
  /** Returns Sitemap object for a shop based on the handle param */
  sitemap?: Maybe<GQLSitemap>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryShopArgs = {
  id: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryShopBySlugArgs = {
  slug: Scalars['String'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryShopSettingsArgs = {
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryAddressValidationArgs = {
  address: GQLAddressInput;
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryAddressValidationRulesArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
  serviceNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  shopId: Scalars['ID'];
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<AddressValidationRuleSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQuerySystemInformationArgs = {
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryEmailTemplatesArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryAccountArgs = {
  id: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryAccountsArgs = {
  groupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<AccountSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryGroupArgs = {
  id: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryGroupsArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<GroupSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryRolesArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<RoleSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryProductArgs = {
  productId: Scalars['ID'];
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryProductsArgs = {
  isArchived?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  metafieldKey?: Maybe<Scalars['String']>;
  metafieldValue?: Maybe<Scalars['String']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  productIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  query?: Maybe<Scalars['String']>;
  shopIds: Array<Maybe<Scalars['ID']>>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<ProductSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryCatalogItemsArgs = {
  shopIds: Array<Maybe<Scalars['ID']>>;
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  booleanFilters?: Maybe<Array<Maybe<GQLCatalogBooleanFilter>>>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  sortByPriceCurrencyCode?: Maybe<Scalars['String']>;
  sortBy?: Maybe<CatalogItemSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryCatalogItemProductArgs = {
  shopId?: Maybe<Scalars['ID']>;
  slugOrId?: Maybe<Scalars['String']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryProductsByTagIdArgs = {
  shopId: Scalars['ID'];
  tagId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryTagArgs = {
  slugOrId: Scalars['String'];
  shopId: Scalars['ID'];
  shouldIncludeInvisible?: Maybe<Scalars['Boolean']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryTagsArgs = {
  shopId: Scalars['ID'];
  filter?: Maybe<Scalars['String']>;
  excludedTagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isTopLevel?: Maybe<Scalars['Boolean']>;
  shouldIncludeDeleted?: Maybe<Scalars['Boolean']>;
  shouldIncludeInvisible?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<TagSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQuerySimpleInventoryArgs = {
  shopId: Scalars['ID'];
  productConfiguration: GQLProductConfigurationInput;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryAnonymousCartByCartIdArgs = {
  cartId: Scalars['ID'];
  cartToken: Scalars['String'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryAccountCartByAccountIdArgs = {
  accountId: Scalars['ID'];
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryOrderByIdArgs = {
  id: Scalars['ID'];
  shopId: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryOrdersArgs = {
  filters?: Maybe<GQLOrderFilterInput>;
  shopIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<OrdersSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryOrdersByAccountIdArgs = {
  accountId: Scalars['ID'];
  orderStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  shopIds: Array<Maybe<Scalars['ID']>>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<OrdersByAccountIdSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryOrderByReferenceIdArgs = {
  id: Scalars['ID'];
  shopId: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryRefundsArgs = {
  orderId: Scalars['ID'];
  shopId: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryRefundsByPaymentIdArgs = {
  orderId: Scalars['ID'];
  paymentId: Scalars['ID'];
  shopId: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryAvailablePaymentMethodsArgs = {
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryPaymentMethodsArgs = {
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryDiscountCodesArgs = {
  shopId: Scalars['ID'];
  filters?: Maybe<GQLDiscountCodeFilterInput>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQuerySurchargesArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<SurchargeSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQuerySurchargeByIdArgs = {
  shopId: Scalars['ID'];
  surchargeId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryFlatRateFulfillmentMethodArgs = {
  methodId: Scalars['ID'];
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryFlatRateFulfillmentMethodsArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryGetFlatRateFulfillmentRestrictionsArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<FlatRateFulfillmentRestrictionSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryGetFlatRateFulfillmentRestrictionArgs = {
  restrictionId: Scalars['ID'];
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryTaxCodesArgs = {
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryTaxServicesArgs = {
  shopId: Scalars['ID'];
};


/** Queries return all requested data, without any side effects */
export type GQLQueryTaxRatesArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryNavigationTreeByIdArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  shopId: Scalars['ID'];
  shouldIncludeSecondary?: Maybe<Scalars['Boolean']>;
};


/** Queries return all requested data, without any side effects */
export type GQLQueryNavigationItemsByShopIdArgs = {
  shopId: Scalars['ID'];
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<NavigationItemSortByField>;
};


/** Queries return all requested data, without any side effects */
export type GQLQuerySitemapArgs = {
  handle: Scalars['String'];
  shopUrl: Scalars['String'];
};

/** Represents a Reaction shop */
export class GQLShop  implements GQLNode {
  __typename?: 'Shop';
  /** The shop ID */
  _id: Scalars['ID'];
  /** An the shop's default address */
  addressBook?: Maybe<Array<Maybe<GQLAddress>>>;
  /** Whether to allow user to checkout without creating an account */
  allowGuestCheckout?: Maybe<Scalars['Boolean']>;
  /** The base unit of length */
  baseUOL?: Maybe<Scalars['String']>;
  /** The base unit of Measure */
  baseUOM?: Maybe<Scalars['String']>;
  /** URLs for various shop assets in various sizes */
  brandAssets?: Maybe<GQLShopBrandAssets>;
  /** The default shop currency */
  currency: GQLCurrency;
  /** Default parcel size for this shop */
  defaultParcelSize?: Maybe<GQLShopParcelSize>;
  /** Shop description */
  description?: Maybe<Scalars['String']>;
  /** The shop's default email record */
  emails?: Maybe<Array<Maybe<GQLEmailRecord>>>;
  /** Shop's keywords */
  keywords?: Maybe<Scalars['String']>;
  /** Shop default language */
  language: Scalars['String'];
  /** Shop name */
  name: Scalars['String'];
  /** Returns URLs for shop logos */
  shopLogoUrls?: Maybe<GQLShopLogoUrls>;
  /** Shop's slug */
  slug?: Maybe<Scalars['String']>;
  /** Returns URLs for various storefront routes */
  storefrontUrls?: Maybe<GQLStorefrontUrls>;
  /** Shop default timezone */
  timezone?: Maybe<Scalars['String']>;
  /** The shop's units of length */
  unitsOfLength?: Maybe<Array<Maybe<GQLUnitOfLength>>>;
  /** The shop's units of measure */
  unitsOfMeasure?: Maybe<Array<Maybe<GQLUnitOfMeasure>>>;
  /** Returns a list of groups for this shop, as a Relay-compatible connection. */
  groups?: Maybe<GQLGroupConnection>;
  /** Returns a list of roles for this shop, as a Relay-compatible connection. */
  roles?: Maybe<GQLRoleConnection>;
  /** Returns a paged list of tags for this shop */
  tags?: Maybe<GQLTagConnection>;
  /** The default navigation tree for this shop */
  defaultNavigationTree?: Maybe<GQLNavigationTree>;
  /** The ID of the shop's default navigation tree */
  defaultNavigationTreeId?: Maybe<Scalars['String']>;
};


/** Represents a Reaction shop */
export type GQLShopGroupsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<GroupSortByField>;
};


/** Represents a Reaction shop */
export type GQLShopRolesArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<RoleSortByField>;
};


/** Represents a Reaction shop */
export type GQLShopTagsArgs = {
  isTopLevel?: Maybe<Scalars['Boolean']>;
  shouldIncludeDeleted?: Maybe<Scalars['Boolean']>;
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<TagSortByField>;
};


/** Represents a Reaction shop */
export type GQLShopDefaultNavigationTreeArgs = {
  language: Scalars['String'];
  shouldIncludeSecondary?: Maybe<Scalars['Boolean']>;
};

/** Objects implementing the Node interface will always have an _id field that is globally unique. */
export type GQLNode = {
  /** The ID of the object */
  _id: Scalars['ID'];
};

/** Represents a physical or mailing address somewhere on Earth */
export class GQLAddress {
  __typename?: 'Address';
  /** The address ID */
  _id?: Maybe<Scalars['ID']>;
  /** The street address / first line */
  address1: Scalars['String'];
  /** Optional second line */
  address2?: Maybe<Scalars['String']>;
  /** City */
  city: Scalars['String'];
  /** Optional company name, if it's a business address */
  company?: Maybe<Scalars['String']>;
  /** Country */
  country: Scalars['String'];
  /**
   * The first name of a person at this address
   * This is an optional field to support legacy and third party platforms
   * We use fullName internally, and use first and last name fields to combine into a full name if needed
   */
  firstName?: Maybe<Scalars['String']>;
  /** The full name of a person at this address */
  fullName: Scalars['String'];
  /** Is this the default address for billing purposes? */
  isBillingDefault?: Maybe<Scalars['Boolean']>;
  /** Is this a commercial address? */
  isCommercial: Scalars['Boolean'];
  /** Is this the default address to use when selecting a shipping address at checkout? */
  isShippingDefault?: Maybe<Scalars['Boolean']>;
  /**
   * The last name of a person at this address
   * This is an optional field to support legacy and third party platforms
   * We use fullName internally, and use first and last name fields to combine into a full name if needed
   */
  lastName?: Maybe<Scalars['String']>;
  /** Arbitrary additional metadata about this address */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** A phone number for someone at this address */
  phone: Scalars['String'];
  /** Postal code */
  postal: Scalars['String'];
  /** Region. For example, a U.S. state */
  region: Scalars['String'];
};

/** User defined attributes */
export class GQLMetafield {
  __typename?: 'Metafield';
  /** Field description */
  description?: Maybe<Scalars['String']>;
  /** Field key */
  key?: Maybe<Scalars['String']>;
  /** Field namespace */
  namespace?: Maybe<Scalars['String']>;
  /** Field scope */
  scope?: Maybe<Scalars['String']>;
  /** Field value */
  value?: Maybe<Scalars['String']>;
  /** Field value type */
  valueType?: Maybe<Scalars['String']>;
};

/** URLs for various shop assets in various sizes */
export class GQLShopBrandAssets {
  __typename?: 'ShopBrandAssets';
  /** URLs for the navigation bar brand logo image */
  navbarBrandImage?: Maybe<GQLImageSizes>;
  /** Internal navigation bar brand logo image ID */
  navbarBrandImageId?: Maybe<Scalars['String']>;
};

/** A list of URLs for various sizes of an image */
export class GQLImageSizes {
  __typename?: 'ImageSizes';
  /** Use this URL to get a large resolution file for this image */
  large?: Maybe<Scalars['String']>;
  /** Use this URL to get a medium resolution file for this image */
  medium?: Maybe<Scalars['String']>;
  /**
   * Use this URL to get this image with its original resolution as uploaded. This may not be
   * the true original size if there is a hard cap on how big image files can be.
   */
  original?: Maybe<Scalars['String']>;
  /** Use this URL to get a small resolution file for this image */
  small?: Maybe<Scalars['String']>;
  /** Use this URL to get a thumbnail resolution file for this image */
  thumbnail?: Maybe<Scalars['String']>;
};

/** Represents one type of currency */
export class GQLCurrency  implements GQLNode {
  __typename?: 'Currency';
  /** ID */
  _id: Scalars['ID'];
  /** Currency code */
  code: Scalars['String'];
  /** Decimal symbol */
  decimal?: Maybe<Scalars['String']>;
  /** Format string */
  format: Scalars['String'];
  /** Exchange rate from shop default currency, if known */
  rate?: Maybe<Scalars['Float']>;
  /** The decimal scale used by this currency */
  scale?: Maybe<Scalars['Int']>;
  /** Currency symbol */
  symbol: Scalars['String'];
  /** Thousands separator symbol */
  thousand?: Maybe<Scalars['String']>;
};

/** Parcel size */
export class GQLShopParcelSize {
  __typename?: 'ShopParcelSize';
  /** Parcel height */
  height?: Maybe<Scalars['Float']>;
  /** Parcel length */
  length?: Maybe<Scalars['Float']>;
  /** Parcel weight */
  weight?: Maybe<Scalars['Float']>;
  /** Parcel width */
  width?: Maybe<Scalars['Float']>;
};

/** A confirmable email record */
export class GQLEmailRecord {
  __typename?: 'EmailRecord';
  /** The actual email address */
  address?: Maybe<Scalars['Email']>;
  /** The services provided by this address */
  provides?: Maybe<Scalars['String']>;
  /** Has this address been verified? */
  verified?: Maybe<Scalars['Boolean']>;
};


/** Shop logo URLs */
export class GQLShopLogoUrls {
  __typename?: 'ShopLogoUrls';
  /** The primary logo URL for this shop. Setting this overrides any uploaded logo. */
  primaryShopLogoUrl?: Maybe<Scalars['String']>;
};

/** Storefront route URLs */
export class GQLStorefrontUrls {
  __typename?: 'StorefrontUrls';
  /** Storefront Account Profile URL (can include `:accountId` in string) */
  storefrontAccountProfileUrl?: Maybe<Scalars['String']>;
  /** Storefront Home URL */
  storefrontHomeUrl?: Maybe<Scalars['String']>;
  /** Storefront login URL */
  storefrontLoginUrl?: Maybe<Scalars['String']>;
  /** Storefront single order URL (can include `:orderReferenceId` and `:orderToken` in string) */
  storefrontOrderUrl?: Maybe<Scalars['String']>;
  /** Storefront orders URL (can include `:accountId` in string) */
  storefrontOrdersUrl?: Maybe<Scalars['String']>;
};

/** Units of length */
export class GQLUnitOfLength {
  __typename?: 'UnitOfLength';
  /** Whether this unit of length is the default */
  default?: Maybe<Scalars['Boolean']>;
  /** The name of the unit of length */
  label?: Maybe<Scalars['String']>;
  /** Unit of length */
  uol?: Maybe<Scalars['String']>;
};

/** Units of measure */
export class GQLUnitOfMeasure {
  __typename?: 'UnitOfMeasure';
  /** Whether this unit of measure is the default */
  default?: Maybe<Scalars['Boolean']>;
  /** The name of the unit of measure */
  label?: Maybe<Scalars['String']>;
  /** Unit of measure */
  uom?: Maybe<Scalars['String']>;
};



/** The order in which the connection results should be sorted, based on the sortBy field. */
export enum SortOrder {
  /** ascending */
  Asc = 'asc',
  /** descending */
  Desc = 'desc'
}

/** The fields by which you are allowed to sort any query that returns an `GroupConnection` */
export enum GroupSortByField {
  /** Group ID */
  Id = '_id',
  /** Date and time at which this group was created */
  CreatedAt = 'createdAt',
  /** Group name */
  Name = 'name',
  /** Date and time at which this group was last updated */
  UpdatedAt = 'updatedAt'
}

/**
 * Wraps a list of `Groups`, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLGroupConnection {
  __typename?: 'GroupConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLGroupEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLGroup>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Group` object */
export class GQLGroupEdge  implements GQLNodeEdge {
  __typename?: 'GroupEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The group */
  node?: Maybe<GQLGroup>;
};

/**
 * Objects implementing the NodeEdge interface will always have a node and a cursor
 * that represents that node for purposes of requesting paginated results.
 */
export type GQLNodeEdge = {
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The node itself */
  node?: Maybe<GQLNode>;
};

/** Represents an account group */
export class GQLGroup  implements GQLNode {
  __typename?: 'Group';
  /** The group ID */
  _id: Scalars['ID'];
  /** The date and time at which this group was created */
  createdAt: Scalars['DateTime'];
  /** The account that created this group */
  createdBy?: Maybe<GQLAccount>;
  /** A free text description of this group */
  description?: Maybe<Scalars['String']>;
  /** A unique name for the group */
  name: Scalars['String'];
  /** The shop to which this group belongs */
  shop?: Maybe<GQLShop>;
  /** A unique URL-safe string representing this group */
  slug: Scalars['String'];
  /** The date and time at which this group was last updated */
  updatedAt: Scalars['DateTime'];
  /** A list of the account permissions implied by membership in this group */
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Represents a single user account */
export class GQLAccount  implements GQLNode {
  __typename?: 'Account';
  /** The account ID */
  _id: Scalars['ID'];
  /** A list of physical or mailing addresses associated with this account */
  addressBook?: Maybe<GQLAddressConnection>;
  /** Bio to display on profile */
  bio?: Maybe<Scalars['String']>;
  /** The date and time at which this account was created */
  createdAt: Scalars['DateTime'];
  /** The preferred currency used by this account */
  currency?: Maybe<GQLCurrency>;
  /** A list of email records associated with this account */
  emailRecords?: Maybe<Array<Maybe<GQLEmailRecord>>>;
  /** The first name of the person this account represents, if known */
  firstName?: Maybe<Scalars['String']>;
  /** The preferred language used by this account */
  language?: Maybe<Scalars['String']>;
  /** The last name of the person this account represents, if known */
  lastName?: Maybe<Scalars['String']>;
  /** Arbitrary additional metadata about this account */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** The full name of the person this account represents, if known */
  name?: Maybe<Scalars['String']>;
  /** Some note about this account */
  note?: Maybe<Scalars['String']>;
  /** URL of picture to display on profile */
  picture?: Maybe<Scalars['String']>;
  /** An object storing plugin-specific preferences for this account */
  preferences?: Maybe<Scalars['JSONObject']>;
  /** The primary email address for the account. This matches the address in `emailRecords` where `provides` is `default`. */
  primaryEmailAddress: Scalars['Email'];
  /** The date and time at which this account was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The Identity user ID with which this account is associated */
  userId: Scalars['String'];
  /** Username */
  username?: Maybe<Scalars['String']>;
  /** A paged list of the account groups in which this account is listed */
  groups?: Maybe<GQLGroupConnection>;
};


/** Represents a single user account */
export type GQLAccountAddressBookArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
};


/** Represents a single user account */
export type GQLAccountGroupsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<GroupSortByField>;
};

/**
 * Wraps a list of `Addresses`, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLAddressConnection {
  __typename?: 'AddressConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLAddressEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLAddress>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is an `Address` object */
export class GQLAddressEdge {
  __typename?: 'AddressEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The address */
  node?: Maybe<GQLAddress>;
};

/**
 * Pagination information. When requesting pages of results, you can use endCursor or startCursor
 * as your before or after parameters for the query you are paging.
 */
export class GQLPageInfo {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};


/** The fields by which you are allowed to sort any query that returns an `RoleConnection` */
export enum RoleSortByField {
  /** Role ID */
  Id = '_id',
  /** Role name */
  Name = 'name'
}

/**
 * Wraps a list of `Roles`, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLRoleConnection {
  __typename?: 'RoleConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLRoleEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLRole>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Role` object */
export class GQLRoleEdge  implements GQLNodeEdge {
  __typename?: 'RoleEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The role */
  node?: Maybe<GQLRole>;
};

/** Represents a named role */
export class GQLRole  implements GQLNode {
  __typename?: 'Role';
  /** The role ID */
  _id: Scalars['ID'];
  /** A unique name for the role */
  name: Scalars['String'];
};

/** The fields by which you are allowed to sort any query that returns a `TagConnection` */
export enum TagSortByField {
  /** Tag ID */
  Id = '_id',
  /** Date and time the tag was created */
  CreatedAt = 'createdAt',
  /** Tag name */
  Name = 'name',
  /** Tag position */
  Position = 'position',
  /** Date and time the tag was last updated */
  UpdatedAt = 'updatedAt'
}

/**
 * Wraps a list of `Tags`, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLTagConnection {
  __typename?: 'TagConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLTagEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLTag>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Tag` object */
export class GQLTagEdge  implements GQLNodeEdge {
  __typename?: 'TagEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The tag */
  node?: Maybe<GQLTag>;
};

/** Represents a single tag */
export class GQLTag  implements GQLNode, GQLDeletable {
  __typename?: 'Tag';
  /** The tag ID */
  _id: Scalars['ID'];
  /** The date and time at which this tag was created */
  createdAt: Scalars['DateTime'];
  /** A string of the title to be displayed on a Tag Listing Page */
  displayTitle?: Maybe<Scalars['String']>;
  /** A list of the IDs of top products in this tag */
  featuredProductIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** A string containing the hero image url for a Tag Listing Page */
  heroMediaUrl?: Maybe<Scalars['String']>;
  /**
   * If `true`, this object should be considered deleted. Soft deleted objects are not
   * returned in query results unless you explicitly ask for them.
   */
  isDeleted: Scalars['Boolean'];
  /** If `true`, this tag should be shown at the top level of the tag hierarchy */
  isTopLevel: Scalars['Boolean'];
  /** If `true`, this tag's Tag Listing Page should be visible to the public */
  isVisible: Scalars['Boolean'];
  /** Arbitrary additional metadata about this tag */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** The display name for the tag. This is unique within a given shop. */
  name: Scalars['String'];
  /** The tag's position relative to other tags at the same level of the tag hierarchy */
  position?: Maybe<Scalars['Int']>;
  /** The shop to which this tag belongs */
  shop: GQLShop;
  /** A unique URL-safe string representing this tag for links */
  slug?: Maybe<Scalars['String']>;
  /** A list of the IDs of tags that have this tag as their parent in the tag hierarchy, in the user-defined order */
  subTagIds: Array<Maybe<Scalars['ID']>>;
  /** The date and time at which this tag was last updated */
  updatedAt: Scalars['DateTime'];
  /** A paged list of tags that have this tag as their parent in the tag hierarchy. Currently only three levels are supported. */
  subTags?: Maybe<GQLTagConnection>;
};


/** Represents a single tag */
export type GQLTagSubTagsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<TagSortByField>;
};

/** Objects implementing the Deletable support soft deletion */
export type GQLDeletable = {
  /**
   * If true, this object should be considered deleted. Soft deleted objects are not
   * returned in query results unless you explicitly ask for them.
   */
  isDeleted: Scalars['Boolean'];
};

/** Represents a navigation tree containing multiple levels of navigation items */
export class GQLNavigationTree  implements GQLNode {
  __typename?: 'NavigationTree';
  /** The navigation tree ID */
  _id: Scalars['ID'];
  /** The draft navigation items that make up this tree */
  draftItems?: Maybe<Array<Maybe<GQLNavigationTreeItem>>>;
  /** Whether the navigation item has unpublished changes */
  hasUnpublishedChanges?: Maybe<Scalars['Boolean']>;
  /** The published navigation items that make up this tree */
  items?: Maybe<Array<Maybe<GQLNavigationTreeItem>>>;
  /** The name of the tree, for operator display purposes. Assumed to be in the primary shop's language */
  name: Scalars['String'];
  /** The ID of the shop this navigation tree belongs to */
  shopId: Scalars['ID'];
};

/** Represents a navigation item and its children in a tree */
export class GQLNavigationTreeItem {
  __typename?: 'NavigationTreeItem';
  /** Whether the navigation item should display its children */
  expanded?: Maybe<Scalars['Boolean']>;
  /** Whether the navigation item should be hidden from customers */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** Whether the navigaton item is a secondary navigation item */
  isSecondary?: Maybe<Scalars['Boolean']>;
  /** Whether the navigation ttem should shown in query results for customers and admins */
  isVisible?: Maybe<Scalars['Boolean']>;
  /** The child navigation items */
  items?: Maybe<Array<Maybe<GQLNavigationTreeItem>>>;
  /** The navigation item */
  navigationItem: GQLNavigationItem;
};

/** Represents a single navigation item */
export class GQLNavigationItem  implements GQLNode {
  __typename?: 'NavigationItem';
  /** The navigation item ID */
  _id: Scalars['ID'];
  /** The date and time at which this navigation item was created */
  createdAt: Scalars['DateTime'];
  /** The published data for this navigation item */
  data?: Maybe<GQLNavigationItemData>;
  /** The draft/unpublished data for this navigation item */
  draftData?: Maybe<GQLNavigationItemData>;
  /** Whether the navigation item has unpublished changes */
  hasUnpublishedChanges?: Maybe<Scalars['Boolean']>;
  /** An object storing additional metadata about the navigation item (such as its related tag) */
  metadata?: Maybe<Scalars['JSONObject']>;
  /** The ID of the shop the navigation item belongs to */
  shopId: Scalars['ID'];
};

/** Represents the data for a navigation item */
export class GQLNavigationItemData {
  __typename?: 'NavigationItemData';
  /** CSS class names to add to the menu item for display */
  classNames?: Maybe<Scalars['String']>;
  /** The content for the navigation item, in one or more languages */
  content?: Maybe<Array<Maybe<GQLNavigationItemContent>>>;
  /** The translated content for a navigation item */
  contentForLanguage?: Maybe<Scalars['String']>;
  /** Whether the provided URL is relative or external */
  isUrlRelative?: Maybe<Scalars['Boolean']>;
  /** Whether the navigation item should trigger a new tab/window to open when clicked */
  shouldOpenInNewWindow?: Maybe<Scalars['Boolean']>;
  /** The URL for the navigation item to link to */
  url?: Maybe<Scalars['String']>;
};

/** Represents the translated content for a navigation item */
export class GQLNavigationItemContent {
  __typename?: 'NavigationItemContent';
  /** The language of the piece of navigation content */
  language: Scalars['String'];
  /** The translated value, in plain text or markdown */
  value?: Maybe<Scalars['String']>;
};

/**
 * App settings that are not shop specific. Plugins extend the GlobalSettings type to support
 * whatever settings they need.
 */
export class GQLGlobalSettings {
  __typename?: 'GlobalSettings';
  /** A fake setting necessary until some plugin implements this with a real setting */
  doNotUse?: Maybe<Scalars['String']>;
};

/**
 * App settings for a specific shop. Plugins extend the ShopSettings type to support
 * whatever settings they need.
 */
export class GQLShopSettings {
  __typename?: 'ShopSettings';
  /** A fake setting necessary until some plugin implements this with a real setting */
  doNotUse?: Maybe<Scalars['String']>;
  /**
   * If there is no known inventory for a product configuration, this setting determines
   * whether that product configuration can be sold and should appear to be available.
   */
  canSellVariantWithoutInventory: Scalars['Boolean'];
  /**
   * If `false` no defined shipping rates will be used when fulfillment
   * quotes are requested for a cart or order. A quick way to disable the entire
   * `reaction-shipping-rates` plugin temporarily.
   */
  isShippingRatesFulfillmentEnabled?: Maybe<Scalars['Boolean']>;
  /** The default value to use for `taxCode` property of a product */
  defaultTaxCode?: Maybe<Scalars['String']>;
  /**
   * The name of the tax service to fall back to if the primary tax service is down.
   * This will match the `name` field of one of the services returned by the `taxServices`
   * query.
   */
  fallbackTaxServiceName?: Maybe<Scalars['String']>;
  /**
   * The name of the tax service to use for calculating taxes on carts and orders.
   * This will match the `name` field of one of the services returned by the `taxServices`
   * query.
   */
  primaryTaxServiceName?: Maybe<Scalars['String']>;
  /**
   * Whether a navigation item added to the navigation tree should be visible only to
   * admins by default.
   */
  shouldNavigationTreeItemsBeAdminOnly: Scalars['Boolean'];
  /**
   * Whether a navigation item added to the navigation tree should be
   * public API/Storefront visible by default.
   */
  shouldNavigationTreeItemsBePubliclyVisible: Scalars['Boolean'];
  /**
   * Whether a navigation item added to the navigation tree should be a secondary
   * navigation item by default.
   */
  shouldNavigationTreeItemsBeSecondaryNavOnly: Scalars['Boolean'];
  /** This setting controls how often the sitemaps for the shop will be rebuilt */
  sitemapRefreshPeriod: Scalars['String'];
};

/** The details of an `Address` to be created or updated */
export type GQLAddressInput = {
  /** The street address / first line */
  address1: Scalars['String'];
  /** Optional second line */
  address2?: Maybe<Scalars['String']>;
  /** Optionally, a name for this address (e.g. 'Home') to easily identify it in the future */
  addressName?: Maybe<Scalars['String']>;
  /** City */
  city: Scalars['String'];
  /** Optional company name, if it's a business address */
  company?: Maybe<Scalars['String']>;
  /** Country */
  country: Scalars['String'];
  /**
   * The first name of a person at this address
   * This is an optional field to support legacy and third party platforms
   * We use fullName internally, and use first and last name fields to combine into a full name if needed
   */
  firstName?: Maybe<Scalars['String']>;
  /** The full name of a person at this address */
  fullName: Scalars['String'];
  /** Is this the default address for billing purposes? */
  isBillingDefault?: Maybe<Scalars['Boolean']>;
  /** Is this a commercial address? */
  isCommercial?: Maybe<Scalars['Boolean']>;
  /** Is this the default address to use when selecting a shipping address at checkout? */
  isShippingDefault?: Maybe<Scalars['Boolean']>;
  /**
   * The last name of a person at this address
   * This is an optional field to support legacy and third party platforms
   * We use fullName internally, and use first and last name fields to combine into a full name if needed
   */
  lastName?: Maybe<Scalars['String']>;
  /** Arbitrary additional metadata about this address */
  metafields?: Maybe<Array<Maybe<GQLMetafieldInput>>>;
  /** A phone number for someone at this address */
  phone: Scalars['String'];
  /** Postal code */
  postal: Scalars['String'];
  /** Region. For example, a U.S. state */
  region: Scalars['String'];
};

/** User defined attributes. You can include only `key` and use these like tags, or also include a `value`. */
export type GQLMetafieldInput = {
  /** Field description */
  description?: Maybe<Scalars['String']>;
  /** Field key */
  key: Scalars['String'];
  /** Field namespace */
  namespace?: Maybe<Scalars['String']>;
  /** Field scope */
  scope?: Maybe<Scalars['String']>;
  /** Field value */
  value?: Maybe<Scalars['String']>;
  /** Field value type */
  valueType?: Maybe<Scalars['String']>;
};

/** The response from `Query.addressValidation` */
export class GQLAddressValidationResults {
  __typename?: 'AddressValidationResults';
  /**
   * A list of suggested addresses. If the address is valid as is OR the address input is invalid OR
   * the shop is not configured to validate addresses, then this will be empty.
   */
  suggestedAddresses: Array<Maybe<GQLSuggestedAddress>>;
  /**
   * This may have information about the ways in which the provided address input is incomplete or invalid.
   * Show these errors in the address review user interface.
   */
  validationErrors: Array<Maybe<GQLAddressValidationError>>;
};

/** An address suggestion returned from an address validation service */
export class GQLSuggestedAddress {
  __typename?: 'SuggestedAddress';
  /** The street address / first line */
  address1: Scalars['String'];
  /** Optional second line */
  address2?: Maybe<Scalars['String']>;
  /** City */
  city: Scalars['String'];
  /** Country */
  country: Scalars['String'];
  /** Postal code */
  postal: Scalars['String'];
  /** Region. For example, a U.S. state */
  region: Scalars['String'];
};

/** Details about an error that was the result of validating an address that is invalid */
export class GQLAddressValidationError {
  __typename?: 'AddressValidationError';
  /** A longer, detailed error message suitable for showing in the user interface */
  details?: Maybe<Scalars['String']>;
  /** An identifier of the source of this error. These are not currently standardized. As long as your client understands it, any string is fine. */
  source?: Maybe<Scalars['String']>;
  /** A short error message suitable for showing in the user interface */
  summary: Scalars['String'];
  /** The error type. These are not currently standardized. As long as your client understands it, any string is fine. */
  type: Scalars['String'];
};

/** A single registered address validation service */
export class GQLAddressValidationService {
  __typename?: 'AddressValidationService';
  /** Human-readable name to show operators */
  displayName: Scalars['String'];
  /** Unique name to serve as a key identifying this service */
  name: Scalars['String'];
  /** An optional list of all country codes that this address service supports. Null means all countries. */
  supportedCountryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The fields by which you are allowed to sort any query that returns an `AddressValidationRuleConnection` */
export enum AddressValidationRuleSortByField {
  /** AddressValidationRule ID */
  Id = '_id',
  /** Date and time at which the rule was created */
  CreatedAt = 'createdAt',
  /** Service name */
  ServiceName = 'serviceName',
  /** Date and time at which the rule was last updated */
  UpdatedAt = 'updatedAt'
}

/**
 * Wraps a list of `AddressValidationRules`, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLAddressValidationRuleConnection {
  __typename?: 'AddressValidationRuleConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLAddressValidationRuleEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLAddressValidationRule>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `AddressValidationRule` object */
export class GQLAddressValidationRuleEdge  implements GQLNodeEdge {
  __typename?: 'AddressValidationRuleEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The address validation rule */
  node?: Maybe<GQLAddressValidationRule>;
};

/**
 * An address validation rule specifies which validation services should run for
 * which countries in each shop.
 */
export class GQLAddressValidationRule  implements GQLNode {
  __typename?: 'AddressValidationRule';
  /** The rule ID */
  _id: Scalars['ID'];
  /** Country codes for which this service is enabled */
  countryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The date and time at which this rule was created */
  createdAt: Scalars['DateTime'];
  /**
   * The name of one of the installed validation services. Use `addressValidationServices`
   * query to get a list with more details about all installed services.
   */
  serviceName: Scalars['String'];
  /** ID of the shop to which this rule applies */
  shopId: Scalars['ID'];
  /** The date and time at which this rule was last updated */
  updatedAt: Scalars['DateTime'];
};

/** Represents Reaction System Infomation */
export class GQLSystemInformation {
  __typename?: 'SystemInformation';
  /** Core api version */
  apiVersion: Scalars['String'];
  /** Mongo version */
  mongoVersion: GQLDatabaseInformation;
  /** Plugins installed with name, version information */
  plugins?: Maybe<Array<Maybe<GQLPlugin>>>;
};

/** Represents Mongo Database information */
export class GQLDatabaseInformation {
  __typename?: 'DatabaseInformation';
  /** Version of database */
  version: Scalars['String'];
};

/** Represents Reaction Plugin */
export class GQLPlugin {
  __typename?: 'Plugin';
  /** Name of plugin */
  name: Scalars['String'];
  /** Version of plugin */
  version?: Maybe<Scalars['String']>;
};

/**
 * Wraps a list of Templates, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLTemplateConnection {
  __typename?: 'TemplateConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLTemplateEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLTemplate>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Template` object */
export class GQLTemplateEdge {
  __typename?: 'TemplateEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The email template */
  node?: Maybe<GQLTemplate>;
};

/** Represents a Template */
export class GQLTemplate  implements GQLNode {
  __typename?: 'Template';
  /** The shop ID */
  _id: Scalars['ID'];
  /** Email template language */
  language?: Maybe<Scalars['String']>;
  /** Email template name */
  name?: Maybe<Scalars['String']>;
  /** The shop that owns the template */
  shopId: Scalars['ID'];
  /** Email template string */
  subject?: Maybe<Scalars['String']>;
  /** Email template body or html text */
  template?: Maybe<Scalars['String']>;
  /** Email template title */
  title?: Maybe<Scalars['String']>;
};

/** The fields by which you are allowed to sort any query that returns an `AccountConnection` */
export enum AccountSortByField {
  /** Account ID */
  Id = '_id',
  /** Date and time at which this account was created */
  CreatedAt = 'createdAt',
  /** Date and time at which this account was last updated */
  UpdatedAt = 'updatedAt'
}

/**
 * Wraps a list of `Accounts`, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLAccountConnection {
  __typename?: 'AccountConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLAccountEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLAccount>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is an `Account` object */
export class GQLAccountEdge  implements GQLNodeEdge {
  __typename?: 'AccountEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The account */
  node?: Maybe<GQLAccount>;
};

/** A Reaction product */
export class GQLProduct {
  __typename?: 'Product';
  /** Product ID */
  _id: Scalars['ID'];
  /** The date and time at which this product was created */
  createdAt: Scalars['DateTime'];
  /** Hash to compare with publishedProductHash, to see if this product has changed since it was last published */
  currentProductHash?: Maybe<Scalars['String']>;
  /** The full product description, which may have newline characters in it */
  description?: Maybe<Scalars['String']>;
  /** True if this product has been deleted. Typically, deleted products are not returned in queries. */
  isDeleted: Scalars['Boolean'];
  /** True if this product should be shown to shoppers. Typically, non-visible products are not returned in queries. */
  isVisible: Scalars['Boolean'];
  /** All media for a product */
  media?: Maybe<Array<Maybe<GQLImageInfo>>>;
  /** The product description to use for page `description` meta element in HTML */
  metaDescription?: Maybe<Scalars['String']>;
  /** Arbitrary additional metadata about this product */
  metafields: Array<Maybe<GQLMetafield>>;
  /** The country of origin */
  originCountry?: Maybe<Scalars['String']>;
  /** Subtitle */
  pageTitle?: Maybe<Scalars['String']>;
  /** An arbitrary product type value, such as from an external system */
  productType?: Maybe<Scalars['String']>;
  /** The date and time at which this product was last published. If `null`, it has never been published. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Hash to compare with currentProductHash, to see if this product has changed since it was last published */
  publishedProductHash?: Maybe<Scalars['String']>;
  /** The shop to which this product belongs */
  shop: GQLShop;
  /** Whether this product will be shown in the generated sitemap */
  shouldAppearInSitemap?: Maybe<Scalars['Boolean']>;
  /** A URL-safe and human-readable string that uniquely identifies this product */
  slug?: Maybe<Scalars['String']>;
  /** Holds metadata specific to a specific social network service */
  socialMetadata?: Maybe<Array<Maybe<GQLSocialMetadata>>>;
  /** When a shopper purchases this product, what types of fulfillment can they choose from? */
  supportedFulfillmentTypes: Array<Maybe<FulfillmentType>>;
  /** The list of tag IDs that have been applied to this product */
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The list of tags that have been applied to this product */
  tags?: Maybe<GQLTagConnection>;
  /** Product title */
  title?: Maybe<Scalars['String']>;
  /** The date and time at which this product was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** A list of all variants for this product */
  variants: Array<Maybe<GQLProductVariant>>;
  /** The product vendor or manufacturer, for display */
  vendor?: Maybe<Scalars['String']>;
  /**
   * Price range
   * @deprecated Use `pricing`
   */
  price?: Maybe<GQLProductPriceRange>;
  /** Pricing information */
  pricing: GQLProductPricingInfo;
};


/** A Reaction product */
export type GQLProductMediaArgs = {
  shouldIncludeVariantMedia?: Maybe<Scalars['Boolean']>;
};


/** A Reaction product */
export type GQLProductTagsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  offset?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<TagSortByField>;
};


/** A Reaction product */
export type GQLProductVariantsArgs = {
  shouldIncludeHidden?: Maybe<Scalars['Boolean']>;
  shouldIncludeArchived?: Maybe<Scalars['Boolean']>;
};

/** Information about an image */
export class GQLImageInfo {
  __typename?: 'ImageInfo';
  /** A list of URLs for various size files of this image */
  URLs?: Maybe<GQLImageSizes>;
  /** ID */
  _id?: Maybe<Scalars['ID']>;
  /**
   * Sort by priority ascending when displaying more than one image for a product in a user interface.
   * This is an integer with 1 being the first / highest priority image.
   */
  priority?: Maybe<Scalars['Int']>;
  /** The related product ID */
  productId?: Maybe<Scalars['ID']>;
  /** The related variant ID, if linked with a particular variant */
  variantId?: Maybe<Scalars['ID']>;
};

/** Holds metadata specific to a specific social network service */
export class GQLSocialMetadata {
  __typename?: 'SocialMetadata';
  /** Default share message to use when sharing this product on this social network */
  message?: Maybe<Scalars['String']>;
  /** Which social network is this metadata for */
  service?: Maybe<SocialNetwork>;
};

/** The list of currently supported social network identifiers */
export enum SocialNetwork {
  /** Facebook */
  Facebook = 'facebook',
  /** Google+ */
  Googleplus = 'googleplus',
  /** Pinterest */
  Pinterest = 'pinterest',
  /** Twitter */
  Twitter = 'twitter'
}

/** Allowed fulfillment types */
export enum FulfillmentType {
  /** An order will be fulfilled digitally, such as by sending a download link */
  Digital = 'digital',
  /** An order will be fulfilled by the customer picking it up */
  Pickup = 'pickup',
  /** An order will be fulfilled by the seller shipping it to the customer */
  Shipping = 'shipping'
}

/** A Reaction product variant or option */
export class GQLProductVariant {
  __typename?: 'ProductVariant';
  /** Variant ID */
  _id: Scalars['ID'];
  /**
   * The attribute label describes the category of variant, for example, `Color` or `Size`.
   * In most cases this will be the same for all variants at the same level.
   */
  attributeLabel?: Maybe<Scalars['String']>;
  /** The product variant barcode value, if it has one */
  barcode?: Maybe<Scalars['String']>;
  /** The date and time at which this variant was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The height of the product variant, if it has physical dimensions */
  height?: Maybe<Scalars['Float']>;
  /** The position of this variant among other variants at the same level of the product-variant-option hierarchy */
  index?: Maybe<Scalars['Int']>;
  /** True if this variant was deleted. Deleted variants are not published to the catalog. */
  isDeleted: Scalars['Boolean'];
  /** True if this variant is visible. Hidden variants are not published to the catalog. */
  isVisible: Scalars['Boolean'];
  /** The length of the product, if it has physical dimensions */
  length?: Maybe<Scalars['Float']>;
  /** All media for a variant */
  media?: Maybe<Array<Maybe<GQLImageInfo>>>;
  /** Arbitrary additional metadata about this product */
  metafields: Array<Maybe<GQLMetafield>>;
  /** The minimum quantity that must be added to a cart */
  minOrderQuantity?: Maybe<Scalars['Int']>;
  /** A short title to use for product detail select lists */
  optionTitle?: Maybe<Scalars['String']>;
  /** Child variants, if any */
  options: Array<Maybe<GQLProductVariant>>;
  /** The country of origin */
  originCountry?: Maybe<Scalars['String']>;
  /** The shop to which this product variant belongs */
  shop: GQLShop;
  /** SKU of variant */
  sku?: Maybe<Scalars['String']>;
  /**
   * The full variant title for use on cart, checkout, and order summaries and on invoices.
   * This fully describes the configured variant. For example, if this is an option with
   * `optionTitle` "Large", its parent variant has `optionTitle` `Red`, and the product
   * `title` is "Fancy T-Shirt", then this `title` will be something like `Fancy T-Shirt - Red - Large`.
   */
  title?: Maybe<Scalars['String']>;
  /** The date and time at which this product was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The weight of the product on Earth, if it has physical dimensions */
  weight?: Maybe<Scalars['Float']>;
  /** The width of the product, if it has physical dimensions */
  width?: Maybe<Scalars['Float']>;
  /**
   * Compare at price of the variant
   * @deprecated Use `pricing`
   */
  compareAtPrice?: Maybe<Scalars['Float']>;
  /**
   * Price of the variant
   * @deprecated Use `pricing`
   */
  price?: Maybe<Scalars['Float']>;
  /** Pricing information */
  pricing: GQLProductPricingInfo;
  /** Whether this variant is taxable */
  isTaxable?: Maybe<Scalars['Boolean']>;
  /** Tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Tax description */
  taxDescription?: Maybe<Scalars['String']>;
};


/** A Reaction product variant or option */
export type GQLProductVariantOptionsArgs = {
  shouldIncludeHidden?: Maybe<Scalars['Boolean']>;
  shouldIncludeArchived?: Maybe<Scalars['Boolean']>;
};

/** The product price or price range for a specific currency */
export class GQLProductPricingInfo {
  __typename?: 'ProductPricingInfo';
  /**
   * A comparison price value, usually MSRP. If `price` is null, this will also be null. That is,
   * only purchasable variants will have a `compareAtPrice`.
   */
  compareAtPrice?: Maybe<GQLMoney>;
  /** The code for the currency these pricing details applies to */
  currency: GQLCurrency;
  /** Pricing converted to specified currency */
  currencyExchangePricing?: Maybe<GQLCurrencyExchangeProductPricingInfo>;
  /**
   * UI should display this price. If a product has multiple potential prices depending on selected
   * variants and options, then this is a price range string such as "$3.95 - $6.99". It includes the currency
   * symbols.
   */
  displayPrice: Scalars['String'];
  /** The price of the most expensive possible variant+option combination */
  maxPrice: Scalars['Float'];
  /** The price of the least expensive possible variant+option combination */
  minPrice: Scalars['Float'];
  /**
   * For variants with no options and for options, this will always be set to a price. For variants
   * with options and products, this will be `null`. There must be a price for a variant to be
   * added to a cart or purchased. Otherwise you would instead add one of its child options to a cart.
   */
  price?: Maybe<Scalars['Float']>;
};


/** The product price or price range for a specific currency */
export type GQLProductPricingInfoCurrencyExchangePricingArgs = {
  currencyCode: Scalars['String'];
};

/** Represents some amount of a single currency */
export class GQLMoney {
  __typename?: 'Money';
  /** The numeric amount */
  amount: Scalars['Float'];
  /** The currency, for interpreting the `amount` */
  currency: GQLCurrency;
  /** The display amount, with any currency symbols and decimal places already added */
  displayAmount: Scalars['String'];
};

/** The product price or price range for a specific currency */
export class GQLCurrencyExchangeProductPricingInfo {
  __typename?: 'CurrencyExchangeProductPricingInfo';
  /**
   * A comparison price value, usually MSRP. If `price` is null, this will also be null. That is,
   * only purchasable variants will have a `compareAtPrice`.
   */
  compareAtPrice?: Maybe<GQLMoney>;
  /** The code for the currency these pricing details applies to */
  currency: GQLCurrency;
  /**
   * UI should display this price. If a product has multiple potential prices depending on selected
   * variants and options, then this is a price range string such as "$3.95 - $6.99". It includes the currency
   * symbols.
   */
  displayPrice: Scalars['String'];
  /** The price of the most expensive possible variant+option combination */
  maxPrice: Scalars['Float'];
  /** The price of the least expensive possible variant+option combination */
  minPrice: Scalars['Float'];
  /**
   * For variants with no options and for options, this will always be set to a price. For variants
   * with options and products, this will be `null`. There must be a price for a variant to be
   * added to a cart or purchased. Otherwise you would instead add one of its child options to a cart.
   */
  price?: Maybe<Scalars['Float']>;
};

/** Product price range */
export class GQLProductPriceRange {
  __typename?: 'ProductPriceRange';
  /** Maximum price in range */
  max?: Maybe<Scalars['Float']>;
  /** Minimum price in range */
  min?: Maybe<Scalars['Float']>;
  /** Price range display */
  range?: Maybe<Scalars['String']>;
};

/** The fields by which you are allowed to sort any query that returns a `ProductConnection` */
export enum ProductSortByField {
  /** Product ID */
  Id = '_id',
  /** Date and time the product was created */
  CreatedAt = 'createdAt',
  /** Product title */
  Title = 'title',
  /** Date and time the product was last updated */
  UpdatedAt = 'updatedAt'
}

/**
 * Wraps a list of Products`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLProductConnection {
  __typename?: 'ProductConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLProductEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLProduct>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Product` object */
export class GQLProductEdge {
  __typename?: 'ProductEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The product */
  node?: Maybe<GQLProduct>;
};

/** A filter to be applied to a Catalog query */
export type GQLCatalogBooleanFilter = {
  /** The name of the filter */
  name: CatalogBooleanFilterName;
  /** The filter value */
  value: Scalars['Boolean'];
};

/** The list of currently supported top level Catalog props on which catalog items can be filtered. */
export enum CatalogBooleanFilterName {
  /** isDeleted */
  IsDeleted = 'isDeleted',
  /** isVisible */
  IsVisible = 'isVisible',
  /** isLowQuantity */
  IsLowQuantity = 'isLowQuantity',
  /** isSoldOut */
  IsSoldOut = 'isSoldOut',
  /** isBackorder */
  IsBackorder = 'isBackorder'
}

/** Allowed values for sorting catalog items */
export enum CatalogItemSortByField {
  /** Sort by item ID */
  Id = '_id',
  /** Sort by date and time at which the item was created */
  CreatedAt = 'createdAt',
  /** Sort in the shop-defined order for the tag filter */
  Featured = 'featured',
  /** Sort by price */
  MinPrice = 'minPrice',
  /** Sort by date and time at which the item was last updated */
  UpdatedAt = 'updatedAt'
}

/**
 * Wraps a list of `CatalogItem`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLCatalogItemConnection {
  __typename?: 'CatalogItemConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLCatalogItemEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLCatalogItem>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `CatalogItem` object */
export class GQLCatalogItemEdge {
  __typename?: 'CatalogItemEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The catalog item */
  node?: Maybe<GQLCatalogItem>;
};

/** Catalog items are combined to create a catalog. Each item can represent a different type of content. */
export type GQLCatalogItem = {
  /** Item ID */
  _id: Scalars['ID'];
  /** Date and time at which the item was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Shop that owns the item */
  shop: GQLShop;
  /** Date and time at which the item was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Represents a catalog item that displays a product */
export class GQLCatalogItemProduct  implements GQLCatalogItem, GQLNode {
  __typename?: 'CatalogItemProduct';
  /** The CatalogItemProduct ID */
  _id: Scalars['ID'];
  /** The date and time at which this CatalogItem was first created, which is when the related product was first published */
  createdAt: Scalars['DateTime'];
  /** The catalog product */
  product?: Maybe<GQLCatalogProduct>;
  /** The shop to which this catalog belongs */
  shop: GQLShop;
  /** The date and time at which this CatalogItem was last updated, which is when the related product was most recently published */
  updatedAt: Scalars['DateTime'];
};

/**
 * Represents a product that has been published into a shop catalog. The related `Product` is the source of truth for
 * shop administrators, but that is then published to a catalog as a `CatalogProduct`, which is what should
 * be displayed to shoppers who browse that catalog.
 */
export class GQLCatalogProduct  implements GQLCatalogProductOrVariant, GQLNode {
  __typename?: 'CatalogProduct';
  /** The CatalogProduct ID. Do not assume that this is the same as the related product ID. See `productId` for that. */
  _id: Scalars['ID'];
  /** The product barcode value, if it has one */
  barcode?: Maybe<Scalars['String']>;
  /** The date and time at which this CatalogProduct was created, which is when the related product was first published */
  createdAt: Scalars['DateTime'];
  /** The full product description, which may have newline characters in it */
  description?: Maybe<Scalars['String']>;
  /** The height of the product, if it has physical dimensions */
  height?: Maybe<Scalars['Float']>;
  /** True if this product has been deleted. Typically, deleted products are not returned in queries. */
  isDeleted: Scalars['Boolean'];
  /** True if this product should be shown to shoppers. Typically, non-visible products are not returned in queries. */
  isVisible: Scalars['Boolean'];
  /** The length of the product, if it has physical dimensions */
  length?: Maybe<Scalars['Float']>;
  /** All media for this product and its variants */
  media?: Maybe<Array<Maybe<GQLImageInfo>>>;
  /** The product description to use for page `description` meta element in HTML */
  metaDescription?: Maybe<Scalars['String']>;
  /** Arbitrary additional metadata about this product */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** The minimum quantity that must be added to a cart */
  minOrderQuantity?: Maybe<Scalars['Int']>;
  /** The country of origin */
  originCountry?: Maybe<Scalars['String']>;
  /** Subtitle */
  pageTitle?: Maybe<Scalars['String']>;
  /** Dimensions and other information about the containers in which this product will be shipped */
  parcel?: Maybe<GQLShippingParcel>;
  /** The primary image */
  primaryImage?: Maybe<GQLImageInfo>;
  /** The related Product ID */
  productId: Scalars['ID'];
  /** An arbitrary product type value, such as from an external system */
  productType?: Maybe<Scalars['String']>;
  /** The shop to which this product belongs */
  shop: GQLShop;
  /** A stock keeping unit (SKU) identifier for this product */
  sku?: Maybe<Scalars['String']>;
  /** A URL-safe and human-readable string that uniquely identifies this product */
  slug?: Maybe<Scalars['String']>;
  /** Holds metadata specific to a specific social network service */
  socialMetadata?: Maybe<Array<Maybe<GQLSocialMetadata>>>;
  /** When a shopper purchases this product, what types of fulfillment can they choose from? */
  supportedFulfillmentTypes: Array<Maybe<FulfillmentType>>;
  /** Product title */
  title?: Maybe<Scalars['String']>;
  /** The date and time at which this CatalogProduct was last updated, which is when the related product was most recently published */
  updatedAt: Scalars['DateTime'];
  /** A flat list of all variants for this product */
  variants?: Maybe<Array<Maybe<GQLCatalogProductVariant>>>;
  /** The product vendor or manufacturer, for display */
  vendor?: Maybe<Scalars['String']>;
  /** The weight of the product on Earth, if it has physical dimensions */
  weight?: Maybe<Scalars['Float']>;
  /** The width of the product, if it has physical dimensions */
  width?: Maybe<Scalars['Float']>;
  /** The list of tag IDs that have been applied to this product */
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The list of tags that have been applied to this product */
  tags?: Maybe<GQLTagConnection>;
  /** Price and related information, per currency */
  pricing: Array<Maybe<GQLProductPricingInfo>>;
  /**
   * True if every purchasable variant of this product is sold out but allows backorders. A storefront UI may use this
   * to decide to show a "Backordered" indicator.
   */
  isBackorder: Scalars['Boolean'];
  /**
   * True if at least one purchasable variant of this product has a low quantity in stock. A storefront UI may use this
   * to decide to show a "Low Quantity" indicator.
   */
  isLowQuantity: Scalars['Boolean'];
  /**
   * True if every purchasable variant of this product is sold out. A storefront UI may use this
   * to decide to show a "Sold Out" indicator when `isBackorder` is not also true.
   */
  isSoldOut: Scalars['Boolean'];
};


/**
 * Represents a product that has been published into a shop catalog. The related `Product` is the source of truth for
 * shop administrators, but that is then published to a catalog as a `CatalogProduct`, which is what should
 * be displayed to shoppers who browse that catalog.
 */
export type GQLCatalogProductTagsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<TagSortByField>;
};

/** This interface represents the fields that are identical for both Products and Variants */
export type GQLCatalogProductOrVariant = {
  /** The product barcode value, if it has one */
  barcode?: Maybe<Scalars['String']>;
  /** The date and time at which this CatalogProduct was created, which is when the related product was first published */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The height of the product, if it has physical dimensions */
  height?: Maybe<Scalars['Float']>;
  /** The length of the product, if it has physical dimensions */
  length?: Maybe<Scalars['Float']>;
  /** Arbitrary additional metadata about this product */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** The minimum quantity that must be added to a cart */
  minOrderQuantity?: Maybe<Scalars['Int']>;
  /** The country of origin */
  originCountry?: Maybe<Scalars['String']>;
  /** The shop to which this product belongs */
  shop: GQLShop;
  /** A stock keeping unit (SKU) identifier for this product */
  sku?: Maybe<Scalars['String']>;
  /** Product or variant title */
  title?: Maybe<Scalars['String']>;
  /** The date and time at which this CatalogProduct was last updated, which is when the related product was most recently published */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The weight of the product on Earth, if it has physical dimensions */
  weight?: Maybe<Scalars['Float']>;
  /** The width of the product, if it has physical dimensions */
  width?: Maybe<Scalars['Float']>;
};

/** A shipping parcel */
export class GQLShippingParcel {
  __typename?: 'ShippingParcel';
  /** Containers */
  containers?: Maybe<Scalars['String']>;
  /** Distance unit */
  distanceUnit?: Maybe<DistanceUnit>;
  /** Height */
  height?: Maybe<Scalars['Float']>;
  /** Length */
  length?: Maybe<Scalars['Float']>;
  /** Mass unit */
  massUnit?: Maybe<MassUnit>;
  /** Weight */
  weight?: Maybe<Scalars['Float']>;
  /** Width */
  width?: Maybe<Scalars['Float']>;
};

/** Distance units */
export enum DistanceUnit {
  /** Centimeter */
  Cm = 'cm',
  /** Foot */
  Ft = 'ft',
  /** Inch */
  In = 'in'
}

/** Mass units */
export enum MassUnit {
  /** Gram */
  G = 'g',
  /** Kilogram */
  Kg = 'kg',
  /** Pound */
  Lb = 'lb',
  /** Ounce */
  Oz = 'oz'
}

/** A variant of a catalog product */
export class GQLCatalogProductVariant  implements GQLCatalogProductOrVariant, GQLNode {
  __typename?: 'CatalogProductVariant';
  /** The CatalogProductVariant ID. Do not assume that this is the same as the related variant ID. See `variantId` for that. */
  _id: Scalars['ID'];
  /**
   * The attribute label describes the category of variant, for example, `Color` or `Size`.
   * In most cases this will be the same for all variants at the same level.
   */
  attributeLabel: Scalars['String'];
  /** The product variant barcode value, if it has one */
  barcode?: Maybe<Scalars['String']>;
  /** The date and time at which this CatalogProductVariant was created, which is when the related product was first published */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The height of the product variant, if it has physical dimensions */
  height?: Maybe<Scalars['Float']>;
  /** The position of this variant among other variants at the same level of the product-variant-option hierarchy */
  index: Scalars['Int'];
  /** The length of the product, if it has physical dimensions */
  length?: Maybe<Scalars['Float']>;
  /** All media for this variant / option */
  media?: Maybe<Array<Maybe<GQLImageInfo>>>;
  /** Arbitrary additional metadata about this product */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** The minimum quantity that must be added to a cart */
  minOrderQuantity?: Maybe<Scalars['Int']>;
  /** A short title to use for product detail select lists */
  optionTitle?: Maybe<Scalars['String']>;
  /** Child variants, if any */
  options?: Maybe<Array<Maybe<GQLCatalogProductVariant>>>;
  /** The country of origin */
  originCountry?: Maybe<Scalars['String']>;
  /** The primary image of this variant / option */
  primaryImage?: Maybe<GQLImageInfo>;
  /** The shop to which this product variant belongs */
  shop: GQLShop;
  /** A stock keeping unit (SKU) identifier for this product */
  sku?: Maybe<Scalars['String']>;
  /**
   * The full variant title for use on cart, checkout, and order summaries and on invoices.
   * This fully describes the configured variant. For example, if this is an option with
   * `optionTitle` `Large`, its parent variant has `optionTitle` `Red`, and the product
   * `title` is `Fancy T-Shirt`, then this `title` will be something like `Fancy T-Shirt - Red - Large`.
   */
  title?: Maybe<Scalars['String']>;
  /** The date and time at which this CatalogProduct was last updated, which is when the related product was most recently published */
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** The related Variant ID */
  variantId: Scalars['ID'];
  /** The weight of the product on Earth, if it has physical dimensions */
  weight?: Maybe<Scalars['Float']>;
  /** The width of the product, if it has physical dimensions */
  width?: Maybe<Scalars['Float']>;
  /** Price and related information, per currency */
  pricing: Array<Maybe<GQLProductPricingInfo>>;
  /**
   * True for a purchasable variant if an order containing this variant will be accepted even when there is insufficient
   * available inventory (`inventoryAvailableToSell`) to fulfill it immediately. For non-purchasable variants, this is true if at least one purchasable
   * child variant can be backordered. A storefront UI may use this in combination with `inventoryAvailableToSell` to
   * decide whether to show or enable an "Add to Cart" button.
   */
  canBackorder: Scalars['Boolean'];
  /**
   * The quantity of this item currently available to sell.
   * This number is updated when an order is placed by the customer.
   * This number does not include reserved inventory (i.e. inventory that has been ordered, but not yet processed by the operator).
   * If this is a variant, this number is created by summing all child option inventory numbers.
   * This is most likely the quantity to display in the storefront UI.
   */
  inventoryAvailableToSell?: Maybe<Scalars['Int']>;
  /**
   * The quantity of this item currently in stock.
   * This number is updated when an order is processed by the operator.
   * This number includes all inventory, including reserved inventory (i.e. inventory that has been ordered, but not yet processed by the operator).
   * If this is a variant, this number is created by summing all child option inventory numbers.
   * This is most likely just used as a reference in the operator UI, and not displayed in the storefront UI.
   */
  inventoryInStock?: Maybe<Scalars['Int']>;
  /**
   * True for a purchasable variant if it is sold out but allows backorders. For non-purchasable variants, this is
   * true if every purchasable child variant is sold out but allows backorders. A storefront UI may use this
   * to decide to show a "Backordered" indicator.
   */
  isBackorder: Scalars['Boolean'];
  /**
   * True for a purchasable variant if it has a low available quantity (`inventoryAvailableToSell`) in stock.
   * For non-purchasable variants, this is true if at least one purchasable child variant has a low available
   * quantity in stock. A storefront UI may use this to decide to show a "Low Quantity" indicator.
   */
  isLowQuantity: Scalars['Boolean'];
  /**
   * True for a purchasable variant if it is sold out (`inventoryAvailableToSell` is 0). For non-purchasable
   * variants, this is true if every purchasable child variant is sold out. A storefront UI may use this
   * to decide to show a "Sold Out" indicator when `isBackorder` is not also true.
   */
  isSoldOut: Scalars['Boolean'];
  /** Is sales tax charged on this item? */
  isTaxable: Scalars['Boolean'];
  /** An optional code which, if understood by the active tax service for the shop, determines how this product should be taxed */
  taxCode?: Maybe<Scalars['String']>;
  /** A description to use for the tax line item on an invoice */
  taxDescription?: Maybe<Scalars['String']>;
};

/**
 * Wraps a list of `TagProduct`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLTagProductConnection {
  __typename?: 'TagProductConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLTagProductEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLTagProduct>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `TagProduct` object */
export class GQLTagProductEdge {
  __typename?: 'TagProductEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The tag product */
  node?: Maybe<GQLTagProduct>;
};

/** A tag product */
export class GQLTagProduct {
  __typename?: 'TagProduct';
  /** The product id */
  _id: Scalars['ID'];
  /** The date and time at which this CatalogProduct was created, which is when the related product was first published */
  createdAt: Scalars['DateTime'];
  /** Position of the product */
  position?: Maybe<Scalars['Int']>;
  /** The title of the product */
  title?: Maybe<Scalars['String']>;
};

/** Input that defines a single configuration of a product */
export type GQLProductConfigurationInput = {
  /** The Product ID */
  productId: Scalars['ID'];
  /** The ProductVariant ID */
  productVariantId: Scalars['ID'];
};

/** Inventory info for a specific product configuration. For inventory managed by the SimpleInventory plugin. */
export class GQLSimpleInventoryInfo {
  __typename?: 'SimpleInventoryInfo';
  /** Whether to allow ordering this product configuration when there is insufficient quantity available */
  canBackorder?: Maybe<Scalars['Boolean']>;
  /** Current quantity of this product configuration in stock */
  inventoryInStock?: Maybe<Scalars['Int']>;
  /**
   * Current quantity of this product configuration unavailable for ordering. This value is calculated
   * by the system based on this product variant being in not-yet-approved orders.
   */
  inventoryReserved?: Maybe<Scalars['Int']>;
  /** Whether the SimpleInventory plugin should manage inventory for this product configuration */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /**
   * The "low quantity" flag will be applied to this product configuration when the available quantity
   * is at or below this threshold
   */
  lowInventoryWarningThreshold?: Maybe<Scalars['Int']>;
  /** The product and chosen options this info applies to */
  productConfiguration: GQLProductConfiguration;
};

/** Product configuration data */
export class GQLProductConfiguration {
  __typename?: 'ProductConfiguration';
  /** The Product ID */
  productId: Scalars['ID'];
  /** The ProductVariant ID */
  productVariantId: Scalars['ID'];
};

/** The cart holds selected items until order is placed. */
export class GQLCart  implements GQLNode {
  __typename?: 'Cart';
  /** The Cart ID */
  _id: Scalars['ID'];
  /**
   * The account that owns the cart. Some carts are created for anonymous users. Anonymous carts have a null account.
   * Every account has exactly one cart per shop.
   */
  account?: Maybe<GQLAccount>;
  /** Holds all information collected for a cart during checkout */
  checkout?: Maybe<GQLCheckout>;
  /** The date and time at which the cart was created, which is when the first item was added to it. */
  createdAt: Scalars['DateTime'];
  /** An email address that has been associated with the cart */
  email?: Maybe<Scalars['String']>;
  /** The date and time at which the cart will expire. Account carts usually do not expire, so they will have a null value here. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** The items that have been added to the cart. A cart is not created until the first item is added. Items can be removed from a cart, and a cart is not deleted if all items are removed from it. Because all items may have been removed, this may be an empty array. */
  items?: Maybe<GQLCartItemConnection>;
  /**
   * If any products or variants become hidden or are deleted after they were added to this cart, they'll be
   * automatically moved from `items` to `missingItems`. Clients may want to use this to show an
   * "items that are no longer available" list to storefront users.
   *
   * If a product becomes visible again, the item will never be automatically moved from `missingItems`
   * back to `items`, but clients may want to provide a way for users to manually do this.
   */
  missingItems?: Maybe<Array<Maybe<GQLCartItem>>>;
  /**
   * If you integrate with third-party systems that require you to send the same ID for order
   * calculations as for cart calculations, you may use this ID, which is the same on a `cart` as on
   * the `order` placed from that cart. This ID can also be customized by plugins and is the best
   * ID to use if it is necessary to show a cart ID in the user interface.
   */
  referenceId?: Maybe<Scalars['String']>;
  /** The shop that owns the cart. */
  shop: GQLShop;
  /** Total quantity of all items in the cart */
  totalItemQuantity: Scalars['Int'];
  /** The date and time at which this cart was last updated. */
  updatedAt: Scalars['DateTime'];
  /** Surcharges applied to this cart */
  surcharges: Array<Maybe<GQLAppliedSurcharge>>;
  /**
   * A summary of calculated taxes for this cart. Null means "not able to calculate",
   * such as when no fulfillment method has been selected for some fulfillment groups.
   */
  taxSummary?: Maybe<GQLTaxSummary>;
};


/** The cart holds selected items until order is placed. */
export type GQLCartItemsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<CartItemsSortByField>;
};

/** Holds all information collected for a cart during checkout */
export class GQLCheckout {
  __typename?: 'Checkout';
  /** One or more fulfillment groups, for example, mapping certain items to certain shipping addresses */
  fulfillmentGroups: Array<Maybe<GQLFulfillmentGroup>>;
  /** A summary of the totals for this cart */
  summary: GQLCartSummary;
};

/**
 * Links one or more cart items to fulfillment data. The most common example is having one FulfillmentGroup
 * per shipping address.
 */
export class GQLFulfillmentGroup  implements GQLNode {
  __typename?: 'FulfillmentGroup';
  /** The fulfillment ID */
  _id: Scalars['ID'];
  /**
   * The list of fulfillment options from which the shopper may choose. This list is created by taking
   * the full list of registered fulfillment methods, keeping only those that match the fulfillment `type`
   * of this group, and then calculating a price and handlingPrice for each based on the `items` in this group.
   */
  availableFulfillmentOptions: Array<Maybe<GQLFulfillmentOption>>;
  /** Information needed by the fulfillment type to properly fulfill the order */
  data?: Maybe<GQLFulfillmentData>;
  /** The items that are included in this fulfillment group */
  items: Array<Maybe<GQLCartItem>>;
  /** The fulfillment method selected by a shopper for this group, with its associated price */
  selectedFulfillmentOption?: Maybe<GQLFulfillmentOption>;
  /** The shipping address collected for this group, if relevant */
  shippingAddress?: Maybe<GQLAddress>;
  /** The shop that owns the items in this group and is responsible for fulfillment */
  shop: GQLShop;
  /** The fulfillment type. Any valid type that has been registered by a fulfillment plugin. Examples: `shipping`, `digital` */
  type: FulfillmentType;
};

/** A fulfillment option for a cart fulfillment group, which is a method with an associated price */
export class GQLFulfillmentOption {
  __typename?: 'FulfillmentOption';
  /** The fulfillment method this pricing is for */
  fulfillmentMethod?: Maybe<GQLFulfillmentMethod>;
  /** The additional amount charged for handling */
  handlingPrice: GQLMoney;
  /** The base price charged */
  price: GQLMoney;
};

/**
 * A single fulfillment method. Fulfillment methods are shown to shoppers along with a quote for them,
 * and the shopper chooses one method per fulfillment group per cart during checkout.
 */
export class GQLFulfillmentMethod  implements GQLNode {
  __typename?: 'FulfillmentMethod';
  /** The fulfillment method ID */
  _id: Scalars['ID'];
  /** A carrier name */
  carrier?: Maybe<Scalars['String']>;
  /** The name of this method, for display in the user interface */
  displayName: Scalars['String'];
  /** The fulfillment types for which this method may be used. For example, `shipping` or `digital`. */
  fulfillmentTypes: Array<Maybe<FulfillmentType>>;
  /** The group to which this method belongs */
  group?: Maybe<Scalars['String']>;
  /** The name of this method, a unique identifier */
  name: Scalars['String'];
};

/** Information needed by the selected fulfillment method to properly fulfill the order */
export class GQLFulfillmentData {
  __typename?: 'FulfillmentData';
  /** The mailing address to which this fulfillment group should be shipped */
  shippingAddress?: Maybe<GQLAddress>;
};

/** A single item in a cart. The item contains information about an intended purchase. */
export class GQLCartItem  implements GQLNode {
  __typename?: 'CartItem';
  /** The cart item ID */
  _id: Scalars['ID'];
  /**
   * "
   * The date and time at which this item was first added to the associated cart.
   * If an item is added, removed, and then added again, this will reflect the most recent addition.
   * However, if an item is added twice, the quantity will increase but this date will remain
   * the initial added date.
   */
  addedAt: Scalars['DateTime'];
  /**
   * FUTURE. Additional attributes of the chosen item. For example, if this item is for a product, socks, where `blue` and `small`
   * options were chosen for some configurable attributes, then `color:blue` and `size:small` will be indicated here.
   */
  attributes?: Maybe<Array<Maybe<GQLCartItemAttribute>>>;
  /** The current comparison (e.g., MSRP) price of the item */
  compareAtPrice?: Maybe<GQLMoney>;
  /**
   * The date and time at which the cart item was created. If an item is added, removed, and then added again,
   * the original item is destroyed and this field will reflect the time it was created for the most recent addition.
   */
  createdAt: Scalars['DateTime'];
  /** The URLs for a picture of the item in various sizes */
  imageURLs?: Maybe<GQLImageSizes>;
  /** Arbitrary additional metadata about this cart item. */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** The selected variant optionTitle */
  optionTitle?: Maybe<Scalars['String']>;
  /** Packing information such as item weight, height, length, and depth. Used for calculating shipping rates. */
  parcel?: Maybe<GQLShippingParcel>;
  /** The current price of the item */
  price: GQLMoney;
  /** The price at which this item was listed when it was added to the cart */
  priceWhenAdded: GQLMoney;
  /** The product and chosen options */
  productConfiguration: GQLProductConfiguration;
  /** The product's slug */
  productSlug?: Maybe<Scalars['String']>;
  /** The list of tags that have been applied to this product */
  productTags?: Maybe<GQLTagConnection>;
  /** The type of product, used to display cart items differently */
  productType?: Maybe<Scalars['String']>;
  /** The product vendor */
  productVendor?: Maybe<Scalars['String']>;
  /** The quantity of this item that has been added to the cart. This must be a positive integer. Remove this `CartItem` from it's associated cart if you want `0` of this item. */
  quantity: Scalars['Int'];
  /** The shop associated with this cart item. */
  shop: GQLShop;
  /** The current price of the item multiplied by the quantity */
  subtotal: GQLMoney;
  /** A title for use in cart/orders that conveys the selected product's title + chosen options */
  title: Scalars['String'];
  /** The date and time at which this item was last updated */
  updatedAt: Scalars['DateTime'];
  /** The selected variant title */
  variantTitle?: Maybe<Scalars['String']>;
  /**
   * The quantity of this item currently available to sell.
   * This number is updated when an order is placed by the customer.
   * This number does not include reserved inventory (i.e. inventory that has been ordered, but not yet processed by the operator).
   * This is most likely the quantity to display in the storefront UI.
   */
  inventoryAvailableToSell?: Maybe<Scalars['Int']>;
  /**
   * True if this item is currently sold out but allows backorders. A storefront UI may use this
   * to decide to show a "Backordered" indicator.
   */
  isBackorder: Scalars['Boolean'];
  /**
   * True if this item has a low available quantity (`inventoryAvailableToSell`) in stock.
   * A storefront UI may use this to decide to show a "Low Quantity" indicator.
   */
  isLowQuantity: Scalars['Boolean'];
  /**
   * True if this item is currently sold out (`inventoryAvailableToSell` is 0). A storefront
   * UI may use this to decide to show a "Sold Out" indicator when `isBackorder` is not also true.
   */
  isSoldOut: Scalars['Boolean'];
  /** Is this a taxable item? */
  isTaxable: Scalars['Boolean'];
  /** Total tax calculated for this item */
  tax?: Maybe<GQLMoney>;
  /** The tax code for this item */
  taxCode?: Maybe<Scalars['String']>;
  /** Amount of subtotal that is taxable */
  taxableAmount?: Maybe<GQLMoney>;
  /** List of calculated taxes due for this item */
  taxes?: Maybe<Array<Maybe<GQLCalculatedTax>>>;
};


/** A single item in a cart. The item contains information about an intended purchase. */
export type GQLCartItemProductTagsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<TagSortByField>;
};

/** One attribute of a cart item */
export class GQLCartItemAttribute {
  __typename?: 'CartItemAttribute';
  /** The attribute label, e.g., Color */
  label?: Maybe<Scalars['String']>;
  /** The attribute value, e.g., Blue */
  value?: Maybe<Scalars['String']>;
};

/** A single calculated tax for a cart, order group, cart item, or order item */
export class GQLCalculatedTax {
  __typename?: 'CalculatedTax';
  /** Calculated tax ID */
  _id: Scalars['ID'];
  /** Jurisdiction ID. It is up to the tax service to determine if and how to use this. */
  jurisdictionId?: Maybe<Scalars['String']>;
  /** Did this tax match due to the order origin or the order destination? */
  sourcing: TaxSource;
  /** Amount of tax due */
  tax: GQLMoney;
  /** A human-readable display name for showing this tax to operators and customers in the UI */
  taxName: Scalars['String'];
  /** The tax rate used for this calculation */
  taxRate: GQLRate;
  /** Amount that was used for calculating the tax due */
  taxableAmount: GQLMoney;
};

/** Tax sources */
export enum TaxSource {
  /** Tax is applied when the destination matches the tax jurisdiction */
  Destination = 'destination',
  /** Tax is applied when the origin matches the tax jurisdiction */
  Origin = 'origin'
}

/** A numeric rate, with its corresponding percent values */
export class GQLRate {
  __typename?: 'Rate';
  /** The rate */
  amount: Scalars['Float'];
  /** The percent as a preformatted string with percent symbol included */
  displayPercent: Scalars['String'];
  /** The percent (rate x 100) */
  percent: Scalars['Float'];
};

/** A summary of the totals for this cart */
export class GQLCartSummary {
  __typename?: 'CartSummary';
  /** The total of all discounts applied, as a positive number */
  discountTotal: GQLMoney;
  /**
   * The calculated tax-exclusive tax rate on all items and fulfillment prices (taxTotal / taxableAmount).
   * This may be null, and there is a difference between null and 0. Null means `not able to calculate`,
   * such as when no fulfillment method has been selected for some fulfillment groups.
   */
  effectiveTaxRate?: Maybe<GQLRate>;
  /**
   * The total price of all chosen fulfillment methods. This may be null, and there is a difference
   * between null and 0. Null means `not able to calculate`, such as when no fulfillment method has
   * been selected for some fulfillment groups.
   */
  fulfillmentTotal?: Maybe<GQLMoney>;
  /** The combined prices of all cart items */
  itemTotal: GQLMoney;
  /**
   * The total estimated tax that has not already been included in the item prices. This may be null,
   * and there is a difference between null and 0. Null means `not able to calculate`, such as when no
   * fulfillment methods have been selected or there is some other issue with the tax service.
   */
  taxTotal?: Maybe<GQLMoney>;
  /** The total amount that was deemed taxable by the tax service */
  taxableAmount: GQLMoney;
  /** The sum of `itemTotal`, `fulfillmentTotal`, and `taxTotal`, minus `discountTotal` */
  total: GQLMoney;
  /**
   * The combined total of all surcharges. This may be null, and there is a difference
   * between null and 0. Null means `not able to calculate`, such as when no fulfillment method has
   * been selected for some fulfillment groups.
   */
  surchargeTotal?: Maybe<GQLMoney>;
};

/** Allowed values for cart item sortBy parameter */
export enum CartItemsSortByField {
  /** Cart item ID */
  Id = '_id',
  /** Date and time at which the item was added to the cart */
  AddedAt = 'addedAt'
}

/**
 * Wraps a list of `CartItem`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLCartItemConnection {
  __typename?: 'CartItemConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLCartItemEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLCartItem>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `CartItem` object */
export class GQLCartItemEdge  implements GQLNodeEdge {
  __typename?: 'CartItemEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The cart item */
  node?: Maybe<GQLCartItem>;
};

/** Defines a surcharge that has been applied to a Cart or Order */
export class GQLAppliedSurcharge  implements GQLNode {
  __typename?: 'AppliedSurcharge';
  /** The surcharge ID */
  _id: Scalars['ID'];
  /** The amount of the surcharge */
  amount: GQLMoney;
  /** The fulfillmentGroupId (for reference) */
  fulfillmentGroupId?: Maybe<Scalars['ID']>;
  /** The message to explain the surchage to customers, translated (if available) based on shop language */
  message?: Maybe<Scalars['String']>;
  /** The surchargeId from the surchages collection (for reference) */
  surchargeDefinitionId: Scalars['ID'];
};


/** Defines a surcharge that has been applied to a Cart or Order */
export type GQLAppliedSurchargeMessageArgs = {
  language: Scalars['String'];
};

/** A summary of tax-related calculations for a cart or order group */
export class GQLTaxSummary {
  __typename?: 'TaxSummary';
  /** The time at which taxes were last calculated for the cart or order group */
  calculatedAt: Scalars['DateTime'];
  /** The name of the tax service that last calculated taxes for the cart or order group */
  calculatedByTaxServiceName?: Maybe<Scalars['String']>;
  /** A reference ID for the external system that calculated the taxes */
  referenceId?: Maybe<Scalars['String']>;
  /** Total tax calculated by the active tax service */
  tax: GQLMoney;
  /** Amount that was deemed subject to any taxes by the active tax service */
  taxableAmount: GQLMoney;
  /** Full list of all taxes that were calculated by the active tax service for the cart or order group */
  taxes: Array<Maybe<GQLCalculatedTax>>;
};

/** An order */
export class GQLOrder  implements GQLNode {
  __typename?: 'Order';
  /** The Order ID */
  _id: Scalars['ID'];
  /** The account that placed the order. Some orders are created for anonymous users. Anonymous orders have a null account. */
  account?: Maybe<GQLAccount>;
  /** The ID of the cart that created this order. Carts are deleted after becoming orders, so this is just a reference. */
  cartId?: Maybe<Scalars['ID']>;
  /** The date and time at which the cart was created, which is when the first item was added to it. */
  createdAt: Scalars['DateTime'];
  /** The order status for display in UI */
  displayStatus: Scalars['String'];
  /** An email address that has been associated with the cart */
  email?: Maybe<Scalars['String']>;
  /** One or more fulfillment groups. Each of these are fulfilled and charged as separate orders. */
  fulfillmentGroups: Array<Maybe<GQLOrderFulfillmentGroup>>;
  /** Notes about the order. This will always return an array but it may be empty */
  notes: Array<Maybe<GQLOrderNote>>;
  /**
   * Payments that collectively have paid or will pay for the total amount due for this order.
   * May be null if no payment is needed.
   */
  payments?: Maybe<Array<Maybe<GQLPayment>>>;
  /**
   * An ID by which the customer can reference this order when enquiring about it. A storefront user
   * interface may show this to customers. Do not display other IDs (`_id`) to customers.
   */
  referenceId: Scalars['String'];
  /** Refunds that have been applied to the payments on this order. */
  refunds?: Maybe<Array<Maybe<GQLRefund>>>;
  /** The shop through which the order was placed */
  shop: GQLShop;
  /** The machine-readable order status. */
  status: Scalars['String'];
  /** A summary of the totals for all fulfillment groups for this order */
  summary: GQLOrderSummary;
  /** Total quantity of all items in the order */
  totalItemQuantity: Scalars['Int'];
  /** The date and time at which this order was last updated */
  updatedAt: Scalars['DateTime'];
  /** Surcharges applied to this order */
  surcharges: Array<Maybe<GQLAppliedSurcharge>>;
};


/** An order */
export type GQLOrderDisplayStatusArgs = {
  language: Scalars['String'];
};

/** An order fulfillment group */
export class GQLOrderFulfillmentGroup  implements GQLNode {
  __typename?: 'OrderFulfillmentGroup';
  /** The order fulfillment group ID */
  _id: Scalars['ID'];
  /** Information needed by the selected fulfillment method to properly fulfill the order */
  data?: Maybe<GQLOrderFulfillmentGroupData>;
  /** The order status for display in UI */
  displayStatus: Scalars['String'];
  /** The items that are part of this fulfillment group */
  items?: Maybe<GQLOrderItemConnection>;
  /** The fulfillment method that was selected, with its price quote */
  selectedFulfillmentOption: GQLFulfillmentOption;
  /** The shipping label URL */
  shippingLabelUrl?: Maybe<Scalars['String']>;
  /** The shop responsible for fulfilling this order */
  shop: GQLShop;
  /** The machine-readable fulfillment group status. */
  status: Scalars['String'];
  /** A summary of the totals for this group */
  summary: GQLOrderSummary;
  /** Total quantity of all items in the group */
  totalItemQuantity: Scalars['Int'];
  /** The order fulfillment group shipment tracking number */
  tracking?: Maybe<Scalars['String']>;
  /** The order fulfillment group shipment tracking URL */
  trackingUrl?: Maybe<Scalars['String']>;
  /** The fulfillment type. Any valid type that has been registered by a fulfillment plugin. Examples: `shipping`, `digital` */
  type: FulfillmentType;
  /** A summary of calculated taxes for this group. */
  taxSummary?: Maybe<GQLTaxSummary>;
};


/** An order fulfillment group */
export type GQLOrderFulfillmentGroupDisplayStatusArgs = {
  language: Scalars['String'];
};


/** An order fulfillment group */
export type GQLOrderFulfillmentGroupItemsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<OrderFulfillmentGroupItemsSortByField>;
};

/** Extra data for an order fulfillment group */
export type GQLOrderFulfillmentGroupData = GQLShippingOrderFulfillmentGroupData;

/** Extra data for an order fulfillment group with type `shipping` */
export class GQLShippingOrderFulfillmentGroupData {
  __typename?: 'ShippingOrderFulfillmentGroupData';
  /** The address to ship to */
  shippingAddress: GQLAddress;
};

/** Allowed values for the `OrderFulfillmentGroupItems` sortBy parameter */
export enum OrderFulfillmentGroupItemsSortByField {
  /** Sort by the item ID */
  Id = '_id',
  /** Sort by the date and time when the item was added to the order */
  AddedAt = 'addedAt'
}

/**
 * Wraps a list of `OrderItem`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLOrderItemConnection {
  __typename?: 'OrderItemConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLOrderItemEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLOrderItem>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `OrderItem` object */
export class GQLOrderItemEdge  implements GQLNodeEdge {
  __typename?: 'OrderItemEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The order item */
  node?: Maybe<GQLOrderItem>;
};

/** A single item in an order. The item contains information about a purchase. */
export class GQLOrderItem  implements GQLNode {
  __typename?: 'OrderItem';
  /** The order item ID */
  _id: Scalars['ID'];
  /**
   * "
   * The date and time at which this item was first added to the associated cart.
   * If an item is added, removed, and then added again, this will reflect the most recent addition.
   * However, if an item is added twice, the quantity will increase but this date will remain
   * the initial added date.
   */
  addedAt?: Maybe<Scalars['DateTime']>;
  /**
   * FUTURE. Additional attributes of the chosen item. For example, if this item is for a product, socks, where `blue` and `small`
   * options were chosen for some configurable attributes, then `color:blue` and `size:small` will be indicated here.
   */
  attributes?: Maybe<Array<Maybe<GQLOrderItemAttribute>>>;
  /** If this order item is canceled, the reason for cancelation, if provided */
  cancelReason?: Maybe<Scalars['String']>;
  /** The date and time at which the order item was created */
  createdAt: Scalars['DateTime'];
  /** The URLs for a picture of the item in various sizes */
  imageURLs?: Maybe<GQLImageSizes>;
  /** Arbitrary additional metadata about this cart item. */
  metafields?: Maybe<Array<Maybe<GQLMetafield>>>;
  /** The short title of the associated option, if this is an option item */
  optionTitle?: Maybe<Scalars['String']>;
  /** Packing information such as item weight, height, length, and depth. Used for calculating shipping rates. */
  parcel?: Maybe<GQLShippingParcel>;
  /** The price of the item at the time of purchase */
  price: GQLMoney;
  /** The product and chosen options */
  productConfiguration: GQLProductConfiguration;
  /** The product's slug */
  productSlug?: Maybe<Scalars['String']>;
  /** The list of tags that have been applied to this product */
  productTags?: Maybe<GQLTagConnection>;
  /** The type of product, used to display cart items differently */
  productType?: Maybe<Scalars['String']>;
  /** The product vendor */
  productVendor?: Maybe<Scalars['String']>;
  /** The quantity of this item that has been added to the cart. This must be a positive integer. Remove this `CartItem` from it's associated cart if you want `0` of this item. */
  quantity: Scalars['Int'];
  /** The shop associated with this cart item. */
  shop: GQLShop;
  /** The machine-readable order item status. */
  status: Scalars['String'];
  /** The price of the item multiplied by the quantity of this item ordered */
  subtotal: GQLMoney;
  /** A title for use in orders that conveys the selected product's title + chosen options */
  title: Scalars['String'];
  /** The date and time at which this item was last updated */
  updatedAt: Scalars['DateTime'];
  /** The selected variant title */
  variantTitle?: Maybe<Scalars['String']>;
  /** Is this a taxable item? */
  isTaxable: Scalars['Boolean'];
  /** Total tax calculated for this item */
  tax: GQLMoney;
  /** The tax code for this item */
  taxCode?: Maybe<Scalars['String']>;
  /** Amount of subtotal that is taxable */
  taxableAmount: GQLMoney;
  /** List of calculated taxes due for this item */
  taxes: Array<Maybe<GQLCalculatedTax>>;
};


/** A single item in an order. The item contains information about a purchase. */
export type GQLOrderItemProductTagsArgs = {
  after?: Maybe<Scalars['ConnectionCursor']>;
  before?: Maybe<Scalars['ConnectionCursor']>;
  first?: Maybe<Scalars['ConnectionLimitInt']>;
  last?: Maybe<Scalars['ConnectionLimitInt']>;
  sortOrder?: Maybe<SortOrder>;
  sortBy?: Maybe<TagSortByField>;
};

/** One attribute of an order item */
export class GQLOrderItemAttribute {
  __typename?: 'OrderItemAttribute';
  /** The attribute label, e.g., Color */
  label?: Maybe<Scalars['String']>;
  /** The attribute value, e.g., Blue */
  value?: Maybe<Scalars['String']>;
};

/** A summary of the totals for this order */
export class GQLOrderSummary {
  __typename?: 'OrderSummary';
  /** The total of all discounts applied, as a positive number */
  discountTotal: GQLMoney;
  /** The calculated tax-exclusive tax rate on all items and fulfillment prices (taxTotal / taxableAmount) */
  effectiveTaxRate: GQLRate;
  /** The total price of all chosen fulfillment methods */
  fulfillmentTotal: GQLMoney;
  /** The combined prices of all cart items */
  itemTotal: GQLMoney;
  /** The total estimated tax that has not already been included in the item prices */
  taxTotal: GQLMoney;
  /** The total amount that was deemed taxable by the tax service */
  taxableAmount: GQLMoney;
  /** The sum of `itemTotal`, `fulfillmentTotal`, and `taxTotal`, minus `discountTotal` */
  total: GQLMoney;
  /** The total of all suurcharges applied */
  surchargeTotal?: Maybe<GQLMoney>;
};

/** A note about an order */
export class GQLOrderNote {
  __typename?: 'OrderNote';
  /** The account who wrote this note */
  account: GQLAccount;
  /** The content of the note */
  content: Scalars['String'];
  /** The date and time at which this note was created */
  createdAt: Scalars['DateTime'];
  /** The date and time at which this note was last updated */
  updatedAt: Scalars['DateTime'];
};

/** Information about a payment made */
export class GQLPayment  implements GQLNode {
  __typename?: 'Payment';
  /** The Payment ID */
  _id: Scalars['ID'];
  /**
   * The amount that will be applied to this payment method. If there are multiple payment methods applied to the
   * cart, this may be less than the cart total.
   */
  amount: GQLMoney;
  /** The billing address for this payment, if one was collected */
  billingAddress?: Maybe<GQLAddress>;
  /** If status is `error` due to a capture error, this code describes the error in a machine-readable way. */
  captureErrorCode?: Maybe<Scalars['String']>;
  /** If status is `error` due to a capture error, this code describes the error in a human-readable way. */
  captureErrorMessage?: Maybe<Scalars['String']>;
  /** For card payments, the brand of the card. Useful for showing card icons for common brands. */
  cardBrand?: Maybe<Scalars['String']>;
  /** The date and time at which this payment was created */
  createdAt: Scalars['DateTime'];
  /**
   * The shopper-provided data needed to complete the payment using this method.
   * For example, a billing address, store credit code, stored credit card ID, etc.
   */
  data?: Maybe<GQLPaymentData>;
  /** Use this identifier when showing this payment in a user interface */
  displayName: Scalars['String'];
  /** Has the payment authorization been canceled? */
  isAuthorizationCanceled: Scalars['Boolean'];
  /** Has the payment been captured? If false, it is just an authorization. */
  isCaptured: Scalars['Boolean'];
  /** The payment method */
  method: GQLPaymentMethod;
  /** The payment mode */
  mode?: Maybe<Scalars['String']>;
  /** The payment processor */
  processor?: Maybe<Scalars['String']>;
  /** Refunds that have been applied to this payment. */
  refunds?: Maybe<Array<Maybe<GQLRefund>>>;
  /** Risk level of payment */
  riskLevel?: Maybe<RiskLevel>;
  /** The current status of this payment */
  status: PaymentStatus;
  /** The payment transaction ID */
  transactionId?: Maybe<Scalars['String']>;
};

/** Data identifying a payment for an order */
export type GQLPaymentData = GQLFakeData | GQLStripeCardPaymentData | GQLExampleIouPaymentData;

/** Do not use this */
export class GQLFakeData {
  __typename?: 'FakeData';
  /** Do not use this */
  doNotUse?: Maybe<Scalars['String']>;
};

/** Data for a Stripe card payment */
export class GQLStripeCardPaymentData {
  __typename?: 'StripeCardPaymentData';
  /** The Stripe charge ID */
  chargeId: Scalars['String'];
  /** The Stripe customer ID, if a Stripe customer exists for this charge */
  customerId?: Maybe<Scalars['String']>;
};

/** Data for an example IOU payment */
export class GQLExampleIouPaymentData {
  __typename?: 'ExampleIOUPaymentData';
  /** The name of the IOU payer entered by the shopper */
  fullName: Scalars['String'];
};

/** Describes a payment method */
export class GQLPaymentMethod {
  __typename?: 'PaymentMethod';
  /** If this is `false`, the payment method does not support refunding. Use this to hide refund UI. */
  canRefund: Scalars['Boolean'];
  /** Data for this method. The data format differs for each method */
  data?: Maybe<GQLPaymentMethodData>;
  /** Human-readable display name */
  displayName: Scalars['String'];
  /** Whether the payment method is enabled on a given shop */
  isEnabled: Scalars['Boolean'];
  /** The payment method name. Any valid name that has been registered by a payment plugin. e.g., saved_card */
  name: Scalars['String'];
  /** Name of the plugin that added the payment method */
  pluginName: Scalars['String'];
};

/** Any extra data needed by the payment method */
export type GQLPaymentMethodData = GQLFakeData | GQLExampleIouPaymentMethodData;

/** Data for an example IOU payment method */
export class GQLExampleIouPaymentMethodData {
  __typename?: 'ExampleIOUPaymentMethodData';
  /** Example */
  example: Scalars['String'];
};

/** A refund of a payment on an order */
export class GQLRefund  implements GQLNode {
  __typename?: 'Refund';
  /** The refund ID */
  _id: Scalars['ID'];
  /** The amount of the refund */
  amount: GQLMoney;
  /** The date and time at which the refund was created */
  createdAt: Scalars['DateTime'];
  /** The display name of the payment refunded to */
  paymentDisplayName: Scalars['String'];
  /** The ID of the payment this refund is applied to */
  paymentId: Scalars['ID'];
  /** The reason for the refund */
  reason?: Maybe<Scalars['String']>;
};

/** Valid payment risk levels */
export enum RiskLevel {
  /** An elevated risk level for a payment */
  Elevated = 'elevated',
  /** The highest risk level for a payment */
  Highest = 'highest',
  /** A normal risk level for a payment */
  Normal = 'normal'
}

/** Valid payment statuses */
export enum PaymentStatus {
  /** A shop operator adjusted the payment amount after the order was placed */
  Adjustments = 'adjustments',
  /** A shop operator has approved this payment */
  Approved = 'approved',
  /** A shop operator has canceled this payment before it was captured */
  Canceled = 'canceled',
  /** A shop operator has captured this payment */
  Completed = 'completed',
  /** Upon placing an order, the status of all payments for that order begins at 'created' */
  Created = 'created',
  /** There was an error capturing the payment */
  Error = 'error',
  /** A shop operator has refunded some but not all of this payment */
  PartialRefund = 'partialRefund',
  /** A shop operator has refunded all of this payment */
  Refunded = 'refunded'
}

/** Input type for filters to by applied to an Orders list */
export type GQLOrderFilterInput = {
  /** A createdAt date range to filter by */
  createdAt?: Maybe<GQLCreatedAtInput>;
  /** An order's fulfillment status */
  fulfillmentStatus?: Maybe<Array<Maybe<OrderFulfillmentStatus>>>;
  /** An order's payment status */
  paymentStatus?: Maybe<Array<Maybe<OrderPaymentStatus>>>;
  /** Keywords typed by the user in the search input field */
  searchField?: Maybe<Scalars['String']>;
  /** The order's status to filter by */
  status?: Maybe<OrderStatus>;
};

/** Input for the createdAt database field */
export type GQLCreatedAtInput = {
  /** Start date, inclusive */
  gte?: Maybe<Scalars['DateTime']>;
  /** End date, inclusive */
  lte?: Maybe<Scalars['DateTime']>;
};

/** Available order fulfillment statuses */
export enum OrderFulfillmentStatus {
  /** An order that has been completed */
  Completed = 'completed',
  /** Newly created order that needs processing */
  New = 'new',
  /** An order that is currently being processed */
  Processing = 'processing'
}

/** Order payment status */
export enum OrderPaymentStatus {
  /** Payments that have been successfully processed */
  Completed = 'completed',
  /** A payment intent has been created */
  Created = 'created'
}

/** Order status */
export enum OrderStatus {
  /** Canceled order */
  Canceled = 'canceled',
  /** A completed order */
  Completed = 'completed',
  /** A new order that needs processing */
  New = 'new',
  /** An order that is being processed */
  Processing = 'processing'
}

/** The fields by which you are allowed to sort any query that returns a `OrderConnection` */
export enum OrdersSortByField {
  /** Sort by the order ID */
  Id = '_id',
  /** Sort by the date and time when the order was placed */
  CreatedAt = 'createdAt'
}

/**
 * Wraps a list of `Order`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLOrderConnection {
  __typename?: 'OrderConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLOrderEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLOrder>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Order` object */
export class GQLOrderEdge  implements GQLNodeEdge {
  __typename?: 'OrderEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The order */
  node?: Maybe<GQLOrder>;
};

/** The fields by which you are allowed to sort any query that returns a `OrdersByAccountIdConnection` */
export enum OrdersByAccountIdSortByField {
  /** Sort by the order ID */
  Id = '_id',
  /** Sort by the date and time when the order was placed */
  CreatedAt = 'createdAt'
}

/**
 * Wraps a list of `Order`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLOrdersByAccountIdConnection {
  __typename?: 'OrdersByAccountIdConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLOrdersByAccountIdEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLOrder>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Order` object */
export class GQLOrdersByAccountIdEdge  implements GQLNodeEdge {
  __typename?: 'OrdersByAccountIdEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The order */
  node?: Maybe<GQLOrder>;
};

/** Input type for filters to be applied to an discount codes list */
export type GQLDiscountCodeFilterInput = {
  /** Keywords typed by the user in the search input field */
  searchField?: Maybe<Scalars['String']>;
};

/**
 * Wraps a list of DiscountCode`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLDiscountCodeConnection {
  __typename?: 'DiscountCodeConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLDiscountCodeEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLDiscountCode>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `DiscountCode` object */
export class GQLDiscountCodeEdge {
  __typename?: 'DiscountCodeEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The discount code */
  node?: Maybe<GQLDiscountCode>;
};

/** A discount code */
export class GQLDiscountCode {
  __typename?: 'DiscountCode';
  /** Discount code ID */
  _id: Scalars['ID'];
  /** How the discount should be applied */
  calculation?: Maybe<GQLDiscountCalculation>;
  /** Discount Code */
  code: Scalars['String'];
  /** Discount code conditions */
  conditions?: Maybe<GQLDiscountConditions>;
  /** Description to describe the discount code */
  description?: Maybe<Scalars['String']>;
  /**
   * Discount is allowed to be string or number.
   * it's a formula value (could be shipping code)
   */
  discount?: Maybe<Scalars['String']>;
  /** Discount method type */
  discountMethod?: Maybe<DiscountMethod>;
  /** Label to describe the code */
  label?: Maybe<Scalars['String']>;
  /** The shop to which this DiscountCode belongs to */
  shop: GQLShop;
  /** History of transactions */
  transactions?: Maybe<Array<Maybe<GQLDiscountTransaction>>>;
};

/** Discount code calculation */
export class GQLDiscountCalculation {
  __typename?: 'DiscountCalculation';
  /** Discount code calculation method */
  method?: Maybe<DiscountCalculationMethod>;
};

/** Discount calculation types */
export enum DiscountCalculationMethod {
  /** Store credit */
  Credit = 'credit',
  /** Discount of order */
  Discount = 'discount',
  /** Sale on an item */
  Sale = 'sale',
  /** Discount to shipping */
  Shipping = 'shipping'
}

/** Conditions for a discount code to be applied */
export class GQLDiscountConditions {
  __typename?: 'DiscountConditions';
  /** Account Limit */
  accountLimit?: Maybe<Scalars['Int']>;
  /** Audience that may apply this discount code */
  audience?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Is this discount code enabled */
  enabled: Scalars['Boolean'];
  /** Order conditions */
  order?: Maybe<GQLDiscountConditionOrder>;
  /** Permissions that may apply this discount code */
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Products that may apply this discount code */
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Number of times this code may be redeemed.
   * Setting to 100 means the first 100 customers may apply this code.
   * Setting this value to 0 will allow this code to be applied an infinite number of times.
   */
  redemptionLimit?: Maybe<Scalars['Int']>;
  /** Tags that may be apply this discount code */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The conditions an order must meet for a discount code to be applied */
export class GQLDiscountConditionOrder {
  __typename?: 'DiscountConditionOrder';
  /** Order date range end */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Maximum order value */
  max?: Maybe<Scalars['Float']>;
  /** Minimum order value */
  min: Scalars['Float'];
  /** Order date range start */
  startDate?: Maybe<Scalars['DateTime']>;
};

/** Discount method types */
export enum DiscountMethod {
  /** Code type */
  Code = 'code',
  /** Rate type */
  Rate = 'rate'
}

/** Transaction history for a discount code */
export class GQLDiscountTransaction {
  __typename?: 'DiscountTransaction';
  /** Date the code was applied */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** Cart id */
  cartId: Scalars['String'];
  /** User id */
  userId: Scalars['String'];
};

/** Allowed values for surcharge `sortBy` parameter */
export enum SurchargeSortByField {
  /** The date the surcharge definition was created */
  CreatedAt = 'createdAt'
}

/**
 * Wraps a list of `Surcharge`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLSurchargeConnection {
  __typename?: 'SurchargeConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLSurchargeEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLSurcharge>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** Total count for all pages */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `Surcharge` object */
export class GQLSurchargeEdge {
  __typename?: 'SurchargeEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The surcharge */
  node?: Maybe<GQLSurcharge>;
};

/** Defines a surcharge for surchargeById and surcharges query. */
export class GQLSurcharge  implements GQLNode {
  __typename?: 'Surcharge';
  /** The surcharge ID. */
  _id: Scalars['ID'];
  /** Amount. */
  amount: GQLMoney;
  /** Attribute restrictions. */
  attributes?: Maybe<Array<Maybe<GQLSurchargeAttributeRestrictions>>>;
  /** The date and time at which this surcharge was created */
  createdAt: Scalars['DateTime'];
  /** Destination restrictions. */
  destination?: Maybe<GQLSurchargeDestinationRestrictions>;
  /** Message translated into provided / default language. */
  message: Scalars['String'];
  /** Messages provided with content and all languages */
  messagesByLanguage?: Maybe<Array<Maybe<GQLSurchargeMessagesByLanguage>>>;
  /** Method IDs to apply this surcharge to. */
  methodIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The shop ID */
  shopId: Scalars['ID'];
  /** The type of this surcharge. Allowed types `surcharge`. */
  type: SurchargeTypeEnum;
  /** The date and time at which this surcharge was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};


/** Defines a surcharge for surchargeById and surcharges query. */
export type GQLSurchargeMessageArgs = {
  language: Scalars['String'];
};

/** Attribute Restrictions attached to a Surcharge */
export class GQLSurchargeAttributeRestrictions {
  __typename?: 'SurchargeAttributeRestrictions';
  /** The operator to use for value comparison */
  operator?: Maybe<Scalars['String']>;
  /** The property to check */
  property?: Maybe<Scalars['String']>;
  /** The type of this property */
  propertyType?: Maybe<SurchargePropertyType>;
  /** The value to check for */
  value?: Maybe<Scalars['String']>;
};

/** A list of the possible property types for surcharges */
export enum SurchargePropertyType {
  /** Boolean */
  Bool = 'bool',
  /** Float */
  Float = 'float',
  /** Integer */
  Int = 'int',
  /** String */
  String = 'string'
}

/**
 * Destination restrictions attached to a surcharge. If multiple of `country`,
 * `region`, and `postal` are set, there is an AND relationship.
 */
export class GQLSurchargeDestinationRestrictions {
  __typename?: 'SurchargeDestinationRestrictions';
  /** Restrict for any of these destination countries */
  country?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination postal codes */
  postal?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination regions */
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Object that includes translated content and language of translation */
export class GQLSurchargeMessagesByLanguage {
  __typename?: 'SurchargeMessagesByLanguage';
  /** The message for this language */
  content: Scalars['String'];
  /** The language code */
  language: Scalars['String'];
};

/** Allowed values for surcharge type */
export enum SurchargeTypeEnum {
  /** Surcharge */
  Surcharge = 'surcharge'
}

/** Defines a fulfillment method that has a fixed price. This type is provided by the `flat-rate` fulfillment plugin. */
export class GQLFlatRateFulfillmentMethod  implements GQLNode {
  __typename?: 'FlatRateFulfillmentMethod';
  /** The flat rate fulfillment method ID */
  _id: Scalars['ID'];
  /** The cost of this fulfillment method to the shop, if you track this */
  cost?: Maybe<Scalars['Float']>;
  /** The fulfillment types for which this method may be used. For example, `shipping` or `digital`. */
  fulfillmentTypes: Array<Maybe<FulfillmentType>>;
  /** The group to which this method belongs */
  group: Scalars['String'];
  /** A fixed price to charge for handling costs when this fulfillment method is selected for an order */
  handling: Scalars['Float'];
  /** Include this as a fulfillment option shown to shoppers during checkout? */
  isEnabled: Scalars['Boolean'];
  /** The name of this method, for display in the user interface */
  label: Scalars['String'];
  /** The name of this method, a unique identifier */
  name: Scalars['String'];
  /** A fixed price to charge for fulfillment costs when this fulfillment method is selected for an order */
  rate: Scalars['Float'];
  /** The shop to which this fulfillment method belongs */
  shop: GQLShop;
};

/**
 * Wraps a list of FlatRateFulfillmentMethods`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLFlatRateFulfillmentMethodConnection {
  __typename?: 'FlatRateFulfillmentMethodConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLFlatRateFulfillmentMethodEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLFlatRateFulfillmentMethod>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `FlatRateFulfillmentMethod` object */
export class GQLFlatRateFulfillmentMethodEdge {
  __typename?: 'FlatRateFulfillmentMethodEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The fulfillment method */
  node?: Maybe<GQLFlatRateFulfillmentMethod>;
};

/** Allowed values for `FlatRateFulfillmentRestriction` sortBy parameter */
export enum FlatRateFulfillmentRestrictionSortByField {
  /** Date the restriction was created */
  CreatedAt = 'createdAt'
}

/**
 * Wraps a list of `FlatRateFulfillmentRestriction`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLFlatRateFulfillmentRestrictionConnection {
  __typename?: 'FlatRateFulfillmentRestrictionConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLFlatRateFulfillmentRestrictionEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLFlatRateFulfillmentRestriction>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `FlatRateFulfillmentRestriction` object */
export class GQLFlatRateFulfillmentRestrictionEdge {
  __typename?: 'FlatRateFulfillmentRestrictionEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The flat rate fulfillment restriction */
  node?: Maybe<GQLFlatRateFulfillmentRestriction>;
};

/** Defines a flat rate fulfillment method restriction. */
export class GQLFlatRateFulfillmentRestriction  implements GQLNode {
  __typename?: 'FlatRateFulfillmentRestriction';
  /** The restriction ID. */
  _id: Scalars['ID'];
  /** Attribute restrictions. Multiple attribute restrictions are evaluated with AND. If both destination and attribute restrictions are present, they evaluate with AND. */
  attributes?: Maybe<Array<Maybe<GQLAttributeRestrictions>>>;
  /** Destination restrictions. If multiple destination restrictions are present, the most localized is the only one evaluated (i.e. evaluate postal if present, then region if present, then country). If both destination and attribute restrictions are present, they evaluate with AND. */
  destination?: Maybe<GQLDestinationRestrictions>;
  /** Method IDs to apply this restriction to. If none, applies to all methods as a universal restriction. */
  methodIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The shop ID */
  shopId: Scalars['ID'];
  /** The type of this restriction. Allowed types are `allow` or `deny`. */
  type: RestrictionTypeEnum;
};

/** An attribute restriction condition */
export class GQLAttributeRestrictions {
  __typename?: 'AttributeRestrictions';
  /** The operator to use for value comparison */
  operator: Scalars['String'];
  /** The property to check */
  property: Scalars['String'];
  /** The type of this property */
  propertyType: Scalars['String'];
  /** The value to check for */
  value: Scalars['String'];
};

/**
 * Destination restriction conditions. If multiple of `country`,
 * `region`, and `postal` are set, there is an AND relationship.
 */
export class GQLDestinationRestrictions {
  __typename?: 'DestinationRestrictions';
  /** Restrict for any of these destination countries */
  country?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination postal codes */
  postal?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination regions */
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Restriction type */
export enum RestrictionTypeEnum {
  /** Allow */
  Allow = 'allow',
  /** Deny */
  Deny = 'deny'
}

/** A tax code that may be used on a product to indicate proper taxation category */
export class GQLTaxCode {
  __typename?: 'TaxCode';
  /** The code */
  code: Scalars['String'];
  /** Short description of what types of products the code is for */
  label: Scalars['String'];
};

/** A service registered by a tax plugin, that provides tax codes and calculations */
export class GQLTaxService {
  __typename?: 'TaxService';
  /** Human-readable display name */
  displayName: Scalars['String'];
  /** The tax service name. Any valid name that has been registered by a tax plugin. */
  name: Scalars['String'];
  /** Name of the plugin that added the tax service */
  pluginName: Scalars['String'];
};

/**
 * Wraps a list of TaxRate`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLTaxRateConnection {
  __typename?: 'TaxRateConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLTaxRateEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLTaxRate>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `TaxRate` object */
export class GQLTaxRateEdge {
  __typename?: 'TaxRateEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The tax rate */
  node?: Maybe<GQLTaxRate>;
};

/** A single calculated tax for a cart, order group, cart item, or order item */
export class GQLTaxRate {
  __typename?: 'TaxRate';
  /** Tax rate ID */
  _id: Scalars['ID'];
  /** An optional country code to limit where this tax is applied, in conjunction with `sourcing` field */
  country?: Maybe<Scalars['String']>;
  /** An optional postal code to limit where this tax is applied, in conjunction with `sourcing` field */
  postal?: Maybe<Scalars['String']>;
  /** The tax rate. For example, 0.05 for a 5% sales tax. */
  rate: Scalars['Float'];
  /** An optional region (e.g., state) to limit where this tax is applied, in conjunction with `sourcing` field */
  region?: Maybe<Scalars['String']>;
  /** The shop to which this TaxRate belongs */
  shop: GQLShop;
  /** Whether the `country`, `postal`, and `region` filters apply to the origin address or the destination address */
  sourcing: TaxSource;
  /** An optional tax code, to apply this tax rate to only products that have this tax code */
  taxCode?: Maybe<Scalars['String']>;
};

/** The fields by which you are allowed to sort any query that returns a `NavigationItemConnection` */
export enum NavigationItemSortByField {
  /** Sort by NavigationItem ID */
  Id = '_id',
  /** Sort by when the NavigationItem was created */
  CreatedAt = 'createdAt'
}

/**
 * Wraps a list of `NavigationItem`s, providing pagination cursors and information.
 *
 * For information about what Relay-compatible connections are and how to use them, see the following articles:
 * - [Relay Connection Documentation](https://facebook.github.io/relay/docs/en/graphql-server-specification.html#connections)
 * - [Relay Connection Specification](https://facebook.github.io/relay/graphql/connections.htm)
 * - [Using Relay-style Connections With Apollo Client](https://www.apollographql.com/docs/react/recipes/pagination.html)
 */
export class GQLNavigationItemConnection {
  __typename?: 'NavigationItemConnection';
  /** The list of nodes that match the query, wrapped in an edge to provide a cursor string for each */
  edges?: Maybe<Array<Maybe<GQLNavigationItemEdge>>>;
  /**
   * You can request the `nodes` directly to avoid the extra wrapping that `NodeEdge` has,
   * if you know you will not need to paginate the results.
   */
  nodes?: Maybe<Array<Maybe<GQLNavigationItem>>>;
  /** Information to help a client request the next or previous page */
  pageInfo: GQLPageInfo;
  /** The total number of nodes that match your query */
  totalCount: Scalars['Int'];
};

/** A connection edge in which each node is a `NavigationItem` object */
export class GQLNavigationItemEdge  implements GQLNodeEdge {
  __typename?: 'NavigationItemEdge';
  /** The cursor that represents this node in the paginated results */
  cursor: Scalars['ConnectionCursor'];
  /** The navigation item */
  node?: Maybe<GQLNavigationItem>;
};

/** Generated sitemap XML for a single shop */
export class GQLSitemap {
  __typename?: 'Sitemap';
  /** Date created */
  createdAt: Scalars['Date'];
  /** The sitemap handle */
  handle: Scalars['String'];
  /** The shop ID */
  shopId: Scalars['String'];
  /** The Sitemap XML content */
  xml: Scalars['String'];
};


/** Mutations have side effects, such as mutating data or triggering a task */
export class GQLMutation {
  __typename?: 'Mutation';
  /** A test mutation that returns whatever string you send it */
  echo?: Maybe<Scalars['String']>;
  /** Archive a MediaRecord to hide it without deleting the backing file data */
  archiveMediaRecord: GQLArchiveMediaRecordPayload;
  /** Create the MediaRecord for file data after you upload it */
  createMediaRecord: GQLCreateMediaRecordPayload;
  /** Delete a MediaRecord to delete both the record and the backing file data */
  deleteMediaRecord: GQLDeleteMediaRecordPayload;
  /** Update the priority metadata for a MediaRecord. Used for sorting product and variant media in the catalog. */
  updateMediaRecordPriority: GQLUpdateMediaRecordPriorityPayload;
  /** Create a new shop */
  createShop: GQLCreateShopPayload;
  /** Given shop data, update the Shops collection with this data */
  updateShop: GQLUpdateShopPayload;
  /**
   * Returns app settings that are not shop specific. Plugins extend the GlobalSettings type to support
   * whatever settings they need.
   */
  updateGlobalSettings: GQLUpdateGlobalSettingsPayload;
  /**
   * Returns app settings for a specific shop. Plugins extend the ShopSettings type to support
   * whatever settings they need.
   */
  updateShopSettings: GQLUpdateShopSettingsPayload;
  /** Retry a failed or cancelled email job */
  retryFailedEmail: GQLRetryFailedEmailPayload;
  /** Create an address validation rule */
  createAddressValidationRule: GQLCreateAddressValidationRulePayload;
  /** Delete an address validation rule */
  deleteAddressValidationRule: GQLDeleteAddressValidationRulePayload;
  /** Update an address validation rule */
  updateAddressValidationRule: GQLUpdateAddressValidationRulePayload;
  /** Use this mutation to verify the SMTP email settings */
  verifySMTPEmailSettings: GQLVerifySmtpEmailSettingsInputPayload;
  /** Updates an existing template */
  updateTemplate: GQLUpdateTemplatePayload;
  /** Add a new address to the `addressBook` field for an account */
  addAccountAddressBookEntry?: Maybe<GQLAddAccountAddressBookEntryPayload>;
  /** Add an email address to an account */
  addAccountEmailRecord?: Maybe<GQLAddAccountEmailRecordPayload>;
  /** Create an account based off a user */
  createAccount?: Maybe<GQLCreateAccountPayload>;
  /** Remove an address from the `addressBook` field for an account */
  removeAccountAddressBookEntry?: Maybe<GQLRemoveAccountAddressBookEntryPayload>;
  /** Remove an email address from an account */
  removeAccountEmailRecord?: Maybe<GQLRemoveAccountEmailRecordPayload>;
  /** Send a reset password email to an email address from an account */
  sendResetAccountPasswordEmail?: Maybe<GQLSendResetAccountPasswordEmailPayload>;
  /** Set default email address for an account */
  setAccountDefaultEmail?: Maybe<GQLSetAccountDefaultEmailPayload>;
  /** Remove an address that exists in the `addressBook` field for an account */
  updateAccountAddressBookEntry?: Maybe<GQLUpdateAccountAddressBookEntryPayload>;
  /** Update account fields */
  updateAccount?: Maybe<GQLUpdateAccountPayload>;
  /** Add an account to a group */
  addAccountToGroup?: Maybe<GQLAddAccountToGroupPayload>;
  /** Create a new account group. These are usually used for account permissions */
  createAccountGroup?: Maybe<GQLCreateAccountGroupPayload>;
  /** Remove an account from a group */
  removeAccountFromGroup?: Maybe<GQLRemoveAccountFromGroupPayload>;
  /** Remove an existing account group */
  removeAccountGroup?: Maybe<GQLRemoveAccountGroupPayload>;
  /** Update an existing account group */
  updateAccountGroup?: Maybe<GQLUpdateAccountGroupPayload>;
  /**
   * Given a person's email address and name, invite them to create an account for a certain shop,
   * and put them in the provided permission group
   */
  inviteShopMember?: Maybe<GQLInviteShopMemberPayload>;
  /** Archive products */
  archiveProducts: GQLArchiveProductsPayload;
  /** Archive product variants */
  archiveProductVariants: GQLArchiveProductVariantsPayload;
  /** Update the isVisible property of an array of products */
  updateProductsVisibility: GQLUpdateProductsVisibilityPayload;
  /** Create a new product */
  createProduct: GQLCreateProductPayload;
  /** Create a new product variant */
  createProductVariant: GQLCreateProductVariantPayload;
  /** Clone an existing product */
  cloneProducts: GQLCloneProductsPayload;
  /** Clone an existing product variant */
  cloneProductVariants: GQLCloneProductVariantsPayload;
  /** Update an existing product */
  updateProduct: GQLUpdateProductPayload;
  /** Update an existing product variant */
  updateProductVariant: GQLUpdateProductVariantPayload;
  /** Bulk operation for adding an array of tags to an array of products */
  addTagsToProducts: GQLProductTagsOperationPayload;
  /** Bulk operation for removing an array of tags from an array of products */
  removeTagsFromProducts: GQLProductTagsOperationPayload;
  /** Publish products to the Catalog collection by product ID */
  publishProductsToCatalog?: Maybe<Array<Maybe<GQLCatalogItemProduct>>>;
  /** Adds a new tag */
  addTag: GQLAddTagPayload;
  /** Removes an existing tag */
  removeTag: GQLRemoveTagPayload;
  /** Add an image to the tag */
  setTagHeroMedia: GQLSetTagHeroMediaPayload;
  /** Updates an existing tag */
  updateTag: GQLUpdateTagPayload;
  /** Update an existing product variants prices */
  updateProductVariantPrices: GQLUpdateProductVariantPricesPayload;
  /** Force recalculation of the system-managed `inventoryReserved` field based on current order statuses */
  recalculateReservedSimpleInventory: GQLRecalculateReservedSimpleInventoryPayload;
  /** Update the SimpleInventory info for a product configuration */
  updateSimpleInventory: GQLUpdateSimpleInventoryPayload;
  /** Add item(s) to a cart */
  addCartItems: GQLAddCartItemsPayload;
  /** Create a new cart */
  createCart: GQLCreateCartPayload;
  /** Reconcile an anonymous cart with the current account cart for the same shop */
  reconcileCarts: GQLReconcileCartsPayload;
  /** Remove item(s) from a cart */
  removeCartItems: GQLRemoveCartItemsPayload;
  /** Set the email address for an anonymous cart */
  setEmailOnAnonymousCart: GQLSetEmailOnAnonymousCartPayload;
  /** Update cart item(s) quantity. Use absolute quantity. If updating to 0, the item will be removed. */
  updateCartItemsQuantity: GQLUpdateCartItemsQuantityPayload;
  /** Select a fulfillment option from the `availableFulfillmentOptions` list for a fulfillment group */
  selectFulfillmentOptionForGroup: GQLSelectFulfillmentOptionForGroupPayload;
  /** Set the shipping address for all fulfillment groups */
  setShippingAddressOnCart: GQLSetShippingAddressOnCartPayload;
  /**
   * Clients should call this as necessary during checkout to update the `availableFulfillmentOptions`
   * property for all fulfillment groups of the cart with fresh price quotes. These need to be
   * recalculated every time the items in that group change. When the order is placed, the chosen
   * option for each group will have its prices recalculated one last time. If the prices do not match,
   * order creation will fail.
   */
  updateFulfillmentOptionsForGroup: GQLUpdateFulfillmentOptionsForGroupPayload;
  /**
   * Use this mutation to add a new order fulfillment group to an order. It must have at least one
   * item. Items may be provided or moved from another existing group or both.
   */
  addOrderFulfillmentGroup: GQLAddOrderFulfillmentGroupPayload;
  /**
   * Use this mutation to cancel one item of an order, either for the full ordered quantity
   * or for a partial quantity. If partial, the item will be split into two items and the
   * original item will have a lower quantity and will be canceled.
   *
   * If this results in all items in a fulfillment group being canceled, the group will also
   * be canceled. If this results in all fulfillment groups being canceled, the full order will
   * also be canceled.
   */
  cancelOrderItem: GQLCancelOrderItemPayload;
  /** Use this mutation to create a refund on a payment method used to make the order */
  createRefund: GQLCreateRefundPayload;
  /** Use this mutation to move one or more items between existing order fulfillment groups. */
  moveOrderItems: GQLMoveOrderItemsPayload;
  /**
   * Use this mutation to place an order, providing information necessary to pay for it.
   * The order will be placed only if authorization is successful for all submitted payments.
   */
  placeOrder: GQLPlaceOrderPayload;
  /**
   * Use this mutation to reduce the quantity of one item of an order and create
   * a new item for the remaining quantity in the same fulfillment group, and with the
   * same item status. You may want to do this if you are only able to partially fulfill
   * the item order right now.
   */
  splitOrderItem: GQLSplitOrderItemPayload;
  /** Use this mutation to update order details after the order has been placed. */
  updateOrder: GQLUpdateOrderPayload;
  /** Use this mutation to update an order fulfillment group status and tracking information. */
  updateOrderFulfillmentGroup: GQLUpdateOrderFulfillmentGroupPayload;
  /** Approve one or more payments for an order */
  approveOrderPayments: GQLApproveOrderPaymentsPayload;
  /** Capture one or more payments for an order */
  captureOrderPayments: GQLCaptureOrderPaymentsPayload;
  /** Enable a payment method for a shop */
  enablePaymentMethodForShop: GQLEnablePaymentMethodForShopPayload;
  /** Create a new discount code */
  createDiscountCode?: Maybe<GQLCreateDiscountCodePayload>;
  /** Update a discount code */
  updateDiscountCode?: Maybe<GQLUpdateDiscountCodePayload>;
  /** Delete a discount code */
  deleteDiscountCode?: Maybe<GQLDeleteDiscountCodePayload>;
  /** Apply a discount code to a cart */
  applyDiscountCodeToCart: GQLApplyDiscountCodeToCartPayload;
  /** Remove a discount code from a cart */
  removeDiscountCodeFromCart: GQLRemoveDiscountCodeFromCartPayload;
  /** Create a surcharge */
  createSurcharge: GQLCreateSurchargePayload;
  /** Delete a flat rate fulfillment restriction */
  deleteSurcharge: GQLDeleteSurchargePayload;
  /** Update a flat rate fulfillment surcharge */
  updateSurcharge: GQLUpdateSurchargePayload;
  /** Create a flat rate fulfillment method */
  createFlatRateFulfillmentMethod: GQLCreateFlatRateFulfillmentMethodPayload;
  /** Update a flat rate fulfillment method */
  updateFlatRateFulfillmentMethod: GQLUpdateFlatRateFulfillmentMethodPayload;
  /** Delete a flat rate fulfillment method */
  deleteFlatRateFulfillmentMethod: GQLDeleteFlatRateFulfillmentMethodPayload;
  /** Create a flat rate fulfillment method restriction. */
  createFlatRateFulfillmentRestriction: GQLCreateFlatRateFulfillmentRestrictionPayload;
  /** Delete a flat rate fulfillment method restriction */
  deleteFlatRateFulfillmentRestriction: GQLDeleteFlatRateFulfillmentRestrictionPayload;
  /** Update a flat rate fulfillment method restriction */
  updateFlatRateFulfillmentRestriction: GQLUpdateFlatRateFulfillmentRestrictionPayload;
  /** Create a new tax rate */
  createTaxRate?: Maybe<GQLCreateTaxRatePayload>;
  /** Update a tax rate */
  updateTaxRate?: Maybe<GQLUpdateTaxRatePayload>;
  /** Delete a tax rate */
  deleteTaxRate?: Maybe<GQLDeleteTaxRatePayload>;
  /** Create a new navigation item */
  createNavigationItem?: Maybe<GQLCreateNavigationItemPayload>;
  /** Create a new navigation tree */
  createNavigationTree: GQLCreateNavigationTreePayload;
  /** Delete a navigation item */
  deleteNavigationItem?: Maybe<GQLDeleteNavigationItemPayload>;
  /** Publish the draft structure for a navigation tree and the draft changes for all of its navigation items. Sets hasUnpublishedChanges to false on tree and its items */
  publishNavigationChanges?: Maybe<GQLPublishNavigationChangesPayload>;
  /** Update an existing navigation item's draft data. Sets hasUnpublishedChanges to true */
  updateNavigationItem?: Maybe<GQLUpdateNavigationItemPayload>;
  /** Update an existing navigation tree's draft items. Sets hasUnpublishedChanges to true */
  updateNavigationTree?: Maybe<GQLUpdateNavigationTreePayload>;
  /** Generate sitemap documents */
  generateSitemaps: GQLGenerateSitemapsPayload;
  /** Load generated products and tags */
  loadProductsAndTags: GQLLoadProductsAndTagsPayload;
  /** Load generated orders */
  loadOrders: GQLLoadOrdersPayload;
  /** Load product images */
  loadProductImages: GQLLoadProductImagesPayload;
  /** Remove all products, tags and orders from the database */
  removeAllData: GQLRemoveDataPayload;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationEchoArgs = {
  str?: Maybe<Scalars['String']>;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationArchiveMediaRecordArgs = {
  input: GQLArchiveMediaRecordInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateMediaRecordArgs = {
  input: GQLCreateMediaRecordInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteMediaRecordArgs = {
  input: GQLDeleteMediaRecordInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateMediaRecordPriorityArgs = {
  input: GQLUpdateMediaRecordPriorityInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateShopArgs = {
  input: GQLCreateShopInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateShopArgs = {
  input: GQLUpdateShopInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateGlobalSettingsArgs = {
  input: GQLUpdateGlobalSettingsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateShopSettingsArgs = {
  input: GQLUpdateShopSettingsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRetryFailedEmailArgs = {
  input: GQLRetryFailedEmailInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateAddressValidationRuleArgs = {
  input: GQLCreateAddressValidationRuleInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteAddressValidationRuleArgs = {
  input: GQLDeleteAddressValidationRuleInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateAddressValidationRuleArgs = {
  input: GQLUpdateAddressValidationRuleInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationVerifySmtpEmailSettingsArgs = {
  input: GQLVerifySmtpEmailSettingsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateTemplateArgs = {
  input: GQLUpdateTemplateInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationAddAccountAddressBookEntryArgs = {
  input: GQLAddAccountAddressBookEntryInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationAddAccountEmailRecordArgs = {
  input: GQLAddAccountEmailRecordInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateAccountArgs = {
  input: GQLCreateAccountInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveAccountAddressBookEntryArgs = {
  input: GQLRemoveAccountAddressBookEntryInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveAccountEmailRecordArgs = {
  input: GQLRemoveAccountEmailRecordInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationSendResetAccountPasswordEmailArgs = {
  input: GQLSendResetAccountPasswordEmailInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationSetAccountDefaultEmailArgs = {
  input: GQLSetAccountDefaultEmailInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateAccountAddressBookEntryArgs = {
  input: GQLUpdateAccountAddressBookEntryInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateAccountArgs = {
  input: GQLUpdateAccountInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationAddAccountToGroupArgs = {
  input: GQLAddAccountToGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateAccountGroupArgs = {
  input: GQLCreateAccountGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveAccountFromGroupArgs = {
  input: GQLRemoveAccountFromGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveAccountGroupArgs = {
  input: GQLRemoveAccountGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateAccountGroupArgs = {
  input: GQLUpdateAccountGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationInviteShopMemberArgs = {
  input: GQLInviteShopMemberInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationArchiveProductsArgs = {
  input: GQLArchiveProductsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationArchiveProductVariantsArgs = {
  input: GQLArchiveProductVariantsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateProductsVisibilityArgs = {
  input: GQLUpdateProductsVisibilityInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateProductArgs = {
  input: GQLCreateProductInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateProductVariantArgs = {
  input: GQLCreateProductVariantInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCloneProductsArgs = {
  input: GQLCloneProductsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCloneProductVariantsArgs = {
  input: GQLCloneProductVariantsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateProductArgs = {
  input: GQLUpdateProductInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateProductVariantArgs = {
  input: GQLUpdateProductVariantInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationAddTagsToProductsArgs = {
  input: GQLProductTagsOperationInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveTagsFromProductsArgs = {
  input: GQLProductTagsOperationInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationPublishProductsToCatalogArgs = {
  productIds: Array<Maybe<Scalars['ID']>>;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationAddTagArgs = {
  input: GQLAddTagInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveTagArgs = {
  input: GQLRemoveTagInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationSetTagHeroMediaArgs = {
  input: GQLSetTagHeroMediaInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateTagArgs = {
  input: GQLUpdateTagInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateProductVariantPricesArgs = {
  input: GQLUpdateProductVariantPricesInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRecalculateReservedSimpleInventoryArgs = {
  input: GQLRecalculateReservedSimpleInventoryInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateSimpleInventoryArgs = {
  input: GQLUpdateSimpleInventoryInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationAddCartItemsArgs = {
  input: GQLAddCartItemsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateCartArgs = {
  input: GQLCreateCartInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationReconcileCartsArgs = {
  input: GQLReconcileCartsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveCartItemsArgs = {
  input: GQLRemoveCartItemsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationSetEmailOnAnonymousCartArgs = {
  input: GQLSetEmailOnAnonymousCartInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateCartItemsQuantityArgs = {
  input: GQLUpdateCartItemsQuantityInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationSelectFulfillmentOptionForGroupArgs = {
  input: GQLSelectFulfillmentOptionForGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationSetShippingAddressOnCartArgs = {
  input: GQLSetShippingAddressOnCartInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateFulfillmentOptionsForGroupArgs = {
  input: GQLUpdateFulfillmentOptionsForGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationAddOrderFulfillmentGroupArgs = {
  input: GQLAddOrderFulfillmentGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCancelOrderItemArgs = {
  input: GQLCancelOrderItemInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateRefundArgs = {
  input: GQLCreateRefundInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationMoveOrderItemsArgs = {
  input: GQLMoveOrderItemsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationPlaceOrderArgs = {
  input: GQLPlaceOrderInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationSplitOrderItemArgs = {
  input: GQLSplitOrderItemInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateOrderArgs = {
  input: GQLUpdateOrderInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateOrderFulfillmentGroupArgs = {
  input: GQLUpdateOrderFulfillmentGroupInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationApproveOrderPaymentsArgs = {
  input: GQLApproveOrderPaymentsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCaptureOrderPaymentsArgs = {
  input: GQLCaptureOrderPaymentsInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationEnablePaymentMethodForShopArgs = {
  input: GQLEnablePaymentMethodForShopInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateDiscountCodeArgs = {
  input: GQLCreateDiscountCodeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateDiscountCodeArgs = {
  input: GQLUpdateDiscountCodeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteDiscountCodeArgs = {
  input: GQLDeleteDiscountCodeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationApplyDiscountCodeToCartArgs = {
  input: GQLApplyDiscountCodeToCartInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveDiscountCodeFromCartArgs = {
  input: GQLRemoveDiscountCodeFromCartInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateSurchargeArgs = {
  input: GQLCreateSurchargeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteSurchargeArgs = {
  input: GQLDeleteSurchargeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateSurchargeArgs = {
  input: GQLUpdateSurchargeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateFlatRateFulfillmentMethodArgs = {
  input: GQLCreateFlatRateFulfillmentMethodInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateFlatRateFulfillmentMethodArgs = {
  input: GQLUpdateFlatRateFulfillmentMethodInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteFlatRateFulfillmentMethodArgs = {
  input: GQLDeleteFlatRateFulfillmentMethodInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateFlatRateFulfillmentRestrictionArgs = {
  input: GQLCreateFlatRateFulfillmentRestrictionInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteFlatRateFulfillmentRestrictionArgs = {
  input: GQLDeleteFlatRateFulfillmentRestrictionInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateFlatRateFulfillmentRestrictionArgs = {
  input: GQLUpdateFlatRateFulfillmentRestrictionInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateTaxRateArgs = {
  input: GQLCreateTaxRateInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateTaxRateArgs = {
  input: GQLUpdateTaxRateInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteTaxRateArgs = {
  input: GQLDeleteTaxRateInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateNavigationItemArgs = {
  input: GQLCreateNavigationItemInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationCreateNavigationTreeArgs = {
  input: GQLCreateNavigationTreeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationDeleteNavigationItemArgs = {
  input: GQLDeleteNavigationItemInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationPublishNavigationChangesArgs = {
  input: GQLPublishNavigationChangesInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateNavigationItemArgs = {
  input: GQLUpdateNavigationItemInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationUpdateNavigationTreeArgs = {
  input: GQLUpdateNavigationTreeInput;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationGenerateSitemapsArgs = {
  input?: Maybe<GQLGenerateSitemapsInput>;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationLoadProductsAndTagsArgs = {
  input?: Maybe<GQLLoadProductsAndTagsInput>;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationLoadOrdersArgs = {
  input?: Maybe<GQLLoadOrdersInput>;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationLoadProductImagesArgs = {
  input?: Maybe<GQLLoadProductImagesInput>;
};


/** Mutations have side effects, such as mutating data or triggering a task */
export type GQLMutationRemoveAllDataArgs = {
  input?: Maybe<GQLRemoveDataInput>;
};

/** Input for the archiveMediaRecord mutation */
export type GQLArchiveMediaRecordInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of MediaRecord to archive */
  mediaRecordId: Scalars['ID'];
  /** ID of shop that owns this MediaRecord */
  shopId: Scalars['ID'];
};

/** Response payload for the archiveMediaRecord mutation */
export class GQLArchiveMediaRecordPayload {
  __typename?: 'ArchiveMediaRecordPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The archived MediaRecord */
  mediaRecord: GQLMediaRecord;
};

/** A FileRecord for a media file */
export class GQLMediaRecord {
  __typename?: 'MediaRecord';
  /** MediaRecord ID */
  _id: Scalars['ID'];
  /** Custom metadata for the media record */
  metadata: GQLMediaRecordMetadata;
  /** Core info about the original uploaded file */
  original: GQLMediaRecordInfo;
};

/** Custom metadata for the media record */
export class GQLMediaRecordMetadata {
  __typename?: 'MediaRecordMetadata';
  /** True if the MediaRecord is archived. This typically means that the media will not show in a storefront but the image file data still exists. */
  isArchived: Scalars['Boolean'];
  /** ID of the account that uploaded the file */
  ownerId?: Maybe<Scalars['String']>;
  /** Priority among media files with similar metadata */
  priority?: Maybe<Scalars['Int']>;
  /** ID of the related product, if the media is for a product */
  productId?: Maybe<Scalars['String']>;
  /** ID of the shop that owns the media */
  shopId: Scalars['String'];
  /** A string that identifies where this media will be used, for filtering */
  type?: Maybe<Scalars['String']>;
  /** ID of the related product variant, if the media is for a product variant */
  variantId?: Maybe<Scalars['String']>;
};

/** Core info about the original uploaded media file */
export class GQLMediaRecordInfo {
  __typename?: 'MediaRecordInfo';
  /** File name */
  name: Scalars['String'];
  /** File size */
  size: Scalars['Int'];
  /** File type */
  type: Scalars['String'];
  /** Date and time at which the file was last updated */
  updatedAt: Scalars['DateTime'];
  /** Date and time at which the file was uploaded */
  uploadedAt: Scalars['DateTime'];
};

/** Input for the createMediaRecord mutation */
export type GQLCreateMediaRecordInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The media record to insert, with related file data already fully uploaded to temporary storage */
  mediaRecord: GQLMediaRecordInput;
  /** ID of shop that owns this MediaRecord */
  shopId: Scalars['ID'];
};

/** A FileRecord for a media file */
export type GQLMediaRecordInput = {
  /** Custom metadata for the media record */
  metadata: GQLMediaRecordMetadataInput;
  /** Core info about the original uploaded file */
  original: GQLMediaRecordInfoInput;
};

/** Custom metadata for the media record */
export type GQLMediaRecordMetadataInput = {
  /** Priority among media files with similar metadata */
  priority?: Maybe<Scalars['Int']>;
  /** ID of the related product, if the media is for a product */
  productId?: Maybe<Scalars['ID']>;
  /** A string that identifies where this media will be used, for filtering */
  type?: Maybe<Scalars['String']>;
  /** ID of the related product variant, if the media is for a product variant */
  variantId?: Maybe<Scalars['ID']>;
};

/** Core info about the original uploaded media file */
export type GQLMediaRecordInfoInput = {
  /** File name */
  name: Scalars['String'];
  /** File size */
  size: Scalars['Int'];
  /** ID of the file uploaded to temporary storage */
  tempStoreId: Scalars['String'];
  /** File type */
  type: Scalars['String'];
  /** Date and time at which the file was last updated */
  updatedAt: Scalars['DateTime'];
  /** Date and time at which the file was uploaded */
  uploadedAt: Scalars['DateTime'];
};

/** Response payload for the createMediaRecord mutation */
export class GQLCreateMediaRecordPayload {
  __typename?: 'CreateMediaRecordPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created MediaRecord */
  mediaRecord: GQLMediaRecord;
};

/** Input for the deleteMediaRecord mutation */
export type GQLDeleteMediaRecordInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of MediaRecord to delete */
  mediaRecordId: Scalars['ID'];
  /** ID of shop that owns this MediaRecord */
  shopId: Scalars['ID'];
};

/** Response payload for the deleteMediaRecord mutation */
export class GQLDeleteMediaRecordPayload {
  __typename?: 'DeleteMediaRecordPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deleted MediaRecord */
  mediaRecord: GQLMediaRecord;
};

/** Input for the updateMediaRecordPriority mutation */
export type GQLUpdateMediaRecordPriorityInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of MediaRecord to update */
  mediaRecordId: Scalars['ID'];
  /** New priority value */
  priority: Scalars['Int'];
  /** ID of shop that owns this MediaRecord */
  shopId: Scalars['ID'];
};

/** Response payload for the updateMediaRecordPriority mutation */
export class GQLUpdateMediaRecordPriorityPayload {
  __typename?: 'UpdateMediaRecordPriorityPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated MediaRecord */
  mediaRecord: GQLMediaRecord;
};

/** Input parameters for the `createShop` mutation */
export type GQLCreateShopInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Currency in which all money values should be assumed to be. Default is `USD`. */
  currencyCode?: Maybe<Scalars['String']>;
  /** Default language for translation and localization. Default is `en`. */
  defaultLanguage?: Maybe<Scalars['String']>;
  /** Primary timezone. Default is `US/Pacific` */
  defaultTimezone?: Maybe<Scalars['String']>;
  /** An optional description of the shop, intended for only admins to see */
  description?: Maybe<Scalars['String']>;
  /** A unique name for the shop */
  name: Scalars['String'];
  /** The shop type. Default is `primary`, but there may be only one primary shop. */
  type?: Maybe<Scalars['String']>;
};

/** The response from the `createShop` mutation */
export class GQLCreateShopPayload {
  __typename?: 'CreateShopPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop which was created */
  shop: GQLShop;
};

/** Input parameters for the updateShop mutation */
export type GQLUpdateShopInput = {
  /** An address book entry to set the primary shop's address */
  addressBook?: Maybe<Array<Maybe<GQLAddressInput>>>;
  /** Whether to allow user to checkout without creating an account */
  allowGuestCheckout?: Maybe<Scalars['Boolean']>;
  /** The base unit of length */
  baseUOL?: Maybe<Scalars['String']>;
  /** The base unit of Measure */
  baseUOM?: Maybe<Scalars['String']>;
  /** ID of media record to be used as the brand asset */
  brandAssets?: Maybe<Scalars['ID']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop's currency */
  currency?: Maybe<Scalars['String']>;
  /** Default parcel size used for this shop */
  defaultParcelSize?: Maybe<GQLShopParcelSizeInput>;
  /** The shop's description */
  description?: Maybe<Scalars['String']>;
  /** The shops primary email address */
  emails?: Maybe<Array<Maybe<GQLEmailRecordInput>>>;
  /** The shop's keywords */
  keywords?: Maybe<Scalars['String']>;
  /** The shop's language */
  language?: Maybe<Scalars['String']>;
  /** The shop's name */
  name?: Maybe<Scalars['String']>;
  /** The ID of the shop to update */
  shopId: Scalars['ID'];
  /** Object of shop logo urls  */
  shopLogoUrls?: Maybe<GQLShopLogoUrlsInput>;
  /** Shop's slug */
  slug?: Maybe<Scalars['String']>;
  /** Object of storefront routes urls */
  storefrontUrls?: Maybe<GQLStorefrontUrlsInput>;
  /** The shop's timezone */
  timezone?: Maybe<Scalars['String']>;
};

/** Parcel size input */
export type GQLShopParcelSizeInput = {
  /** Parcel height */
  height?: Maybe<Scalars['Float']>;
  /** Parcel length */
  length?: Maybe<Scalars['Float']>;
  /** Parcel weight */
  weight?: Maybe<Scalars['Float']>;
  /** Parcel width */
  width?: Maybe<Scalars['Float']>;
};

/** A confirmable email record */
export type GQLEmailRecordInput = {
  /** The actual email address */
  address?: Maybe<Scalars['Email']>;
  /** The services provided by this address */
  provides?: Maybe<Scalars['String']>;
  /** Has this address been verified? */
  verified?: Maybe<Scalars['Boolean']>;
};

/** Shop Logo URLs to provide for the updateShop mutation */
export type GQLShopLogoUrlsInput = {
  /** The primary logo URL for this shop. Setting this overrides any uploaded logo. */
  primaryShopLogoUrl?: Maybe<Scalars['String']>;
};

/** Storefront route URLs to provide for the updateShop mutation */
export type GQLStorefrontUrlsInput = {
  /** Storefront Account Profile URL (can include `:accountId` in string) */
  storefrontAccountProfileUrl?: Maybe<Scalars['String']>;
  /** Storefront Home URL */
  storefrontHomeUrl?: Maybe<Scalars['String']>;
  /** Storefront login URL */
  storefrontLoginUrl?: Maybe<Scalars['String']>;
  /** Storefront single order URL (can include `:orderReferenceId` and `:orderToken` in string) */
  storefrontOrderUrl?: Maybe<Scalars['String']>;
  /** Storefront orders URL (can include `:accountId` in string) */
  storefrontOrdersUrl?: Maybe<Scalars['String']>;
};

/** The response from the `updateShop` mutation */
export class GQLUpdateShopPayload {
  __typename?: 'UpdateShopPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop which was updated */
  shop: GQLShop;
};

/** Input for the `updateGlobalSettings` mutation */
export type GQLUpdateGlobalSettingsInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Updated settings values. Only includes settings to be changed. */
  settingsUpdates: GQLGlobalSettingsUpdates;
};

/**
 * Updates for app settings that are not shop specific. Plugins extend
 * this input type to support whatever settings they need. All fields
 * must be optional.
 */
export type GQLGlobalSettingsUpdates = {
  /** Do not use this field */
  doNotUse?: Maybe<Scalars['String']>;
};

/** Response payload for the `updateGlobalSettings` mutation */
export class GQLUpdateGlobalSettingsPayload {
  __typename?: 'UpdateGlobalSettingsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Updated global settings */
  globalSettings: GQLGlobalSettings;
};

/** Input for the `updateShopSettings` mutation */
export type GQLUpdateShopSettingsInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Updated settings values. Only includes settings to be changed. */
  settingsUpdates: GQLShopSettingsUpdates;
  /** The ID of the shop to update some settings for */
  shopId: Scalars['ID'];
};

/**
 * Updates for app settings that are not shop specific. Plugins extend
 * this input type to support whatever settings they need. All fields
 * must be optional.
 */
export type GQLShopSettingsUpdates = {
  /** Do not use this field */
  doNotUse?: Maybe<Scalars['String']>;
  /**
   * If there is no known inventory for a product configuration, this setting determines
   * whether that product configuration can be sold and should appear to be available.
   */
  canSellVariantWithoutInventory?: Maybe<Scalars['Boolean']>;
  /**
   * Set to `false` to prevent any defined shipping rates from being used when fulfillment
   * quotes are requested for a cart or order. A quick way to disable the entire
   * `reaction-shipping-rates` plugin temporarily.
   */
  isShippingRatesFulfillmentEnabled?: Maybe<Scalars['Boolean']>;
  /** The default value to use for `taxCode` property of a product */
  defaultTaxCode?: Maybe<Scalars['String']>;
  /**
   * Optionally, set the name of the tax service to fall back to if the primary tax service is down.
   * This must match the `name` field of one of the services returned by the `taxServices` query.
   */
  fallbackTaxServiceName?: Maybe<Scalars['String']>;
  /**
   * Set the name of the tax service to use for calculating taxes on carts and orders.
   * This will match the `name` field of one of the services returned by the `taxServices`
   * query. There will be no taxes charged for any carts or orders if this is not set.
   */
  primaryTaxServiceName?: Maybe<Scalars['String']>;
  /**
   * Whether a navigation item added to the navigation tree should be visible only to
   * admins by default.
   */
  shouldNavigationTreeItemsBeAdminOnly?: Maybe<Scalars['Boolean']>;
  /**
   * Whether a navigation item added to the navigation tree should be
   * public API/Storefront visible by default.
   */
  shouldNavigationTreeItemsBePubliclyVisible?: Maybe<Scalars['Boolean']>;
  /**
   * Whether a navigation item added to the navigation tree should be a secondary
   * navigation item by default.
   */
  shouldNavigationTreeItemsBeSecondaryNavOnly?: Maybe<Scalars['Boolean']>;
  /** This setting controls how often the sitemaps for the shop will be rebuilt */
  sitemapRefreshPeriod?: Maybe<Scalars['String']>;
};

/** Response payload for the `updateShopSettings` mutation */
export class GQLUpdateShopSettingsPayload {
  __typename?: 'UpdateShopSettingsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Updated shop settings */
  shopSettings: GQLShopSettings;
};

/** Input for `retryFailedEmail` mutation */
export type GQLRetryFailedEmailInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of Email Job to retry */
  jobId: Scalars['ID'];
  /** Shop ID of Email Job */
  shopId: Scalars['ID'];
};

/** Response payload for `retryFailedEmail` mutation */
export class GQLRetryFailedEmailPayload {
  __typename?: 'RetryFailedEmailPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Email retry status */
  emailSent: Scalars['Boolean'];
};

/** Input for the `createAddressValidationRule` mutation */
export type GQLCreateAddressValidationRuleInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Country codes for which this service is enabled. `null` means all, while an empty array means none. */
  countryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * The name of one of the installed validation services. Use `addressValidationServices`
   * query to get a list, and then use the `name` field value from one of them.
   */
  serviceName: Scalars['String'];
  /** ID of the shop to which this rule applies */
  shopId: Scalars['ID'];
};

/** Payload for the `createAddressValidationRule` mutation */
export class GQLCreateAddressValidationRulePayload {
  __typename?: 'CreateAddressValidationRulePayload';
  /** Created address validation rule */
  addressValidationRule: GQLAddressValidationRule;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the `deleteAddressValidationRule` mutation */
export type GQLDeleteAddressValidationRuleInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the rule you want to delete */
  ruleId: Scalars['ID'];
  /** Shop ID of the rule you want to delete */
  shopId: Scalars['ID'];
};

/** Payload for the `deleteAddressValidationRule` mutation */
export class GQLDeleteAddressValidationRulePayload {
  __typename?: 'DeleteAddressValidationRulePayload';
  /** Deleted address validation rule */
  addressValidationRule: GQLAddressValidationRule;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the `updateAddressValidationRule` mutation */
export type GQLUpdateAddressValidationRuleInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Country codes for which this service is enabled. `null` means all, while an empty array means none. */
  countryCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID of the rule you want to update */
  ruleId: Scalars['ID'];
  /**
   * The name of one of the installed validation services. Use `addressValidationServices`
   * query to get a list, and then use the `name` field value from one of them.
   */
  serviceName: Scalars['String'];
  /** Shop ID of the rule you want to update. This is not something you can modify. */
  shopId: Scalars['ID'];
};

/** Payload for the `updateAddressValidationRule` mutation */
export class GQLUpdateAddressValidationRulePayload {
  __typename?: 'UpdateAddressValidationRulePayload';
  /** Updated address validation rule */
  addressValidationRule: GQLAddressValidationRule;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for an `VerifySMTPEmailSettingsInput` */
export type GQLVerifySmtpEmailSettingsInput = {
  /** The ID of the shop this setting belongs to */
  shopId: Scalars['ID'];
};

/** Response payload for the verifySMTPEmailSettings mutation */
export class GQLVerifySmtpEmailSettingsInputPayload {
  __typename?: 'VerifySMTPEmailSettingsInputPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** True if the SMTP connection was made and authentication was successful. */
  isVerified: Scalars['Boolean'];
};

/** Input for `updateTemplate` mutation */
export type GQLUpdateTemplateInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of template to modify */
  id: Scalars['ID'];
  /** The shop that owns the template */
  shopId: Scalars['ID'];
  /** Email template string */
  subject?: Maybe<Scalars['String']>;
  /** Email template body or html text */
  template?: Maybe<Scalars['String']>;
  /** Email template title */
  title?: Maybe<Scalars['String']>;
};

/** Response payload for `updateTemplate` mutation */
export class GQLUpdateTemplatePayload {
  __typename?: 'UpdateTemplatePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated template */
  template: GQLTemplate;
};

/** Defines a new Address and the account to which it should be added */
export type GQLAddAccountAddressBookEntryInput = {
  /** The account ID */
  accountId: Scalars['ID'];
  /** The address to add */
  address: GQLAddressInput;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The response from the `addAccountAddressBookEntry` mutation */
export class GQLAddAccountAddressBookEntryPayload {
  __typename?: 'AddAccountAddressBookEntryPayload';
  /** The added address */
  address?: Maybe<GQLAddress>;
  /** The added address edge */
  addressEdge?: Maybe<GQLAddressEdge>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Defines a new Email and the account to which it should be added */
export type GQLAddAccountEmailRecordInput = {
  /** The account ID, which defaults to the viewer account */
  accountId?: Maybe<Scalars['ID']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The email address to add */
  email: Scalars['Email'];
};

/** The response from the `addAccountEmailRecord` mutation */
export class GQLAddAccountEmailRecordPayload {
  __typename?: 'AddAccountEmailRecordPayload';
  /** The account, with updated `emailRecords` */
  account?: Maybe<GQLAccount>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Defines the account which should be created */
export type GQLCreateAccountInput = {
  /** Bio to display on profile */
  bio?: Maybe<Scalars['String']>;
  /** Email record to create account with */
  emails: Array<Maybe<GQLEmailRecordInput>>;
  /** Name to display on profile */
  name?: Maybe<Scalars['String']>;
  /** URL of picture to display on profile */
  picture?: Maybe<Scalars['String']>;
  /** The ID of the shop this account will belong to */
  shopId: Scalars['ID'];
  /** The userID account was created from create a new account from */
  userId: Scalars['ID'];
  /** Username */
  username?: Maybe<Scalars['String']>;
};

/** The response from the `createAccount` mutation */
export class GQLCreateAccountPayload {
  __typename?: 'CreateAccountPayload';
  /** The added account */
  account?: Maybe<GQLAccount>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Describes which address should be removed from which account */
export type GQLRemoveAccountAddressBookEntryInput = {
  /** The account ID */
  accountId: Scalars['ID'];
  /** The address ID */
  addressId: Scalars['ID'];
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The response from the `removeAccountAddressBookEntry` mutation */
export class GQLRemoveAccountAddressBookEntryPayload {
  __typename?: 'RemoveAccountAddressBookEntryPayload';
  /** The removed address */
  address?: Maybe<GQLAddress>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Defines which email address should be removed from which account */
export type GQLRemoveAccountEmailRecordInput = {
  /** The account ID, which defaults to the viewer account */
  accountId?: Maybe<Scalars['ID']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The email address to remove */
  email: Scalars['Email'];
};

/** The response from the `removeAccountEmailRecord` mutation */
export class GQLRemoveAccountEmailRecordPayload {
  __typename?: 'RemoveAccountEmailRecordPayload';
  /** The account, with updated `emailRecords` */
  account?: Maybe<GQLAccount>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Describes which email should be sent a password reset link */
export type GQLSendResetAccountPasswordEmailInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The email address of the account to send reset email to */
  email: Scalars['String'];
};

/** The response from the `sendResetAccountPasswordEmail` mutation */
export class GQLSendResetAccountPasswordEmailPayload {
  __typename?: 'SendResetAccountPasswordEmailPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The email address of the account to send reset email to */
  email: Scalars['String'];
};

/** Defines which email address should be set as the default for which account */
export type GQLSetAccountDefaultEmailInput = {
  /** The account ID, which defaults to the viewer account */
  accountId?: Maybe<Scalars['ID']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The email address to set as default */
  email: Scalars['Email'];
};

/** The response from the `setAccountDefaultEmail` mutation */
export class GQLSetAccountDefaultEmailPayload {
  __typename?: 'SetAccountDefaultEmailPayload';
  /** The account, with updated `emailRecords` */
  account?: Maybe<GQLAccount>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Describes changes that should be applied to one of the addresses for an account */
export type GQLUpdateAccountAddressBookEntryInput = {
  /** The account ID */
  accountId: Scalars['ID'];
  /** The address ID */
  addressId: Scalars['ID'];
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** If present, make this address the default address of this type */
  type?: Maybe<AddressType>;
  /** The address changes to apply */
  updates: GQLAddressInput;
};

/** A list of the possible types of `Address` */
export enum AddressType {
  /** Address can be used for payment transactions and invoicing */
  Billing = 'billing',
  /** Address can be used as a mailing address for sending physical items */
  Shipping = 'shipping'
}

/** The response from the `updateAccountAddressBookEntry` mutation */
export class GQLUpdateAccountAddressBookEntryPayload {
  __typename?: 'UpdateAccountAddressBookEntryPayload';
  /** The updated address */
  address?: Maybe<GQLAddress>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Describes an account update */
export type GQLUpdateAccountInput = {
  /** The account ID, which defaults to the viewer account */
  accountId?: Maybe<Scalars['ID']>;
  /** Bio to display on profile */
  bio?: Maybe<Scalars['String']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The preferred currency code used by this account */
  currencyCode?: Maybe<Scalars['String']>;
  /** The first name of the person this account represents */
  firstName?: Maybe<Scalars['String']>;
  /** The preferred language (code) used by this account */
  language?: Maybe<Scalars['String']>;
  /** The last name of the person this account represents */
  lastName?: Maybe<Scalars['String']>;
  /** The full name of the person this account represents */
  name?: Maybe<Scalars['String']>;
  /** Some note about this account */
  note?: Maybe<Scalars['String']>;
  /** URL of picture to display on profile */
  picture?: Maybe<Scalars['String']>;
  /** Username */
  username?: Maybe<Scalars['String']>;
};

/** The response from the `updateAccount` mutation */
export class GQLUpdateAccountPayload {
  __typename?: 'UpdateAccountPayload';
  /** The updated account */
  account: GQLAccount;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Defines a group and account that should be linked */
export type GQLAddAccountToGroupInput = {
  /** The account ID */
  accountId: Scalars['ID'];
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The group ID */
  groupId: Scalars['ID'];
};

/** The response from the `addAccountToGroup` mutation */
export class GQLAddAccountToGroupPayload {
  __typename?: 'AddAccountToGroupPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated group */
  group?: Maybe<GQLGroup>;
};

/** The details for creating a group */
export type GQLCreateAccountGroupInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The group to create */
  group: GQLGroupInput;
  /** The ID of the shop this group belongs to */
  shopId?: Maybe<Scalars['ID']>;
};

/** A group definition */
export type GQLGroupInput = {
  /** A free text description of this group */
  description?: Maybe<Scalars['String']>;
  /** A unique name for the group */
  name: Scalars['String'];
  /** A unique URL-safe string representing this group */
  slug?: Maybe<Scalars['String']>;
  /** A list of the account permissions implied by membership in this group */
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The response from the `createAccountGroup` mutation */
export class GQLCreateAccountGroupPayload {
  __typename?: 'CreateAccountGroupPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The new group */
  group?: Maybe<GQLGroup>;
};

/** Defines a group and account that should be unlinked */
export type GQLRemoveAccountFromGroupInput = {
  /** The account ID */
  accountId: Scalars['ID'];
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The group ID */
  groupId: Scalars['ID'];
};

/** The response from the `removeAccountFromGroup` mutation */
export class GQLRemoveAccountFromGroupPayload {
  __typename?: 'RemoveAccountFromGroupPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The removed group */
  group: GQLGroup;
};

/** The details for removing a group */
export type GQLRemoveAccountGroupInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The group ID */
  groupId: Scalars['ID'];
  /** The ID of the shop this group belongs to */
  shopId?: Maybe<Scalars['ID']>;
};

/** The response from the `removeGroup` mutation */
export class GQLRemoveAccountGroupPayload {
  __typename?: 'RemoveAccountGroupPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The removed group */
  group?: Maybe<GQLGroup>;
};

/** The details for updating a group */
export type GQLUpdateAccountGroupInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The changes to apply to the group */
  group: GQLUpdateGroupInput;
  /** The group ID */
  groupId: Scalars['ID'];
  /** The ID of the shop this group belongs to */
  shopId?: Maybe<Scalars['ID']>;
};

/** Fields to update for an existing account group */
export type GQLUpdateGroupInput = {
  /** A free text description of this group */
  description?: Maybe<Scalars['String']>;
  /** A unique name for the group */
  name?: Maybe<Scalars['String']>;
  /** A unique URL-safe string representing this group */
  slug?: Maybe<Scalars['String']>;
  /** A list of the account permissions implied by membership in this group */
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** The response from the `updateAccountGroup` mutation */
export class GQLUpdateAccountGroupPayload {
  __typename?: 'UpdateAccountGroupPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated group */
  group?: Maybe<GQLGroup>;
};

/** Input parameters for the inviteShopMember mutation */
export type GQLInviteShopMemberInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The email address of the person to invite */
  email: Scalars['String'];
  /** The permission group for this person's new account */
  groupId: Scalars['ID'];
  /** The invitee's full name */
  name: Scalars['String'];
  /** The ID of the shop to which you want to invite this person */
  shopId: Scalars['ID'];
};

/** The response from the `inviteShopMember` mutation */
export class GQLInviteShopMemberPayload {
  __typename?: 'InviteShopMemberPayload';
  /** The account that was successfully created or found and updated by inviting this shop member */
  account?: Maybe<GQLAccount>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the `archiveProducts` mutation */
export type GQLArchiveProductsInput = {
  /** Array of IDs of products to archive */
  productIds: Array<Maybe<Scalars['ID']>>;
  /** ID of shop that owns all products you are archiving */
  shopId: Scalars['ID'];
};

/** Response payload of `archiveProducts` mutation */
export class GQLArchiveProductsPayload {
  __typename?: 'ArchiveProductsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Array of newly archived products */
  products: Array<Maybe<GQLProduct>>;
};

/** Input for the `archiveProducts` mutation */
export type GQLArchiveProductVariantsInput = {
  /** ID of shop that owns all variants you are archiving */
  shopId: Scalars['ID'];
  /** Array of IDs of variants to archive */
  variantIds: Array<Maybe<Scalars['ID']>>;
};

/** Response payload of `archiveProductVariants` mutation */
export class GQLArchiveProductVariantsPayload {
  __typename?: 'ArchiveProductVariantsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Array of newly archived variants */
  variants: Array<Maybe<GQLProductVariant>>;
};

/** Input for the `updateProductsVisibility` mutation */
export type GQLUpdateProductsVisibilityInput = {
  /** The desired visibility */
  isVisible: Scalars['Boolean'];
  /** Array of product ids to update */
  productIds: Array<Maybe<Scalars['ID']>>;
  /** ID of shop the products belong to */
  shopId: Scalars['ID'];
};

/** Response payload for `updateProductsVisibility` mutation */
export class GQLUpdateProductsVisibilityPayload {
  __typename?: 'UpdateProductsVisibilityPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The number of products that were updated successfully */
  updatedCount?: Maybe<Scalars['Int']>;
};

/** Input for the `createProduct` mutation */
export type GQLCreateProductInput = {
  /** Product input */
  product?: Maybe<GQLProductInput>;
  /** ID of shop product will belong to */
  shopId: Scalars['ID'];
  /** Set to false if you do not want to auto-create the first variant of the product */
  shouldCreateFirstVariant?: Maybe<Scalars['Boolean']>;
};

/** Mutation input for a product */
export type GQLProductInput = {
  /**
   * Any string to use as the internal ID for a new product. Do not prefix or base64 encode this ID.
   * This field is allowed only when creating a product. If you include an ID for an update, you
   * will get an error. The string must also be different from any existing product, variant, or
   * option internal ID or you will get a duplicate ID error. If you do not include this when creating
   * a product, a random unique string is generated for you.
   */
  _id?: Maybe<Scalars['String']>;
  /** The full product description, which may have newline characters in it */
  description?: Maybe<Scalars['String']>;
  /** Facebook message */
  facebookMsg?: Maybe<Scalars['String']>;
  /** Google message */
  googleplusMsg?: Maybe<Scalars['String']>;
  /** True if this product has been deleted. Typically, deleted products are not returned in queries. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** True if this product should be shown to shoppers. Typically, non-visible products are not returned in queries. */
  isVisible?: Maybe<Scalars['Boolean']>;
  /** The product description to use for page `description` meta element in HTML */
  metaDescription?: Maybe<Scalars['String']>;
  /** Arbitrary additional metadata about this product */
  metafields?: Maybe<Array<Maybe<GQLMetafieldInput>>>;
  /** The country of origin */
  originCountry?: Maybe<Scalars['String']>;
  /** Subtitle */
  pageTitle?: Maybe<Scalars['String']>;
  /** Pinterest message */
  pinterestMsg?: Maybe<Scalars['String']>;
  /** An arbitrary product type value, such as from an external system */
  productType?: Maybe<Scalars['String']>;
  /** Whether this product will be shown in the generated sitemap */
  shouldAppearInSitemap?: Maybe<Scalars['Boolean']>;
  /** A URL-safe and human-readable string that uniquely identifies this product */
  slug?: Maybe<Scalars['String']>;
  /** When a shopper purchases this product, what types of fulfillment can they choose from? */
  supportedFulfillmentTypes?: Maybe<Array<Maybe<FulfillmentType>>>;
  /** The list of tag IDs that have been applied to this product */
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Product title */
  title?: Maybe<Scalars['String']>;
  /** Twitter message */
  twitterMsg?: Maybe<Scalars['String']>;
  /** The product vendor or manufacturer, for display */
  vendor?: Maybe<Scalars['String']>;
};

/** Response payload of `createProduct` mutation */
export class GQLCreateProductPayload {
  __typename?: 'CreateProductPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created product */
  product: GQLProduct;
};

/** Input for the `createProductVariant` mutation */
export type GQLCreateProductVariantInput = {
  /** ID of product variant belongs to */
  productId: Scalars['ID'];
  /** ID of shop product variant will belong to */
  shopId: Scalars['ID'];
  /** Variant input */
  variant?: Maybe<GQLProductVariantInput>;
};

/** Mutation input for a product variant or option */
export type GQLProductVariantInput = {
  /**
   * Any string to use as the internal ID for a new variant. Do not prefix or base64 encode this ID.
   * This field is allowed only when creating a variant. If you include an ID for an update, you
   * will get an error. The string must also be different from any existing product, variant, or
   * option internal ID or you will get a duplicate ID error. If you do not include this when creating
   * a variant, a random unique string is generated for you.
   */
  _id?: Maybe<Scalars['String']>;
  /**
   * The attribute label describes the category of variant, for example, `Color` or `Size`.
   * In most cases this will be the same for all variants at the same level.
   */
  attributeLabel?: Maybe<Scalars['String']>;
  /** The product variant barcode value, if it has one */
  barcode?: Maybe<Scalars['String']>;
  /** The height of the product variant, if it has physical dimensions */
  height?: Maybe<Scalars['Float']>;
  /** The position of this variant among other variants at the same level of the product-variant-option hierarchy */
  index?: Maybe<Scalars['Int']>;
  /** True if this variant was deleted. Deleted variants are not published to the catalog. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** True if this variant is visible. Hidden variants are not published to the catalog. */
  isVisible?: Maybe<Scalars['Boolean']>;
  /** The length of the product, if it has physical dimensions */
  length?: Maybe<Scalars['Float']>;
  /** Arbitrary additional metadata about this product */
  metafields?: Maybe<Array<Maybe<GQLMetafieldInput>>>;
  /** The minimum quantity that must be added to a cart */
  minOrderQuantity?: Maybe<Scalars['Int']>;
  /** A short title to use for product detail select lists */
  optionTitle?: Maybe<Scalars['String']>;
  /** The country of origin */
  originCountry?: Maybe<Scalars['String']>;
  /** SKU of variant */
  sku?: Maybe<Scalars['String']>;
  /**
   * The full variant title for use on cart, checkout, and order summaries and on invoices.
   * This fully describes the configured variant. For example, if this is an option with
   * `optionTitle` `Large`, its parent variant has `optionTitle` `Red`, and the product
   * `title` is `Fancy T-Shirt`, then this `title` will be something like `Fancy T-Shirt - Red - Large`.
   */
  title?: Maybe<Scalars['String']>;
  /** The weight of the product on Earth, if it has physical dimensions */
  weight?: Maybe<Scalars['Float']>;
  /** The width of the product, if it has physical dimensions */
  width?: Maybe<Scalars['Float']>;
  /** Variant compareAtPrice. DEPRECATED. Use the `updateProductVariantPrices` mutation to set product variant prices. */
  compareAtPrice?: Maybe<Scalars['Float']>;
  /** Variant price. DEPRECATED. Use the `updateProductVariantPrices` mutation to set product variant prices. */
  price?: Maybe<Scalars['Float']>;
  /** Whether this variant is taxable */
  isTaxable?: Maybe<Scalars['Boolean']>;
  /** Tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** Tax description */
  taxDescription?: Maybe<Scalars['String']>;
};

/** Response payload of `createProductVariant` mutation */
export class GQLCreateProductVariantPayload {
  __typename?: 'CreateProductVariantPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created product variant */
  variant: GQLProductVariant;
};

/** Input for the `cloneProducts` mutation */
export type GQLCloneProductsInput = {
  /** Array of IDs of products to clone */
  productIds: Array<Maybe<Scalars['ID']>>;
  /** ID of shop that owns all products you are cloning */
  shopId: Scalars['ID'];
};

/** Response payload of `cloneProducts` mutation */
export class GQLCloneProductsPayload {
  __typename?: 'CloneProductsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Array of newly cloned products */
  products: Array<Maybe<GQLProduct>>;
};

/** Input for the `cloneProductVariants` mutation */
export type GQLCloneProductVariantsInput = {
  /** ID of shop that owns all product variants you want to clone */
  shopId: Scalars['ID'];
  /** Array of IDs of variants to clone */
  variantIds: Array<Maybe<Scalars['ID']>>;
};

/** Response payload of `cloneProductVariants` mutation */
export class GQLCloneProductVariantsPayload {
  __typename?: 'CloneProductVariantsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Array of newly cloned product variants */
  variants: Array<Maybe<GQLProductVariant>>;
};

/** Input for the `updateProduct` mutation */
export type GQLUpdateProductInput = {
  /** Product input */
  product: GQLProductInput;
  /** ID of product to update */
  productId: Scalars['ID'];
  /** ID of shop that owns the product to update */
  shopId: Scalars['ID'];
};

/** Response payload of `updateProduct` mutation */
export class GQLUpdateProductPayload {
  __typename?: 'UpdateProductPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Updated product */
  product: GQLProduct;
};

/** Input for the `updateProductVariantField` mutation */
export type GQLUpdateProductVariantInput = {
  /** ID of shop that owns the variant to update */
  shopId: Scalars['ID'];
  /** Variant input */
  variant: GQLProductVariantInput;
  /** ID of variant to update */
  variantId: Scalars['ID'];
};

/** Response payload of `updateProductVariantField` mutation */
export class GQLUpdateProductVariantPayload {
  __typename?: 'UpdateProductVariantPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Updated variant */
  variant: GQLProductVariant;
};

/** Input for adding tags to products in bulk */
export type GQLProductTagsOperationInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An array of product productIds to which an array of tags will be added */
  productIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The shop id */
  shopId: Scalars['ID'];
  /** An array of tag ids to add to an array of products */
  tagIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

/** Response payload managing tags on products */
export class GQLProductTagsOperationPayload {
  __typename?: 'ProductTagsOperationPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The number of products found */
  foundCount?: Maybe<Scalars['Int']>;
  /** The number of products for which a match was not found */
  notFoundCount?: Maybe<Scalars['Int']>;
  /** The number of products successfully updated */
  updatedCount?: Maybe<Scalars['Int']>;
  /** An array of write errors if any were generated */
  writeErrors?: Maybe<Array<Maybe<GQLWriteError>>>;
};

/** A bulk write error type */
export class GQLWriteError {
  __typename?: 'WriteError';
  /** The documentId(_id) on which the error occurred */
  documentId?: Maybe<Scalars['Int']>;
  /** Error message for a documentId */
  errorMsg?: Maybe<Scalars['String']>;
};

/** Input for `addTag` mutation */
export type GQLAddTagInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Title to display to customers */
  displayTitle?: Maybe<Scalars['String']>;
  /** Hero media URL */
  heroMediaUrl?: Maybe<Scalars['String']>;
  /** Whether the tag is visible */
  isVisible: Scalars['Boolean'];
  /** Tag metafields */
  metafields?: Maybe<Array<Maybe<GQLMetafieldInput>>>;
  /** Unique name of the tag */
  name: Scalars['String'];
  /** The shop that owns the tag */
  shopId: Scalars['ID'];
  /** The tag slug. If left blank, the name will be slugified and saved as the slug */
  slug?: Maybe<Scalars['String']>;
};

/** Response payload for `addTag` mutation */
export class GQLAddTagPayload {
  __typename?: 'AddTagPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop that owns the tag */
  shopId: Scalars['ID'];
  /** The newly-created tag */
  tag: GQLTag;
};

/** Input for `removeTag` mutation */
export type GQLRemoveTagInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of tag to delete */
  id: Scalars['ID'];
  /** The shop that owns the tag */
  shopId: Scalars['ID'];
};

/** Response payload for `removeTag` mutation */
export class GQLRemoveTagPayload {
  __typename?: 'RemoveTagPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deleted tag */
  tag: GQLTag;
};

/** Input for `setTagHeroMedia` mutation */
export type GQLSetTagHeroMediaInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** File record document */
  fileRecord?: Maybe<Scalars['JSONObject']>;
  /** ID of tag to add the hero image record to */
  id: Scalars['ID'];
  /** The shop that owns the tag */
  shopId: Scalars['ID'];
};

/** Response payload for `setTagHeroMedia` mutation */
export class GQLSetTagHeroMediaPayload {
  __typename?: 'SetTagHeroMediaPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Tag the hero image was added to */
  tag: GQLTag;
};

/** Input for `updateTag` mutation */
export type GQLUpdateTagInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Title to display to customers */
  displayTitle?: Maybe<Scalars['String']>;
  /** A list of the IDs of top products in this tag */
  featuredProductIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Hero media URL */
  heroMediaUrl?: Maybe<Scalars['String']>;
  /** ID of rule to modify */
  id: Scalars['ID'];
  /** Whether the tag is visible */
  isVisible: Scalars['Boolean'];
  /** Tag metafields */
  metafields?: Maybe<Array<Maybe<GQLMetafieldInput>>>;
  /** Unique name of the tag */
  name: Scalars['String'];
  /** The shop that owns the tag */
  shopId: Scalars['ID'];
  /** The tag slug. If left blank, the name will be slugified and saved as the slug */
  slug?: Maybe<Scalars['String']>;
};

/** Response payload for `updateTag` mutation */
export class GQLUpdateTagPayload {
  __typename?: 'UpdateTagPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated tag */
  tag: GQLTag;
};

/** Input for the `updateProductVariantField` mutation */
export type GQLUpdateProductVariantPricesInput = {
  /** Prices to update */
  prices: GQLProductVariantPricesInput;
  /** ID of shop that owns the variant to update */
  shopId: Scalars['ID'];
  /** ID of variant to update */
  variantId: Scalars['ID'];
};

/** Mutation input for a product variant or option */
export type GQLProductVariantPricesInput = {
  /** Variant compareAtPrice */
  compareAtPrice?: Maybe<Scalars['Float']>;
  /** Variant price */
  price?: Maybe<Scalars['Float']>;
};

/** Response payload of `updateProductVariantPricesField` mutation */
export class GQLUpdateProductVariantPricesPayload {
  __typename?: 'UpdateProductVariantPricesPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Updated variant */
  variant: GQLProductVariant;
};

/** Input for the `recalculateReservedSimpleInventory` mutation */
export type GQLRecalculateReservedSimpleInventoryInput = {
  /** The product and chosen options this info applies to */
  productConfiguration: GQLProductConfigurationInput;
  /** Shop that owns the product */
  shopId: Scalars['ID'];
};

/** Response payload for the `updateSimpleInventory` mutation */
export class GQLRecalculateReservedSimpleInventoryPayload {
  __typename?: 'RecalculateReservedSimpleInventoryPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated inventory info */
  inventoryInfo: GQLSimpleInventoryInfo;
};

/** Input for the `updateSimpleInventory` mutation. In addition to `shopId`, at least one field to update is required. */
export type GQLUpdateSimpleInventoryInput = {
  /**
   * Whether to allow ordering this product configuration when there is insufficient quantity available.
   * Set this to `true` or `false` if you want to update it.
   */
  canBackorder?: Maybe<Scalars['Boolean']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Current quantity of this product configuration in stock. Set this to an integer if you want to update it. */
  inventoryInStock?: Maybe<Scalars['Int']>;
  /**
   * Whether the SimpleInventory plugin should manage inventory for this product configuration.
   * Set this to `true` or `false` if you want to update it.
   */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /**
   * The "low quantity" flag will be applied to this product configuration when the available quantity
   * is at or below this threshold. Set this to an integer if you want to update it.
   */
  lowInventoryWarningThreshold?: Maybe<Scalars['Int']>;
  /** The product and chosen options this info applies to */
  productConfiguration: GQLProductConfigurationInput;
  /** Shop that owns the product */
  shopId: Scalars['ID'];
};

/** Response payload for the `updateSimpleInventory` mutation */
export class GQLUpdateSimpleInventoryPayload {
  __typename?: 'UpdateSimpleInventoryPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated inventory info */
  inventoryInfo: GQLSimpleInventoryInfo;
};

/** Input for the `addCartItems` mutation */
export type GQLAddCartItemsInput = {
  /** The cart ID */
  cartId: Scalars['ID'];
  /** If this cart is anonymous, provide the `cartToken` that was returned in the `CreateCartPayload` */
  cartToken?: Maybe<Scalars['String']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Array of items to be added to the cart */
  items: Array<Maybe<GQLCartItemInput>>;
};

/** Information about an item to add to a cart */
export type GQLCartItemInput = {
  /** Arbitrary additional metadata about this cart item. */
  metafields?: Maybe<Array<Maybe<GQLMetafieldInput>>>;
  /**
   * The price of this item, for validating that this matches the actual price in the system,
   * in case the client has stale data.
   */
  price: GQLMoneyInput;
  /** The product and chosen options */
  productConfiguration: GQLProductConfigurationInput;
  /** The number of this item to add to the cart */
  quantity: Scalars['Int'];
};

/** Represents input for some amount of a single currency */
export type GQLMoneyInput = {
  /** The numeric amount */
  amount: Scalars['Float'];
  /** The currency code, for interpreting the `amount` */
  currencyCode: Scalars['String'];
};

/** The payload returned from the `addCartItems` mutation call */
export class GQLAddCartItemsPayload {
  __typename?: 'AddCartItemsPayload';
  /**
   * The modified cart. You should check `incorrectPriceFailures` and `minOrderQuantityFailures` for
   * information necessary to display errors to the shopper. Some items may not have been added.
   */
  cart?: Maybe<GQLCart>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * Clients should check to see if any items failed to be added due to the price not matching the current price.
   * In general, a user interface should display the correct current prices to the shopper, confirm that they still
   * want to add the items, and then call `createCart` or `addCartItems` to do so.
   *
   * Note that this field will always exist but may be an empty array if there were no failures of this type.
   */
  incorrectPriceFailures: Array<Maybe<GQLIncorrectPriceFailureDetails>>;
  /**
   * Clients should check to see if any items failed to be added due to quantity being below the minimum order
   * quantity defined for the product variant. In general, a user interface should display the minimum order
   * quantity to the shopper and allow them to add that quantity or greater.
   *
   * Note that this field will always exist but may be an empty array if there were no failures of this type.
   */
  minOrderQuantityFailures: Array<Maybe<GQLMinOrderQuantityFailureDetails>>;
};

/** Details about a CartItemInput that failed to be added to a cart due to a price mismatch */
export class GQLIncorrectPriceFailureDetails {
  __typename?: 'IncorrectPriceFailureDetails';
  /** The current price in the system for this product configuration in the requested currency */
  currentPrice: GQLMoney;
  /** The productConfiguration that was provided with the CartItemInput that caused this failure */
  productConfiguration: GQLProductConfiguration;
  /** The price that was provided with the CartItemInput that caused this failure */
  providedPrice: GQLMoney;
};

/** Details about a CartItemInput that failed to be added to a cart due to a quantity error */
export class GQLMinOrderQuantityFailureDetails {
  __typename?: 'MinOrderQuantityFailureDetails';
  /** The minimum quantity that can be added to a cart */
  minOrderQuantity: Scalars['Int'];
  /** The productConfiguration that was provided with the CartItemInput that caused this failure */
  productConfiguration: GQLProductConfiguration;
  /** The quantity that was provided with the CartItemInput that caused this failure */
  quantity: Scalars['Int'];
};

/** The input necessary to create a cart */
export type GQLCreateCartInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Array of items to add to new cart. */
  items: Array<Maybe<GQLCartItemInput>>;
  /** ShopId association for the cart. */
  shopId: Scalars['ID'];
};

/** The payload returned from the `createCart` mutation call */
export class GQLCreateCartPayload {
  __typename?: 'CreateCartPayload';
  /**
   * The created cart, if at least one item could be added. Otherwise null, and you should check
   * `incorrectPriceFailures` and `minOrderQuantityFailures` for information necessary to display
   * errors to the shopper.
   */
  cart?: Maybe<GQLCart>;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * Clients should check to see if any items failed to be added due to the price not matching the current price.
   * In general, a user interface should display the correct current prices to the shopper, confirm that they still
   * want to add the items, and then call `createCart` or `addCartItems` to do so.
   *
   * Note that this field will always exist but may be an empty array if there were no failures of this type.
   */
  incorrectPriceFailures: Array<Maybe<GQLIncorrectPriceFailureDetails>>;
  /**
   * Clients should check to see if any items failed to be added due to quantity being below the minimum order
   * quantity defined for the product variant. In general, a user interface should display the minimum order
   * quantity to the shopper and allow them to add that quantity or greater.
   *
   * Note that this field will always exist but may be an empty array if there were no failures of this type.
   */
  minOrderQuantityFailures: Array<Maybe<GQLMinOrderQuantityFailureDetails>>;
  /**
   * If no identity token is provided with the request, then this mutation will create an anonymous cart. All
   * anonymous carts have a token associated with them, which allows the client that created the cart to access
   * that cart in the future. This is the only time this token is returned, so clients must store this securely
   * in some type of local storage solution, and then send it along with all future anonymous cart queries and
   * mutations.
   */
  token?: Maybe<Scalars['String']>;
};

/** Input for the `reconcileCarts` mutation call */
export type GQLReconcileCartsInput = {
  /** An anonymous cart ID */
  anonymousCartId: Scalars['ID'];
  /** An anonymous cart token */
  cartToken: Scalars['String'];
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * If both an anonymous cart and a cart for the authenticated account are found, how do we combine them?
   * Default mode is `merge`, where all anonymous items are moved into the account cart along with existing
   * account cart items, and quantities are combined.
   */
  mode?: Maybe<CartReconciliationMode>;
  /** The ID of the shop that owns both carts */
  shopId: Scalars['ID'];
};

/** Supported cart reconciliation modes */
export enum CartReconciliationMode {
  /** Delete the anonymous cart and use the account cart. */
  KeepAccountCart = 'keepAccountCart',
  /** Assign the anonymous cart to the account, and delete the account cart. */
  KeepAnonymousCart = 'keepAnonymousCart',
  /**
   * Move all items from the anonymous cart into the account cart along with existing
   * account cart items. If the same item is in both carts, combine the quantities.
   */
  Merge = 'merge'
}

/** The payload returned from the `reconcileCarts` mutation call */
export class GQLReconcileCartsPayload {
  __typename?: 'ReconcileCartsPayload';
  /** The account cart, potentially modified */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the `removeCartItems` mutation */
export type GQLRemoveCartItemsInput = {
  /** The cart ID */
  cartId: Scalars['ID'];
  /** Array of items to remove from the cart. */
  cartItemIds: Array<Maybe<Scalars['ID']>>;
  /** If this cart is anonymous, provide the `cartToken` that was returned in the `CreateCartPayload` */
  cartToken?: Maybe<Scalars['String']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The payload returned from the `removeCartItems` mutation call */
export class GQLRemoveCartItemsPayload {
  __typename?: 'RemoveCartItemsPayload';
  /** The modified cart */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the `setEmailOnAnonymousCart` mutation call */
export type GQLSetEmailOnAnonymousCartInput = {
  /** An anonymous cart ID */
  cartId: Scalars['ID'];
  /** Provide the `cartToken` that was returned in the `CreateCartPayload` */
  cartToken: Scalars['String'];
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * The email address to associate with this cart. This address is used for order communication and
   * other fulfillment purposes.
   */
  email: Scalars['String'];
};

/** The payload returned from the `setEmailOnAnonymousCart` mutation call */
export class GQLSetEmailOnAnonymousCartPayload {
  __typename?: 'SetEmailOnAnonymousCartPayload';
  /** The modified cart */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the `updateCartItemsQuantity` mutation */
export type GQLUpdateCartItemsQuantityInput = {
  /** The cart ID */
  cartId: Scalars['ID'];
  /** If this cart is anonymous, provide the `cartToken` that was returned in the `CreateCartPayload` */
  cartToken?: Maybe<Scalars['String']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Array of cart item quantities to update. */
  items: Array<Maybe<GQLUpdateCartItemInput>>;
};

/** Input for the `updateCartItem` mutation */
export type GQLUpdateCartItemInput = {
  /** The cart item ID */
  cartItemId: Scalars['ID'];
  /** New absolute value for specified cart item's quantity. Not an incremental value. */
  quantity: Scalars['Int'];
};

/** The payload returned from the `updateCartItemsQuantity` mutation call */
export class GQLUpdateCartItemsQuantityPayload {
  __typename?: 'UpdateCartItemsQuantityPayload';
  /** The modified cart */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input needed to select a fulfillment option for a single fulfillment group on a cart */
export type GQLSelectFulfillmentOptionForGroupInput = {
  /** The cart to select this option for */
  cartId: Scalars['ID'];
  /** The token for the cart, required if it is an anonymous cart */
  cartToken?: Maybe<Scalars['String']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The group to select this option for */
  fulfillmentGroupId: Scalars['ID'];
  /** The fulfillment method ID from the option the shopper selected */
  fulfillmentMethodId: Scalars['ID'];
};

/** The response from the `selectFulfillmentOptionForGroup` mutation */
export class GQLSelectFulfillmentOptionForGroupPayload {
  __typename?: 'SelectFulfillmentOptionForGroupPayload';
  /** The updated Cart */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input needed when setting the shipping address on a cart */
export type GQLSetShippingAddressOnCartInput = {
  /** The shipping address */
  address: GQLAddressInput;
  /** If set, this will be saved as the Address._id. Otherwise an ID will be generated. */
  addressId?: Maybe<Scalars['String']>;
  /** The cart to set shipping address on */
  cartId: Scalars['ID'];
  /** The token for the cart, required if it is an anonymous cart */
  cartToken?: Maybe<Scalars['String']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The response from the `setShippingAddressOnCart` mutation */
export class GQLSetShippingAddressOnCartPayload {
  __typename?: 'SetShippingAddressOnCartPayload';
  /** The updated Cart */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** A request to update the available fulfillment options for a single fulfillment group */
export type GQLUpdateFulfillmentOptionsForGroupInput = {
  /** The cart to update fulfillment options for */
  cartId: Scalars['ID'];
  /** The token for the cart, required if it is an anonymous cart */
  cartToken?: Maybe<Scalars['String']>;
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The group to update fulfillment options for */
  fulfillmentGroupId: Scalars['ID'];
};

/** The response from the `updateFulfillmentOptionsForGroup` mutation */
export class GQLUpdateFulfillmentOptionsForGroupPayload {
  __typename?: 'UpdateFulfillmentOptionsForGroupPayload';
  /** The updated Cart */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the addOrderFulfillmentGroup mutation */
export type GQLAddOrderFulfillmentGroupInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The order fulfillment group input, used to build the new group */
  fulfillmentGroup: GQLOrderFulfillmentGroupExistingOrderInput;
  /** Optional list of order item IDs that should be moved from an existing group to the new group */
  moveItemIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** ID of the order that has the item you want to add the group to */
  orderId: Scalars['ID'];
};

/** Similar to `OrderFulfillmentGroupInput` but `items` can be omitted if moving existing items to the new group */
export type GQLOrderFulfillmentGroupExistingOrderInput = {
  /** Information needed by the selected fulfillment method to properly fulfill the order */
  data?: Maybe<GQLOrderFulfillmentGroupDataInput>;
  /** The list of items to be ordered */
  items?: Maybe<Array<Maybe<GQLOrderFulfillmentGroupItemInput>>>;
  /** The ID of the fulfillment method to be used for this order group */
  selectedFulfillmentMethodId: Scalars['ID'];
  /** The shop that owns these items and needs to fulfill this part of the order */
  shopId: Scalars['ID'];
  /**
   * The total price of the items, fulfillment, and taxes, for this group, less any discounts, in the
   * `order.currencyCode` currency. This value is not trusted; the actual total is calculated by the
   * Order service. However, providing this value prevents an order being created for an amount that
   * does not match what was shown to the shopper in order preview.
   */
  totalPrice?: Maybe<Scalars['Float']>;
  /** The fulfillment type. Any valid type that has been registered by a fulfillment plugin. Examples: `shipping`, `digital` */
  type: FulfillmentType;
};

/** Information needed by the selected fulfillment method to properly fulfill the order */
export type GQLOrderFulfillmentGroupDataInput = {
  /** The mailing address to which this fulfillment group should be shipped */
  shippingAddress?: Maybe<GQLAddressInput>;
};

/** Input for an `OrderFulfillmentGroupItem` */
export type GQLOrderFulfillmentGroupItemInput = {
  /** The date and time at which this item was first added to the source cart, if this is something you want to track */
  addedAt?: Maybe<Scalars['DateTime']>;
  /**
   * The price of the item, in the `order.currencyCode` currency. This value is not trusted; the actual price
   * is confirmed by the Order service. However, providing this value prevents an order being created for an
   * amount that does not match what was shown to the shopper in order preview.
   */
  price: Scalars['Float'];
  /** The product and chosen options */
  productConfiguration: GQLProductConfigurationInput;
  /** The desired quantity of this item. This must be a positive integer. */
  quantity: Scalars['Int'];
};

/** Response payload for the addOrderFulfillmentGroup mutation */
export class GQLAddOrderFulfillmentGroupPayload {
  __typename?: 'AddOrderFulfillmentGroupPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the added fulfillment group */
  newFulfillmentGroupId: Scalars['ID'];
  /** The updated order */
  order: GQLOrder;
};

/** Input for the cancelOrderItem mutation */
export type GQLCancelOrderItemInput = {
  /** Quantity to cancel. Must be equal to or less than the item quantity. */
  cancelQuantity: Scalars['Int'];
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the item order you want to cancel */
  itemId: Scalars['ID'];
  /** ID of the order that has the item you want to cancel */
  orderId: Scalars['ID'];
  /**
   * An optional free text reason for cancellation, which may be shown to operators
   * or to the user who placed the order.
   */
  reason?: Maybe<Scalars['String']>;
};

/** Response payload for the cancelOrderItem mutation */
export class GQLCancelOrderItemPayload {
  __typename?: 'CancelOrderItemPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order */
  order: GQLOrder;
};

/** Input for the cancelOrderItem mutation */
export type GQLCreateRefundInput = {
  /** Amount to cancel. Must be equal to or less than the remaining non-refunded payment amount for this payment method. */
  amount: Scalars['Float'];
  /** ID of the order that has the item you want to cancel */
  orderId: Scalars['ID'];
  /** ID of the payment that you want to refund */
  paymentId: Scalars['ID'];
  /**
   * An optional free text reason for refund, which may be shown to operators
   * or to the user who requested the refund.
   */
  reason?: Maybe<Scalars['String']>;
};

/** Response payload for the createRefund mutation */
export class GQLCreateRefundPayload {
  __typename?: 'CreateRefundPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order */
  order: GQLOrder;
};

/** Input for the moveOrderItems mutation */
export type GQLMoveOrderItemsInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the order fulfillment group from which all the items are to be moved. */
  fromFulfillmentGroupId: Scalars['ID'];
  /** The list of item IDs to move. The full quantity must be moved. */
  itemIds: Array<Maybe<Scalars['ID']>>;
  /** ID of the order that has the items you want to move */
  orderId: Scalars['ID'];
  /** The ID of the order fulfillment group to which all the items are to be moved. */
  toFulfillmentGroupId: Scalars['ID'];
};

/** Response payload for the moveOrderItems mutation */
export class GQLMoveOrderItemsPayload {
  __typename?: 'MoveOrderItemsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order */
  order: GQLOrder;
};

/** Input for the placeOrder mutation */
export type GQLPlaceOrderInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The order to be placed, if payment is accepted */
  order: GQLOrderInput;
  /**
   * The information necessary to pay. Collect this information from the shopper during a checkout flow.
   * You need not provide any payment input if the total is zero.
   * The total of all payment input `amount` fields must add up to the order total. The first payment
   * method where the `amount` field is `null` will be charged the remainder due.
   */
  payments?: Maybe<Array<Maybe<GQLPaymentInput>>>;
};

/** Input for placing an order */
export type GQLOrderInput = {
  /**
   * The ID of the cart that is becoming an order. This is optional, and you can create an order without ever
   * creating a cart. If you do have a cart, there are two good reasons to provide this. First, it serves as a
   * reference. Second, it allows the Cart service to automatically delete the related cart after the order is
   * created.
   */
  cartId?: Maybe<Scalars['String']>;
  /** The code for the currency in which all values are being provided */
  currencyCode: Scalars['String'];
  /**
   * An email address to use for order tracking and correspondence. If a logged in user is placing an order,
   * we recommend that you use their "orders" email address, if they have one, or their default email address.
   * Or you can ask them to provide any email address.
   */
  email: Scalars['String'];
  /**
   * One or more fulfillment groups for the order. These are the actual orders that need to be fulfilled,
   * separate by shop, fulfillment type, and shipping origin or destination.
   */
  fulfillmentGroups: Array<Maybe<GQLOrderFulfillmentGroupInput>>;
  /**
   * The shop through which the order should be placed. Payment settings from this shop will be used. Note that
   * each fulfillment group also has a shop ID, which represents the shop that needs to fulfill that part of the
   * order, and those shop IDs may or may not match this one.
   */
  shopId: Scalars['String'];
};

/** Input for an `OrderFulfillmentGroup` */
export type GQLOrderFulfillmentGroupInput = {
  /** Information needed by the selected fulfillment method to properly fulfill the order */
  data?: Maybe<GQLOrderFulfillmentGroupDataInput>;
  /** The list of items to be ordered */
  items: Array<Maybe<GQLOrderFulfillmentGroupItemInput>>;
  /** The ID of the fulfillment method to be used for this order group */
  selectedFulfillmentMethodId: Scalars['ID'];
  /** The shop that owns these items and needs to fulfill this part of the order */
  shopId: Scalars['ID'];
  /**
   * The total price of the items, fulfillment, and taxes, for this group, less any discounts, in the
   * `order.currencyCode` currency. This value is not trusted; the actual total is calculated by the
   * Order service. However, providing this value prevents an order being created for an amount that
   * does not match what was shown to the shopper in order preview.
   */
  totalPrice?: Maybe<Scalars['Float']>;
  /** The fulfillment type. Any valid type that has been registered by a fulfillment plugin. Examples: `shipping`, `digital` */
  type: FulfillmentType;
};

/** Input for adding order payments */
export type GQLPaymentInput = {
  /**
   * Amount to charge, which must be less than or equal to the order total. This is assumed
   * to be in the same currency as the order. Set to `null` to charge the remaining amount
   * to this payment method, which might be the full order total if this is the only payment.
   */
  amount: Scalars['Float'];
  /**
   * The billing address entered by the shopper. If omitted, the billing address on the order input
   * will be used. Some payment methods may not require a billing address but others will fail
   * authorization without one, so be sure that client UI code is aware of which payment methods
   * require collecting one.
   */
  billingAddress?: Maybe<GQLAddressInput>;
  /** Any additional user-provided input necessary to authorize and capture the payment */
  data?: Maybe<Scalars['JSONObject']>;
  /** The name of the payment method to use for this payment */
  method: PaymentMethodName;
};

/** The name of a payment method, which is how payment methods are keyed */
export enum PaymentMethodName {
  /** No payment method */
  None = 'none',
  /** Stripe Card payment method */
  StripeCard = 'stripe_card',
  /** IOU Example payment method */
  IouExample = 'iou_example'
}

/** Response payload for the placeOrder mutation */
export class GQLPlaceOrderPayload {
  __typename?: 'PlaceOrderPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Orders that were created */
  orders: Array<Maybe<GQLOrder>>;
  /** If you are not logged in, this will be a token that can be used for future requests */
  token?: Maybe<Scalars['String']>;
};

/** Input for the splitOrderItem mutation */
export type GQLSplitOrderItemInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the item order you want to split */
  itemId: Scalars['ID'];
  /** The quantity that will be transferred to a new order item on the same fulfillment group. */
  newItemQuantity: Scalars['Int'];
  /** ID of the order that has the item you want to split */
  orderId: Scalars['ID'];
};

/** Response payload for the splitOrderItem mutation */
export class GQLSplitOrderItemPayload {
  __typename?: 'SplitOrderItemPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the new order item that was created */
  newItemId: Scalars['ID'];
  /** The updated order */
  order: GQLOrder;
};

/** Input for the updateOrder mutation */
export type GQLUpdateOrderInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Set the order email to this */
  email?: Maybe<Scalars['String']>;
  /** ID of the order to update */
  orderId: Scalars['ID'];
  /** Set the current order status to this */
  status?: Maybe<Scalars['String']>;
};

/** Response payload for the updateOrder mutation */
export class GQLUpdateOrderPayload {
  __typename?: 'UpdateOrderPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order */
  order: GQLOrder;
};

/** Input for the updateOrderFulfillmentGroup mutation */
export type GQLUpdateOrderFulfillmentGroupInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID of the order fulfillment group to update */
  orderFulfillmentGroupId: Scalars['ID'];
  /** ID of the order to update */
  orderId: Scalars['ID'];
  /** Set the current order fulfillment group status to this */
  status?: Maybe<Scalars['String']>;
  /** Set this as the current order fulfillment group shipment tracking reference */
  tracking?: Maybe<Scalars['String']>;
  /** Set this as the current order fulfillment group shipment tracking URL */
  trackingUrl?: Maybe<Scalars['String']>;
};

/** Response payload for the updateOrderFulfillmentGroup mutation */
export class GQLUpdateOrderFulfillmentGroupPayload {
  __typename?: 'UpdateOrderFulfillmentGroupPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order */
  order: GQLOrder;
};

/** Input for the `approveOrderPayments` mutation */
export type GQLApproveOrderPaymentsInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The order ID */
  orderId: Scalars['ID'];
  /** The IDs of one or more payments to approve for this order */
  paymentIds: Array<Maybe<Scalars['ID']>>;
  /** The ID of the shop that owns this order */
  shopId: Scalars['ID'];
};

/** Response from the `approveOrderPayments` mutation */
export class GQLApproveOrderPaymentsPayload {
  __typename?: 'ApproveOrderPaymentsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order */
  order: GQLOrder;
};

/** Input for the `captureOrderPayments` mutation */
export type GQLCaptureOrderPaymentsInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The order ID */
  orderId: Scalars['ID'];
  /** The IDs of one or more payments to capture for this order */
  paymentIds: Array<Maybe<Scalars['ID']>>;
  /** The ID of the shop that owns this order */
  shopId: Scalars['ID'];
};

/** Response from the `captureOrderPayments` mutation */
export class GQLCaptureOrderPaymentsPayload {
  __typename?: 'CaptureOrderPaymentsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated order */
  order: GQLOrder;
};

/** Input for the `enablePaymentMethodForShop` mutation */
export type GQLEnablePaymentMethodForShopInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** True to enable it or false to disable it */
  isEnabled: Scalars['Boolean'];
  /** The name of the payment method to enable or disable */
  paymentMethodName: Scalars['String'];
  /** The ID of the shop for which this payment method should be enabled or disabled */
  shopId: Scalars['ID'];
};

/** Response payload for the `enablePaymentMethodForShop` mutation */
export class GQLEnablePaymentMethodForShopPayload {
  __typename?: 'EnablePaymentMethodForShopPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The full list of payment methods for the shop */
  paymentMethods: Array<Maybe<GQLPaymentMethod>>;
};

/** Describes the input for creating a discount code */
export type GQLCreateDiscountCodeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The discount code to update */
  discountCode?: Maybe<GQLDiscountCodeInput>;
  /** The shop ID of the discount code to update */
  shopId: Scalars['ID'];
};

/** Input type for a discount code */
export type GQLDiscountCodeInput = {
  /** How the discount should be applied */
  calculation?: Maybe<GQLDiscountCalculationInput>;
  /** Discount Code */
  code: Scalars['String'];
  /** Discount code conditions */
  conditions?: Maybe<GQLDiscountConditionsInput>;
  /** Description to describe the discount code */
  description?: Maybe<Scalars['String']>;
  /**
   * Discount is allowed to be string or number.
   * it's a formula value (could be shipping code)
   */
  discount?: Maybe<Scalars['String']>;
  /** Discount method type */
  discountMethod?: Maybe<DiscountMethod>;
  /** Label to describe the code */
  label?: Maybe<Scalars['String']>;
  /** History of transactions */
  transactions?: Maybe<Array<Maybe<GQLDiscountTransactionInput>>>;
};

/** Input type for discount calculation */
export type GQLDiscountCalculationInput = {
  /** Discount code calculation method */
  method?: Maybe<DiscountCalculationMethod>;
};

/** Discount conditions input type */
export type GQLDiscountConditionsInput = {
  /** Account Limit */
  accountLimit?: Maybe<Scalars['Int']>;
  /** Audience that may apply this discount code */
  audience?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Is this discount code enabled */
  enabled: Scalars['Boolean'];
  /** Order conditions */
  order?: Maybe<GQLDiscountConditionOrderInput>;
  /** Permissions that may apply this discount code */
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Products that may apply this discount code */
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Number of times this code may be redeemed.
   * Setting to 100 means the first 100 customers may apply this code.
   * Setting this value to 0 will allow this code to be applied an infinite number of times.
   */
  redemptionLimit?: Maybe<Scalars['Int']>;
  /** Tags that may be apply this discount code */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Discount order conditions input type */
export type GQLDiscountConditionOrderInput = {
  /** Order date range end */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Maximum order value */
  max?: Maybe<Scalars['Float']>;
  /** Minimum order value */
  min: Scalars['Float'];
  /** Order date range start */
  startDate?: Maybe<Scalars['DateTime']>;
};

/** Discount transation input type */
export type GQLDiscountTransactionInput = {
  /** Date the code was applied */
  appliedAt?: Maybe<Scalars['DateTime']>;
  /** Cart id */
  cartId: Scalars['String'];
  /** User id */
  userId: Scalars['String'];
};

/** The response from the `createDiscountCode` mutation */
export class GQLCreateDiscountCodePayload {
  __typename?: 'CreateDiscountCodePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created discount code */
  discountCode?: Maybe<GQLDiscountCode>;
};

/** Describes the input for updating a discount code */
export type GQLUpdateDiscountCodeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The discount code to update */
  discountCode?: Maybe<GQLDiscountCodeInput>;
  /** The ID of the discount code to update */
  discountCodeId: Scalars['ID'];
  /** The shop ID of the discount code to update */
  shopId: Scalars['ID'];
};

/** The response from the `updateDiscountCode` mutation */
export class GQLUpdateDiscountCodePayload {
  __typename?: 'UpdateDiscountCodePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated discount code */
  discountCode?: Maybe<GQLDiscountCode>;
};

/** Describes the input for removing a discount code */
export type GQLDeleteDiscountCodeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The discount code ID */
  discountCodeId: Scalars['ID'];
  /** Shop ID */
  shopId: Scalars['ID'];
};

/** The response from the `deleteDiscountCode` mutation */
export class GQLDeleteDiscountCodePayload {
  __typename?: 'DeleteDiscountCodePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deleted discount code */
  discountCode?: Maybe<GQLDiscountCode>;
};

/** Input for an `ApplyDiscountCodeToCartInput` */
export type GQLApplyDiscountCodeToCartInput = {
  /** Cart to add discount to */
  cartId: Scalars['ID'];
  /** Discount code to add to cart */
  discountCode: Scalars['String'];
  /** Shop cart belongs to */
  shopId: Scalars['ID'];
  /** Cart token, if anonymous */
  token?: Maybe<Scalars['String']>;
};

/** Response from the `applyDiscountCodeToCart` mutation */
export class GQLApplyDiscountCodeToCartPayload {
  __typename?: 'ApplyDiscountCodeToCartPayload';
  /** The updated cart with discount code applied */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for an `RemoveDiscountCodeFromCartInput` */
export type GQLRemoveDiscountCodeFromCartInput = {
  /** Cart to add discount to */
  cartId: Scalars['ID'];
  /** ID of the discount you want to remove from the cart */
  discountId: Scalars['ID'];
  /** Shop cart belongs to */
  shopId: Scalars['ID'];
  /** Cart token, if anonymous */
  token?: Maybe<Scalars['String']>;
};

/** Response from the `removeDiscountCodeFromCart` mutation */
export class GQLRemoveDiscountCodeFromCartPayload {
  __typename?: 'RemoveDiscountCodeFromCartPayload';
  /** The updated cart with discount code removed */
  cart: GQLCart;
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Input for the `CreateSurcharge` mutation */
export type GQLCreateSurchargeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop to create this surcharge for */
  shopId: Scalars['ID'];
  /** This defines the surcharge that you want to create */
  surcharge: GQLSurchargeInput;
};

/** Defines a surcharge. */
export type GQLSurchargeInput = {
  /** Amount. */
  amount: Scalars['Float'];
  /** Attribute restrictions. */
  attributes?: Maybe<Array<Maybe<GQLSurchargeAttributeRestrictionsInput>>>;
  /** Destination restrictions. */
  destination?: Maybe<GQLSurchargeDestinationRestrictionsInput>;
  /** Messages by language. */
  messagesByLanguage: Array<Maybe<GQLMessagesByLanguageInput>>;
  /** Method IDs to apply this surcharge to. */
  methodIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The type of this surcharge. Allowed types are `surcharge`. */
  type: SurchargeTypeEnum;
};

/** Input to add a surcharge attribute restriction */
export type GQLSurchargeAttributeRestrictionsInput = {
  /** The operator to use for value comparison */
  operator?: Maybe<Scalars['String']>;
  /** The property to check */
  property?: Maybe<Scalars['String']>;
  /** The type of this property */
  propertyType?: Maybe<SurchargePropertyType>;
  /** The value to check for */
  value?: Maybe<Scalars['String']>;
};

/** Input to add a surcharge destination restriction */
export type GQLSurchargeDestinationRestrictionsInput = {
  /** Restrict for any of these destination countries */
  country?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination postal codes */
  postal?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination regions */
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input to add a surcharge message with language */
export type GQLMessagesByLanguageInput = {
  /** The message for this language */
  content: Scalars['String'];
  /** The language code */
  language: Scalars['String'];
};

/** Response from the `CreateSurcharge` mutation */
export class GQLCreateSurchargePayload {
  __typename?: 'CreateSurchargePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created surcharge */
  surcharge: GQLSurcharge;
};

/** Input for the `deleteSurcharge` mutation */
export type GQLDeleteSurchargeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop that owns the method */
  shopId: Scalars['ID'];
  /** The ID of the flat rate fulfillment method you want to delete */
  surchargeId: Scalars['ID'];
};

/** Response from the `deleteSurcharge` mutation */
export class GQLDeleteSurchargePayload {
  __typename?: 'DeleteSurchargePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The removed fulfillment method */
  surcharge: GQLSurcharge;
};

/** Input for the `updateSurcharge` mutation */
export type GQLUpdateSurchargeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop that owns the method */
  shopId: Scalars['ID'];
  /** The updated surcharge. Pass the whole updated surcharge object without the ID. */
  surcharge: GQLSurchargeInput;
  /** The ID of the flat rate fulfillment surcharge you want to update */
  surchargeId: Scalars['ID'];
};

/** Response from the `updateFlatRateFulfillmentMethod` mutation */
export class GQLUpdateSurchargePayload {
  __typename?: 'UpdateSurchargePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated fulfillment surcharge */
  surcharge: GQLSurcharge;
};

/** Input for the `createFlatRateFulfillmentMethod` mutation */
export type GQLCreateFlatRateFulfillmentMethodInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** This defines the flat rate fulfillment method that you want to create */
  method: GQLFlatRateFulfillmentMethodInput;
  /** The shop to create this flat rate fulfillment method for */
  shopId: Scalars['ID'];
};

/** Defines a fulfillment method that has a fixed price. This type is provided by the `flat-rate` fulfillment plugin. */
export type GQLFlatRateFulfillmentMethodInput = {
  /** The cost of this fulfillment method to the shop, if you track this */
  cost?: Maybe<Scalars['Float']>;
  /** The fulfillment types for which this method may be used. For example, `shipping` or `digital`. */
  fulfillmentTypes: Array<Maybe<FulfillmentType>>;
  /** The group to which this method belongs */
  group: Scalars['String'];
  /** A fixed price to charge for handling costs when this fulfillment method is selected for an order */
  handling: Scalars['Float'];
  /** Include this as a fulfillment option shown to shoppers during checkout? */
  isEnabled: Scalars['Boolean'];
  /** The name of this method, for display in the user interface */
  label: Scalars['String'];
  /** The name of this method, a unique identifier */
  name: Scalars['String'];
  /** A fixed price to charge for fulfillment costs when this fulfillment method is selected for an order */
  rate: Scalars['Float'];
};

/** Response from the `createFlatRateFulfillmentMethod` mutation */
export class GQLCreateFlatRateFulfillmentMethodPayload {
  __typename?: 'CreateFlatRateFulfillmentMethodPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created fulfillment method */
  method: GQLFlatRateFulfillmentMethod;
};

/** Input for the `updateFlatRateFulfillmentMethod` mutation */
export type GQLUpdateFlatRateFulfillmentMethodInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated method. Pass the whole updated method object without the ID. */
  method: GQLFlatRateFulfillmentMethodInput;
  /** The ID of the flat rate fulfillment method you want to update */
  methodId: Scalars['ID'];
  /** The shop that owns the method */
  shopId: Scalars['ID'];
};

/** Response from the `updateFlatRateFulfillmentMethod` mutation */
export class GQLUpdateFlatRateFulfillmentMethodPayload {
  __typename?: 'UpdateFlatRateFulfillmentMethodPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated fulfillment method */
  method: GQLFlatRateFulfillmentMethod;
};

/** Input for the `deleteFlatRateFulfillmentMethod` mutation */
export type GQLDeleteFlatRateFulfillmentMethodInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the flat rate fulfillment method you want to delete */
  methodId: Scalars['ID'];
  /** The shop that owns the method */
  shopId: Scalars['ID'];
};

/** Response from the `deleteFlatRateFulfillmentMethod` mutation */
export class GQLDeleteFlatRateFulfillmentMethodPayload {
  __typename?: 'DeleteFlatRateFulfillmentMethodPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The removed fulfillment method */
  method: GQLFlatRateFulfillmentMethod;
};

/** Input for the `CreateFlatRateFulfillmentRestriction` mutation */
export type GQLCreateFlatRateFulfillmentRestrictionInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** This defines the flat rate fulfillment method restriction that you want to create */
  restriction: GQLFlatRateFulfillmentRestrictionInput;
  /** The shop to create this flat rate fulfillment method restriction for */
  shopId: Scalars['ID'];
};

/** Defines the input for a flat rate fulfillment method restriction. */
export type GQLFlatRateFulfillmentRestrictionInput = {
  /** Attribute restrictions. Multiple attribute restrictions are evaluated with AND. If both destination and attribute restrictions are present, they evaluate with AND. */
  attributes?: Maybe<Array<Maybe<GQLAttributeRestrictionsInput>>>;
  /** Destination restrictions. If multiple destination restrictions are present, the most localized is the only one evaluated (i.e. evaluate postal if present, then region if present, then country). If both destination and attribute restrictions are present, they evaluate with AND. */
  destination?: Maybe<GQLDestinationRestrictionsInput>;
  /** Method IDs to apply this restriction to. If none, applies to all methods as a universal restriction. */
  methodIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The type of this restriction. Allowed types are `allow` or `deny`. */
  type: RestrictionTypeEnum;
};

/** Input to create an attribute restriction condition */
export type GQLAttributeRestrictionsInput = {
  /** The operator to use for value comparison */
  operator: Scalars['String'];
  /** The property to check */
  property: Scalars['String'];
  /** The type of this property */
  propertyType: Scalars['String'];
  /** The value to check for */
  value: Scalars['String'];
};

/** Input for a destination restriction condition */
export type GQLDestinationRestrictionsInput = {
  /** Restrict for any of these destination countries */
  country?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination postal codes */
  postal?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Restrict for any of these destination regions */
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Response from the `CreateFlatRateFulfillmentRestriction` mutation */
export class GQLCreateFlatRateFulfillmentRestrictionPayload {
  __typename?: 'CreateFlatRateFulfillmentRestrictionPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created flat rate fulfillment method restriction */
  restriction: GQLFlatRateFulfillmentRestriction;
};

/** Input for the `deleteFlatRateFulfillmentRestriction` mutation */
export type GQLDeleteFlatRateFulfillmentRestrictionInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the flat rate fulfillment method restriction you want to delete */
  restrictionId: Scalars['ID'];
  /** The shop that owns the flat rate fulfillment method restriction */
  shopId: Scalars['ID'];
};

/** Response from the `deleteFlatRateFulfillmentRestriction` mutation */
export class GQLDeleteFlatRateFulfillmentRestrictionPayload {
  __typename?: 'DeleteFlatRateFulfillmentRestrictionPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The removed flat rate fulfillment method restriction */
  restriction: GQLFlatRateFulfillmentRestriction;
};

/** Input for the `updateFlatRateFulfillmentRestriction` mutation */
export type GQLUpdateFlatRateFulfillmentRestrictionInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated flat rate fulfillment method restriction. Pass the whole updated restriction object without the ID. */
  restriction: GQLFlatRateFulfillmentRestrictionInput;
  /** The ID of the flat rate fulfillment method restriction you want to update */
  restrictionId: Scalars['ID'];
  /** The shop that owns the flat rate fulfillment method restriction */
  shopId: Scalars['ID'];
};

/** Response from the `updateFlatRateFulfillmentMethod` mutation */
export class GQLUpdateFlatRateFulfillmentRestrictionPayload {
  __typename?: 'UpdateFlatRateFulfillmentRestrictionPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated flat rate fulfillment method restriction */
  restriction: GQLFlatRateFulfillmentRestriction;
};

/** The input for creating a tax rate */
export type GQLCreateTaxRateInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An optional country code to limit where this tax is applied based on destination address */
  country?: Maybe<Scalars['String']>;
  /** An optional postal code to limit where this tax is applied based on destination address */
  postal?: Maybe<Scalars['String']>;
  /** The tax rate. For example, 0.05 for a 5% sales tax. */
  rate: Scalars['Float'];
  /** An optional region (e.g., state) to limit where this tax is applied based on destination address */
  region?: Maybe<Scalars['String']>;
  /** Shop ID */
  shopId: Scalars['ID'];
  /** Whether the `country`, `postal`, and `region` filters apply to the origin address or the destination address */
  sourcing?: Maybe<TaxSource>;
  /** An optional tax code, to apply this tax rate to only products that have this tax code */
  taxCode?: Maybe<Scalars['String']>;
};

/** The response from the `createTaxRate` mutation */
export class GQLCreateTaxRatePayload {
  __typename?: 'CreateTaxRatePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created tax rate */
  taxRate: GQLTaxRate;
};

/**
 * The input for updating a tax rate. Note that missing values will cause the field to be cleared, so
 * send all optional fields with every request unless they aren't currently set or you intend to clear them.
 */
export type GQLUpdateTaxRateInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An optional country code to limit where this tax is applied based on destination address */
  country?: Maybe<Scalars['String']>;
  /** An optional postal code to limit where this tax is applied based on destination address */
  postal?: Maybe<Scalars['String']>;
  /** The tax rate. For example, 0.05 for a 5% sales tax. */
  rate: Scalars['Float'];
  /** An optional region (e.g., state) to limit where this tax is applied based on destination address */
  region?: Maybe<Scalars['String']>;
  /** Shop ID */
  shopId: Scalars['ID'];
  /** Whether the `country`, `postal`, and `region` filters apply to the origin address or the destination address */
  sourcing?: Maybe<TaxSource>;
  /** An optional tax code, to apply this tax rate to only products that have this tax code */
  taxCode?: Maybe<Scalars['String']>;
  /** ID of the tax rate you want to update */
  taxRateId: Scalars['ID'];
};

/** The response from the `updateTaxRate` mutation */
export class GQLUpdateTaxRatePayload {
  __typename?: 'UpdateTaxRatePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated tax rate */
  taxRate: GQLTaxRate;
};

/** Describes the input for removing a tax rate */
export type GQLDeleteTaxRateInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Shop ID */
  shopId: Scalars['ID'];
  /** The tax rate ID */
  taxRateId: Scalars['ID'];
};

/** The response from the `deleteTaxRate` mutation */
export class GQLDeleteTaxRatePayload {
  __typename?: 'DeleteTaxRatePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deleted tax rate */
  taxRate: GQLTaxRate;
};

/** Input for the `createNavigationItem` mutation */
export type GQLCreateNavigationItemInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The navigation item to create */
  navigationItem: GQLNavigationItemInput;
};

/** NavigationItem input */
export type GQLNavigationItemInput = {
  /** The draft/unpublished data for this navigation item */
  draftData?: Maybe<GQLNavigationItemDataInput>;
  /** An object storing additional metadata about the navigation item (such as its related tag) */
  metadata?: Maybe<Scalars['JSONObject']>;
  /** Shop ID of the navigation item */
  shopId: Scalars['ID'];
};

/** NavigationItemData input */
export type GQLNavigationItemDataInput = {
  /** CSS class names to add to the menu item for display */
  classNames?: Maybe<Scalars['String']>;
  /** The content for the navigation item, in one or more languages */
  content?: Maybe<Array<Maybe<GQLNavigationItemContentInput>>>;
  /** Whether the provided URL is relative or external */
  isUrlRelative?: Maybe<Scalars['Boolean']>;
  /** Whether the navigation item should trigger a new tab/window to open when clicked */
  shouldOpenInNewWindow?: Maybe<Scalars['Boolean']>;
  /** The URL for the navigation item to link to */
  url?: Maybe<Scalars['String']>;
};

/** NavigationItem content input */
export type GQLNavigationItemContentInput = {
  /** The language of the piece of navigation content */
  language: Scalars['String'];
  /** The translated value, in plain text or markdown */
  value?: Maybe<Scalars['String']>;
};

/** Response payload for the `createNavigationItem` mutation */
export class GQLCreateNavigationItemPayload {
  __typename?: 'CreateNavigationItemPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created navigation item */
  navigationItem?: Maybe<GQLNavigationItem>;
};

/** Input for the `createNavigationTree` mutation */
export type GQLCreateNavigationTreeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The draft navigation items that make up this tree */
  draftItems?: Maybe<Array<Maybe<GQLNavigationTreeItemInput>>>;
  /** The name of the tree, for operator display purposes */
  name: Scalars['String'];
  /** The ID of the shop this navigation tree belongs to */
  shopId: Scalars['ID'];
};

/** NavigationTree item input */
export type GQLNavigationTreeItemInput = {
  /** Whether the navigation item should display its children */
  expanded?: Maybe<Scalars['Boolean']>;
  /** Whether the navigation item should be hidden from customers */
  isPrivate?: Maybe<Scalars['Boolean']>;
  /** Whether the navigaton item is a secondary navigation item */
  isSecondary?: Maybe<Scalars['Boolean']>;
  /** Whether the navigation ttem should shown in query results for customers and admins */
  isVisible?: Maybe<Scalars['Boolean']>;
  /** The child navigation items */
  items?: Maybe<Array<Maybe<GQLNavigationTreeItemInput>>>;
  /** The ID of the navigation item */
  navigationItemId: Scalars['ID'];
};

/** Response payload for the `createNavigationTree` mutation */
export class GQLCreateNavigationTreePayload {
  __typename?: 'CreateNavigationTreePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The created navigation tree */
  navigationTree: GQLNavigationTree;
};

/** Input for the `deleteNavigationItem` mutation */
export type GQLDeleteNavigationItemInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the navigation item to delete */
  id: Scalars['ID'];
  /** The ID of the shop navigation item belongs to */
  shopId: Scalars['ID'];
};

/** Response payload for the `deleteNavigationItem` mutation */
export class GQLDeleteNavigationItemPayload {
  __typename?: 'DeleteNavigationItemPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The deleted navigation item */
  navigationItem?: Maybe<GQLNavigationItem>;
};

/** Input for the `publishNavigationChanges` mutation */
export type GQLPublishNavigationChangesInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the navigation tree */
  id: Scalars['ID'];
  /** Shop ID of the navigation tree */
  shopId: Scalars['ID'];
};

/** Response payload for the `publishNavigationChanges` mutation */
export class GQLPublishNavigationChangesPayload {
  __typename?: 'PublishNavigationChangesPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The navigation tree with updated items */
  navigationTree?: Maybe<GQLNavigationTree>;
};

/** Input for the `updateNavigationItem` mutation */
export type GQLUpdateNavigationItemInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the navigation item to update */
  id: Scalars['ID'];
  /** The field updates to apply */
  navigationItem: GQLNavigationItemInput;
  /** The ID of the shop navigation item belongs to */
  shopId: Scalars['ID'];
};

/** Response payload for the `updateNavigationItem` mutation */
export class GQLUpdateNavigationItemPayload {
  __typename?: 'UpdateNavigationItemPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated navigation item */
  navigationItem?: Maybe<GQLNavigationItem>;
};

/** Input for the `updateNavigationTree` mutation */
export type GQLUpdateNavigationTreeInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the navigation tree to update */
  id: Scalars['ID'];
  /** The field updates to apply */
  navigationTree: GQLNavigationTreeInput;
  /** The ID of the shop navigation item belongs to */
  shopId: Scalars['ID'];
};

/** NavigationTree input */
export type GQLNavigationTreeInput = {
  /** The draft navigation items that make up this tree */
  draftItems?: Maybe<Array<Maybe<GQLNavigationTreeItemInput>>>;
  /** The name of the tree, for operator display purposes */
  name?: Maybe<Scalars['String']>;
};

/** Response payload for the `updateNavigationTree` mutation */
export class GQLUpdateNavigationTreePayload {
  __typename?: 'UpdateNavigationTreePayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The updated navigation tree */
  navigationTree?: Maybe<GQLNavigationTree>;
};

/** Input for the `generateSitemaps` mutation */
export type GQLGenerateSitemapsInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the shop to generate sitemap for */
  shopId: Scalars['ID'];
};

/** Response for the `generateSitemaps` mutation */
export class GQLGenerateSitemapsPayload {
  __typename?: 'GenerateSitemapsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the sitemap generation job was successfully scheduled */
  wasJobScheduled: Scalars['Boolean'];
};

/** Input for the `loadProductsAndTags` mutation */
export type GQLLoadProductsAndTagsInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop ID */
  shopId: Scalars['String'];
  /** The desired count of products to reach in the database */
  desiredProductCount: Scalars['Int'];
  /** The desired count of tags to reach in the database */
  desiredTagCount: Scalars['Int'];
};

/** Response for the `loadProductsAndTags` mutation */
export class GQLLoadProductsAndTagsPayload {
  __typename?: 'LoadProductsAndTagsPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The number of products created */
  productsCreated: Scalars['Int'];
  /** The number of tags created */
  tagsCreated: Scalars['Int'];
};

export type GQLLoadOrdersInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop ID */
  shopId: Scalars['String'];
  /** The desired count of orders to reach in the database */
  desiredOrderCount: Scalars['Int'];
};

/** Response for the `loadProductsAndTags` mutation */
export class GQLLoadOrdersPayload {
  __typename?: 'LoadOrdersPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The number of orders created */
  ordersCreated: Scalars['Int'];
};

export type GQLLoadProductImagesInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop ID */
  shopId: Scalars['String'];
};

/** Response for the `loadProductImages` mutation */
export class GQLLoadProductImagesPayload {
  __typename?: 'LoadProductImagesPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether data was loaded or not */
  wasDataLoaded: Scalars['Boolean'];
};

export type GQLRemoveDataInput = {
  /** An optional string identifying the mutation call, which will be returned in the response payload */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The shop ID */
  shopId: Scalars['String'];
};

/** Response from the `removeAllData` mutation */
export class GQLRemoveDataPayload {
  __typename?: 'RemoveDataPayload';
  /** The same string you sent with the mutation params, for matching mutation calls with their responses */
  clientMutationId?: Maybe<Scalars['String']>;
  wasDataRemoved: Scalars['Boolean'];
};

/** Subscriptions allow you to request to get updated data whenever it changes */
export class GQLSubscription {
  __typename?: 'Subscription';
  /** A test subscription that returns an incremented number every 1 second for 10 seconds */
  tick: Scalars['Int'];
};

/** Represents a catalog item that displays some non-product content */
export class GQLCatalogItemContent  implements GQLCatalogItem, GQLNode {
  __typename?: 'CatalogItemContent';
  /** The CatalogItemProduct ID */
  _id: Scalars['ID'];
  /** The date and time at which this CatalogItem was first created */
  createdAt: Scalars['DateTime'];
  /** The shop to which this catalog belongs */
  shop: GQLShop;
  /** The date and time at which this CatalogItem was last updated */
  updatedAt: Scalars['DateTime'];
};

/** One product catalog for a particular shop */
export class GQLCatalog  implements GQLNode {
  __typename?: 'Catalog';
  /** The Catalog ID */
  _id: Scalars['ID'];
  /** The date and time at which this Catalog was first created */
  createdAt: Scalars['DateTime'];
  /** The shop to which this catalog belongs */
  shop: GQLShop;
  /** The date and time at which this Catalog was last updated */
  updatedAt: Scalars['DateTime'];
};


