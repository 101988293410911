import { ProductsService } from './../../../../../core/point-of-interest/_services/products.service';
import { BaseDataSource } from './../../../../../core/_base/crud/models/_base.datasource';
// Angular
import {
	Component,
	Input,
	ChangeDetectionStrategy,
	OnInit,
	ChangeDetectorRef,
	OnDestroy,
	Output,
	EventEmitter
} from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';

// CRUD
import {
	TypesUtilsService,
	LayoutUtilsService,
	MessageType
} from './../../../../../core/_base/crud';
import { VariantLine } from './variant-line.model';
import {
	markAllFormAs,
	isDefined,
	toInteger,
	isNumber,
	objectPathExists
} from './../../../../../core/_base/crud/utils/helper.function';
import { trigger, state, style, transition, animate } from '@angular/animations';
// Services and Models

@Component({
	selector: 'kt-variant-list',
	templateUrl: './variant-list.component.html',
	styleUrls: ['./variant-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition(
				'expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
			)
		])
	]
})
export class VariantListComponent implements OnInit, OnDestroy {
	// Incoming data
	@Input() pointOfInterestId: number;
	@Input() variantLines: VariantLine[];
	@Input() currencySymbol = '';
	@Input() productId;
	_inventoryFlags = {
		canBackorder: false,
		isEnabled: false
	};
	@Input() set inventoryFlags(newValue: {
		canBackorder: boolean;
		isEnabled: boolean;
	}) {
		if (this.variantLines && this.variantLines.length > 0) {
			this.variantLines.forEach((variant) => {
				variant.setInventoryFlags(newValue);
			});
			this.cd.detectChanges();
		}
		this._inventoryFlags = newValue;
	}

	// Beacause the variant is the container of the inventory info is need to communicate to the Product-Form
	// if the variants have or not the inventory and the canBackorder active only after get this info from server.
	// NOTE: If the inventory or the canbackorder is enabled to one variant is enable to all variants and options.
	@Output() inventoryFlagsChanged = new EventEmitter<{
		canBackorder?: boolean;
		isEnabled?: boolean;
	}>();

	// Table fields
	dataSource: BaseDataSource;
	displayedColumns = [
		'collapse',
		'attribute',
		'label',
		'price',
		'saleprice',
		'quantity',
		'state',
		'actions'
	];
	expandedElement: VariantLine | null;
	formGroup: FormGroup;

	// Private properties
	inView = true;

	// Add and Edit
	loadingAfterSubmit = false;
	isSwitchedToEditMode = false;

	/**
	 * Component constructor
	 *
	 * @param fb: FormBuilder
	 * @param dialog: MatDialog
	 * @param typesUtilsService: TypeUtilsService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param cd: ChangeDetectorRef
	 */
	constructor(
		private fb: FormBuilder,
		public dialog: MatDialog,
		public typesUtilsService: TypesUtilsService,
		private layoutUtilsService: LayoutUtilsService,
		private cd: ChangeDetectorRef,
		private productsService: ProductsService
	) {}

	async ngOnInit() {
		if (!this.variantLines) {
			return;
		}

		this.clearForm();
		// Init DataSource
		this.dataSource = new BaseDataSource();
		this.dataSource.entitySubject.next(this.variantLines);
		this.variantLines = this.variantLines;
		// Request to the extra info to server
		await this.loadExtraVariantInfo();
		this.cd.detectChanges();
	}

	ngOnDestroy() {
		this.inView = false;
	}
	/**
	 * Loading Remarks list
	 */
	async loadExtraVariantInfo() {
		// Load the inventory info for all variants and sub-options
		if (
			this.variantLines &&
			this.variantLines.length !== 0 &&
			!!this.pointOfInterestId
		) {
			this.inventoryFlagsChanged.next({ isEnabled: false, canBackorder: false });
			// tslint:disable-next-line: prefer-for-of
			for (let i = 0; i < this.variantLines.length; i++) {
				const variantLine = this.variantLines[i];
				if (!objectPathExists(variantLine, 'productId')) {
					return;
				}
				// Get the quantity in the variants that don't have any options.
				if (!variantLine.hasOptions && isDefined(variantLine._id)) {
					const inventoryValues = await this.productsService.getInventoryInfo(
						variantLine.productId,
						variantLine._id,
						this.pointOfInterestId
					);
					if (!!inventoryValues) {
						variantLine.inventoryInfo = {
							...variantLine.inventoryInfo,
							inventoryInStock: inventoryValues.inventoryInStock,
							inventoryReserved: inventoryValues.inventoryReserved,
							lowInventoryWarningThreshold:
								inventoryValues.lowInventoryWarningThreshold
						};
						this.inventoryFlagsChanged.next({
							isEnabled: inventoryValues.isEnabled,
							canBackorder: inventoryValues.canBackorder
						});
					}
					// Init the quanity with current inventoryInStock
					variantLine.quantity =
						objectPathExists(variantLine, 'inventoryInfo.inventoryInStock', true) ||
						undefined;
				} else if (variantLine.hasOptions) {
					// Variants with options the quantity is managed on the option.
					variantLine.options.forEach(async (opt) => {
						if (isDefined(opt._id) && isDefined(opt.productId)) {
							const inventoryValues = await this.productsService.getInventoryInfo(
								opt.productId,
								opt._id,
								this.pointOfInterestId
							);
							if (!!inventoryValues) {
								opt.inventoryInfo = {
									...opt.inventoryInfo,
									inventoryInStock: inventoryValues.inventoryInStock,
									inventoryReserved: inventoryValues.inventoryReserved,
									lowInventoryWarningThreshold:
										inventoryValues.lowInventoryWarningThreshold
								};
								this.inventoryFlagsChanged.next({
									isEnabled: inventoryValues.isEnabled,
									canBackorder: inventoryValues.canBackorder
								});
							}
						}
						// Init the quanity with current inventoryInStock
						opt.quantity =
							objectPathExists(opt, 'inventoryInfo.inventoryInStock', true) ||
							undefined;
					});
				}
			}
		}
	}

	/**
	 * Update the dom datasource list when add/remove items from list
	 */
	updateDataSource() {
		this.isSwitchedToEditMode = false;
		this.dataSource.entitySubject.next(this.variantLines);
		this.cd.detectChanges();
	}

	// Form FUNCTIONS: createFormGroup | clearForm | checkForm
	/**
	 * Create Reactive Form
	 * @param _item: VariantLine
	 */

	createFormGroup(item: VariantLine) {
		this.formGroup = this.fb.group({
			attribute: [item.attribute, Validators.compose([Validators.required])],
			label: [item.label, Validators.compose([Validators.required])],
			price: [
				item.price,
				Validators.compose([item.hasOptions ? null : Validators.required])
			],
			saleprice: [
				item.saleprice,
				Validators.compose([item.hasOptions ? null : Validators.required])
			],
			quantity: [
				isDefined(item.quantity) ? item.quantity : null,
				objectPathExists(item, 'inventoryInfo.isEnabled', true) && !item.hasOptions
					? Validators.compose([Validators.required])
					: null
			],
			state: [item.state, Validators.compose([Validators.required])]
		});
	}

	/**
	 * Clear the Reactive Form
	 */
	clearForm() {
		const clearVariant = new VariantLine();
		clearVariant._isEditMode = false;
		clearVariant.currencySymbol = this.currencySymbol;
		this.createFormGroup(clearVariant);
	}

	/**
	 * Check if Form is Valid
	 */
	checkForm() {
		markAllFormAs(this.formGroup, 'touched');
		return !this.formGroup.invalid;
	}

	editModechange(newValue: boolean) {
		this.isSwitchedToEditMode = newValue;
	}
	// ADD Variant FUNCTIONS: addVariantButtonOnClick

	/**
	 * Open Variant Add Form
	 */
	addVariantButtonOnClick() {
		if (this.isSwitchedToEditMode) {
			return;
		}
		let newVariantLine = new VariantLine();
		newVariantLine._isEditMode = true;
		newVariantLine._isNew = true;
		newVariantLine.options = [];
		newVariantLine.currencySymbol = this.currencySymbol;
		newVariantLine.productId = this.productId;

		// All variants at same level shoud be have the same attributeLabel.
		// If ins't the first variation get the attribute of first not deleted line.
		if (this.variantLines && this.variantLines.length !== 0) {
			const mainVariant = this.variantLines.find((variant) => !variant._isDeleted);
			if (!!mainVariant) {
				newVariantLine.attribute = mainVariant.attribute;
			}
		}

		this.createFormGroup(newVariantLine);
		this.variantLines.push(newVariantLine);

		this.updateDataSource();
		this.isSwitchedToEditMode = true;
	}

	isTheMainVariant(index) {
		if (this.variantLines && this.variantLines.length !== 0) {
			const mainVariantIndex = this.variantLines.findIndex(
				(variant) => !variant._isDeleted
			);
			return mainVariantIndex === index;
		}
		return true;
	}
	// EDIT Variant FUNCTIONS:  editVariantButtonOnClick | cancelEditButtonOnClick |

	/**
	 * Update Variant
	 *
	 * @param item: VariantLine
	 */
	editVariantButtonOnClick(item: VariantLine) {
		this.createFormGroup(item);
		item._isEditMode = true;
		this.isSwitchedToEditMode = true;
	}

	/**
	 * Cancel Variant edit
	 *
	 * @param item: VariantLine
	 */
	cancelEditButtonOnClick(item: VariantLine) {
		this.clearForm();
		item._isEditMode = false;
		this.isSwitchedToEditMode = false;
	}

	/**
	 * Save Variant
	 *
	 * @param index: number
	 */
	saveVariant(index: number) {
		if (!this.checkForm()) {
			return;
		}

		this.loadingAfterSubmit = true;
		const controls = this.formGroup.controls;
		const objectForUpdate: VariantLine = this.variantLines[index];
		if (!!objectForUpdate) {
			// Any change on the attribute value should be applied to all variants
			// (Note: Variants at the same level should have the same attribute value )
			this.variantLines.forEach(
				(v: VariantLine) => (v.attribute = controls.attribute.value)
			);

			objectForUpdate.label = controls.label.value;
			if (isDefined(controls.price.value) && controls.price.dirty) {
				const intPrice = toInteger(controls.price.value);
				if (isNumber(intPrice)) {
					objectForUpdate.setPrice(intPrice);
				}
			}
			if (isDefined(controls.saleprice.value) && controls.saleprice.dirty) {
				const intSalePrice = toInteger(controls.saleprice.value);
				if (isNumber(intSalePrice)) {
					objectForUpdate.setSalePrice(intSalePrice);
				}
			}
			if (
				objectPathExists(objectForUpdate, 'inventoryInfo.isEnabled', true) &&
				isDefined(controls.quantity.value) &&
				controls.quantity.dirty
			) {
				const intQtd = toInteger(controls.quantity.value);
				if (isNumber(intQtd)) {
					objectForUpdate.setQuantity(intQtd);
				}
			}
			objectForUpdate.state = controls.state.value;
			objectForUpdate._isEditMode = false;

			this.isSwitchedToEditMode = false;
			this.layoutUtilsService.showActionNotification(
				'PRODUCTS.FORM.MESSAGES.SAVE_VARIANT_SUCCESS',
				MessageType.Update,
				1000,
				true,
				true
			);
		}
		this.loadingAfterSubmit = false;
	}

	/** ACTIONS */
	/**
	 * Delete variant
	 *
	 * @param index: number
	 */
	deleteVariant(index: number) {
		const _title = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.TITLE';
		const _description = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.DESCRIPTION';
		const _waitDesciption = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.WAIT_DESCRIPTION';
		const _deleteMessage = 'PRODUCTS.FORM.DELETE_VARIANT_MODAL.DELETE_MESSAGE';

		const dialogRef = this.layoutUtilsService.deleteElement(
			_title,
			_description,
			_waitDesciption
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			// this.variantLines.splice(index, 1);
			const variant = this.variantLines[index];
			if (!!variant) {
				if (variant._isNew) {
					this.variantLines.splice(index, 1);
				} else {
					variant._isDeleted = true;
					variant._isEditMode = false;
				}
				this.expandedElement = null;
				this.updateDataSource();

				this.layoutUtilsService.showActionNotification(
					_deleteMessage,
					MessageType.Delete
				);
			}
		});
	}

	/* UI **/
	/**
	 * Returns CSS Class by state
	 *
	 * @param state: boolean
	 */
	getItemCssClassByStatus(state: boolean): string {
		return state ? 'success' : 'danger';
	}
	/**
	 * Returns translate key for state
	 * @param state: boolean
	 */
	getItemStatusTranslateKey(state: boolean): string {
		return state ? 'PRODUCTS.STATE.ACTIVE' : 'PRODUCTS.STATE.INACTIVE';
	}

	showHideVariantLineOptions(row: VariantLine) {
		this.expandedElement = this.expandedElement === row ? null : row;
	}
}
