// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// State
import { TagsState } from './../_reducers/tags.reducers';
import { TagModel } from './../_models/tag.model';

export const selectTagsState = createFeatureSelector<TagsState>('tags');

export const selectTagById = (smId: string) =>
	createSelector(selectTagsState, (tagsState) => tagsState.entities[smId]);

export const selectTagsPageLoading = createSelector(
	selectTagsState,
	(tagsState) => tagsState.listLoading
);

export const selectTagsActionLoading = createSelector(
	selectTagsState,
	(tagsState) => tagsState.actionsloading
);

export const selectTagsActionMessage = createSelector(
	selectTagsState,
	(tagsState) => tagsState.actionMessage
);

export const selectTagsInStore = createSelector(selectTagsState, (tagsState) => {
	const sortPredicate = (tag1: TagModel, tag2: TagModel): number => {
		const position1 = TagModel.getPosition(tag1) || Number.POSITIVE_INFINITY;
		const position2 = TagModel.getPosition(tag2) || Number.POSITIVE_INFINITY;

		return Number(position1) - Number(position2);
	};

	const items = Object.values(tagsState.entities).sort(sortPredicate);

	return new QueryResultsModel(items, tagsState.totalCount, '');
});

export const selectTagsShowInitWaitingMessage = createSelector(
	selectTagsState,
	(tagsState) => tagsState.showInitWaitingMessage
);
export const selectTagsShowErrorMessage = createSelector(
	selectTagsState,
	(tagsState) => tagsState.showErrorMessage
);
