import { OrdersModule } from './views/pages/orders/orders.module';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () =>
			import('app/views/pages/auth/auth.module').then((m) => m.AuthModule)
	},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () =>
					import('app/views/pages/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					)
			},
			{
				path: 'service-page',
				loadChildren: () =>
					import('app/views/pages/service-page/service-page.module').then(
						(m) => m.ServicePageModule
					)
			},
			{
				path: 'notifications',
				loadChildren: () =>
					import('app/views/pages/notifications/notifications.module').then(
						(m) => m.NotificationsModule
					)
			},
			{
				path: 'products',
				loadChildren: () =>
					import('app/views/pages/products/products.module').then(
						(m) => m.ProductsModule
					)
			},
			{
				path: 'tags',
				loadChildren: () =>
					import('app/views/pages/tags/tags.module').then((m) => m.TagsModule)
			},
			{
				path: 'orders',
				loadChildren: () =>
					import('app/views/pages/orders/orders.module').then((m) => m.OrdersModule)
			},
			{
				path: 'settings',
				loadChildren: () =>
					import('app/views/pages/settings/settings.module').then(
						(m) => m.SettingsModule
					)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc:
						"Looks like you don't have permission to access for requested page.<br> Please, contact administrator"
				}
			},
			{ path: 'error/:type', component: ErrorPageComponent },
			{ path: '', redirectTo: 'service-page/profile', pathMatch: 'full' },
			{ path: '**', redirectTo: 'service-page/profile', pathMatch: 'full' }
		]
	},

	{ path: '**', redirectTo: 'error/403', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
