import { MetafieldInput } from './metafield.model';
import { ReactionQueryResultsModel } from './../../_base/crud/models/query-models/reaction-query-results.model';
import { GQLTag } from '../../../../generated/graphql';
import { ApolloQueryResult } from 'apollo-client';

export interface CreateTagReactionResponse
	extends ApolloQueryResult<{
		addTag: { tag: TagModel };
	}> {}
export interface UpdateTagReactionResponse
	extends ApolloQueryResult<{
		updateTag: { tag: TagModel };
	}> {}

export interface RemoveTagReactionResponse
	extends ApolloQueryResult<{
		removeTag: { tag: TagModel };
	}> {}

export interface TagsReactionResponse
	extends ApolloQueryResult<{
		tags: ReactionQueryResultsModel<TagModel>;
	}> {}

const TAGS_POSITION_NAMESPACE = 'tag-position';
const TAGS_POSITION_KEY = 'position';

// Tags are product categories
export class TagModel extends GQLTag {
	// To prevent colisions between tags from different stores
	static getUniqueSlug(value: string, identifier?: string) {
		const uniq = identifier || new Date().getTime();
		return `${value}_${uniq}`;
	}

	static getPositionMetafield(position: string): MetafieldInput {
		return {
			key: TAGS_POSITION_KEY,
			namespace: TAGS_POSITION_NAMESPACE,
			value: position
		};
	}

	static getPosition(tag: TagModel): string {
		if (!tag || !tag.metafields || !tag.metafields.length) return null;

		const positionMetafield = tag.metafields.find(
			(m) => m.namespace === TAGS_POSITION_NAMESPACE && m.key === TAGS_POSITION_KEY
		);
		if (!positionMetafield) return null;

		return positionMetafield.value;
	}
}
