import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Role } from '../_models/role.model';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_AUTH_RESET_PSSW_URL = `${environment.apiEndpoint}/admin/plugins/users-permissions/auth/reset-password`;
const API_AUTH_URL = `${environment.apiEndpoint}/auth`;
const API_MY_USER_URL = `${environment.apiEndpoint}/my/user`;
const API_ROLES_URL = `${environment.apiEndpoint}/users-permissions/roles`;

export interface AuthenticatedUser {
	jwt: string;
	user: User;
}
@Injectable()
export class AuthService {
	constructor(private http: HttpClient) {}
	// Authentication/Authorization
	login(email: string, password: string): Observable<AuthenticatedUser> {
		return this.http.post<AuthenticatedUser>(`${API_AUTH_URL}/local`, {
			identifier: email,
			password
		});
	}

	getLoggedUserData(): Observable<User> {
		return this.http.get<User>(API_MY_USER_URL);
	}

	register(user: User): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		return this.http
			.post<User>(`${API_AUTH_URL}/local/register`, user, { headers: httpHeaders })
			.pipe(
				map((res: User) => {
					return res;
				}),
				catchError((err) => {
					return null;
				})
			);
	}

	/*
	 * Submit forgot password request
	 *
	 * @param {string} email
	 * @returns {Observable<any>}
	 */
	public requestPassword(email: string): Observable<any> {
		return this.http
			.post(`${environment.apiEndpoint}/auth/forgot-password`, {
				email,
				// Is the url link that user will receive.
				// After the user triggers a new password reset, it is used to redirect the user to the new-password form.
				url: API_AUTH_RESET_PSSW_URL
			})
			.pipe(catchError(this.handleError('forgot-password', [])));
	}

	// UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(API_MY_USER_URL, _user, { headers: httpHeaders });
	}

	// Roles
	getAllRoles(): Observable<Role[]> {
		return this.http.get<Role[]>(API_ROLES_URL);
	}

	// getRoleById(roleId: number): Observable<Role> {
	// 	return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
	// }

	/*
	 * Handle Http operation that failed.
	 * Let the app continue.
	 *
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return of(result);
		};
	}
}
