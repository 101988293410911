import {
	ProductModel,
	PRODUCT_METAFIELDS_NAMESPACES,
	PRODUCT_METAFIELDS_KEYS
} from './../../../../../../core/point-of-interest/_models/product.model';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'kt-product-variants-info',
	templateUrl: './product-variants-info.component.html'
})
export class ProductVariantsInfoComponent {
	variationsLabel: string = null;
	optionsLabel: string = null;

	constructor() {}

	loadVariantsAttrs(product: ProductModel) {
		// GET ATTRIBUTE NAMES (for variants and options)
		// ____________________________________________________________
		const variationsLabelMetafield = (product.metafields || []).find(
			(meta) =>
				meta.namespace === PRODUCT_METAFIELDS_NAMESPACES.ATTRIBUTES_LABELS &&
				meta.key === PRODUCT_METAFIELDS_KEYS.VARIATIONS_LABEL
		);
		this.variationsLabel =
			(variationsLabelMetafield && variationsLabelMetafield.value) || null;

		const optionsLabelMetafield = (product.metafields || []).find(
			(meta) =>
				meta.namespace === PRODUCT_METAFIELDS_NAMESPACES.ATTRIBUTES_LABELS &&
				meta.key === PRODUCT_METAFIELDS_KEYS.OPTIONS_LABEL
		);

		this.optionsLabel =
			(optionsLabelMetafield && optionsLabelMetafield.value) || null;
	}

	getLightProductValidation(product: ProductModel) {
		if (!product.variants || product.variants.length === 0) return true;

		// Check num of options
		const numOptionsPerVariant = product.variants.map(
			(vAr) => (vAr.options || []).length
		);

		const numOpsRef = numOptionsPerVariant[0];

		if (numOptionsPerVariant.every((num) => num === numOpsRef)) return true;

		return false;
	}
}
