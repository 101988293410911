import {
	selectOrdersShowErrorMessage,
	selectOrdersActionLoading
} from './../_selectors/order.selectors';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import {
	selectOrdersInStore,
	selectOrdersPageLoading,
	selectOrdersShowInitWaitingMessage
} from '../_selectors/order.selectors';

export class OrdersDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(select(selectOrdersPageLoading));
		this.actionLoading$ = this.store.pipe(select(selectOrdersActionLoading));
		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectOrdersShowInitWaitingMessage)
		);

		this.showErrorMessage$ = this.store.pipe(select(selectOrdersShowErrorMessage));

		this.store
			.pipe(select(selectOrdersInStore))
			.subscribe((response: QueryResultsModel) => {
				this.paginatorTotalSubject.next(response.totalCount);
				this.entitySubject.next(response.items);
			});
	}
}
