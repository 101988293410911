import { NgxMaskModule } from 'ngx-mask';
import { ServiceInformationComponent } from './service-information/service-information.component';
import { ServicePageComponent } from './service-page.component';
import { FileUploadModule } from './../../partials/content/general/file-upload/file-upload.module';
import { LayoutUtilsService } from './../../../core/_base/crud/utils/layout-utils.service';
import { TypesUtilsService } from './../../../core/_base/crud/utils/types-utils.service';
import { HttpUtilsService } from './../../../core/_base/crud/utils/http-utils.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import {
	MatDialogModule,
	MatTooltipModule,
	MatSelectModule,
	MatInputModule,
	MatFormFieldModule,
	MatIconModule,
	MatButtonModule,
	MatAutocompleteModule,
	MatSliderModule
} from '@angular/material';
import { NgbProgressbarModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent,
	ConfirmationDialogComponent
} from '../../partials/content/crud';
import { ServiceGalleryComponent } from './service-gallery/service-gallery.component';
import { ServiceGeofenceComponent } from './service-geofence/service-geofence.component';

const routes: Routes = [
	{
		path: '',
		component: ServicePageComponent,
		children: [
			{
				path: 'profile',
				component: ServiceInformationComponent
			},
			{
				path: 'geofence',
				component: ServiceGeofenceComponent
			},
			{
				path: 'gallery',
				component: ServiceGalleryComponent
			}
		]
	},
	{ path: '', redirectTo: 'profile', pathMatch: 'full' },
	{ path: '**', redirectTo: 'profile' }
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgbModule,
		RouterModule.forChild(routes),
		CoreModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatTooltipModule,
		MatButtonModule,
		MatSelectModule,
		MatInputModule,
		MatIconModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		NgbProgressbarModule,
		FileUploadModule,
		NgxMaskModule.forChild(),
		MatSliderModule
	],
	providers: [HttpUtilsService, TypesUtilsService, LayoutUtilsService],
	entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		ConfirmationDialogComponent
	],
	declarations: [
		ServicePageComponent,
		ServiceInformationComponent,
		ServiceGalleryComponent,
		ServiceGeofenceComponent
	],
	exports: []
})
export class ServicePageModule {}
