import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'kt-form-input-feedback',
	templateUrl: './form-input-feedback.component.html'
})
export class FormInputFeedbackComponent implements OnInit {
	@Input() form: FormGroup;
	@Input() field: string;

	constructor() {}

	ngOnInit() {}

	getErrors() {
		const control = this.form.get(this.field);
		if (!control) {
			return false;
		}
		return control.touched && control.errors;
	}
}
