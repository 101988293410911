import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TagModel } from './../_models/tag.model';

export enum TagsActionTypes {
	TagsActionToggleLoading = '[Tags] Tags Action Toggle Loading',
	TagsActionMessage = '[Tags] Tags Action Message',
	TagsListToggleLoading = '[Tags] Tags List Toggle Loading',
	TagsActionError = '[Tags] Tags Action Error',
	TagOnServerCreated = '[Tags] Tags Action on server created',
	TagCreated = '[Tags] Tags Action on creation',
	TagUpdated = '[Tags] Tags Action on updated',
	OneTagDeleted = '[Tags] Tags Action on delete',
	TagsPageRequest = '[Tags] Tags page request',
	TagsPageLoaded = '[Tags] Tags page loaded'
}

export class TagsPageRequest implements Action {
	readonly type = TagsActionTypes.TagsPageRequest;

	constructor(
		public payload: {
			shopId: string;
			force: boolean;
		}
	) {}
}

export class TagsPageLoaded implements Action {
	readonly type = TagsActionTypes.TagsPageLoaded;

	constructor(
		public payload: {
			tags: TagModel[];
			totalCount: number;
		}
	) {}
}

export class TagsOnServerCreated implements Action {
	readonly type = TagsActionTypes.TagOnServerCreated;
	constructor(public payload: { shopId: string; tag: TagModel }) {}
}

export class TagCreated implements Action {
	readonly type = TagsActionTypes.TagCreated;
	constructor(public payload: { tag: TagModel }) {}
}

export class TagUpdated implements Action {
	readonly type = TagsActionTypes.TagUpdated;
	constructor(
		public payload: {
			partialTag: Update<TagModel>; // For State update
		}
	) {}
}
export class OneTagDeleted implements Action {
	readonly type = TagsActionTypes.OneTagDeleted;
	constructor(public payload: { id: string }) {}
}

export class TagsActionToggleLoading implements Action {
	readonly type = TagsActionTypes.TagsActionToggleLoading;

	constructor(public payload: { isLoading: boolean }) {}
}

export class TagsActionMessage implements Action {
	readonly type = TagsActionTypes.TagsActionMessage;

	constructor(
		public payload: {
			actionMessage: { type: 'error' | 'info' | 'success'; message: string };
		}
	) {}
}

export class TagsListToggleLoading implements Action {
	readonly type = TagsActionTypes.TagsListToggleLoading;

	constructor(public payload: { isLoading: boolean }) {}
}

export type TagsActions =
	| TagsPageRequest
	| TagsPageLoaded
	| TagsOnServerCreated
	| TagCreated
	| TagUpdated
	| OneTagDeleted
	| TagsActionToggleLoading
	| TagsActionMessage
	| TagsListToggleLoading;
