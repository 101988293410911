import { environment } from './../../../environments/environment';
import { NgModule, InjectionToken } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';

const uri = `${environment.ecommerceEndpoint}/graphql`;
export const ECOMMERCE_GRAPHQL_URI = new InjectionToken<string>(
	'GRAPHQL-ECOMMERCE-URI'
);

export function provideApollo(httpLink: HttpLink) {
	/**
	 * Token is added through http interceptor
	 */

	const link = ApolloLink.from([httpLink.create({ uri })]);
	const cache = new InMemoryCache();

	return {
		link,
		cache
	};
}

@NgModule({
	exports: [HttpClientModule, ApolloModule, HttpLinkModule],
	providers: [
		{ provide: ECOMMERCE_GRAPHQL_URI, useValue: uri },
		{
			provide: APOLLO_OPTIONS,
			useFactory: provideApollo,
			deps: [HttpLink]
		}
	]
})
export class GraphQLModule {}
