import { EcommerceInfoState } from './../_reducers/e-commerce.reducers';
// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectEcommerceInfoState = createFeatureSelector<EcommerceInfoState>(
	'ecommerceInfo'
);

/**
 *
 * return {null | -1 | string}.
 * Null if there is no info; -1 if there is no relation and string when a relations exist and a shopId is returned.
 */
export const selectShopIdByPoiId = (pointOfInterestId: number) =>
	createSelector(selectEcommerceInfoState, (ecommerceInfoState) => {
		if (!ecommerceInfoState.relations) {
			return null;
		}
		const relation = ecommerceInfoState.relations.find(
			(r) => r.poiId === pointOfInterestId
		);

		return (relation && relation.shopId) || null;
	});

export const selectRelations = createSelector(
	selectEcommerceInfoState,
	(ecommerceInfoState) => ecommerceInfoState.relations
);

export const selectEcommerceInfoLoading = createSelector(
	selectEcommerceInfoState,
	(ecommerceInfoState) => ecommerceInfoState.isLoading
);
