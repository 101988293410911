import gql from 'graphql-tag';

const ordersQuery = gql`
	query ordersQuery(
		$shopIds: [ID]
		$filter: OrderFilterInput
		$first: ConnectionLimitInt
		$offset: Int
		$sortBy: OrdersSortByField
		$sortOrder: SortOrder
	) {
		orders(
			shopIds: $shopIds
			filters: $filter
			first: $first
			offset: $offset
			sortBy: $sortBy
			sortOrder: $sortOrder
		) {
			nodes {
				_id
				referenceId
				createdAt
				email
				payments {
					status
					displayName
					__typename
				}
				fulfillmentGroups {
					status
					__typename
				}
				summary {
					total {
						displayAmount
						__typename
					}
					__typename
				}
				email
				status
				__typename
			}
			totalCount
			__typename
		}
	}
`;

export default ordersQuery;
