// Angular
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
// Object-Path
import * as objectPath from 'object-path';

/**
 * USAGE EXAMPLE:
 *
 * <div ktAvatar #ktAvatar class="kt-avatar" id="kt_user_avatar_2">
 *		<div class="kt-avatar__holder" style="background-image: url(./assets/media/users/default.jpg)"></div>
 *		<label class="kt-avatar__upload" data-toggle="kt-tooltip" title="" data-original-title="Change avatar">
 *			<i class="fa fa-pen"></i>
 *			<input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" formControlName="image"/>
 *		</label>
 *		<span class="kt-avatar__cancel" data-toggle="kt-tooltip" title="" data-original-title="Cancel avatar">
 *			<i class="fa fa-times"></i>
 *		</span>
 *	</div>
 */

/**
 * Configure menu
 */
@Directive({
	selector: '[ktAvatar]',
	exportAs: 'ktAvatar'
})
export class AvatarDirective implements AfterViewInit {
	// Public propeties
	@Input() options: any;
	// Private properites
	private avatar: any;

	/**
	 * Directive Constructor
	 * @param el: ElementRef
	 */
	constructor(private el: ElementRef) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
		this.setupOptions();
		this.avatar = new KTAvatar(this.el.nativeElement, this.options);
	}

	/**
	 * Return the avatar
	 */
	getAvatar() {
		return this.avatar;
	}

	/**
	 * Setup avatar options
	 */
	private setupOptions() {}
}
