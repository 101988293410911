import { TranslateService } from '@ngx-translate/core';
// Angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';

@Component({
	selector: 'kt-widget14',
	templateUrl: './widget14.component.html',
	styleUrls: ['./widget14.component.scss'],
})
export class Widget14Component implements OnInit {
	_data: { labels: string[]; datasets: any[] } = null;
	// Public properties
	@Input() title: string;
	@Input() desc: string;
	@Input() set data( d: { labels: string[]; datasets: any[] }) {
		this._data = d;
		this.initChartJS();
	}
	@ViewChild('chart', {static: true}) chart: ElementRef;

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private layoutConfigService: LayoutConfigService,
		private translate: TranslateService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (!this._data) {
			this._data = {
				// tslint:disable-next-line: max-line-length
				labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10', 'Day 11', 'Day 12', 'Day 13', 'Day 14'],
				datasets: [
					{
						label: this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.OPENED'),
						backgroundColor: this.layoutConfigService.getConfig('colors.state.success'),
						data: [ 10, 10, 15, 10, 5, 0, 10, 13, 15, 20, 20, 15, 10, 14 ]
					},
					{
						label: this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.SAW'),
						backgroundColor: '#f3f3fb',
						data: [ 13, 15, 20, 20, 15, 10, 14, 10, 10, 15, 10, 5, 0, 10]
					}
				]
			};
		}

		this.initChartJS();
	}

	/** Init chart */
	initChartJS() {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html

		const chart = new Chart(this.chart.nativeElement, {
			type: 'bar',
			data: this._data,
			options: {
				title: {
					display: false,
				},
				tooltips: {
					intersect: false,
					mode: 'nearest',
					xPadding: 10,
					yPadding: 10,
					caretPadding: 10
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				barRadius: 4,
				scales: {
					xAxes: [{
						display: false,
						gridLines: false,
						stacked: true
					}],
					yAxes: [{
						display: false,
						stacked: true,
						gridLines: false
					}]
				},
				layout: {
					padding: {
						left: 0,
						right: 0,
						top: 0,
						bottom: 0
					}
				}
			}
		});
	}
}
