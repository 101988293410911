// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { NotificationsModule } from './notifications/notifications.module';
import { ServicePageModule } from './service-page/service-page.module';
import { ProductsModule } from './products/products.module';
import { OrdersModule } from './orders/orders.module';

// NgRx
import { ecommerceInfoReducer } from './../../core/point-of-interest/_reducers/e-commerce.reducers';
import { ECommerceInfoEffects } from './../../core/point-of-interest/_effects/e-commerce.effects';
import { PointofinterestShopInfoComponent } from './shared/pointofinterest-shop-info/pointofinterest-shop-info.component';

@NgModule({
	declarations: [PointofinterestShopInfoComponent],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		NotificationsModule,
		StoreModule.forFeature('ecommerceInfo', ecommerceInfoReducer),
		EffectsModule.forFeature([ECommerceInfoEffects]),
		ProductsModule,
		OrdersModule,
		ServicePageModule
	],
	providers: []
})
export class PagesModule {}
