import { OrderRequested } from './../_actions/order.actions';
import { OrderModel, ORDER_STATUS } from './../_models/order.model';
import { ECommerceConnectionService } from '../../graphql/e-commerce/e-commerce.service';
import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { forkJoin, of, merge } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { map, mergeMap, tap, catchError } from 'rxjs/operators';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { OrdersService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
	OrderActionToggleLoading,
	OrderActionTypes,
	OrdersPageLoaded,
	OrdersPageRequest,
	OrdersPageToggleLoading,
	OrdersUpdateStatus,
	OrderUpdated,
	OrderActionError,
	OrdersStatusUpdated,
	OrderRequest
} from '../_actions/order.actions';
import { Update } from '@ngrx/entity';

@Injectable()
export class OrderEffects {
	showPageLoadingDistpatcher = new OrdersPageToggleLoading({ isLoading: true });
	showActionLoadingDistpatcher = new OrderActionToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new OrderActionToggleLoading({ isLoading: false });

	@Effect()
	requestOrder$ = this.actions$.pipe(
		ofType<OrderRequest>(OrderActionTypes.OrderRequest),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showActionLoadingDistpatcher);
			return this.ordersService
				.getOrderById(payload.shopId, payload.referenceId)
				.pipe(
					catchError((error) => {
						console.error('Order not found.', error);
						return of(false);
					})
				);
		}),
		map((value) => {
			if (!!value) {
				const order = ECommerceConnectionService.getEntityFromQuery<OrderModel>(
					'orderByReferenceId',
					value
				);

				if (!order) return;

				const orderFullInfoUpdate: Update<OrderModel> = {
					id: order._id,
					changes: order
				};
				this.store.dispatch(
					new OrderRequested({ orderFullInfo: orderFullInfoUpdate })
				);
			}
			return this.hideActionLoadingDistpatcher;
		})
	);

	@Effect()
	loadOrdersPage$ = this.actions$.pipe(
		ofType<OrdersPageRequest>(OrderActionTypes.OrdersPageRequest),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showPageLoadingDistpatcher);
			const requestToServer = this.ordersService
				.findOrders(payload.page, payload.shopId)
				.pipe(
					catchError((error) => {
						console.error('Orders not found.', error);
						this.store.dispatch(
							new OrderActionError({
								showErrorMessage: 'ORDER.ERROR_REQUESTING'
							})
						);
						return of(false);
					})
				);
			const lastQuery = of(payload.page);
			return forkJoin([requestToServer, lastQuery]);
		}),
		map((response) => {
			const results = ECommerceConnectionService.getEntitiesFromQuery(
				'orders',
				response[0]
			);
			// QueryResultsModel = response[0];
			const lastQuery: QueryParamsModel = response[1];
			return new OrdersPageLoaded({
				orders: (results && results.items) || null,
				totalCount: (results && results.totalCount) || null,
				page: lastQuery
			});
		})
	);

	@Effect()
	updateOrder$ = this.actions$.pipe(
		ofType<OrderUpdated>(OrderActionTypes.OrderUpdated),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showActionLoadingDistpatcher);
			return this.ordersService.updateOrder(payload.order);
		}),
		map(() => {
			return this.hideActionLoadingDistpatcher;
		})
	);

	@Effect()
	updateOrdersStatus$ = this.actions$.pipe(
		ofType<OrdersUpdateStatus>(OrderActionTypes.OrdersUpdateStatus),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showActionLoadingDistpatcher);
			return this.ordersService
				.updateStatusForOrder(payload.orders, payload.status)
				.pipe(
					catchError((error) => {
						console.error('Orders not found.', error);
						this.store.dispatch(
							new OrderActionError({
								showErrorMessage: 'ORDER.ERROR_UPDATING_ORDER'
							})
						);
						return of(false);
					})
				);
		}),
		map((response) => {
			if (!!response && !!(response as any[]).length) {
				const orders = response.map((r) => {
					const order: { _id: string; status: ORDER_STATUS } =
						r.data && r.data.updateOrder && r.data.updateOrder.order;
					return { _id: order._id, status: order.status };
				});

				this.store.dispatch(new OrdersStatusUpdated({ orders }));
			}

			return this.hideActionLoadingDistpatcher;
		})
	);

	constructor(
		private actions$: Actions,
		private ordersService: OrdersService,
		private store: Store<AppState>
	) {}
}
