import {
	ORDER_STATUS,
	ORDER_PAYMENT_STATUS,
	OrderModel
} from './../../../../core/point-of-interest/_models/order.model';

export class OrderUtils {
	/** UI */
	/**
	 * Retursn CSS Class Name by status
	 *
	 * @param status: number
	 */
	static getItemCssClassByStatus(status: ORDER_STATUS): string {
		switch (status) {
			case ORDER_STATUS.CANCELED:
				return 'secondary';
			case ORDER_STATUS.COMPLETED:
				return 'success';
			case ORDER_STATUS.NEW:
				return 'primary';
			case ORDER_STATUS.PROCESSING:
				return 'warning';
		}
		return '';
	}

	/**
	 * Returns Item Status in string
	 * @param status: number
	 */
	static getItemStatusString(status: ORDER_STATUS): string {
		if (!!status) return `ORDER.STATUS.${status.toUpperCase()}`;
		return 'GENERIC.ND';
	}

	/**
	 * Returns Payment Method
	 * @param orderModel: OrderModer
	 */
	static getPayment(order: OrderModel) {
		const payment = (order && order.payments && order.payments[0]) || null;

		if (!payment) return 'n/a';

		return payment.displayName;
	}
	/**
	 * Returns Payment Status
	 * @param orderModel: OrderModer
	 */
	static getPaymentStatus(order: OrderModel) {
		const payment = (order && order.payments && order.payments[0]) || null;

		return (payment && payment.status) || null;
	}

	/**
	 * Returns Payment Status translation key
	 * @param orderModel: OrderModer
	 */
	static getPaymentStatusTranslationKey(order: OrderModel) {
		const payment = (order && order.payments && order.payments[0]) || null;

		if (!payment || !payment.status) return 'n/a';

		return `ORDER.PAYMENT_STATUS.${payment.status.toUpperCase()}`;
	}

	/** UI */
	/**
	 * Retursn CSS Class Name by payment status
	 *
	 * @param status: number
	 */
	static getItemCssClassByPaymentStatus(status: ORDER_PAYMENT_STATUS): string {
		switch (status) {
			case ORDER_PAYMENT_STATUS.CREATED:
				return 'light';
			case ORDER_PAYMENT_STATUS.COMPLETED:
				return 'info';
		}
		return '';
	}
}
