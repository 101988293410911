import { DateInterval } from './../../../_base/crud/models/date-interval.model';
import { DayInterval } from './../../../_base/crud/models/day-interval.model';

/**
 * Model used to represent the opening time of POI.
 */

export class OpeningHours {
	id?: number;
	opening_hours?: string;
	closing_hours?: string;
	day_interval?: DayInterval;
	valid?: DateInterval;

	public static hoursValidator(data: OpeningHours) {
		let result = {};
		if (!!data) {
			// Day Interval Validator
			result = {
				...result,
				...DayInterval.validator(data.day_interval)
			};
			// Opening hours validator
			if (!data.opening_hours || !data.closing_hours) {
				result = {
					...result,
					opening_hours: 'INPUT.VALIDATIONS.OPENING_HOURS.MANDATORY'
				};
			} else {
				// Transform the hours to integer (ex: 12:33 => 1233)
				const openning_hours_int: number = parseInt(
					data.opening_hours.replace(':', '')
				);
				// Transform the hours to integer (ex: 12:33 => 1233)
				const closing_hours_int: number = parseInt(
					data.closing_hours.replace(':', '')
				);
				if (openning_hours_int >= closing_hours_int) {
					result = {
						...result,
						opening_hours: 'INPUT.VALIDATIONS.OPENING_HOURS.CLOSE_AFTER_OPEN'
					};
				}
			}
		}
		return result;
	}

	clear() {
		this.id = undefined;
		this.opening_hours = '';
		this.closing_hours = '';
		this.day_interval = new DayInterval();
		this.day_interval.clear();
		this.valid = undefined;
	}
}
