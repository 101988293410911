import { DomSanitizer } from '@angular/platform-browser';
import { ProductVariantsInfoComponent } from '../shared/product-variants-info/product-variants-info.component';
import { takeWhile, skip } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../../../../../core/point-of-interest/_services/products.service';
import { BehaviorSubject } from 'rxjs';
import {
	LayoutUtilsService,
	MessageType
} from '../../../../../core/_base/crud/utils/layout-utils.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	OnDestroy,
	EventEmitter,
	Output
} from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material';

import {
	ProductModel,
	ProductVariantModel,
	ProductMetafields,
	PRODUCT_METAFIELDS_KEYS,
	PRODUCT_METAFIELDS_NAMESPACES,
	GetProductByIdReactionResponse
} from '../../../../../core/point-of-interest/_models/product.model';
import {
	objectPathExists,
	runPromisesSequentially
} from '../../../../../core/_base/crud/utils/helper.function';

const ATTR_DEFAULT_UI_LABEL = '-';

// tslint:disable-next-line:class-name
interface SIMPLE_PRODUCT_VARIANT_REPRESENATATION {
	_id: string;
	optionTitle: string;
}

const extractSimpleRepresentation = ({
	_id,
	optionTitle
}: ProductVariantModel): SIMPLE_PRODUCT_VARIANT_REPRESENATATION => ({
	_id,
	optionTitle
});

enum ACTION_TYPE {
	ATTR_ACTION = 'attr-action',
	ENTITIES_ACTION = 'entities-action',
	ALL_ACTIONS = 'all-actions'
}

@Component({
	selector: 'kt-product-variants',
	templateUrl: './product-variants.component.html',
	styleUrls: ['./product-variants.component.scss']
})
export class ProductVariantsComponent extends ProductVariantsInfoComponent
	implements OnInit, OnDestroy {
	@Input() product: ProductModel;

	@Input() shopId: string;

	public isComponentDisabled = false;
	@Input() set disabled(value: boolean) {
		this.isComponentDisabled = value;

		if (this.productVariationsLabelsForm)
			value
				? this.productVariationsLabelsForm.enable()
				: this.productVariationsLabelsForm.disable();
	}

	// Used to notify parent that a product is being updated - just when starts.
	@Output() startUpdating = new EventEmitter<boolean>();

	listOfVariations: SIMPLE_PRODUCT_VARIANT_REPRESENATATION[] = [];
	listOfOptions: SIMPLE_PRODUCT_VARIANT_REPRESENATATION[] = [];

	productVariationsLabelsForm: FormGroup;

	allowedToAddVariationsAndOption = true;

	separatorKeysCodes = [ENTER, COMMA];

	actionLoadingForProductAttributes$ = new BehaviorSubject<boolean>(false);
	actionLoadingForProductVariationsEntities$ = new BehaviorSubject<boolean>(false);

	isPreparingTable = false;
	priceTable: { price: number; salePrice: number }[][];

	uiVariantInfo = this.domSanitizer.bypassSecurityTrustHtml(
		this.translate.instant('PRODUCTS.FORM.VARIANTS_AND_OPTIONS.INFO')
	);

	// Used to simplify the variants UI area (and hide options for those case that are not necessary)
	// default: there are no options.
	areOptionsControlsVisible = false;

	inView = true;

	constructor(
		private formBuilder: FormBuilder,
		private layoutUtilsSrvc: LayoutUtilsService,
		private translate: TranslateService,
		private productSrvc: ProductsService,
		private cd: ChangeDetectorRef,
		private domSanitizer: DomSanitizer
	) {
		super();
	}

	ngOnInit() {
		this.loadProduct(this.product);
		this.setUI_State(this.product);
		// this.prepareTable();
		// On the first time, we load the product passed through parameters
		this.productSrvc
			.getProductByIdWatch(this.shopId, this.product._id)
			.pipe(
				skip(1),
				takeWhile(() => this.inView)
			)
			.subscribe((p: GetProductByIdReactionResponse) => {
				const product = objectPathExists(p, 'data.product', true);
				if (!product) return;
				this.loadProduct(product);
				this.setActionLoadingState(false, ACTION_TYPE.ALL_ACTIONS);
			});
	}

	async loadProduct(product?: ProductModel) {
		try {
			if (!product) {
				product = await this.productSrvc.getProductById(
					this.shopId,
					this.product._id
				);
				if (!product) throw new Error('PRODUCTS.FORM.ERRORS.PRODUCT_NOT_FOUND');
			}
			// Get variants and options labels (attribute label)
			this.loadVariantsAndOptionsInfo(product);
		} catch (error) {
			this.layoutUtilsSrvc.showActionNotification(
				this.translate.instant(
					(error && error.message) || 'PRODUCTS.FORM.ERRORS.ERROR_GETTING_PRODUCT'
				),
				MessageType.Create
			);
		}
	}

	setUI_State(product?: ProductModel) {
		if (
			ProductModel.hasVariants(product) &&
			ProductModel.variantHasOptions(product.variants[0])
		) {
			this.areOptionsControlsVisible = true;
		}
	}

	showOptionsControl() {
		this.areOptionsControlsVisible = true;
	}

	// Variations and Options info
	// ______________________________________

	loadVariantsAndOptionsInfo(product: ProductModel) {
		this.loadVariantsAttrs(product);

		// create form
		this.productVariationsLabelsForm = this.formBuilder.group({
			variationAttributeName: [this.variationsLabel, Validators.required],
			optionAttributeName: [this.optionsLabel]
		});

		this.isComponentDisabled
			? this.productVariationsLabelsForm.disable()
			: this.productVariationsLabelsForm.enable();

		// GET VARIANTS/ OPTIONS TITLES
		// ____________________________________________________________
		this.listOfVariations = product.variants.map(extractSimpleRepresentation);

		const firstVariant = ProductModel.getProductFirstVariant(product);
		if (firstVariant)
			this.listOfOptions = firstVariant.options.map(extractSimpleRepresentation);

		// TODO: Check if product is in accordance strategy rules (all product has the same number of options)

		this.cd.detectChanges();
	}

	async updateloadVariantsAndOptionsInfo() {
		try {
			const {
				variationAttributeName,
				optionAttributeName
			} = this.productVariationsLabelsForm.controls;

			const metafields: Array<ProductMetafields> = [
				{
					namespace: PRODUCT_METAFIELDS_NAMESPACES.ATTRIBUTES_LABELS,
					key: PRODUCT_METAFIELDS_KEYS.VARIATIONS_LABEL,
					value: variationAttributeName.value
				},
				{
					namespace: PRODUCT_METAFIELDS_NAMESPACES.ATTRIBUTES_LABELS,
					key: PRODUCT_METAFIELDS_KEYS.OPTIONS_LABEL,
					value: optionAttributeName.value
				}
			];

			this.setActionLoadingState(true);
			const updatedProduct = await this.productSrvc.updateProduct(
				this.shopId,
				this.product._id,
				{ metafields }
			);

			if (!updatedProduct) {
				throw new Error('Product not updated');
			}

			// Update all variations and
			const updates = this.loadProduct(updatedProduct);
		} catch (error) {
			this.layoutUtilsSrvc.showActionNotification(
				this.translate.instant('PRODUCTS.FORM.ERRORS.ERROR_UPDATING_PRODUCT'),
				MessageType.Create
			);
		} finally {
			this.setActionLoadingState(false);
		}
	}

	async addVariation(event: MatChipInputEvent) {
		const input = event.input;
		if (input) input.value = '';

		const value = event.value;

		if (!value || !value.trim()) return;

		const answer = this.canBeAdded(this.listOfVariations, value);

		this.setActionLoadingState(true, ACTION_TYPE.ENTITIES_ACTION);

		try {
			if (!answer.result) throw new Error(answer.error);

			const createdVariant = await this.productSrvc.createProductVariant(
				this.shopId,
				this.product._id,
				this.product._id,
				{
					optionTitle: answer.value
				},
				true
			);

			if (!createdVariant)
				throw new Error('PRODUCTS.FORM.ERRORS.ERROR_CREATING_VARIANT');

			const requests = this.listOfOptions.map((option, i) => () =>
				this.productSrvc.createProductVariant(
					this.shopId,
					this.product._id,
					createdVariant._id,
					{
						optionTitle: option.optionTitle
					},
					i === this.listOfOptions.length - 1 // if last
				)
			);

			const createdOptions = await runPromisesSequentially<ProductVariantModel>(
				requests
			);

			if (createdOptions.some((r) => !r))
				throw new Error('PRODUCTS.FORM.ERROR_CREATING_VARIANT');
		} catch (error) {
			this.layoutUtilsSrvc.showSimpleAlertDialog(
				'warning',
				'PRODUCTS.FORM.VARIANTS_AND_OPTIONS.ADD_VARIANT',
				answer.error || error.message,
				true
			);
			this.setActionLoadingState(false);
		}
	}

	removeVariant(value: SIMPLE_PRODUCT_VARIANT_REPRESENATATION) {
		const dialogRef = this.layoutUtilsSrvc.deleteElement(
			`${value.optionTitle} `,
			'PRODUCTS.FORM.DELETE_VARIANT_MODAL.DESCRIPTION',
			'PRODUCTS.FORM.DELETE_VARIANT_MODAL.WAIT_DESCRIPTION'
		);
		dialogRef.afterClosed().subscribe(async (res) => {
			if (!res) {
				return;
			}
			this.setActionLoadingState(true, ACTION_TYPE.ENTITIES_ACTION);
			const wasArchived = await this.productSrvc.archiveProductVariant(
				this.shopId,
				this.product._id,
				value._id,
				true
			);

			if (!wasArchived)
				this.layoutUtilsSrvc.showActionNotification(
					this.translate.instant(
						'PRODUCTS.FORM.ERRORS.PRODUCT_VARIANT_NOT_ARCHIVED',
						MessageType.Create
					)
				);
			else
				this.layoutUtilsSrvc.showActionNotification(
					'PRODUCTS.FORM.DELETE_VARIANT_MODAL.DELETE_MESSAGE',
					MessageType.Delete
				);

			this.setActionLoadingState(false, ACTION_TYPE.ENTITIES_ACTION);
		});
	}

	async addOption(event: MatChipInputEvent) {
		const input = event.input;
		if (input) input.value = '';

		const value = event.value;

		if (!value || !value.trim()) return;

		const answer = this.canBeAdded(this.listOfVariations, value);

		this.setActionLoadingState(true, ACTION_TYPE.ENTITIES_ACTION);

		try {
			if (!answer.result) throw new Error(answer.error);

			const requests = this.listOfVariations.map((variation, i) => () =>
				this.productSrvc.createProductVariant(
					this.shopId,
					this.product._id,
					variation._id,
					{
						optionTitle: answer.value
					},
					i === this.listOfVariations.length - 1 // if last
				)
			);

			const createdVariants = await runPromisesSequentially<ProductVariantModel>(
				requests
			);

			if (createdVariants.some((r) => !r))
				throw new Error('PRODUCTS.FORM.ERRORS.ERROR_CREATING_OPTIONS');
		} catch (error) {
			this.layoutUtilsSrvc.showSimpleAlertDialog(
				'warning',
				'PRODUCTS.FORM.VARIANTS_AND_OPTIONS.CREATE_OPTION',
				answer.error || error.message,
				true
			);
			this.setActionLoadingState(false, ACTION_TYPE.ENTITIES_ACTION);
		}
	}

	removeOption(value: SIMPLE_PRODUCT_VARIANT_REPRESENATATION) {
		const dialogRef = this.layoutUtilsSrvc.deleteElement(
			`${value.optionTitle} `,
			'PRODUCTS.FORM.VARIANTS_AND_OPTIONS.REMOVE_VAR_OR_OPTION',
			'PRODUCTS.FORM.VARIANTS_AND_OPTIONS.REMOVING_VAR_OR_OPTION'
		);
		dialogRef.afterClosed().subscribe(async (res) => {
			if (!res) {
				return;
			}
			this.setActionLoadingState(true, ACTION_TYPE.ENTITIES_ACTION);

			const requests = this.product.variants.map((variant, i) => {
				const option = variant.options.find(
					(o) => o.optionTitle.toLowerCase() === value.optionTitle.toLowerCase()
				);

				return () =>
					this.productSrvc.archiveProductVariant(
						this.shopId,
						this.product._id,
						option._id,
						i === this.listOfVariations.length - 1 // if last
					);
			});

			const archivedVariants = await runPromisesSequentially<ProductVariantModel>(
				requests
			);

			if (archivedVariants.some((v) => !v)) {
				this.layoutUtilsSrvc.showActionNotification(
					this.translate.instant(
						'PRODUCTS.FORM.ERRORS.PRODUCT_OPTION_NOT_ARCHIVED',
						MessageType.Create
					)
				);
				this.setActionLoadingState(false, ACTION_TYPE.ENTITIES_ACTION);
			} else
				this.layoutUtilsSrvc.showActionNotification(
					'PRODUCTS.FORM.VARIANTS_AND_OPTIONS.DELTED_VAR_OR_OPTION',
					MessageType.Delete
				);
		});
	}

	selectedOption(event: MatChipInputEvent) {}

	canBeAdded(
		collection: SIMPLE_PRODUCT_VARIANT_REPRESENATATION[],
		value: string,
		toCapitalize: boolean = false
	) {
		let val = (value || '').trim();

		if (!val)
			return {
				result: false,
				error: 'PRODUCTS.FORM.VARIANTS_SECTION.EMPTY_VALUE'
			};

		if (collection.some((v) => v.optionTitle.toLowerCase() === val.toLowerCase()))
			return {
				result: false,
				error: 'PRODUCTS.FORM.VARIANTS_SECTION.ALREADY_EXIST'
			};

		if (toCapitalize) {
			val = val.toLowerCase();
			val = val.charAt(0).toUpperCase() + val.slice(1);
		}

		return {
			result: true,
			value: val
		};
	}

	setActionLoadingState(
		isLoading: boolean,
		type: ACTION_TYPE = ACTION_TYPE.ATTR_ACTION
	) {
		if (!!this.productVariationsLabelsForm) {
			if (isLoading) this.productVariationsLabelsForm.disable();
			else this.productVariationsLabelsForm.enable();
		}

		if (isLoading) this.startUpdating.emit(isLoading);

		switch (type) {
			case ACTION_TYPE.ATTR_ACTION:
				this.actionLoadingForProductAttributes$.next(isLoading);
				break;

			case ACTION_TYPE.ENTITIES_ACTION:
				this.allowedToAddVariationsAndOption = !isLoading;
				this.actionLoadingForProductVariationsEntities$.next(isLoading);
				break;
			case ACTION_TYPE.ALL_ACTIONS:
				this.actionLoadingForProductAttributes$.next(isLoading);
				this.allowedToAddVariationsAndOption = !isLoading;
				this.actionLoadingForProductVariationsEntities$.next(isLoading);
				break;
		}
	}

	get isFormInvalid() {
		return (
			!this.productVariationsLabelsForm ||
			this.productVariationsLabelsForm.invalid ||
			this.productVariationsLabelsForm.pristine
		);
	}

	get variationLabel() {
		if (!this.productVariationsLabelsForm) return ATTR_DEFAULT_UI_LABEL;
		const { variationAttributeName } = this.productVariationsLabelsForm.controls;
		return (
			(variationAttributeName && variationAttributeName.value) ||
			ATTR_DEFAULT_UI_LABEL
		);
	}

	get optionLabel() {
		if (!this.productVariationsLabelsForm) return ATTR_DEFAULT_UI_LABEL;
		const { optionAttributeName } = this.productVariationsLabelsForm.controls;
		return (
			(optionAttributeName && optionAttributeName.value) || ATTR_DEFAULT_UI_LABEL
		);
	}

	get allowedToRemoveVariants() {
		return this.listOfVariations.length > 1;
	}

	get allowedToRemoveOptions() {
		return this.listOfVariations.length > 1;
	}

	ngOnDestroy() {
		this.inView = false;
	}
}
