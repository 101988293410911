import { BaseModel } from '../../_base/crud';

export const PROVIDER_ROLE_TYPE = 'providerrole';
export class Role extends BaseModel {
    name: string;
    description: string;
    type: string;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.description = '';
        this.type = '';
	}
}
