import gql from 'graphql-tag';

const updateShopInfoMutation = gql`
	mutation updateShopInfoMutation($input: UpdateShopInput!) {
		updateShop(input: $input) {
			shop {
				_id
				__typename
			}
			__typename
		}
	}
`;

export default updateShopInfoMutation;
