import { PostalCode } from './../_models/postal-code.model';
import { HttpUtilsService } from './../../_base/crud/utils/http-utils.service';
import { environment } from './../../../../environments/environment';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS

// CRUD

// Models

const API_POSTAL_CODE_URL = `${environment.apiEndpoint}/postal-codes`;

// Real REST API
@Injectable({
	providedIn: 'root'
})
export class PostalCodeService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

	// READ
	// Server should return filtered result
	findPostalCodes(filterParams: object): Promise<PostalCode[]> {
		const httpParams = this.httpUtils.getFilterHTTPParams(filterParams);

		const url = API_POSTAL_CODE_URL;
		return this.http
			.get<PostalCode[]>(url, { params: httpParams })
			.toPromise()
			.then((res) => res)
			.catch(() => []);
	}
}
