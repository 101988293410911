// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions

// Models
import { ShippingMethodModel } from './../_models/shipping-methods.model';

import {
	ShippingMethodsActions,
	ShippingMethodsActionTypes,
	ShippingMethodsPageLoaded
} from './../_actions/shipping-methods.actions';

export interface ShippingMethodsState extends EntityState<ShippingMethodModel> {
	listLoading: boolean;
	actionsloading: boolean;
	totalCount: number;
	lastCreatedShippingMethodId: number;
	showInitWaitingMessage: boolean;
	showErrorMessage: false | string;
	actionMessage: { type: 'error' | 'info' | 'success'; message: string };
}

export const adapter: EntityAdapter<ShippingMethodModel> = createEntityAdapter<
	ShippingMethodModel
>({
	selectId: (publishedData: ShippingMethodModel) => publishedData._id
});

export const initialShippingMethodsState: ShippingMethodsState = adapter.getInitialState(
	{
		listLoading: false,
		actionsloading: false,
		totalCount: 0,
		lastCreatedShippingMethodId: undefined,
		showInitWaitingMessage: true,
		showErrorMessage: false,
		actionMessage: null
	}
);

export function shippingMethodsReducer(
	state = initialShippingMethodsState,
	action: ShippingMethodsActions
): ShippingMethodsState {
	switch (action.type) {
		case ShippingMethodsActionTypes.ShippingMethodsPageLoaded: {
			return adapter.addMany(action.payload.shippingMethods, {
				...state,
				totalCount: action.payload.totalCount,
				listLoading: false
			});
		}

		case ShippingMethodsActionTypes.ShippingMethodsListToggleLoading: {
			return {
				...state,
				listLoading: action.payload.isLoading
			};
		}

		case ShippingMethodsActionTypes.ShippingMethodsActionToggleLoading: {
			return {
				...state,
				actionsloading: action.payload.isLoading
			};
		}

		case ShippingMethodsActionTypes.ShippingMethodsActionMessage: {
			return {
				...state,
				actionMessage: action.payload.actionMessage
			};
		}

		case ShippingMethodsActionTypes.ShippingMethodUpdated:
			return adapter.updateOne(action.payload.partialShippingMethod, state);

		case ShippingMethodsActionTypes.ShippingMethodCreated:
			return adapter.addOne(action.payload.shippingMethod, state);

		case ShippingMethodsActionTypes.OneShippingMethodDeleted:
			return adapter.removeOne(action.payload.id, state);

		default:
			return state;
	}
}

export const getOrderState = createFeatureSelector<ShippingMethodModel>(
	'shippingMethods'
);

export const {
	selectAll,
	selectEntities,
	selectIds,
	selectTotal
} = adapter.getSelectors();
