import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsService } from './../../../../../core/point-of-interest/_services/notifications.service';
import { USER_NOTIFICATION_STATE } from './../../../../../core/point-of-interest/_consts/specification.dictionary';
import { NotificationModel } from './../../../../../core/point-of-interest/_models/notification.model';
import { LayoutConfigService } from './../../../../../core/_base/layout/services/layout-config.service';
import { TranslateService } from '@ngx-translate/core';
import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	ChangeDetectorRef
} from '@angular/core';
import { isDefined } from './../../../../../core/_base/crud/utils/helper.function';

@Component({
	selector: 'kt-notification-activity-widget',
	templateUrl: './notification-activity-widget.component.html',
	styleUrls: ['./notification-activity-widget.component.scss']
})
export class NotificationActivityWidgetComponent implements OnInit {
	noData = false;
	// Loading
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;

	activityData: { labels: string[]; datasets: any[] } = null;
	_notification: NotificationModel = null;

	@Input() set notification(notification: NotificationModel) {
		this.initData(notification);
	}

	@ViewChild('chart', { static: false }) chartElem: ElementRef;
	chart: any;
	@ViewChild('legends', { static: false }) legends: ElementRef;

	constructor(
		private layoutConfigService: LayoutConfigService,
		private translate: TranslateService,
		private cd: ChangeDetectorRef,
		private notificationsService: NotificationsService
	) {}

	/**
	 * On init
	 */
	ngOnInit() {
		// Init the loading
		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		// Clear data
		this.activityData = this.initActivityNotificationData();
	}

	async initData(notification: NotificationModel) {
		this._notification = notification;
		this.loadingSubject.next(true);
		// Clear data
		this.activityData = this.initActivityNotificationData();

		if (!!this._notification) {
			// Get Daily Activity data
			await this.getActivityData();
		}
		this.cd.detectChanges();

		if (!this.noData) {
			this.initChartJS();
		}
		this.loadingSubject.next(false);
	}

	/**
	 * Daily Activity data
	 */

	async getActivityData() {
		if (!this._notification) {
			return;
		}

		const filter = {
			from: null,
			to: null,
			point_of_interest: this._notification.point_of_interest,
			notification: this._notification.id
		};
		// Get data
		const sourceData = await this.notificationsService.notificationActivity(filter);
		this.noData = !sourceData || sourceData.sent <= 0;
		this.activityData = this.prepareNotificationChartData(sourceData);
	}

	private prepareNotificationChartData(data: object) {
		const result = this.initActivityNotificationData();
		// USER_NOTIFICATION_STATE.Received data
		result.datasets[0].data[0] = data[USER_NOTIFICATION_STATE.Received] || 0;
		// USER_NOTIFICATION_STATE.Viewed data
		result.datasets[0].data[1] = data[USER_NOTIFICATION_STATE.Viewed] || 0;
		// USER_NOTIFICATION_STATE.Opened data
		result.datasets[0].data[2] = data[USER_NOTIFICATION_STATE.Opened] || 0;

		return result;
	}

	private initActivityNotificationData(): {
		labels: string[];
		datasets: { data: number[]; backgroundColor: string[] }[];
	} {
		return {
			// These labels appear in the legend and in the tooltips when hovering different arcs
			labels: [
				this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.RECEIVED'),
				this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.SAW'),
				this.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.LEGENDS.OPENED')
			],
			datasets: [
				{
					data: [],
					backgroundColor: [
						this.layoutConfigService.getConfig('colors.state.warning'),
						this.layoutConfigService.getConfig('colors.state.primary'),
						this.layoutConfigService.getConfig('colors.state.success')
					]
				}
			]
		};
	}

	/** Init chart */
	initChartJS() {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html
		const that = this;
		this.chart = new Chart(this.chartElem.nativeElement, {
			type: 'doughnut',
			data: this.activityData,
			options: {
				cutoutPercentage: 70,
				title: {
					display: false
				},
				tooltips: {
					intersect: false,
					mode: 'nearest',
					xPadding: 10,
					yPadding: 10,
					caretPadding: 10
				},
				legendCallback: function(chart) {
					const legendHtml = [];
					const item = chart.data.datasets[0];
					for (let i = 0; i < item.data.length; i++) {
						if (isDefined(item.data[i])) {
							legendHtml.push('<div class="kt-widget14__legend">');
							legendHtml.push(
								'<span class="kt-widget14__bullet" style="background-color:' +
									item.backgroundColor[i] +
									'"></span>'
							);
							legendHtml.push(
								'<span class="kt-widget14__stats">' +
									item.data[i] +
									' ' +
									that.translate.instant('NOTIFICATIONS.CARD.ACTIVITY.PERSONS') +
									' - ' +
									chart.data.labels[i] +
									'</span>'
							);
							legendHtml.push('</div>');
						}
					}
					return legendHtml.join('');
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				layout: {
					padding: {
						left: 10,
						right: 10,
						top: 10,
						bottom: 10
					}
				}
			}
		});
		this.legends.nativeElement.innerHTML = this.chart.generateLegend();
	}
}
