import { Apollo } from 'apollo-angular';
import {
	ProductModel,
	ProductMetafields,
	CreateProductReactionResponse,
	CreateProductVariantReactionResponse,
	UpdateProductProductReactionResponse,
	GetProductByIdReactionResponse,
	ProductVariantModel,
	ArchiveProductVariantsReactionResponse,
	UpdateProductVariantPriceReactionResponse,
	PublishProductReactionResponse,
	UpdateProductVariantReactionResponse
} from './../_models/product.model';
import {
	GQLTag,
	GQLSimpleInventoryInfo,
	GQLProduct,
	GQLProductInput,
	GQLMediaRecord
} from './../../../../generated/graphql';
import { environment } from './../../../../environments/environment';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { BehaviorSubject, Observable } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel } from '../../_base/crud';
import { ReactionQueryResultsModel } from '../../_base/crud/models/query-models/reaction-query-results.model';
import { isDefined, objectPathExists } from '../../_base/crud/utils/helper.function';

// GQL
import createProductMutation from '../../graphql/e-commerce/mutations/createProductMutation';
import createProductVariantMutation from '../../graphql/e-commerce/mutations/createProductVariantMutation';
import updateProductMutation from '../../graphql/e-commerce/mutations/updateProductMutation';
import archiveProductVariants from '../../graphql/e-commerce/mutations/archiveProductVariantsMutation';
import updateProductVariantPrices from '../../graphql/e-commerce/mutations/updateProductVariantPricesMutation';
import publishProductsToCatalog from '../../graphql/e-commerce/mutations/publishProductsToCatalogMutation';
import updateProductVariant from '../../graphql/e-commerce/mutations/updateProductVariantMutation';
import product from '../../graphql/e-commerce/queries/productQuery';
import { FetchPolicy } from 'apollo-client';

// Models
const API_PRODUCTS_URL = `${environment.apiEndpoint}/e-commerce/products`;
const API_PRODUCT_VARIANTS_URL = `${environment.apiEndpoint}/e-commerce/product-variants`;
const API_TAGS_URL = `${environment.apiEndpoint}/e-commerce/tags`;
const API_MEDIA_RECORDS_URL = `${environment.apiEndpoint}/e-commerce/media-records`;

const getProductQuery = (shopId: string, productId: string) => [
	{
		query: product,
		variables: {
			shopId,
			productId
		}
	}
];

// Real REST API
@Injectable({
	providedIn: 'root'
})
export class ProductsService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(
		new QueryParamsModel({}, 'asc', '', 0, 10)
	);

	constructor(
		private http: HttpClient,
		private httpUtils: HttpUtilsService,
		private apollo: Apollo
	) {}

	// --------------------------------- PRODUCTS ---------------------------------
	// Server should return filtered/sorted result
	findProducts(queryParams: QueryParamsModel): Observable<ProductModel[]> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = API_PRODUCTS_URL;
		return this.http.get<ProductModel[]>(url, {
			headers: httpHeaders,
			params: httpParams
		});
	}

	getProductByIdWatch(shopId: string, productId: string) {
		return this.apollo.watchQuery({
			query: product,
			variables: {
				shopId,
				productId
			}
		}).valueChanges;
	}

	getProductById(
		shopId: string,
		productId: string,
		fetchPolicy: FetchPolicy = 'network-only'
	): Promise<ProductModel> {
		return this.apollo
			.query({
				query: product,
				variables: {
					shopId,
					productId
				},
				fetchPolicy
			})
			.toPromise()
			.then(
				(v: GetProductByIdReactionResponse) =>
					(!!v && !!v.data && v.data.product) || null
			)
			.catch(() => null);
	}

	// CREATE =>  POST: add a new product to the server
	createProduct(
		shopId: string,
		shouldCreateFirstVariant: boolean = false
	): Promise<CreateProductReactionResponse> {
		return this.apollo
			.mutate({
				mutation: createProductMutation,
				variables: {
					shopId,
					shouldCreateFirstVariant
				}
			})
			.toPromise()
			.then((v) => v)
			.catch(() => null);
	}

	// At the moment, only used for metafields.
	updateProduct(
		shopId: string,
		productId: string,
		changes: { metafields: ProductMetafields[] }
	): Promise<ProductModel> {
		return this.apollo
			.mutate({
				mutation: updateProductMutation,
				variables: {
					input: {
						shopId,
						productId,
						product: changes
					}
				}
			})
			.toPromise()
			.then(
				(v: UpdateProductProductReactionResponse) =>
					(v && v.data && v.data.updateProduct && v.data.updateProduct.product) ||
					null
			)
			.catch(() => null);
	}
	async createProductVariant(
		shopId: string,
		productId: string,
		parentProductId: string,
		variant: { attributeLabel?: string; optionTitle: string },
		refetchProduct = false
	): Promise<ProductVariantModel> {
		if (!shopId || !parentProductId || !variant) return null;

		const refetchQueries = refetchProduct
			? getProductQuery(shopId, productId)
			: undefined;

		return this.apollo
			.mutate({
				mutation: createProductVariantMutation,
				variables: {
					input: {
						shopId,
						productId: parentProductId,
						variant: { ...variant, isVisible: true }
					}
				},
				refetchQueries
			})
			.toPromise()
			.then(
				(v: CreateProductVariantReactionResponse) =>
					(!!v &&
						!!v.data &&
						!!v.data.createProductVariant &&
						v.data.createProductVariant.variant) ||
					null
			)
			.catch(() => null);
	}

	async archiveProductVariant(
		shopId: string,
		productId: string,
		variantIds: string,
		refetchProduct = false
	): Promise<ProductVariantModel> {
		if (!shopId || !variantIds) return null;

		const refetchQueries = refetchProduct
			? getProductQuery(shopId, productId)
			: undefined;

		return this.apollo
			.mutate({
				mutation: archiveProductVariants,
				variables: {
					input: {
						shopId,
						variantIds: [variantIds]
					}
				},
				refetchQueries
			})
			.toPromise()
			.then((v: ArchiveProductVariantsReactionResponse) => {
				if (objectPathExists(v, 'data.archiveProductVariants.variants.length'))
					return v.data.archiveProductVariants.variants[0];

				return null;
			})
			.catch(() => null);
	}

	async updateProductVariant(
		shopId: string,
		productId: string,
		variantId: string,
		changes: { [key: string]: any },
		refetchProduct = false
	) {
		if (!shopId || !variantId) return null;

		const refetchQueries = refetchProduct
			? getProductQuery(shopId, productId)
			: undefined;

		return this.apollo
			.mutate({
				mutation: updateProductVariant,
				variables: {
					input: {
						shopId,
						variantId,
						variant: changes
					}
				},
				refetchQueries
			})
			.toPromise()
			.then((v: UpdateProductVariantReactionResponse) => {
				if (objectPathExists(v, 'data.updateProductVariant.variant'))
					return v.data.updateProductVariant.variant;

				return null;
			})
			.catch(() => null);
	}
	async updateProductVariantPrices(
		shopId: string,
		productId: string,
		variantId: string,
		price: number,
		compareAtPrice: number,
		refetchProduct = false
	) {
		if (!shopId || !variantId) return null;

		const refetchQueries = refetchProduct
			? getProductQuery(shopId, productId)
			: undefined;

		return this.apollo
			.mutate({
				mutation: updateProductVariantPrices,
				variables: {
					input: {
						shopId,
						variantId,
						prices: {
							price,
							compareAtPrice
						}
					}
				},
				refetchQueries
			})
			.toPromise()
			.then((v: UpdateProductVariantPriceReactionResponse) => {
				if (objectPathExists(v, 'data.updateProductVariantPrices.variant'))
					return v.data.updateProductVariantPrices.variant;

				return null;
			})
			.catch(() => null);
	}

	async publishProduct(shopId: string, productId: string, refetchProduct = false) {
		if (!shopId || !productId) return null;

		const refetchQueries = refetchProduct
			? getProductQuery(shopId, productId)
			: undefined;

		return this.apollo
			.mutate({
				mutation: publishProductsToCatalog,
				variables: {
					productIds: [productId]
				},
				refetchQueries
			})
			.toPromise()
			.then((v: PublishProductReactionResponse) => {
				if (objectPathExists(v, 'data.publishProductsToCatalog.length'))
					return v.data.publishProductsToCatalog;

				return null;
			})
			.catch(() => null);
	}

	// UPDATE => PUT: update the product on the server
	async updateProductThrougProxy(
		point_of_interest_id: number,
		productId: string,
		product: GQLProductInput
	): Promise<{ product: GQLProduct }> {
		if (!product || !productId || !point_of_interest_id) {
			return null;
		}
		const body = {
			point_of_interest_id,
			product
		};
		return this.http
			.put(`${API_PRODUCTS_URL}/${productId}`, body)
			.toPromise()
			.then((res) => res)
			.catch(() => null);
	}

	// Publish Products
	publishProducts(productIds: string[]): Promise<boolean> {
		const body = {
			ids: productIds
		};

		return this.http
			.put<any[]>(`${API_PRODUCTS_URL}/publish-to-catalog`, body)
			.toPromise()
			.then((res) => !!res && res.length === productIds.length)
			.catch(() => false);
	}

	// UPDATE Visibility
	updateVisibilityForProducts(
		point_of_interest_id: number,
		product_ids: string[],
		isVisible: string
	): Promise<any> {
		const body = {
			point_of_interest_id,
			product_ids,
			isVisible
		};
		return this.http
			.put(`${API_PRODUCTS_URL}/update-visibility`, body)
			.toPromise()
			.then((res) => res)
			.catch(() => null);
	}

	// DELETE => archive the products with product_ids
	deleteProducts(
		point_of_interest_id: number,
		product_ids: string[]
	): Promise<boolean> {
		const body = {
			point_of_interest_id,
			product_ids
		};
		return this.http
			.put<any>(`${API_PRODUCTS_URL}/archive-products`, body)
			.toPromise()
			.then(
				(res) =>
					!!res && !!res.products && res.products.length === product_ids.length
			)
			.catch(() => false);
	}

	// --------------------------------- PRODUCT-VARIANTS ---------------------------------

	// DELETE => archive the product variants with variant_ids
	deleteProductVariantsThroughProxy(
		point_of_interest_id: number,
		variant_ids: string[]
	): Promise<boolean> {
		const body = {
			point_of_interest_id,
			variant_ids
		};
		return this.http
			.put<any>(`${API_PRODUCT_VARIANTS_URL}/archive-variants`, body)
			.toPromise()
			.then(
				(res) =>
					!!res && !!res.variants && res.variants.length === variant_ids.length
			)
			.catch(() => false);
	}

	// Server should return inventory info for the variantId of productId
	getInventoryInfo(
		productId: string,
		productVariantId: string,
		point_of_interest_id: number
	): Promise<GQLSimpleInventoryInfo> {
		if (!productId || !productVariantId) {
			return null;
		}
		const httpParams = this.httpUtils.getFilterHTTPParams({ point_of_interest_id });

		const url = `${API_PRODUCTS_URL}/${productId}/variant/${productVariantId}/inventory`;
		return this.http
			.get<GQLSimpleInventoryInfo>(url, { params: httpParams })
			.toPromise()
			.then((res) => res as GQLSimpleInventoryInfo)
			.catch(() => null);
	}

	// UPDATE => PUT: update the product variant inventory info
	async updateInventoryInfo(
		point_of_interest_id: number,
		productId: string,
		productVariantId: string,
		inventory: {
			canBackorder: boolean;
			inventoryInStock: number;
			isEnabled: boolean;
			lowInventoryWarningThreshold: number;
		}
	): Promise<GQLSimpleInventoryInfo> {
		if (!productId || !productVariantId || !point_of_interest_id) {
			return null;
		}
		const body = {
			point_of_interest_id,
			inventory
		};
		const url = `${API_PRODUCTS_URL}/${productId}/variant/${productVariantId}/inventory`;
		return this.http
			.put(url, body)
			.toPromise()
			.then((res) => res)
			.catch(() => null);
	}

	// ------------------------------ PRODUCT-MEDIA-RECORDS ------------------------------

	createMediaRecord(
		point_of_interest_id: number,
		mediaRecord: GQLMediaRecord
	): Promise<GQLMediaRecord> {
		const body = {
			point_of_interest_id,
			mediaRecord
		};

		return this.http
			.post<{ mediaRecord: GQLMediaRecord }>(API_MEDIA_RECORDS_URL, body)
			.toPromise()
			.then((res) => !!res && res.mediaRecord)
			.catch(() => null);
	}

	// Delete a MediaRecord to delete both the record and the backing file data
	deleteMediaRecord(
		point_of_interest_id: number,
		mediaRecordId: string
	): Promise<boolean> {
		if (!point_of_interest_id || !mediaRecordId) {
			return;
		}
		const body = {
			point_of_interest_id
		};
		return this.http
			.put<{ mediaRecord: GQLMediaRecord }>(
				`${API_MEDIA_RECORDS_URL}/${mediaRecordId}/delete`,
				body
			)
			.toPromise()
			.then((res) => !!res && !!res.mediaRecord)
			.catch(() => false);
	}

	// Update the priority metadata for a MediaRecord. Used for sorting product and variant media in the catalog.
	updatePriorityMediaRecord(
		point_of_interest_id: number,
		mediaRecordId: string,
		priority: number
	): Promise<GQLMediaRecord> {
		if (!point_of_interest_id || !mediaRecordId || !isDefined(priority)) {
			return;
		}
		const body = {
			point_of_interest_id,
			priority
		};
		return this.http
			.put<{ mediaRecord: GQLMediaRecord }>(
				`${API_MEDIA_RECORDS_URL}/${mediaRecordId}/priority`,
				body
			)
			.toPromise()
			.then((res) => !!res && res.mediaRecord)
			.catch(() => null);
	}
}
