import { selectRelations } from './../../../../core/point-of-interest/_selectors/e-commerce.selectors';
import { selectedPointOfInterest } from './../../../../core/auth/_selectors/auth.selectors';
import { AppState } from './../../../../core/reducers/index';
import { PointOfInterest } from './../../../../core/point-of-interest/_models/point-of-interest.model';
import { Component, OnDestroy } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeWhile } from 'rxjs/operators';

export interface PointOfInterestAndShopId {
	shopId: string;
	poiId: number;
	data?: {
		pointOfInterest: PointOfInterest;
	};
}

@Component({
	selector: 'kt-pointofinterest-shop-info',
	templateUrl: './pointofinterest-shop-info.component.html'
})
export class PointofinterestShopInfoComponent implements OnDestroy {
	protected pointOfInterest$: Observable<PointOfInterest>;
	protected pointOfInterestRelations$: Observable<PointOfInterestAndShopId[]>;

	protected pointOfInterestAndShopIds$ = new BehaviorSubject<
		PointOfInterestAndShopId
	>(null);

	protected inView = true;

	constructor(public store: Store<AppState>) {}

	getPoiAndShopInfo() {
		// Get the current selected point_of_interest
		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterestRelations$ = this.store.pipe(select(selectRelations));

		combineLatest([this.pointOfInterest$, this.pointOfInterestRelations$])
			.pipe(takeWhile(() => this.inView))
			.subscribe(([pointOfInterest, poiRelations]) => {
				if (!pointOfInterest || !poiRelations || !poiRelations.length) return;

				const poiId = pointOfInterest.id;

				const relation = poiRelations.find((rel) => rel.poiId === poiId);
				if (relation) {
					this.pointOfInterestAndShopIds$.next({
						poiId,
						shopId: relation.shopId,
						data: { pointOfInterest }
					});
				}
			});
	}

	ngOnDestroy() {
		this.inView = false;
	}
}
