import { ProductsState } from './../_reducers/product.reducers';
import { ProductModel } from './../_models/product.model';
// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State

export const selectProductsState = createFeatureSelector<ProductsState>('products');

export const selectProductById = (productId: number) =>
	createSelector(
		selectProductsState,
		(productsState) => productsState.entities[productId]
	);

export const selectProductsPageLoading = createSelector(
	selectProductsState,
	(productsState) => (!!productsState && productsState.listLoading) || null
);

export const selectProductsActionLoading = createSelector(
	selectProductsState,
	(notificationsState) => notificationsState.actionsloading
);

export const selectProductsPageLastQuery = createSelector(
	selectProductsState,
	(productsState) => (!!productsState && productsState.lastQuery) || null
);

export const selectLastCreatedProductId = createSelector(
	selectProductsState,
	(productsState) => (!!productsState && productsState.lastCreatedProductId) || null
);

export const selectProductsInitWaitingMessage = createSelector(
	selectProductsState,
	(productsState) =>
		(!!productsState && productsState.showInitWaitingMessage) || null
);

export const selectProductsInStore = createSelector(
	selectProductsState,
	(productsState) => {
		const items: ProductModel[] = [];
		if (!productsState) return;

		each(productsState.entities, (element) => {
			items.push(element);
		});
		const httpExtension = new HttpExtenstionsModel();
		const result: ProductModel[] = httpExtension.sortArray(
			items,
			productsState.lastQuery.sortField,
			productsState.lastQuery.sortOrder
		);
		return new QueryResultsModel(result, productsState.totalCount, '');
	}
);

export const selectHasProductsInStore = createSelector(
	selectProductsInStore,
	(queryResult) => {
		if (!queryResult.totalCount) {
			return false;
		}

		return true;
	}
);
