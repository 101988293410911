// NGRX
import { createFeatureSelector } from '@ngrx/store';
import {
	EntityState,
	EntityAdapter,
	createEntityAdapter,
	Update
} from '@ngrx/entity';
// Actions
import { ProductActions, ProductActionTypes } from '../_actions/product.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { ProductModel } from './../_models/product.model';

export interface ProductsState extends EntityState<ProductModel> {
	listLoading: boolean;
	actionsloading: boolean;
	totalCount: number;
	lastQuery: QueryParamsModel;
	lastCreatedProductId: string;
	showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ProductModel> = createEntityAdapter<
	ProductModel
>({ selectId: (publishedData: ProductModel) => publishedData._id });

export const initialProductsState: ProductsState = adapter.getInitialState({
	listLoading: false,
	actionsloading: false,
	totalCount: 0,
	lastQuery: new QueryParamsModel({}),
	lastCreatedProductId: undefined,
	showInitWaitingMessage: true
});

export function productsReducer(
	state = initialProductsState,
	action: ProductActions
): ProductsState {
	switch (action.type) {
		case ProductActionTypes.ProductsPageToggleLoading:
			return {
				...state,
				listLoading: action.payload.isLoading,
				lastCreatedProductId: undefined
			};
		case ProductActionTypes.ProductsActionToggleLoading:
			return {
				...state,
				actionsloading: action.payload.isLoading
			};
		case ProductActionTypes.ProductsPageCancelled:
			return {
				...state,
				listLoading: false,
				lastQuery: new QueryParamsModel({})
			};
		case ProductActionTypes.ProductsPageLoaded:
			return adapter.addMany(action.payload.products, {
				...initialProductsState,
				totalCount: action.payload.totalCount,
				listLoading: false,
				lastQuery: action.payload.page,
				showInitWaitingMessage: false
			});
		default:
			return state;
	}
}

export const getProductState = createFeatureSelector<ProductModel>('products');

export const {
	selectAll,
	selectEntities,
	selectIds,
	selectTotal
} = adapter.getSelectors();
