/**
 * Model used to represent a Geographic point.
 * :lat -> Geographic latitude
 * :lng -> Geographic longitude
 */

export class LatLng {
	id?: number;
	lat: number;
	lng: number;

    clear() {
		this.id = undefined;
		this.lat = undefined;
		this.lng = undefined;
    }
}
