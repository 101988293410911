import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';
import { ReactionQueryResultsModel } from '../../_base/crud/models/query-models/reaction-query-results.model';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { QueryResultsModel } from '../../_base/crud/models/query-models/query-results.model';
import { objectPathExists } from '../../_base/crud/utils/helper.function';

@Injectable({
	providedIn: 'root'
})
export class ECommerceConnectionService {
	constructor() {}

	static getEntitiesFromQuery<T>(
		entitiesName: string,
		result: ApolloQueryResult<any> | boolean
	): QueryResultsModel {
		if (!entitiesName || !result) return null;
		const data = (result as ApolloQueryResult<any>).data;
		const queryResult = (data && data[entitiesName]) || null;
		const items: T[] = (queryResult && queryResult.nodes) || null;
		const totalCount = (queryResult as ReactionQueryResultsModel<any>).totalCount;
		return {
			items,
			totalCount,
			errorMessage:
				!items || !totalCount ? `Nothing returned from ${entitiesName}` : ''
		};
	}

	static getEntityFromQuery<T>(
		entitiyField: string,
		result: ApolloQueryResult<any> | boolean
	): T {
		if (!entitiyField || !result) return null;
		const data = (result as ApolloQueryResult<any>).data;
		return (data && (objectPathExists(data, entitiyField, true) as T)) || null;
	}

	static queryParamsModelToGraphQlFilter(queryParams: QueryParamsModel) {
		return {
			sortBy: queryParams.sortField,
			sortOrder: queryParams.sortOrder,
			first: queryParams.limit,
			offset: queryParams.start,
			filter: queryParams.filter
		};
	}
}
