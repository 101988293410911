import { TagModel } from './../_models/tag.model';
// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions

import { TagsActions, TagsActionTypes } from '../_actions/tags.actions';

export interface TagsState extends EntityState<TagModel> {
	listLoading: boolean;
	actionsloading: boolean;
	totalCount: number;
	showInitWaitingMessage: boolean;
	showErrorMessage: false | string;
	actionMessage: { type: 'error' | 'info' | 'success'; message: string };
}

export const adapter: EntityAdapter<TagModel> = createEntityAdapter<TagModel>({
	selectId: (publishedData: TagModel) => publishedData._id
});

export const initialTagsState: TagsState = adapter.getInitialState({
	listLoading: false,
	actionsloading: false,
	totalCount: 0,
	showInitWaitingMessage: true,
	showErrorMessage: false,
	actionMessage: null
});

export function tagsReducer(
	state = initialTagsState,
	action: TagsActions
): TagsState {
	switch (action.type) {
		case TagsActionTypes.TagsListToggleLoading: {
			return {
				...state,
				listLoading: action.payload.isLoading
			};
		}

		case TagsActionTypes.TagsActionToggleLoading: {
			return {
				...state,
				actionsloading: action.payload.isLoading
			};
		}

		case TagsActionTypes.TagsActionMessage: {
			return {
				...state,
				actionMessage: action.payload.actionMessage
			};
		}

		case TagsActionTypes.TagUpdated:
			return adapter.updateOne(action.payload.partialTag, state);

		case TagsActionTypes.TagCreated:
			return adapter.addOne(action.payload.tag, state);

		case TagsActionTypes.OneTagDeleted:
			return adapter.removeOne(action.payload.id, state);

		case TagsActionTypes.TagsPageLoaded:
			return adapter.addMany(action.payload.tags, {
				...initialTagsState,
				totalCount: action.payload.totalCount,
				listLoading: false,
				showInitWaitingMessage: false
			});

		default:
			return state;
	}
}

export const getOrderState = createFeatureSelector<TagModel>('tags');

export const {
	selectAll,
	selectEntities,
	selectIds,
	selectTotal
} = adapter.getSelectors();
