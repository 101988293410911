import { Category } from './../_models/category.model';
import { QueryParamsModel } from './../../_base/crud/models/query-models/query-params.model';
import { HttpUtilsService } from './../../_base/crud/utils/http-utils.service';
import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS

// CRUD

// Models

const API_CATEGORY_URL = `${environment.apiEndpoint}/categories`;

// Real REST API
@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// READ
	// Server should return filtered result
	findCategories(filterParams: object): Promise<Category[]> {
		const httpParams = this.httpUtils.getFilterHTTPParams(filterParams);

		const url = API_CATEGORY_URL;
		return this.http.get<Category[]>(url, {params: httpParams})
			.toPromise()
			.then( res => res)
			.catch( () => []);
	}
}
