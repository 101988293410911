import { TagsService } from './../_services/tags.service';
import { ECommerceConnectionService } from './../../graphql/e-commerce/e-commerce.service';
import { AppState } from '../../reducers/index';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Effect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import {
	TagsActionToggleLoading,
	TagsListToggleLoading,
	TagUpdated,
	TagsActionTypes,
	TagsActionMessage,
	TagsOnServerCreated,
	TagCreated,
	TagsPageRequest,
	TagsPageLoaded
} from './../_actions/tags.actions';
import { TagModel } from '../_models/tag.model';

@Injectable()
export class TagsEffects {
	showActionLoadingDistpatcher = new TagsActionToggleLoading({
		isLoading: true
	});
	hideActionLoadingDistpatcher = new TagsActionToggleLoading({
		isLoading: false
	});

	showListLoadingDistpatcher = new TagsListToggleLoading({
		isLoading: true
	});
	hideListLoadingDistpatcher = new TagsListToggleLoading({
		isLoading: false
	});

	@Effect()
	loadProductsPage$ = this.actions$.pipe(
		ofType<TagsPageRequest>(TagsActionTypes.TagsPageRequest),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showListLoadingDistpatcher);
			return this.tagsSrvc.getTags(payload.shopId, payload.force).pipe(
				catchError((error) => {
					console.error('Cannot find the tags!', error);
					return of(false);
				})
			);
		}),
		map((value) => {
			if (!!value) {
				const tagsResponse = ECommerceConnectionService.getEntitiesFromQuery<
					TagModel
				>('tags', value);

				if (!tagsResponse) return;

				return new TagsPageLoaded({
					tags: tagsResponse.items,
					totalCount: tagsResponse.totalCount
				});
			}
			return this.hideListLoadingDistpatcher;
		})
	);

	@Effect()
	updateTag$ = this.actions$.pipe(
		ofType<TagUpdated>(TagsActionTypes.TagUpdated),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showActionLoadingDistpatcher);
			return of(true);
			// return this.shippingMethosSrvc
			// 	.updateFlatShippingMethod(payload.tag, payload.shopId)
			// 	.pipe(
			// 		catchError((error) => {
			// 			console.error('Cannot update the shipping method!', error);
			// 			return of(false);
			// 		})
			// 	);
		}),
		map((value) => {
			let actionMessage;
			if (!!value) {
				actionMessage = {
					type: 'success',
					message: 'PRODUCTS.TAGS.SUCCESSFULLY_UPDATED'
				};
			} else {
				actionMessage = {
					type: 'error',
					message: 'PRODUCTS.TAGS.ERROR_ON_UPDATE'
				};
			}

			this.store.dispatch(
				new TagsActionMessage({
					actionMessage
				})
			);

			return this.hideActionLoadingDistpatcher;
		})
	);

	@Effect()
	createTag$ = this.actions$.pipe(
		ofType<TagsOnServerCreated>(TagsActionTypes.TagOnServerCreated),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showActionLoadingDistpatcher);
			return of(true);
			// return this.shippingMethosSrvc
			// 	.createFlatShippingMethod(payload.shopId, payload.shippingMethod)
			// 	.pipe(
			// 		catchError((error) => {
			// 			console.error('Cannot update the shipping method!', error);
			// 			return of(false);
			// 		})
			// 	);
		}),
		map((value) => {
			let actionMessage;
			if (!!value) {
				actionMessage = {
					type: 'success',
					message: 'PRODUCTS.TAGS.SUCCESSFULLY_CREATED'
				};

				const tag = ECommerceConnectionService.getEntityFromQuery<TagModel>(
					'createFlatRateFulfillmentMethod.method',
					value
				);
				// TODO: 👆

				this.store.dispatch(new TagCreated({ tag }));
			} else {
				actionMessage = {
					type: 'error',
					message: 'PRODUCTS.TAGS.METHODS.ERROR_ON_CREATION'
				};
			}

			this.store.dispatch(
				new TagsActionMessage({
					actionMessage
				})
			);

			return this.hideActionLoadingDistpatcher;
		})
	);

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private tagsSrvc: TagsService
	) {}
}
