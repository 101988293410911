import {
	ShippingMethodModel,
	ShippingMethodReactionResponse
} from './../_models/shipping-methods.model';

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
// RxJS
import { Observable } from 'rxjs';
// CRUD
import { HttpUtilsService } from '../../_base/crud';

import { ReactionQueryResultsModel } from '../../_base/crud/models/query-models/reaction-query-results.model';
import flatRateFulfillmentMethodsQuery from '../../graphql/e-commerce/queries/flatRateFulfillmentMethodsQuery';
import createFlatRateFulfillmentMethodMutation from '../../graphql/e-commerce/mutations/createFlatRateFulfillmentMethodMutation';
import updateFlatRateFulfillmentMethodMutation from '../../graphql/e-commerce/mutations/updateFlatRateFulfillmentMethodMutation';
import deleteFlatRateFulfillmentMethodMutation from '../../graphql/e-commerce/mutations/deleteFlatRateFulfillmentMethodMutation';

@Injectable({
	providedIn: 'root'
})
export class ShippingMethodsService {
	constructor(private apollo: Apollo) {}

	createFlatShippingMethod(
		shopId: string,
		shippingMethod: ShippingMethodModel
	): Observable<any> {
		const method = { ...shippingMethod, _id: undefined };

		return this.apollo.mutate({
			mutation: createFlatRateFulfillmentMethodMutation,
			variables: {
				input: {
					shopId,
					method
				}
			}
		});
	}
	updateFlatShippingMethod(
		shippingMethod: ShippingMethodModel,
		shopId: string
	): Observable<any> {
		const method = { ...shippingMethod, _id: undefined };

		return this.apollo.mutate({
			mutation: updateFlatRateFulfillmentMethodMutation,
			variables: {
				input: {
					methodId: shippingMethod._id,
					shopId,
					method
				}
			}
		});
	}

	getAllShippingMethods(shopId: string): Observable<ShippingMethodReactionResponse> {
		return this.apollo.query<{
			flatRateFulfillmentMethods: ReactionQueryResultsModel<ShippingMethodModel>;
		}>({
			query: flatRateFulfillmentMethodsQuery,
			variables: {
				shopId
			}
		});
	}

	deleteById(id: string, shopId: string): Observable<any> {
		return this.apollo.mutate({
			mutation: deleteFlatRateFulfillmentMethodMutation,
			variables: {
				input: {
					methodId: id,
					shopId
				}
			}
		});
	}
}
