import { ReactiveFormsModule } from '@angular/forms';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Perfect ScrollBar
// Core Module
import { CoreModule } from '../../../../../core/core.module';
import { AvatarFileUploadComponent } from './avatar-file-upload.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		CoreModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),

	],
	exports: [AvatarFileUploadComponent],
	declarations: [AvatarFileUploadComponent]
})
export class FileUploadModule {
}
