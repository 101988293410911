import { LatLng } from './lat-lng.model';

/**
 * Model used to represent an Polygon geofence.
 * :paths -> array of Geographic points that represent an closed polygon
 */
export const POLYGON_FENCE_TYPE = 'maps.polygon-fence';
export class PolygonFence {
	id: number;
	__component: string;
	paths: LatLng[];

    clear() {
		this.id = undefined;
		this.__component = POLYGON_FENCE_TYPE;
		this.paths = [];
    }
}
