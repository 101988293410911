import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'kt-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
	@Input() showTools: false;
	@Input() title: '';
	constructor() {}

	ngOnInit() {}
}
