import { FileRecord } from '@reactioncommerce/file-collections';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));

// Reaction Commerce file-collections
// source: https://github.com/reactioncommerce/reaction-file-collections#browser-setup
// These need to be set in only one client-side file
FileRecord.downloadEndpointPrefix = '/assets/files';
FileRecord.uploadEndpoint = '/assets/uploads';
FileRecord.absoluteUrlPrefix = environment.ecommerceEndpoint;
