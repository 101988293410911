import gql from 'graphql-tag';
import TagInfo from './../fragments/tag';

const removeTagMutation = gql`
	mutation removeTagMutation($input: RemoveTagInput!) {
		removeTag(input: $input) {
			tag {
				...TagInfo
			}
		}
	}
	${TagInfo}
`;

export default removeTagMutation;
