import gql from 'graphql-tag';

const flatRateFulfillmentMethodsQuery = gql`
	query flatRateFulfillmentMethodsQuery($shopId: ID!) {
		flatRateFulfillmentMethods(shopId: $shopId) {
			nodes {
				_id
				name
				label
				fulfillmentTypes
				rate
				cost
				isEnabled
			}
		}
	}
`;

export default flatRateFulfillmentMethodsQuery;
