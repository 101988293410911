// Angular
import { Component, Input } from '@angular/core';

@Component({
	selector: 'kt-widget-2-values',
	templateUrl: './widget-2-values.component.html',
	styleUrls: ['./widget-2-values.component.scss']
})
export class Widget2ValuesComponent {
	// Public properties
	@Input() title: string;
	@Input() desc: string;
	@Input() values: {
		v1: { label: string; value: number };
		v2: { label: string; value: number };
	};
	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor() {}
}
