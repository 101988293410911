import { Address } from './../../../../core/point-of-interest/_models/components/address.model';
import {
	PointOfInterestSelected,
	UserRequested
} from './../../../../core/auth/_actions/auth.actions';
import { HttpErrorResponse } from '@angular/common/http';
import {} from 'googlemaps';
import { MAP_STYLES } from './../../../../core/point-of-interest/_consts/map-style';
import { LatLng } from './../../../../core/point-of-interest/_models/components/lat-lng.model';
import {
	PolygonFence,
	POLYGON_FENCE_TYPE
} from './../../../../core/point-of-interest/_models/components/polygon-fence.model';
import {
	CircularFence,
	CIRCULAR_FENCE_TYPE,
	DEFAULT_FENCE_RADIUS
} from './../../../../core/point-of-interest/_models/components/circular-fence.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { selectedPointOfInterest } from './../../../../core/auth/_selectors/auth.selectors';
import {
	LayoutUtilsService,
	MessageType
} from './../../../../core/_base/crud/utils/layout-utils.service';
import { PointOfInterestService } from './../../../../core/point-of-interest/_services/point-of-interest.service';
import { Store, select } from '@ngrx/store';
import { AppState } from './../../../../core/reducers/index';
import { PointOfInterest } from './../../../../core/point-of-interest/_models/point-of-interest.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	Component,
	OnInit,
	ChangeDetectorRef,
	ElementRef,
	ViewChild,
	OnDestroy
} from '@angular/core';
import {
	markAllFormAs,
	toFormData
} from './../../../../core/_base/crud/utils/helper.function';

@Component({
	selector: 'kt-service-geofence',
	templateUrl: './service-geofence.component.html',
	styleUrls: ['./service-geofence.component.scss']
})
export class ServiceGeofenceComponent implements OnInit, OnDestroy {
	inView = true;
	// Google Maps
	searchedAddress = '';
	geocoder: google.maps.Geocoder;

	showMap = false;
	map: google.maps.Map;
	mapZoom = 16;
	mapElement: ElementRef;
	@ViewChild('mapRef', { static: false }) set mapRef(elem: ElementRef) {
		this.mapElement = elem;
		this.initMap();
	}
	poiCircle = new google.maps.Circle();
	poiMarker = new google.maps.Marker();

	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	pointOfInterest$: Observable<PointOfInterest>;
	pointOfInterest: PointOfInterest = null;
	geofence: CircularFence | PolygonFence = null;
	geofenceForm: FormGroup;
	mapSearchControl = new FormControl('');
	POLYGON_FENCE_TYPE = POLYGON_FENCE_TYPE;
	CIRCULAR_FENCE_TYPE = CIRCULAR_FENCE_TYPE;
	// Alert panel
	@ViewChild('alert', { static: false }) set content(content: ElementRef) {
		if (!!content && !!content.nativeElement) {
			content.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.alertElement = content;
	}
	alertElement: ElementRef;
	showAlertMessage = false;
	alertMessage: {
		message?: string;
		type?: 'primary' | 'accent' | 'warn';
	} = {};

	constructor(
		private cdr: ChangeDetectorRef,
		private store: Store<AppState>,
		private pointOfInterestService: PointOfInterestService,
		private layoutUtilsService: LayoutUtilsService,
		private formBuilder: FormBuilder
	) {}

	ngOnInit() {
		this.geocoder = new google.maps.Geocoder();

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterest$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterest) => {
				if (!!pointOfInterest) {
					// Get the most recent info from server
					this.pointOfInterest = await this.pointOfInterestService.getPointOfInterestById(
						pointOfInterest.id
					);
					this.loadGeofence(this.pointOfInterest);
				} else {
					this.loadingSubject.next(false);
				}
			});
	}
	ngOnDestroy() {
		this.inView = false;
	}

	async searchByAddress(address: string) {
		address = address || this.mapSearchControl.value;
		const location: { lat: number; lng: number } = await this.geocoderSearchAddress(
			address
		);
		if (!location) {
			this.alertMessage = {
				message: "Don't find any location...",
				type: 'warn'
			};
			this.showAlert();
		} else {
			// Update to the current position
			this.updatePosition(location, true, true);
		}
	}

	/**
	 * Close alert
	 *
	 */
	onAlertClose($event) {
		this.showAlertMessage = false;
		this.alertMessage = {};
	}
	/**
	 * Show alert
	 *
	 */
	showAlert() {
		this.showAlertMessage = true;
		this.cdr.detectChanges();
	}

	submit() {
		/** check form */
		if (this.geofenceForm.invalid) {
			markAllFormAs(this.geofenceForm, 'touched');
			return;
		}
		if (this.geofenceForm.pristine) {
			this.alertMessage = {
				message: 'NOTIFICATIONS.FORM.ALERTS.NOTHING_TO_SAVE',
				type: 'primary'
			};
			this.showAlert();
			return;
		}

		const payload: FormData = this.prepareFormPayload();
		if (!payload) {
			console.log('Missing the information to update the geofence info!!');
			return;
		} else {
			this.updatePointOfInterest(this.pointOfInterest.id, payload);
			return;
		}
	}

	prepareFormPayload() {
		if (!this.pointOfInterest || this.geofenceForm.pristine) {
			return null;
		}

		const data = this.geofenceForm.getRawValue();
		// Remove null values
		const newGeofence = {};
		Object.keys(data).forEach((key) => {
			if (data[key] !== null) {
				newGeofence[key] = data[key];
			}
		});

		const geofenceData = {
			geofence: [newGeofence]
		};

		return toFormData({ data: JSON.stringify(geofenceData) });
	}

	/**
	 * Update PointOfinterest
	 *
	 * @param pointOfInterestId: number
	 * @param payload: FormData
	 */
	updatePointOfInterest(id: number, payload: FormData) {
		if (!id || !payload) {
			return null;
		}

		this.loadingSubject.next(true);
		this.pointOfInterestService
			.updatePointOfInterest(id, payload)
			.then((res) => {
				this.loadingSubject.next(false);
				const message = 'POINT_OF_INTEREST.FORM.ALERTS.UPDATE_SUCCESS';
				this.layoutUtilsService.showActionNotification(message, MessageType.Update);
				this.refreshPointOfInterest(res[0]);
			})
			.catch((error) => {
				this.loadingSubject.next(false);
				if (error instanceof HttpErrorResponse) {
					this.alertMessage = {
						message: 'POINT_OF_INTEREST.FORM.ALERTS.UPDATE_ERROR',
						type: 'warn'
					};
					this.showAlert();
				}
			});
	}

	/**
	 * Refresh PointOfInterest
	 *
	 * @param pointOfInterest: PointOfInterest
	 */
	refreshPointOfInterest(pointOfInterest: PointOfInterest) {
		this.loadingSubject.next(false);
		if (!!pointOfInterest) {
			this.store.dispatch(new UserRequested());
			this.pointOfInterest = pointOfInterest;
			this.loadGeofence(this.pointOfInterest);
		}
	}

	/**
	 * Load the Geofence info of Point of Interest
	 */
	async loadGeofence(pointOfInterest: PointOfInterest) {
		this.geofence = null;
		if (
			!!pointOfInterest &&
			!!pointOfInterest.geofence &&
			pointOfInterest.geofence.length > 0
		) {
			this.mapZoom = 16;
			this.geofence = pointOfInterest.geofence[0]; // The structure is an array but there will always be only one geofence per PointOfInterest
		} else if (
			!!pointOfInterest &&
			!!pointOfInterest.address &&
			(!!pointOfInterest.address.postal_code || !!pointOfInterest.address.region)
		) {
			// Use the geocoder to search the coords with the postal code Region,Country
			const location: {
				lat: number;
				lng: number;
			} = await this.geocoderSearchAddress(
				`${pointOfInterest.address.postal_code} ${pointOfInterest.address.region},${pointOfInterest.address.country}`
			);
			if (!!location) {
				this.geofence = new CircularFence();
				this.geofence.clear();
				// Set the default values
				this.geofence.radius = DEFAULT_FENCE_RADIUS;
				this.geofence.center.lat = location.lat;
				this.geofence.center.lng = location.lng;
			}
		}

		// If can't find any location try the current browser location
		if (!this.geofence) {
			this.geofence = new CircularFence();
			this.geofence.clear();
			// Set the default values
			this.geofence.radius = DEFAULT_FENCE_RADIUS;
			this.geofence.center.lat = 40.52433; // Portugal center
			this.geofence.center.lng = -7.23416; // Portugal center
			this.mapZoom = 5;
			// Get the current location to center the map if available
			this.getCurrentLocation();
		}

		this.initGeofence();
		this.initMap();
		this.cdr.detectChanges();
		// Radius form Listner
		this.subscribeRadiusChange();
	}

	subscribeRadiusChange() {
		const radiusForm = !!this.geofenceForm && this.geofenceForm.get('radius');
		if (!radiusForm) {
			return;
		}
		this.geofenceForm
			.get('radius')
			.valueChanges.pipe(takeWhile(() => this.inView))
			.subscribe((value) => {
				if (!!value) {
					this.poiCircle.setRadius(+value);
				}
			});
	}

	initMap() {
		this.showMap = true;
		if (!this.mapElement) return;

		const options = {
			zoom: this.mapZoom,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: true,
			streetViewControl: false,
			rotateControl: true,
			fullscreenControl: true,
			styles: []
		};
		options.styles.push(...MAP_STYLES);

		this.map = new google.maps.Map(this.mapElement.nativeElement, options);

		let position = null;
		// Center
		const centerLat =
			this.geofenceForm.get('center.lat') &&
			this.geofenceForm.get('center.lat').value;
		const centerLng =
			this.geofenceForm.get('center.lng') &&
			this.geofenceForm.get('center.lng').value;
		// Radius
		const radius: number =
			this.geofenceForm.get('radius') && +this.geofenceForm.get('radius').value;
		if (!centerLat || !centerLng || !radius) {
			return; // Missing the center or radius values
		}
		position = new google.maps.LatLng({ lat: centerLat, lng: centerLng });

		/* -------- Init the Circular GeoFence on map -------- */
		// Center the map in the device position
		this.map.setCenter(position);

		// Create the Point of Interest circle geofence
		this.poiCircle = new google.maps.Circle({
			strokeColor: '#0F5BFF',
			strokeOpacity: 0.8,
			strokeWeight: 1,
			fillColor: '#0F5BFF',
			fillOpacity: 0.35,
			map: this.map,
			center: position,
			radius: radius // meters
		});

		// Create the Point of Interest Marker
		this.poiMarker = new google.maps.Marker({
			position,
			map: this.map,
			title: this.pointOfInterest.title
				? (this.pointOfInterest.title as string)
				: '',
			draggable: true,
			zIndex: 1000,
			icon: {
				url: `https://proxim.inflightit.com/uploads/707d97ea807e443f947a3118b9cdd623.png`
			}
		});

		// Start the Marker listner
		const that = this;
		this.poiMarker.addListener('dragend', async () => {
			const newPosition = that.poiMarker.getPosition();
			// Update to the new position
			this.updatePosition({ lat: newPosition.lat(), lng: newPosition.lng() });
		});
	}
	/**
	 * Init Geofence
	 */
	initGeofence() {
		this.createForm();

		// init the search address form
		let searchValue = '';
		if (
			this.pointOfInterest.address &&
			(!!this.pointOfInterest.address.postal_code ||
				!!this.pointOfInterest.address.region)
		) {
			searchValue = `${this.pointOfInterest.address.postal_code} ${this.pointOfInterest.address.region}, ${this.pointOfInterest.address.country}`;
		}
		this.mapSearchControl.setValue(searchValue);

		this.loadingSubject.next(false);
	}

	/**
	 * Create form
	 */
	createForm() {
		if (!this.geofence) {
			return;
		}

		this.geofenceForm = this.formBuilder.group({
			id: [this.geofence.id || undefined],
			__component: [this.geofence.__component]
		});

		// Polygon Geofence
		if (this.geofence.__component === POLYGON_FENCE_TYPE) {
			this.geofenceForm.addControl(
				'paths',
				this.formBuilder.array(
					(this.geofence as PolygonFence).paths
						? (this.geofence as PolygonFence).paths.map((path: LatLng) =>
								this.createPathControl(path, true)
						  )
						: []
				)
			);
			// Circular Geofence
		} else if (this.geofence.__component === CIRCULAR_FENCE_TYPE) {
			this.geofenceForm.addControl(
				'center',
				this.createPathControl((this.geofence as CircularFence).center, true)
			);
			this.geofenceForm.addControl(
				'radius',
				new FormControl((this.geofence as CircularFence).radius, Validators.required)
			);
		}
	}

	createPathControl(path: LatLng, disabled = false) {
		if (!!path) {
			return this.formBuilder.group({
				id: [path.id || undefined],
				lng: [{ value: path.lng, disabled }],
				lat: [{ value: path.lat, disabled }]
			});
		}
	}

	/**
	 * Returns the errors of form field
	 */
	getErrors(field: string) {
		const control = this.geofenceForm.get(field);
		if (!control) {
			return false;
		}
		return control.touched && control.errors;
	}

	/**
	 * Update the Position
	 */
	updatePosition(
		newPosition: { lat: number; lng: number },
		updateMarker = false,
		markAsDirty = true
	) {
		if (!newPosition || (!!newPosition && (!newPosition.lat || !newPosition.lng))) {
			return false;
		}
		// Update the Point of Interest Form Location
		const centerFormLat = this.geofenceForm.get('center.lat');
		const centerFormLng = this.geofenceForm.get('center.lng');
		if (!!centerFormLat && !!centerFormLng) {
			centerFormLng.setValue(+newPosition.lng, { disabled: true });
			centerFormLat.setValue(+newPosition.lat, { disabled: true });
			if (!!markAsDirty) {
				centerFormLat.markAsDirty();
				centerFormLng.markAsDirty();
			}
		}
		// Update the Map Marker position
		if (!!updateMarker) {
			this.poiMarker.setPosition(newPosition);
			this.map.setZoom(17);
		}
		// Update the circle position
		this.poiCircle.setCenter(newPosition);

		// Update the map center
		this.map.setCenter(newPosition);

		this.cdr.detectChanges();
	}
	/**
	 * Get the current location
	 */
	getCurrentLocation() {
		if (!navigator.geolocation) {
			console.error('No support for geolocation');
			return;
		}
		navigator.geolocation.getCurrentPosition((position) => {
			if (
				!!position.coords &&
				!!position.coords.longitude &&
				position.coords.latitude
			) {
				// Update to the current position
				this.updatePosition(
					{ lat: position.coords.latitude, lng: position.coords.longitude },
					true,
					false
				);
				return;
			}
			console.error("Can't get the geolocation");
		});
	}

	/**
	 * Get the location of address string
	 * @param address
	 */
	geocoderSearchAddress(address: string) {
		return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
			console.log('Search by: ', address);
			if (!address || !this.geocoder) {
				reject('Missing the address to search...');
			}
			this.geocoder.geocode({ address }, (results, status) => {
				if (status === 'OK') {
					console.log('Results:', results);
					console.log('Result: ', {
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng()
					});
					resolve({
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng()
					});
				} else {
					reject("Don't find any location... ");
				}
			});
		})
			.then((res) => res)
			.catch(() => null);
	}
}
