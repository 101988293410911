import { NOTIFICATION_STATE } from './../_consts/specification.dictionary';
// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { NotificationModel } from '../_models/notification.model';
import { Update } from '@ngrx/entity';

export enum NotificationActionTypes {
    NotificationOnServerCreated = '[Edit Notification Component] Notification On Server Created',
    NotificationCreated = '[Edit Notification Component] Notification Created',
    NotificationUpdated = '[Edit Notification Component] Notification Updated',
    NotificationsStateUpdated = '[Notifications List Page] Notifications State Updated',
    OneNotificationDeleted = '[Notifications List Page] One Notification Deleted',
    ManyNotificationsDeleted = '[Notifications List Page] Many Selected Notifications Deleted',
    NotificationsPageRequested = '[Notifications List Page] Notifications Page Requested',
    NotificationsPageLoaded = '[Notifications API] Notifications Page Loaded',
    NotificationsPageCancelled = '[Notifications API] Notifications Page Cancelled',
    NotificationsPageToggleLoading = '[Notifications] Notifications Page Toggle Loading',
    NotificationsActionToggleLoading = '[Notifications] Notifications Action Toggle Loading'
}

export class NotificationOnServerCreated implements Action {
    readonly type = NotificationActionTypes.NotificationOnServerCreated;
    constructor(public payload: {
		notification: NotificationModel,
		imageFile?: Blob // Image file for send to Server (through service)
	 }) { }
}

export class NotificationCreated implements Action {
    readonly type = NotificationActionTypes.NotificationCreated;
    constructor(public payload: { notification: NotificationModel }) { }
}

export class NotificationUpdated implements Action {
    readonly type = NotificationActionTypes.NotificationUpdated;
    constructor(public payload: {
        partialNotification: Update<NotificationModel>, // For State update
        notification: NotificationModel // For Server update (through service)
        imageFile?: Blob // Image file for Server update (through service)
    }) { }
}

export class NotificationsStateUpdated implements Action {
    readonly type = NotificationActionTypes.NotificationsStateUpdated;
    constructor(public payload: {
        notifications: NotificationModel[],
        state: NOTIFICATION_STATE
    }) { }
}

export class OneNotificationDeleted implements Action {
    readonly type = NotificationActionTypes.OneNotificationDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyNotificationsDeleted implements Action {
    readonly type = NotificationActionTypes.ManyNotificationsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class NotificationsPageRequested implements Action {
    readonly type = NotificationActionTypes.NotificationsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class NotificationsPageLoaded implements Action {
    readonly type = NotificationActionTypes.NotificationsPageLoaded;
    constructor(public payload: { notifications: NotificationModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class NotificationsPageCancelled implements Action {
    readonly type = NotificationActionTypes.NotificationsPageCancelled;
}

export class NotificationsPageToggleLoading implements Action {
    readonly type = NotificationActionTypes.NotificationsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class NotificationsActionToggleLoading implements Action {
    readonly type = NotificationActionTypes.NotificationsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type NotificationActions = NotificationOnServerCreated
| NotificationCreated
| NotificationUpdated
| NotificationsStateUpdated
| OneNotificationDeleted
| ManyNotificationsDeleted
| NotificationsPageRequested
| NotificationsPageLoaded
| NotificationsPageCancelled
| NotificationsPageToggleLoading
| NotificationsActionToggleLoading;
