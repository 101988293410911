import { Role } from './role.model';
import { BaseModel } from '../../_base/crud';
import { MediaFile } from '../../_base/crud/models/media-file.model';
import { PointOfInterest } from '../../point-of-interest/_models/point-of-interest.model';

export class User extends BaseModel {
	username: string;
	password: string;
	email: string;
	display_name: string;
	age: number;
	gender: 'male' | 'female';
	trial: boolean;
	provider: string;
	confirmed: boolean;
	blocked: boolean;
	role?: Role;
	pic?: MediaFile;
	point_of_interests?: PointOfInterest[];
	user_configs: object;

	clear(): void {
		this.id = undefined;
		this.username = '';
		this.password = '';
		this.email = '';
		this.age = null;
		this.gender = null;
		this.trial = true;
		this.provider = '';
		this.confirmed = false;
		this.blocked = false;
		this.role = null;
		this.pic = null;
		this.point_of_interests = null;
		this.user_configs = {};
	}
}
