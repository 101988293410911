import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface GalleryPicture {
	id: any; // this value can be an number (strapi ids) or string (reaction ids)
	serverId?: any; // this value can be an number (strapi ids) or string (reaction ids)
	name?: string;
	url: string | ArrayBuffer;
	mainPicture: boolean;
	file?: any;
}

@Component({
	selector: 'kt-gallery-picture',
	templateUrl: './gallery-picture.component.html',
	styleUrls: ['./gallery-picture.component.scss']
})
export class GalleryPictureComponent implements OnInit {
	@Input() picture: GalleryPicture;
	@Input() disabled = false;
	@Input() placeholderImagePath = './assets/media/bg/no-image.png';
	@Output() uploadFiles = new EventEmitter<FileList>();
	@Output() deletePicture = new EventEmitter<any>();
	@Output() markAsMainPicture = new EventEmitter<any>();

	constructor() {}

	ngOnInit() {}

	// @HostListener('change', ['$event.target.files'])
	emitFiles(event) {
		if (event.target.files.length > 0) {
			this.uploadFiles.next(event.target.files);
		}
	}
}
