import { GalleryPicture } from './../../../partials/content/widgets/gallery-picture/gallery-picture.component';
import { TranslateService } from '@ngx-translate/core';
import { UserRequested } from './../../../../core/auth/_actions/auth.actions';
import {
	LayoutUtilsService,
	MessageType
} from './../../../../core/_base/crud/utils/layout-utils.service';
import { MediaFile } from './../../../../core/_base/crud/models/media-file.model';
import { takeWhile } from 'rxjs/operators';
import { selectedPointOfInterest } from './../../../../core/auth/_selectors/auth.selectors';
import { PointOfInterestService } from './../../../../core/point-of-interest/_services/point-of-interest.service';
import { AppState } from './../../../../core/reducers/index';
import { Store, select } from '@ngrx/store';
import { Observable, BehaviorSubject } from 'rxjs';
import { PointOfInterest } from './../../../../core/point-of-interest/_models/point-of-interest.model';
import {
	Component,
	OnInit,
	ChangeDetectorRef,
	ElementRef,
	ViewChild,
	OnDestroy,
	HostListener
} from '@angular/core';
import { isNumber } from '../../../../core/_base/crud/utils/helper.function';
import { HttpErrorResponse } from '@angular/common/http';
import { MAX_IMAGE_SIZE } from '../../../../../app/views/partials/content/general/file-upload/avatar-file-upload.component';

export const MAX_IMAGES_COUNT = 5;

@Component({
	selector: 'kt-service-gallery',
	templateUrl: './service-gallery.component.html',
	styleUrls: ['./service-gallery.component.scss']
})
export class ServiceGalleryComponent implements OnInit, OnDestroy {
	inView = true;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	hasChanges = false;
	@ViewChild('mainPictureElem', { static: false }) mainPictureElem: ElementRef;
	@ViewChild('alert', { static: false }) set content(content: ElementRef) {
		if (!!content && !!content.nativeElement) {
			content.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.alertElement = content;
	}
	alertElement: ElementRef;
	showAlertMessage = false;
	alertMessage: {
		message?: string;
		type?: 'primary' | 'accent' | 'warn';
	} = {};

	constructor(
		private cdr: ChangeDetectorRef,
		private store: Store<AppState>,
		private pointOfInterestService: PointOfInterestService,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService
	) {}

	pointOfInterest$: Observable<PointOfInterest>;
	pointOfInterest: PointOfInterest = null;
	gallery: GalleryPicture[] = [];

	ngOnInit() {
		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterest$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterest) => {
				if (!!pointOfInterest) {
					// Get the most recent info from server
					this.pointOfInterest = await this.pointOfInterestService.getPointOfInterestById(
						pointOfInterest.id
					);
					this.initGallery();
					this.loadingSubject.next(false);
					this.cdr.detectChanges();
				} else {
					this.loadingSubject.next(false);
				}
			});
	}

	ngOnDestroy() {
		this.inView = false;
	}

	get mainPicture() {
		return (
			!!this.gallery && this.gallery.find((pic) => !!pic.url && !!pic.mainPicture)
		);
	}

	get otherPictures() {
		return (
			!!this.gallery && this.gallery.filter((pic) => !!pic.url && !pic.mainPicture)
		);
	}

	initGallery() {
		this.gallery = [];
		if (!!this.pointOfInterest) {
			// Main Picture
			if (!!this.pointOfInterest.mainImage) {
				this.gallery.push({
					id: this.pointOfInterest.mainImage.id,
					serverId: this.pointOfInterest.mainImage.id,
					name: this.pointOfInterest.mainImage.name,
					url: MediaFile.getFilePath(this.pointOfInterest.mainImage),
					mainPicture: true
				});
			}
			// Other pictures
			if (!!this.pointOfInterest.image) {
				this.pointOfInterest.image.forEach((image) => {
					this.gallery.push({
						id: image.id,
						serverId: image.id,
						name: image.name,
						url: MediaFile.getFilePath(image),
						mainPicture: false
					});
				});
			}
		}
	}

	/**
	 * Close alert
	 *
	 */
	onAlertClose($event) {
		this.showAlertMessage = false;
		this.alertMessage = {};
	}
	/**
	 * Show alert
	 *
	 */
	showAlert() {
		this.showAlertMessage = true;
		this.cdr.detectChanges();
	}

	get isValid() {
		return !!this.mainPicture;
	}

	save() {
		if (this.isValid) {
			const payload = this.prepareFormPayload();
			this.updatePointOfInterest(this.pointOfInterest.id, payload);
		}
	}

	prepareFormPayload() {
		const formData = new FormData();
		const data = { mainImage: null, image: [] };

		// -- Main Picture --
		// Main Picture is some of the already uploaded pictures
		if (!!this.mainPicture && !!this.mainPicture.serverId) {
			data.mainImage = this.mainPicture.serverId;
		} else if (!!this.mainPicture && !!this.mainPicture.file) {
			// Main Picture to be uploaded
			formData.append('files.mainImage', this.mainPicture.file);
		}

		// -- Other Pictures --
		this.otherPictures.forEach((picture) => {
			// Picture already exists on server
			if (!!picture.serverId) {
				data.image.push(picture.id);
			} else if (!!picture.file) {
				// New picture to be uploaded
				formData.append('files.image', picture.file);
			}
		});

		formData.append('data', JSON.stringify(data));

		return formData;
	}

	/**
	 * Update PointOfinterest
	 *
	 * @param pointOfInterestId: number
	 * @param payload: FormData
	 */
	updatePointOfInterest(id: number, payload: FormData) {
		if (!id || !payload) {
			return null;
		}

		this.loadingSubject.next(true);
		this.pointOfInterestService
			.updatePointOfInterest(id, payload)
			.then((res) => {
				this.loadingSubject.next(false);
				const message = 'POINT_OF_INTEREST.FORM.ALERTS.UPDATE_SUCCESS';
				this.layoutUtilsService.showActionNotification(message, MessageType.Update);
				this.refreshPointOfInterest(res[0]);
			})
			.catch((error) => {
				this.loadingSubject.next(false);
				if (error instanceof HttpErrorResponse) {
					this.alertMessage = {
						message: 'POINT_OF_INTEREST.FORM.ALERTS.UPDATE_ERROR',
						type: 'warn'
					};
					this.showAlert();
				}
			});
	}

	/**
	 * Refresh PointOfInterest
	 *
	 * @param pointOfInterest: PointOfInterest
	 */
	refreshPointOfInterest(pointOfInterest: PointOfInterest) {
		this.loadingSubject.next(false);
		if (!!pointOfInterest) {
			this.store.dispatch(new UserRequested());
			this.pointOfInterest = pointOfInterest;
			this.initGallery();
			this.hasChanges = false;
			this.cdr.detectChanges();
		}
	}

	markAsMainPicture(id: number, forceUpdate = true) {
		this.hasChanges = true;
		this.gallery.map((picture) => {
			picture.mainPicture = picture.id === id;
			return picture;
		});
		if (forceUpdate) {
			if (!!this.mainPictureElem && !!this.mainPictureElem.nativeElement) {
				this.mainPictureElem.nativeElement.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				});
			}
			this.cdr.detectChanges();
		}
	}

	deletePicture(id: number) {
		this.hasChanges = true;
		const picToDelete = !!this.gallery && this.gallery.find((pic) => pic.id === id);
		if (!!picToDelete) {
			// Need set another main picture
			if (!!picToDelete.mainPicture) {
				const newMain = this.gallery.find(
					(pic) => pic.id !== picToDelete.id && !!pic.url && !pic.mainPicture
				);
				if (!!newMain) {
					this.markAsMainPicture(newMain.id, false);
				}
			}
			const index = this.gallery.findIndex((pic) => pic.id === picToDelete.id);
			this.gallery.splice(index, 1);
		}
		this.cdr.detectChanges();
	}

	get canAddMorePictures() {
		return !!this.gallery && this.gallery.length < MAX_IMAGES_COUNT;
	}

	async uploadFiles(files: FileList) {
		if (!files) {
			return;
		}
		this.hasChanges = true;
		let last_id = Math.max.apply(
			Math,
			this.gallery.map((p) => p.id)
		);
		last_id = isNumber(last_id) ? last_id : 0;

		const ignored_files = [];
		for (let i = 0; i < files.length; i++) {
			if (
				files.item(i).size <= MAX_IMAGE_SIZE &&
				this.gallery.length < MAX_IMAGES_COUNT
			) {
				const data = await this.readFile(files.item(i));
				this.gallery.push({
					id: last_id + i + 1,
					name: files.item(i).name,
					url: data,
					mainPicture: false,
					file: files.item(i)
				});
			} else {
				ignored_files.push(files.item(i));
			}
		}

		// Need set one MainPicture
		if (!this.mainPicture && this.gallery && this.gallery.length) {
			this.markAsMainPicture(this.gallery[0].id, false);
		}

		if (ignored_files.length > 0) {
			const ignoredFiles_str = Object.values(ignored_files).reduce(
				(acc, { name }) => {
					acc = acc ? acc + ', ' + name : name;
					return acc;
				},
				''
			);
			this.alertMessage = {
				message: this.translate.instant(
					'POINT_OF_INTEREST.FORM.ALERTS.UPLOAD_FILES_ERROR',
					{
						ignoreFiles: ignoredFiles_str
					}
				),
				type: 'warn'
			};
			this.showAlert();
		}
		this.cdr.detectChanges();
	}

	readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}
}
