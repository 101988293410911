import { StickyDirective } from './../../../../core/_base/layout/directives/sticky.directive';
import { DomSanitizer } from '@angular/platform-browser';
import { OffcanvasOptions } from './../../../../core/_base/layout/directives/offcanvas.directive';
import {
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	HostBinding,
	HostListener,
	OnInit,
	OnDestroy,
	AfterViewInit,
	ElementRef,
	Inject,
	PLATFORM_ID
} from '@angular/core';

const DEFAULT_ICON = `
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<rect x="0" y="0" width="24" height="24"/>
			<path d="M20.5,11 L22.5,11 C23.3284271,11 24,11.6715729 24,12.5 C24,13.3284271 23.3284271,14 22.5,14 L20.5,14 C19.6715729,14 19,13.3284271 19,12.5 C19,11.6715729 19.6715729,11 20.5,11 Z M1.5,11 L3.5,11 C4.32842712,11 5,11.6715729 5,12.5 C5,13.3284271 4.32842712,14 3.5,14 L1.5,14 C0.671572875,14 1.01453063e-16,13.3284271 0,12.5 C-1.01453063e-16,11.6715729 0.671572875,11 1.5,11 Z" fill="#000000" opacity="0.3"/>
			<path d="M12,16 C13.6568542,16 15,14.6568542 15,13 C15,11.3431458 13.6568542,10 12,10 C10.3431458,10 9,11.3431458 9,13 C9,14.6568542 10.3431458,16 12,16 Z M12,18 C9.23857625,18 7,15.7614237 7,13 C7,10.2385763 9.23857625,8 12,8 C14.7614237,8 17,10.2385763 17,13 C17,15.7614237 14.7614237,18 12,18 Z" fill="#000000" fill-rule="nonzero"/>
		</g>
	</svg>`;

export interface AsideMenu {
	id: any;
	title?: string;
	route?: string[];
	params?: any;
	iconSVG?: string;
	icon?: string;
	disabled?: boolean;
	badge?: {
		icon: string;
		tooltip?: string;
	};
}

@Component({
	selector: 'kt-page-aside',
	templateUrl: './page-aside.component.html',
	styleUrls: ['./page-aside.component.scss']
})
export class PageAsideComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() menus: AsideMenu[] = [];
	@Input() svgIcons: { id: any; icon: string }[] = [];
	@Input() activeMenu: any;
	@Input() headInfo: {
		title: string;
		description?: string;
		picture?: string;
		state?: { icon: string; tooltip: string };
	};
	@Output() menuClick = new EventEmitter<any>();
	// enable sticky portlet header
	@Input() sticky: boolean;

	menuCanvasOptions: OffcanvasOptions = {
		baseClass: 'kt-app__aside',
		overlay: true,
		closeBy: 'kt_page_aside-close',
		toggleBy: {
			target: 'kt_page_aside_toggle',
			state: 'kt-header-mobile__toolbar-toggler--active'
		}
	};

	// Sticky data
	@HostBinding('attr.ktSticky') stickyDirective: StickyDirective;
	private lastScrollTop = 0;
	private isScrollDown = false;

	constructor(
		private sanitizer: DomSanitizer,
		private cdr: ChangeDetectorRef,
		private el: ElementRef,
		@Inject(PLATFORM_ID) private platformId: string
	) {
		this.stickyDirective = new StickyDirective(this.el, this.platformId);
	}
	// constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platformId: string, private ktDialogService: KtDialogService) {
	// 	this.stickyDirective = new StickyDirective(this.el, this.platformId);
	// }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (this.sticky) {
			this.stickyDirective.ngOnInit();
		}
	}

	ngAfterViewInit(): void {
		if (this.sticky) {
			this.updateStickyPosition();
			this.stickyDirective.ngAfterViewInit();
		}
	}

	ngOnDestroy(): void {
		if (this.sticky) {
			this.stickyDirective.ngOnDestroy();
		}
	}

	getIcon(menu: AsideMenu) {
		let res = '';
		if (!menu) {
			res = DEFAULT_ICON;
		} else if (menu.iconSVG) {
			res = menu.iconSVG;
		} else if (menu.icon) {
			res = `<i class="${menu.icon} kt-padding-5"></i>`;
		} else {
			res = DEFAULT_ICON;
		}
		return this.sanitizer.bypassSecurityTrustHtml(res);
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.updateStickyPosition();
	}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		this.updateStickyPosition();
		const st = window.pageYOffset || document.documentElement.scrollTop;
		this.isScrollDown = st > this.lastScrollTop;
		this.lastScrollTop = st <= 0 ? 0 : st;
	}

	updateStickyPosition() {
		if (this.sticky) {
			Promise.resolve(null).then(() => {
				// get boundary top margin for sticky header
				const headerElement = document.querySelector('.kt-header') as HTMLElement;
				const subheaderElement = document.querySelector(
					'.kt-subheader'
				) as HTMLElement;
				const headerMobileElement = document.querySelector(
					'.kt-header-mobile'
				) as HTMLElement;

				let height = 0;

				if (headerElement != null) {
					// mobile header
					if (window.getComputedStyle(headerElement).height === '0px') {
						height += headerMobileElement.offsetHeight;
					} else {
						// desktop header
						if (document.body.classList.contains('kt-header--minimize-topbar')) {
							// hardcoded minimized header height
							height = 60;
						} else {
							// normal fixed header
							if (document.body.classList.contains('kt-header--fixed')) {
								height += headerElement.offsetHeight;
							}
							if (document.body.classList.contains('kt-subheader--fixed')) {
								height += subheaderElement.offsetHeight;
							}
						}
					}
				}

				this.stickyDirective.marginTop = height;
			});
		}
	}

	getImageUri(relativePath: string) {}
}
