import { User } from './../../../../core/auth/_models/user.model';
import { PointOfInterest } from './../../../../core/point-of-interest/_models/point-of-interest.model';
import {
	selectedPointOfInterest,
	currentUser
} from './../../../../core/auth/_selectors/auth.selectors';
import { TranslationService } from './../../../../core/_base/layout/services/translation.service';
import {
	NOTIFICATION_TYPE,
	NOTIFICATION_STATE
} from './../../../../core/point-of-interest/_consts/specification.dictionary';
import { MediaFile } from './../../../../core/_base/crud/models/media-file.model';
import { TranslationField } from './../../../../core/_base/crud/models/translation-field.model';
import {
	NgbDateStruct,
	NgbDateParserFormatter,
	NgbCalendar
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
// Angular
import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	OnDestroy,
	ChangeDetectorRef,
	ViewChild,
	ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { takeWhile, take } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Layout
// CRUD
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud';
// Services and Models
import {
	NotificationModel,
	NotificationsService
} from '../../../../core/point-of-interest';
import {
	dateAfter,
	dateBefore,
	dateEquals,
	dateToEnvironmentFormat,
	markAllFormAs,
	toISODate,
	toNgbDateStruct,
	toDate,
	objectPathExists
} from '../../../../core/_base/crud/utils/helper.function';
import { HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
// Lodash
import { chain } from 'lodash';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-notification-form',
	templateUrl: './notification-form.component.html',
	styleUrls: ['./notification-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationFormComponent implements OnInit, OnDestroy {
	NOTIFICATION_STATE = NOTIFICATION_STATE;
	editMode = false;
	multiLanguage = false;

	// DatePicker data
	minDate = null;
	maxDate = null;
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	blockedDays = [];

	// Alert panel
	@ViewChild('alert', { static: false }) set content(content: ElementRef) {
		if (!!content && !!content.nativeElement) {
			content.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.alertElement = content;
	}
	alertElement: ElementRef;
	showAlertMessage = false;
	alertMessage: {
		message?: string;
		type?: 'primary' | 'accent' | 'warn' | 'info';
		duration?: number;
	} = {};

	// Public properties
	notificationForm: FormGroup;
	notification: NotificationModel;

	// Current User (used to get the user limits)
	user$: Observable<User>;
	currentUser: User;

	// ---------------------------------------------------------------------------------------------------------

	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;

	// Private
	private oldNotification: NotificationModel;
	private componentSubscriptions: Subscription;
	private pointOfInterest$: Observable<PointOfInterest>;
	private pointOfInterestId;
	private inView = true;
	// Datepicker auxiliar methods
	isHovered = (date) =>
		this.fromDate &&
		!this.toDate &&
		this.hoveredDate &&
		dateAfter(date, this.fromDate) &&
		dateBefore(date, this.hoveredDate);

	isBlocked = (date) => {
		if (!!this.blockedDays) {
			return !!this.blockedDays.find((day) =>
				dateEquals(toNgbDateStruct(day.day), date)
			);
		}
		return false;
	};

	isInside = (date) =>
		dateAfter(date, this.fromDate) && dateBefore(date, this.toDate);

	isFrom = (date) => dateEquals(date, this.fromDate);
	isTo = (date) => dateEquals(date, this.toDate);
	getInputDateValue(
		startDate: Date | string | NgbDateStruct,
		endDate: Date | string | NgbDateStruct
	) {
		return !startDate && !endDate
			? null
			: (!!startDate ? dateToEnvironmentFormat(startDate) : '') +
					' ' +
					this.translate.instant('GENERIC.TO') +
					' ' +
					(!!endDate ? dateToEnvironmentFormat(endDate) : '');
	}

	/**
	 * Component constructor
	 *
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private translate: TranslateService,
		private translationService: TranslationService,
		private notificationFB: FormBuilder,
		public dialog: MatDialog,
		private layoutUtilsService: LayoutUtilsService,
		private notificationService: NotificationsService,
		private cdr: ChangeDetectorRef,
		public formatter: NgbDateParserFormatter,
		protected calendar: NgbCalendar
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	async ngOnInit() {
		this.user$ = this.store.pipe(select(currentUser));
		this.user$.pipe(takeWhile(() => this.inView)).subscribe((user) => {
			if (!!user) {
				this.currentUser = user;
			}
		});
		// Get the current selected point_of_interest
		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterest$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterest) => {
				if (pointOfInterest) {
					if (
						!!this.pointOfInterestId &&
						this.pointOfInterestId !== pointOfInterest.id
					) {
						// If the point of interest changed need redirect to the Notification List dash.
						this.goBackWithoutId();
					} else {
						this.pointOfInterestId = pointOfInterest.id;

						const notificationId = this.activatedRoute.snapshot.paramMap.get('id');
						if (!!notificationId) {
							this.editMode = true;
							this.loadNotificationFromService(notificationId);
						} else {
							this.editMode = false;
							const newNotification = new NotificationModel();
							newNotification.clear();
							// When create an new Notification the initial state is active;
							newNotification.state = NOTIFICATION_STATE.Active;
							this.loadNotification(newNotification, true);
						}
					}
				}
			});

		this.minDate = this.calendar.getToday();
		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
	}

	get maxDaysRange() {
		let value = objectPathExists(
			this.currentUser,
			'user_configs.notifications_max_date_range_in_days',
			true
		);
		value = parseInt(value);
		return !isNaN(value) ? value : null;
	}

	get maxActiveNotificationsPerDay() {
		let value = objectPathExists(
			this.currentUser,
			'user_configs.notifications_max_active_per_day',
			true
		);
		value = parseInt(value);
		return !isNaN(value) ? value : null;
	}

	dayTooltip(date) {
		let res = '';
		if (this.isBlocked(date)) {
			res = this.translate.instant(
				'NOTIFICATIONS.FORM.DETAILS_SECTION.BLOCKED_DAY.MSG'
			);
			if (!!this.maxActiveNotificationsPerDay) {
				res += this.translate.instant(
					'NOTIFICATIONS.FORM.DETAILS_SECTION.BLOCKED_DAY.MAX_VALUE',
					{ max_value: this.maxActiveNotificationsPerDay }
				);
			}
		}
		return res;
	}
	onDateChange(date: NgbDateStruct) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && dateAfter(date, this.fromDate)) {
			this.toDate = date;
		} else {
			this.fromDate = date;
			this.toDate = null;
		}

		const from_date_str = toISODate(this.fromDate, false);
		this.notificationForm.get('valid_from').setValue(from_date_str);
		let endDate: Date;
		if (!this.toDate) {
			endDate = toDate(this.fromDate) || null;
			if (!!this.maxDaysRange) {
				this.maxDate = toNgbDateStruct(
					moment(from_date_str as string)
						.add(this.maxDaysRange - 1, 'days')
						.toDate()
				);
			}
		} else {
			endDate = toDate(this.toDate) || null;
			this.maxDate = null;
		}
		endDate.setUTCHours(23, 59, 59);
		this.notificationForm.get('valid_to').setValue(toISODate(endDate, false));
		this.notificationForm.get('valid_from').markAsDirty();
		this.notificationForm.get('valid_from').markAsTouched();
		this.notificationForm.get('valid_to').markAsDirty();
		this.notificationForm.get('valid_to').markAsTouched();

		this.updateAllowEdit();
	}

	multiLanguageToggle() {
		this.multiLanguage = !this.multiLanguage;
	}

	async loadNotification(notification, fromService: boolean = false) {
		if (!notification) {
			this.goBackWithoutId();
		}
		this.notificationService
			.notAvailableDays({
				point_of_interest: this.pointOfInterestId,
				notification_nin: notification.id,
				from: moment().format('YYYY-MM-DDTHH:MM')
			})
			.then((res) => (this.blockedDays = res));

		this.notification = notification;
		this.oldNotification = Object.assign({}, notification);
		this.initNotification();
		if (fromService) {
			this.cdr.detectChanges();
		}
	}

	// Get the notification from the server
	async loadNotificationFromService(notificationId) {
		this.notificationService
			.getNotificationById(notificationId, false)
			.subscribe((res) => {
				this.loadNotification(res, true);
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		this.inView = false;
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	/**
	 * Init notification
	 */
	initNotification() {
		this.createForm();
		this.loadingSubject.next(false);
		// Init dates
		if (!!this.notification) {
			if (!!this.notification.valid_from) {
				this.fromDate = toNgbDateStruct(this.notification.valid_from) || null;
			}
			if (!!this.notification.valid_to) {
				this.toDate = toNgbDateStruct(this.notification.valid_to) || null;
			}
		}
		this.updateAllowEdit();
	}

	updateAllowEdit() {
		// If the dates is in past the form is bloked until change the dates
		const valid_to =
			!!this.notificationForm.get('valid_to') &&
			!!this.notificationForm.get('valid_to').value &&
			moment(this.notificationForm.get('valid_to').value).toDate();

		if (!!valid_to && valid_to < moment().toDate()) {
			this.disableForm();
		} else {
			this.enableForm();
		}
	}

	disableForm() {
		this.notificationForm.disable();
		this.alertMessage = {
			message: 'NOTIFICATIONS.FORM.ALERTS.RANGE_IN_PAST',
			type: 'info',
			duration: 0
		};
		this.showAlert();
	}

	enableForm() {
		this.notificationForm.enable();
		this.onAlertClose(null);
	}

	/**
	 * Create form
	 */
	createForm() {
		if (!this.notification) {
			return;
		}

		let image = '';
		if (this.notification.image) {
			image = MediaFile.getFilePath(this.notification.image);
		}

		this.notificationForm = this.notificationFB.group({
			id: [this.notification.id],
			point_of_interest: [this.notification.point_of_interest],
			type: [NOTIFICATION_TYPE.Offer],
			state: [
				this.notification.state === NOTIFICATION_STATE.Active,
				Validators.required
			],
			valid_from: [this.notification.valid_from, Validators.required],
			valid_to: [this.notification.valid_to, Validators.required],
			title: this.notificationFB.group({
				pt: [
					(this.notification.title as TranslationField).pt,
					[Validators.required, Validators.minLength(5), Validators.maxLength(75)]
				],
				en: [
					(this.notification.title as TranslationField).en,
					[Validators.minLength(5), Validators.maxLength(75)]
				]
			}),
			description: this.notificationFB.group({
				pt: [
					(this.notification.description as TranslationField).pt,
					[Validators.required, Validators.maxLength(255)]
				],
				en: [
					(this.notification.description as TranslationField).en,
					[Validators.maxLength(255)]
				]
			}),
			image: [image, !this.editMode ? Validators.required : null]
		});
	}

	get defaultImg() {
		if (!this.notification || (!!this.notification && !this.notification.image)) {
			return './assets/media/bg/no-image.png';
		} else {
			return MediaFile.getFilePath(this.notification.image);
		}
	}
	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		this.router.navigate(['/notifications/edit/', id]);
	}

	goBackWithoutId(force: boolean = true) {
		if (!force && !!this.notificationForm.dirty) {
			const title = 'GO_BACK_MODAL.TITLE';
			const description = 'GO_BACK_MODAL.MESSAGE';

			const dialogRef = this.layoutUtilsService.goBack(title, description);
			dialogRef.afterClosed().subscribe((res) => {
				if (!res) {
					return;
				}
				this.router.navigate(['/notifications']);
			});
		} else {
			this.router.navigate(['/notifications']);
		}
	}

	/**
	 * Refresh notification
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshNotification(isNew: boolean = false, notification?: NotificationModel) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.loadNotification(notification, true);
			return;
		}
		if (!!notification && !!notification.id) {
			url = `/notifications/edit/${notification.id}`;
		} else {
			url = `/notifications`;
		}
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Returns Portlet title
	 */
	getPortletTitle() {
		if (!this.notification || (!!this.notification && !this.notification.title)) {
			return '';
		}
		if (typeof this.notification.title === 'string') {
			return this.notification.title;
		} else {
			const lang = this.translationService.getSelectedLanguage();
			return TranslationField.getTranslationOfField(this.notification.title, lang);
		}
	}

	/**
	 * Close alert
	 *
	 */
	onAlertClose($event) {
		this.showAlertMessage = false;
		this.alertMessage = {};
	}
	/**
	 * Show alert
	 *
	 */
	showAlert() {
		this.showAlertMessage = true;
		this.cdr.detectChanges();
	}

	/**
	 * Returns the error of form field
	 */
	hasError(field: string, error: string) {
		const control = this.notificationForm.get(field);
		if (!control) {
			return false;
		}
		return control.touched && control.hasError(error);
	}

	/**
	 * Returns the errors of form field
	 */
	getErrors(field: string) {
		const control = this.notificationForm.get(field);
		if (!control) {
			return false;
		}
		return control.touched && control.errors;
	}

	/**
	 * Reset to initial state
	 */
	reset() {
		this.notification = Object.assign({}, this.oldNotification);
		this.createForm();
		this.showAlertMessage = false;
		this.alertMessage = {};
		markAllFormAs(this.notificationForm, 'pristine');
		markAllFormAs(this.notificationForm, 'untouched');
		this.notificationForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		/** check form */
		if (this.notificationForm.invalid) {
			markAllFormAs(this.notificationForm, 'touched');
			return;
		}
		if (this.notificationForm.pristine) {
			this.alertMessage = {
				message: 'NOTIFICATIONS.FORM.ALERTS.NOTHING_TO_SAVE',
				type: 'primary',
				duration: 10000
			};
			this.showAlert();
			return;
		}

		const editedNotification: {
			notification: NotificationModel;
			imageFile?: Blob;
		} = this.prepareNotification();
		if (
			!editedNotification ||
			(!!editedNotification && !editedNotification.notification)
		) {
			console.log('Missing the information to create or update the notification!!');
			return;
		}
		if (editedNotification.notification.id > 0) {
			this.updateNotification(editedNotification, withBack);
			return;
		}

		this.addNotification(editedNotification, withBack);
	}

	/**
	 * Returns object for saving
	 *
	 */
	prepareNotification(): { notification: NotificationModel; imageFile?: Blob } {
		if (!this.pointOfInterestId || this.notificationForm.pristine) {
			return null;
		}

		const _notification = new NotificationModel();
		_notification.point_of_interest = this.pointOfInterestId;
		// ID
		_notification.id =
			this.notificationForm.get('id') && this.notificationForm.get('id').value;
		// Type
		_notification.type = this.notificationForm.get('type').value;

		// State
		_notification.state = this.notificationForm.get('state').value
			? NOTIFICATION_STATE.Active
			: NOTIFICATION_STATE.Inactive;
		// Title
		_notification.title = new TranslationField();
		_notification.title.clear();
		_notification.title.pt = this.notificationForm.get('title.pt').value;
		_notification.title.en = this.notificationForm.get('title.en').value;

		// Description
		_notification.description = new TranslationField();
		_notification.description.clear();
		_notification.description.pt = this.notificationForm.get('description.pt').value;
		_notification.description.en = this.notificationForm.get('description.en').value;

		// Valid Dates
		_notification.valid_from = this.notificationForm.get('valid_from').value;
		_notification.valid_to = this.notificationForm.get('valid_to').value;

		const imageControl = this.notificationForm.get('image');
		let imageFile = null;
		if (imageControl && !imageControl.pristine) {
			imageFile = imageControl.value;
		}

		return { notification: _notification, imageFile };
	}

	/**
	 * Add notification
	 *
	 * @param _notification: NotificationModel
	 * @param withBack: boolean
	 */
	addNotification(
		data: { notification: NotificationModel; imageFile?: Blob },
		withBack: boolean = false
	) {
		if (!data || (!!data && !data.notification)) {
			return null;
		}

		this.loadingSubject.next(true);
		this.notificationService
			.createNotification(data.notification, data.imageFile)
			.then((res) => {
				this.loadingSubject.next(false);
				const message = 'NOTIFICATIONS.FORM.ALERTS.CREATE_SUCCESS';
				this.layoutUtilsService.showActionNotification(message, MessageType.Create);
				if (withBack) {
					this.goBackWithoutId();
				} else {
					this.refreshNotification(true, res);
				}
			})
			.catch((error) => {
				this.loadingSubject.next(false);
				if (error instanceof HttpErrorResponse) {
					const hasAnyFormError = this.updateServerErrorOnForm(
						!!error.error && error.error.message
					);
					let message = '';
					if (!hasAnyFormError) {
						message = 'NOTIFICATIONS.FORM.ALERTS.CREATE_ERROR';
					} else {
						message = 'NOTIFICATIONS.FORM.ALERTS.FORM_ERROR';
					}
					this.alertMessage = {
						message,
						type: 'warn',
						duration: 10000
					};
					this.showAlert();
				}
			});
	}

	/**
	 * Update notification
	 *
	 * @param _notification: NotificationModel
	 * @param withBack: boolean
	 */
	updateNotification(
		data: { notification: NotificationModel; imageFile?: Blob },
		withBack: boolean = false
	) {
		if (!data || (!!data && !data.notification)) {
			return null;
		}
		this.loadingSubject.next(true);
		this.notificationService
			.updateNotification(data.notification, data.imageFile)
			.then((res) => {
				this.loadingSubject.next(false);
				const message = 'NOTIFICATIONS.FORM.ALERTS.UPDATE_SUCCESS';
				this.layoutUtilsService.showActionNotification(message, MessageType.Update);
				if (withBack) {
					this.goBackWithoutId();
				} else {
					this.refreshNotification(false);
				}
			})
			.catch((error) => {
				this.loadingSubject.next(false);
				if (error instanceof HttpErrorResponse) {
					const hasAnyFormError = this.updateServerErrorOnForm(
						!!error.error && error.error.message
					);
					let message = '';
					if (!hasAnyFormError) {
						message = 'NOTIFICATIONS.FORM.ALERTS.UPDATE_ERROR';
					} else {
						message = 'NOTIFICATIONS.FORM.ALERTS.FORM_ERROR';
					}
					this.alertMessage = {
						message,
						type: 'warn',
						duration: 10000
					};
					this.showAlert();
				}
			});
	}

	// Add the server errors to the reactive form
	private updateServerErrorOnForm(errorMessages) {
		let anyError = false;
		if (!!errorMessages) {
			this.notificationForm.updateValueAndValidity();
			const groupedErrors = chain(errorMessages)
				.groupBy('field')
				.value();

			Object.keys(groupedErrors).forEach((prop) => {
				const formControl = this.notificationForm.get(prop);
				if (!!formControl && !!groupedErrors[prop]) {
					anyError = true;
					let errorObject = {};
					groupedErrors[prop].forEach((error) => {
						errorObject = {
							...errorObject,
							[error.code]: error.values
						};
					});

					formControl.setErrors(errorObject);
					formControl.markAsTouched();
				}
			});
		}

		return anyError;
	}
}
