import { filter } from 'rxjs/operators';


export class TranslationField {
	id?: string;
	pt?: string;
	en?: string;

	constructor() {
		this.clear();
	}

	public static getTranslationOfField(field: TranslationField, lang?: string): string {
		if (!!lang && !!field && field[lang]) {  return field[lang]; }

		const availableKey = Object.keys(field).find( key => key !== 'id' && !!field[key]);

		return !!availableKey && field[availableKey] || '';
	}

	clear() {
		this.id = undefined;
		this.pt = undefined;
		this.en = undefined;
	}
}
