import { selectNotificationsInitWaitingMessage } from './../_selectors/notification.selectors';
// RxJS
import { delay, distinctUntilChanged } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
// Selectors
import { selectNotificationsInStore, selectNotificationsPageLoading } from '../_selectors/notification.selectors';

export class NotificationsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectNotificationsPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectNotificationsInitWaitingMessage)
		);

		this.store.pipe(
			select(selectNotificationsInStore)
		).subscribe((response: QueryResultsModel) => {
			if (response) {
				this.paginatorTotalSubject.next(response.totalCount);
				this.entitySubject.next(response.items);
			}
		});
	}
}
