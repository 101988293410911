import { ProductModel } from './../_models/product.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, catchError } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Services
import { ProductsService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
	ProductActionTypes,
	ProductsPageRequested,
	ProductsPageLoaded,
	ProductsPageToggleLoading
} from '../_actions/product.actions';
import { of } from 'rxjs';

@Injectable()
export class ProductEffects {
	showPageLoadingDistpatcher = new ProductsPageToggleLoading({
		isLoading: true
	});
	showLoadingDistpatcher = new ProductsPageToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new ProductsPageToggleLoading({
		isLoading: false
	});

	@Effect()
	loadProductsPage$ = this.actions$.pipe(
		ofType<ProductsPageRequested>(ProductActionTypes.ProductsPageRequested),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showPageLoadingDistpatcher);
			const requestToServer = this.productsService.findProducts(payload.page).pipe(
				catchError((error) => {
					console.error("Can't find the product!", error);
					return of(false);
				})
			);
			const lastQuery = of(payload.page);
			return forkJoin(requestToServer, lastQuery);
		}),
		map((response) => {
			const results: {
				totalCount: number;
				nodes: ProductModel[]; //TODO: need ajust the type
			} = response[0] as any;
			const lastQuery: QueryParamsModel = response[1];
			return new ProductsPageLoaded({
				products: results ? results.nodes : [],
				totalCount: results ? results.totalCount : 0,
				page: lastQuery
			});
		})
	);

	constructor(
		private actions$: Actions,
		private productsService: ProductsService,
		private store: Store<AppState>
	) {}
}
