import gql from 'graphql-tag';
import Product from './../fragments/productWithVariants';

const createProductMutation = gql`
	mutation createProduct($shopId: ID!, $shouldCreateFirstVariant: Boolean) {
		createProduct(
			input: { shopId: $shopId, shouldCreateFirstVariant: $shouldCreateFirstVariant }
		) {
			product {
				...Product
			}
			__typename
		}
	}
	${Product}
`;

export default createProductMutation;
