import { NotificationModel } from './../_models/notification.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, catchError } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Services
import { NotificationsService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
	NotificationActionTypes,
	NotificationsPageRequested,
	NotificationsPageLoaded,
	ManyNotificationsDeleted,
	OneNotificationDeleted,
	NotificationsPageToggleLoading,
	NotificationsStateUpdated,
	NotificationUpdated,
	NotificationCreated,
	NotificationOnServerCreated
} from '../_actions/notification.actions';
import { of } from 'rxjs';

@Injectable()
export class NotificationEffects {
	showPageLoadingDistpatcher = new NotificationsPageToggleLoading({
		isLoading: true
	});
	showLoadingDistpatcher = new NotificationsPageToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new NotificationsPageToggleLoading({
		isLoading: false
	});

	@Effect()
	loadNotificationsPage$ = this.actions$.pipe(
		ofType<NotificationsPageRequested>(
			NotificationActionTypes.NotificationsPageRequested
		),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showPageLoadingDistpatcher);
			const requestToServer = this.notificationsService
				.findNotifications(payload.page)
				.pipe(
					catchError((error) => {
						console.error('Can\'t find the notification!', error);
						return of(false);
					})
				);
			const lastQuery = of(payload.page);
			return forkJoin(requestToServer, lastQuery);
		}),
		map((response) => {
			const results: {totalCount: number, entities: NotificationModel[]} = response[0] as any;
			const lastQuery: QueryParamsModel = response[1];
			return new NotificationsPageLoaded({
				notifications: results ? results.entities : [],
				totalCount: results ? results.totalCount : 0,
				page: lastQuery
			});
		})
	);

	@Effect()
	deleteNotification$ = this.actions$.pipe(
		ofType<OneNotificationDeleted>(NotificationActionTypes.OneNotificationDeleted),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showLoadingDistpatcher);
			return this.notificationsService.deleteNotification(payload.id).pipe(
				catchError((error) => {
					console.error('Can\'t delete the notification!', error);
					return of(false);
				})
			);
		}),
		map((result) => {
			return this.hideActionLoadingDistpatcher;
		})
	);

	@Effect()
	updateNotification$ = this.actions$.pipe(
		ofType<NotificationUpdated>(NotificationActionTypes.NotificationUpdated),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showLoadingDistpatcher);
			return this.notificationsService
				.updateNotification(payload.notification, payload.imageFile)
				.then(res => res);
				// .pipe(
				// 	catchError((error) => {
				// 		console.error("Can't update the notification!", error);
				// 		return of(false);
				// 	})
				// );
		}),
		map((result) => {
			return this.hideActionLoadingDistpatcher;
		})
	);

	@Effect()
	createNotification$ = this.actions$.pipe(
		ofType<NotificationOnServerCreated>(
			NotificationActionTypes.NotificationOnServerCreated
		),
		mergeMap(({ payload }) => {
			if (!payload) {
				return of(null);
			}
			this.store.dispatch(this.showLoadingDistpatcher);
			return this.notificationsService
				.createNotification(payload.notification, payload.imageFile)
				.then( res => {
					if (!!res) {
						this.store.dispatch(new NotificationCreated({ notification: res }));
					}
					return res;
				});
		}),
		map((result) => {
			return this.hideActionLoadingDistpatcher;
		})
	);

	constructor(
		private actions$: Actions,
		private notificationsService: NotificationsService,
		private store: Store<AppState>
	) {}
}
