import { NotificationsService } from './../../../core/point-of-interest/_services/notifications.service';
import {
	LayoutUtilsService,
	MessageType
} from './../../../core/_base/crud/utils/layout-utils.service';
import { selectedPointOfInterest } from './../../../core/auth/_selectors/auth.selectors';
import { PointOfInterestService } from './../../../core/point-of-interest/_services/point-of-interest.service';
import { NotificationModel } from './../../../core/point-of-interest/_models/notification.model';
import { PointOfInterest } from './../../../core/point-of-interest/_models/point-of-interest.model';
import { takeWhile } from 'rxjs/operators';
import { AppState } from './../../../core/reducers/index';
import { select, Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { POI_STATE } from './../../../core/point-of-interest';

@Component({
	selector: 'kt-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
	inView = true;
	pointOfInterest$: Observable<PointOfInterest>;
	activeNotification: NotificationModel = null;
	pointOfInterest_id = null;

	constructor(
		private store: Store<AppState>,
		private pointOfInterestService: PointOfInterestService,
		private notificationsService: NotificationsService,
		private layoutUtilsService: LayoutUtilsService,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterest$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterest) => {
				if (pointOfInterest && pointOfInterest.state === POI_STATE.Active) {
					/** For now don't exists active notification */
					// this.activeNotification = await this.getActiveNotification(
					// 	pointOfInterest.id
					// );
					this.pointOfInterest_id = pointOfInterest.id;
				} else {
					this.activeNotification = null;
					this.pointOfInterest_id = null;
				}
				this.cd.detectChanges();
			});
	}
	ngOnDestroy() {
		this.inView = false;
	}

	async getActiveNotification(poi: number) {
		if (!!poi) {
			return await this.pointOfInterestService.getActiveNotification(poi);
		}
		return null;
	}

	/**
	 * Delete notification
	 *
	 * @param item: NotificationModel
	 */
	deleteActiveNotification(item: NotificationModel) {
		const title = 'NOTIFICATIONS.DELETE_MODAL.TITLE';
		const description = 'NOTIFICATIONS.DELETE_MODAL.DESCRIPTION';
		const waitDescription = 'NOTIFICATIONS.DELETE_MODAL.WAIT_DESCRIPTION';
		const deleteMessage = 'NOTIFICATIONS.DELETE_MODAL.DELETE_MESSAGE';
		const dialogRef = this.layoutUtilsService.deleteElement(
			title,
			description,
			waitDescription
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}
			this.notificationsService
				.deleteNotification(item.id)
				.toPromise()
				.then(async (result) => {
					if (!!result) {
						this.activeNotification = await this.getActiveNotification(
							this.pointOfInterest_id
						);
						this.layoutUtilsService.showActionNotification(
							deleteMessage,
							MessageType.Delete
						);
						this.cd.detectChanges();
					} else {
						console.error("Can't delete the active notification");
					}
				})
				.catch((error) => {
					console.error("Can't delete the active notification");
				});
		});
	}
}
