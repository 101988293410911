import { FileUploadModule } from './../../partials/content/general/file-upload/file-upload.module';
import { LayoutUtilsService } from './../../../core/_base/crud/utils/layout-utils.service';
import { TypesUtilsService } from './../../../core/_base/crud/utils/types-utils.service';
import { HttpUtilsService } from './../../../core/_base/crud/utils/http-utils.service';
// import { InterceptService } from './../../../core/_base/crud/';
import { notificationsReducer } from '../../../core/point-of-interest';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { NotificationsComponent } from './notifications.component';
import {
	MatSelectModule,
	MatDialogModule,
	MatIconModule,
	MatSortModule,
	MatTableModule,
	MatCheckboxModule,
	MatMenuModule,
	MatProgressSpinnerModule,
	MatPaginatorModule,
	MatButtonModule,
	MatInputModule,
	MatAutocompleteModule,
	MatRadioModule,
	MatNativeDateModule,
	MatProgressBarModule,
	MatDatepickerModule,
	MatCardModule,
	MatSnackBarModule,
	MatTabsModule,
	MatTooltipModule,
	MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material';
import { NgbProgressbarModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from '../../../core/point-of-interest';
import { HttpClientModule } from '@angular/common/http';
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent,
	ConfirmationDialogComponent
} from '../../partials/content/crud';
import { ActiveNotificationWidgetComponent } from './active-notification-card/active-notification-widget/active-notification-widget.component';
import { ActiveNotificationCardComponent } from './active-notification-card/active-notification-card.component';
import { NotificationFormComponent } from './notification-form/notification-form.component';
import { NotificationDailyActivityWidgetComponent } from './active-notification-card/notification-daily-activity-widget/notification-daily-activity-widget.component';
import { NotificationActivityWidgetComponent } from './active-notification-card/notification-activity-widget/notification-activity-widget.component';

// tslint:disable-next-line:class-name
const routes: Routes = [
	{
		path: '',
		component: NotificationsComponent
	},
	{
		path: 'add',
		component: NotificationFormComponent
	},
	{
		path: 'edit/:id',
		component: NotificationFormComponent
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgbModule,
		RouterModule.forChild(routes),
		StoreModule.forFeature('notifications', notificationsReducer),
		EffectsModule.forFeature([NotificationEffects]),
		CoreModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		NgbProgressbarModule,
		FileUploadModule
	],
	providers: [
		// { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
	],
	entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		ConfirmationDialogComponent
	],
	declarations: [
		NotificationsComponent,
		NotificationFormComponent,
		NotificationsListComponent,
		ActiveNotificationCardComponent,
		ActiveNotificationWidgetComponent,
		NotificationDailyActivityWidgetComponent,
		NotificationActivityWidgetComponent
	]
})
export class NotificationsModule {}
