export enum POI_STATE {
	Active = 'active',
	Inactive = 'inactive',
	Pending = 'pending',
	NotApproved = 'not_approved'
}

export enum NOTIFICATION_STATE {
	Active = 'active',
	Inactive = 'inactive'
}
export enum NOTIFICATION_TYPE {
	Offer = 'offer',
	Standard = 'standard'
}

export enum USER_NOTIFICATION_STATE {
	Sent = 'sent',
	Received = 'received',
	Viewed = 'viewed',
	Opened = 'opened'
}

export enum PAYMENT_TYPE {
	MONEY = 'money',
	ATM = 'atm',
	ATM_CONTACTLESS = 'atm_contactless',
	MBWay = 'mb_way'
}

export enum REQUEST_LOG_ACTIONS {
	VIEW = 'view',
	LIST = 'list'
}
