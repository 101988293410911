import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
	Component,
	ChangeDetectorRef,
	ElementRef,
	ViewChild,
	Input,
	Output,
	EventEmitter
} from '@angular/core';
import { isNumber } from '../../../../../core/_base/crud/utils/helper.function';
import { MAX_IMAGE_SIZE } from '../../../../../../app/views/partials/content/general/file-upload/avatar-file-upload.component';
import { GalleryPicture } from '../../../../../../app/views/partials/content/widgets/gallery-picture/gallery-picture.component';

@Component({
	selector: 'kt-product-gallery',
	templateUrl: './product-gallery.component.html',
	styleUrls: ['./product-gallery.component.scss']
})
export class ProductGalleryComponent {
	inView = true;
	@ViewChild('mainPictureElem', { static: false }) mainPictureElem: ElementRef;

	constructor(private cdr: ChangeDetectorRef, private translate: TranslateService) {}

	@Input() gallery: GalleryPicture[] = [];
	@Input() maxImagesCount = 5;
	@Input() galleryChangeDetector = new BehaviorSubject<boolean>(false);
	@Output() uploadError = new EventEmitter<{ message: string }>();

	get mainPicture() {
		return !!this.gallery && this.gallery.find((pic) => !!pic.mainPicture);
	}

	get otherPictures() {
		return !!this.gallery && this.gallery.filter((pic) => !pic.mainPicture);
	}

	markAsMainPicture(id: number, forceUpdate = true) {
		this.gallery.map((picture) => {
			picture.mainPicture = picture.id === id;
			return picture;
		});
		if (forceUpdate) {
			if (!!this.mainPictureElem && !!this.mainPictureElem.nativeElement) {
				this.mainPictureElem.nativeElement.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				});
			}
			this.cdr.detectChanges();
		}

		this.galleryChangeDetector.next(true);
	}

	deletePicture(id: number) {
		const picToDelete = !!this.gallery && this.gallery.find((pic) => pic.id === id);
		if (!!picToDelete) {
			// Need set another main picture
			if (!!picToDelete.mainPicture) {
				const newMain = this.gallery.find(
					(pic) => pic.id !== picToDelete.id && !!pic.url && !pic.mainPicture
				);
				if (!!newMain) {
					this.markAsMainPicture(newMain.id, false);
				}
			}
			const index = this.gallery.findIndex((pic) => pic.id === picToDelete.id);
			this.gallery.splice(index, 1);

			this.galleryChangeDetector.next(true);
		}
		this.cdr.detectChanges();
	}

	get canAddMorePictures() {
		return !!this.gallery && this.gallery.length < this.maxImagesCount;
	}

	async uploadFiles(files: FileList) {
		if (!files) {
			return;
		}
		let last_id = Math.max.apply(
			Math,
			this.gallery.map((p) => p.id)
		);
		last_id = isNumber(last_id) ? last_id : 0;

		const ignored_files = [];
		for (let i = 0; i < files.length; i++) {
			if (
				files.item(i).size <= MAX_IMAGE_SIZE &&
				this.gallery.length < this.maxImagesCount
			) {
				const data = await this.readFile(files.item(i));
				this.gallery.push({
					id: last_id + i + 1,
					name: files.item(i).name,
					url: data,
					mainPicture: false,
					file: files.item(i)
				});
			} else {
				ignored_files.push(files.item(i));
			}
		}

		// Need set one MainPicture
		if (!this.mainPicture && this.gallery && this.gallery.length) {
			this.markAsMainPicture(this.gallery[0].id, false);
		}

		if (ignored_files.length > 0) {
			const ignoredFiles_str = Object.values(ignored_files).reduce(
				(acc, { name }) => {
					acc = acc ? acc + ', ' + name : name;
					return acc;
				},
				''
			);
			this.uploadError.next({
				message: `${this.getUploadHint()} ${this.translate.instant(
					'INPUT.VALIDATIONS.UPLOAD_FILES_ERROR',
					{
						ignoreFiles: ignoredFiles_str
					}
				)}`
			});
		} else this.galleryChangeDetector.next(true);
		this.cdr.detectChanges();
	}

	getUploadHint() {
		return this.translate.instant('INPUT.VALIDATIONS.UPLOAD_FILES_HINT', {
			maxCount: this.maxImagesCount,
			maxSize: (MAX_IMAGE_SIZE / Math.pow(1024, 2)).toFixed(2)
		});
	}

	readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}
}
