export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					title: 'Dashboard',
					root: true,
					icon: 'flaticon-home',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
					bullet: 'dot'
				},
				{
					title: 'Profile',
					desc: 'Some my description goes here',
					root: true,
					bullet: 'dot',
					translate: 'MENU.SERVICE-PAGE.SERVICE-PAGE',
					icon: 'flaticon-user',
					submenu: [
						{
							title: 'Profile',
							translate: 'MENU.SERVICE-PAGE.PROFILE',
							page: '/service-page/profile'
						},
						{
							title: 'Geofence',
							translate: 'MENU.SERVICE-PAGE.GEOFENCE',
							page: '/service-page/geofence'
						},
						{
							title: 'Gallery',
							translate: 'MENU.SERVICE-PAGE.GALLERY',
							page: '/service-page/gallery'
						}
					]
				},
				{
					title: 'Notifications',
					desc: 'Some my description goes here',
					root: true,
					page: '/notifications',
					translate: 'MENU.NOTIFICATIONS',
					icon: 'flaticon-speech-bubble-1'
				},
				{
					title: 'Shop',
					desc: 'Some my description goes here',
					root: true,
					bullet: 'dot',
					translate: 'MENU.SHOP.SHOP',
					icon: 'flaticon-cart',
					submenu: [
						{
							title: 'Products',
							translate: 'MENU.SHOP.PRODUCTS',
							page: '/products'
						},
						{
							title: 'Categorias',
							translate: 'MENU.SHOP.CATEGORIES',
							page: '/tags'
						},
						{
							title: 'Encomendas',
							translate: 'MENU.SHOP.ORDERS',
							page: '/orders'
						},
						{
							title: 'Configurações',
							translate: 'MENU.SHOP.SETTINGS',
							page: '/settings'
						}
					]
				}

				// {section: 'Applications'},
			]
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
