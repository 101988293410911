import { PAYMENT_TYPE } from './../../_consts/specification.dictionary';

/**
 * Model used to represent a Geographic point.
 * :lat -> Geographic latitude
 * :lng -> Geographic longitude
 */

export class Payment {
	id?: number;
	type: PAYMENT_TYPE;

	public static haveAnyDigitalPayment(payments: Payment[]) {
		return !!payments.find(
			(p) =>
				p.type === PAYMENT_TYPE.ATM_CONTACTLESS ||
				p.type === PAYMENT_TYPE.MBWay ||
				p.type === PAYMENT_TYPE.ATM
		);
	}

	clear() {
		this.id = undefined;
		this.type = undefined;
	}
}
