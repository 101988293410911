import gql from 'graphql-tag';

const deleteFlatRateFulfillmentMethodMutation = gql`
	mutation deleteFlatRateFulfillmentMethod(
		$input: DeleteFlatRateFulfillmentMethodInput!
	) {
		deleteFlatRateFulfillmentMethod(input: $input) {
			method {
				_id
			}
		}
	}
`;

export default deleteFlatRateFulfillmentMethodMutation;
