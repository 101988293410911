import gql from 'graphql-tag';
import TagInfo from './../fragments/tag';

export default gql`
	query tagsQuery($shopId: ID!, $cursor: ConnectionCursor) {
		tags(shopId: $shopId, first: 200, after: $cursor, sortBy: createdAt) {
			pageInfo {
				endCursor
				startCursor
				hasNextPage
			}
			nodes {
				...TagInfo
			}
		}
	}
	${TagInfo}
`;
