export class Contacts {
	id: number;
	email: string;
    phone_number: string;

    clear() {
		this.id = undefined;
		this.email = '';
		this.phone_number = '';
    }
}
