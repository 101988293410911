
export class SocialNetworks {
	id: number;
	linkedIn: string;
	facebook: string;
	twitter: string;
    instagram: string;

    clear() {
		this.id = undefined;
		this.linkedIn = '';
		this.facebook = '';
		this.twitter = '';
		this.instagram = '';
    }
}
