import {
	MessageType,
	LayoutUtilsService
} from './../../../../../core/_base/crud/utils/layout-utils.service';
import { PointOfInterestService } from './../../../../../core/point-of-interest/_services/point-of-interest.service';
import { Router } from '@angular/router';
import {
	PointOfInterestSelected,
	UserRequested
} from './../../../../../core/auth/_actions/auth.actions';
import { selectedPointOfInterest } from './../../../../../core/auth/_selectors/auth.selectors';
import { PointOfInterest } from './../../../../../core/point-of-interest/_models/point-of-interest.model';
import { POI_STATE } from './../../../../../core/point-of-interest/_consts/specification.dictionary';
import { MediaFile } from './../../../../../core/_base/crud/models/media-file.model';
// Angular
import {
	Component,
	Input,
	OnInit,
	OnDestroy,
	ChangeDetectorRef
} from '@angular/core';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';
import { takeWhile } from 'rxjs/operators';

@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit, OnDestroy {
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;

	// Public properties
	user$: Observable<User>;
	myUser: User = null;
	pointOfInterest$: Observable<PointOfInterest>;
	selectedPoiID: number;
	picturePath = './assets/media/users/default.jpg';
	inView = true;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private store: Store<AppState>,
		private cdr: ChangeDetectorRef,
		private pointOfInterestService: PointOfInterestService,
		private layoutUtilsService: LayoutUtilsService,
		private router: Router
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Loadings
		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(false);

		this.user$ = this.store.pipe(select(currentUser));
		this.user$.pipe(takeWhile(() => this.inView)).subscribe((user) => {
			this.myUser = user;
			if (!!user && !!user.pic) {
				this.picturePath =
					MediaFile.getFilePath(user.pic) || './assets/media/users/default.jpg';
			}
			this.cdr.detectChanges();
		});
		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterest$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterest) => {
				if (!!pointOfInterest) {
					this.selectedPoiID = pointOfInterest.id;
				} else {
					this.selectedPoiID = null;
				}
				this.cdr.detectChanges();
			});
	}

	/**
	 * Log out
	 */
	logout() {
		this.store.dispatch(new Logout());
	}

	ngOnDestroy() {
		this.inView = false;
	}

	get newPointOfInterestDisabled() {
		let result = false;
		if (!!this.myUser && !!this.myUser.trial) {
			result =
				this.myUser.point_of_interests && this.myUser.point_of_interests.length > 0
					? this.myUser.point_of_interests.filter((poi) => !poi.demo).length >= 1
					: false;
		}

		return result;
	}

	addNewPointOfInterest() {
		if (this.newPointOfInterestDisabled) {
			return;
		}
		this.store.dispatch(new PointOfInterestSelected({ pointOfInterest: null }));
		this.router.navigate(['/service-page', 'profile']);
	}

	selectPointOfInterest(poi: PointOfInterest) {
		if (!!poi && poi.id !== this.selectedPoiID) {
			this.store.dispatch(new PointOfInterestSelected({ pointOfInterest: poi }));
		}
	}

	getImagePath(img: MediaFile) {
		return img ? MediaFile.getFilePath(img) : './assets/media/bg/no-image.png';
	}

	getStateIcon(state: string) {
		return state === POI_STATE.Active
			? 'flaticon2-correct kt-font-primary'
			: 'fas fa-exclamation-circle kt-font-danger';
	}

	getStateTooltip(state: string) {
		return state === POI_STATE.Active
			? 'POINT_OF_INTEREST.STATE.ACTIVE.TOOLTIP'
			: 'POINT_OF_INTEREST.STATE.PENDING.TOOLTIP';
	}

	poiIsActive(state: string) {
		return state === POI_STATE.Active;
	}

	truncateString(desc: string, length) {
		if (!!desc && desc.length > length) {
			return `${desc.slice(0, length)}...`;
		}
		return desc;
	}

	get hasDemo() {
		return !this.myUser.point_of_interests.find((poi) => !!poi.demo);
	}

	addDemoPOI() {
		this.loadingSubject.next(true);
		this.pointOfInterestService
			.createDemoPointOfInterest()
			.then((res) => {
				this.loadingSubject.next(false);
				const message = 'TOPBAR.USER_PROFILE.ALERTS.DEMO_CREATED_SUCCESS';
				this.layoutUtilsService.showActionNotification(message, MessageType.Create);
				this.store.dispatch(new UserRequested());
			})
			.catch((error) => {
				this.loadingSubject.next(false);
				const message = 'TOPBAR.USER_PROFILE.ALERTS.DEMO_CREATED_ERROR';
				this.layoutUtilsService.showActionNotification(message, MessageType.Create);
			});
	}

	deleteDemoPOI(event: Event, poi: PointOfInterest) {
		event.stopPropagation();
		if (!poi || (!!poi && !poi.id)) {
			return;
		}
		this.loadingSubject.next(true);
		this.pointOfInterestService
			.deleteDemoPointOfInterest(poi.id)
			.then((res) => {
				this.loadingSubject.next(false);
				this.store.dispatch(new UserRequested());
				const message = 'TOPBAR.USER_PROFILE.ALERTS.DEMO_DELETED_SUCCESS';
				this.layoutUtilsService.showActionNotification(message, MessageType.Delete);
			})
			.catch((error) => {
				this.loadingSubject.next(false);
				const message = 'TOPBAR.USER_PROFILE.ALERTS.DEMO_DELETED_ERROR';
				this.layoutUtilsService.showActionNotification(message, MessageType.Delete);
			});
	}
}
