import { TagModel } from './../../../point-of-interest/_models/tag.model';
// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
// Partials for CRUD
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	ConfirmationDialogComponent,
	FetchEntityDialogComponent,
	CreateTagDialogComponent,
	PublishProductDialogComponent
} from '../../../../views/partials/content/crud';

import { UpdateStatusDialogComponent } from './../../../../views/partials/content/crud/update-status-dialog/update-status-dialog.component';
import { AlertDialogComponent } from './../../../../views/partials/content/crud/alert-dialog/alert-dialog.component';

export enum MessageType {
	Create,
	Read,
	Update,
	Delete
}

export enum UI_INFO_TYPE {
	SUCCESS = 'success',
	DANGER = 'danger',
	WARNING = 'warning',
	INFO = 'info'
}

@Injectable()
export class LayoutUtilsService {
	/**
	 * Service constructor
	 *
	 * @param snackBar: MatSnackBar
	 * @param dialog: MatDialog
	 */
	constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

	/**
	 * Showing (Mat-Snackbar) Notification
	 *
	 * @param message: string
	 * @param type: MessageType
	 * @param duration: number
	 * @param showCloseButton: boolean
	 * @param showUndoButton: boolean
	 * @param undoButtonDuration: number
	 * @param verticalPosition: 'top' | 'bottom' = 'top'
	 * @param horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right' = 'center'
	 */
	showActionNotification(
		message: string,
		type: MessageType = MessageType.Create,
		duration: number = 10000,
		showCloseButton: boolean = true,
		showUndoButton: boolean = false,
		undoButtonDuration: number = 3000,
		verticalPosition: 'top' | 'bottom' = 'bottom',
		horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right' = 'center'
	) {
		const data = {
			message,
			snackBar: this.snackBar,
			showCloseButton,
			showUndoButton,
			undoButtonDuration,
			verticalPosition,
			type,
			action: 'Undo'
		};
		return this.snackBar.openFromComponent(ActionNotificationComponent, {
			duration,
			data,
			verticalPosition,
			horizontalPosition
		});
	}

	/**
	 * Showing Confirmation (Mat-Dialog) before go out Form
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
	goBack(title: string = '', description: string = '', buttons = null) {
		return this.dialog.open(ConfirmationDialogComponent, {
			data: { title, description, buttons },
			width: '440px'
		});
	}

	/**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
	deleteElement(
		title: string = '',
		description: string = '',
		waitDesciption: string = ''
	) {
		return this.dialog.open(DeleteEntityDialogComponent, {
			data: { title, description, waitDesciption },
			width: '440px'
		});
	}

	/**
	 * Showing Fetching Window(Mat-Dialog)
	 *
	 * @param data: any
	 */
	fetchElements(data) {
		return this.dialog.open(FetchEntityDialogComponent, {
			data,
			width: '600px'
		});
	}

	/**
	 * Showing Update Status for Entites Window
	 *
	 * @param title: string
	 * @param statuses: string[]
	 * @param messages: string[]
	 */
	showSimpleAlertDialog(
		type: 'success' | 'danger' | 'warning' | 'info' = 'info',
		title,
		message,
		showCloseButton = true
	) {
		return this.dialog.open(AlertDialogComponent, {
			data: { type, title, message, showCloseButton }
		});
	}

	/**
	 * Show Simple Alert Message
	 *
	 */
	updateKeyForEntities(
		title,
		key,
		statuses,
		messages,
		options: { width?: string; placeholder?: string } = {}
	) {
		return this.dialog.open(UpdateStatusDialogComponent, {
			data: { title, key, statuses, messages, placeholder: options.placeholder },
			width: options.width || '480px'
		});
	}

	/**
	 * Showing Publish Modal
	 *
	 * @param title: string
	 * @param messages: string[]
	 */
	publishProductEntities(title, key, messages) {
		return this.dialog.open(PublishProductDialogComponent, {
			data: { title, key, messages },
			width: '480px'
		});
	}

	/**
	 * Showing Create tag Modal
	 */
	createOrEditTagModal(tag?: TagModel, editPosition: boolean = false) {
		return this.dialog.open(CreateTagDialogComponent, {
			width: '480px',
			data: {
				tag,
				displayPosition: editPosition
			}
		});
	}
}
