import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsService } from './../../../../../core/point-of-interest/_services/notifications.service';
import { USER_NOTIFICATION_STATE } from './../../../../../core/point-of-interest/_consts/specification.dictionary';
import { NotificationModel } from './../../../../../core/point-of-interest/_models/notification.model';
import { LayoutConfigService } from './../../../../../core/_base/layout/services/layout-config.service';
import { TranslateService } from '@ngx-translate/core';
import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	ChangeDetectorRef
} from '@angular/core';
import { toNgbDateStruct } from './../../../../../core/_base/crud/utils/helper.function';

@Component({
	selector: 'kt-notification-daily-activity-widget',
	templateUrl: './notification-daily-activity-widget.component.html',
	styleUrls: ['./notification-daily-activity-widget.component.scss']
})
export class NotificationDailyActivityWidgetComponent implements OnInit {
	noData = false;
	// Loading
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;


	dailyActivityData: { labels: string[]; datasets: any[] } = null;
	_notification: NotificationModel = null;

	@Input() set notification(notification: NotificationModel) {
		this.initData(notification);
	}

	@ViewChild('chart', { static: false }) chartElem: ElementRef;
	chart: any;
	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private translate: TranslateService,
		private cd: ChangeDetectorRef,
		private notificationsService: NotificationsService
	) {}

	/**
	 * On init
	 */
	ngOnInit() {
		// Init the loading
		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(false);

		// Clear data
		this.dailyActivityData = this.initActivityNotificationData();
	}

	async initData(notification: NotificationModel) {
		this._notification = notification;
		this.loadingSubject.next(true);
		// Clear data
		this.dailyActivityData = this.initActivityNotificationData();

		if (!!this._notification) {
			// Get Daily Activity data
			await this.getDailyActivityData();
		}
		this.cd.detectChanges();

		if (!this.noData) {
			this.initChartJS();
		}
		this.loadingSubject.next(false);
	}

	/**
	 * Daily Activity data
	 */

	async getDailyActivityData() {
		if (!this._notification) {
			return;
		}

		const filter = {
			from: null,
			to: null,
			point_of_interest: this._notification.point_of_interest,
			notification: this._notification.id
		};

		// Get data
		const sourceData = await this.notificationsService.notificationsDailyActivity(filter);
		this.noData = !sourceData || Object.keys(sourceData).length <= 0;
		this.dailyActivityData = this.prepareNotificationChartData(sourceData);

	}

	private prepareNotificationChartData(data: object) {
		const result = this.initActivityNotificationData();

		Object.keys(data).forEach((datakey) => {
			const date = toNgbDateStruct(datakey);
			// Label
			result.labels.push(`${date.day}/${date.month}`);
			// USER_NOTIFICATION_STATE.Sent dataset
			result.datasets[0].data.push(data[datakey][USER_NOTIFICATION_STATE.Sent] || 0);
			// USER_NOTIFICATION_STATE.Received dataset
			result.datasets[1].data.push(
				data[datakey][USER_NOTIFICATION_STATE.Received] || 0
			);
			// USER_NOTIFICATION_STATE.Viewed dataset
			result.datasets[2].data.push(
				data[datakey][USER_NOTIFICATION_STATE.Viewed] || 0
			);
			// USER_NOTIFICATION_STATE.Opened dataset
			result.datasets[3].data.push(
				data[datakey][USER_NOTIFICATION_STATE.Opened] || 0
			);
		});
		// Dummy data
		// return ACTIVE_NOTIFICATIONS_DATA;
		return result;
	}

	private initActivityNotificationData(): {
		labels: string[];
		datasets: { label: string; data: number[]; backgroundColor: string }[];
	} {
		return {
			labels: [],
			datasets: [
				// USER_NOTIFICATION_STATE.Sent dataset
				{
					label: this.translate.instant('DASHBOARD.ACTIVITY_CHART.LEGENDS.SENT'),
					data: [],
					backgroundColor: 'lightblue'
				},
				// USER_NOTIFICATION_STATE.Received dataset
				{
					label: this.translate.instant('DASHBOARD.ACTIVITY_CHART.LEGENDS.RECEIVED'),
					data: [],
					backgroundColor: this.layoutConfigService.getConfig('colors.state.warning')
				},
				// USER_NOTIFICATION_STATE.Viewed dataset
				{
					label: this.translate.instant('DASHBOARD.ACTIVITY_CHART.LEGENDS.SAW'),
					data: [],
					backgroundColor: this.layoutConfigService.getConfig('colors.state.primary')
				},
				// USER_NOTIFICATION_STATE.Opened dataset
				{
					label: this.translate.instant('DASHBOARD.ACTIVITY_CHART.LEGENDS.OPENED'),
					data: [],
					backgroundColor: this.layoutConfigService.getConfig('colors.state.success')
				}
			]
		};
	}

	/** Init chart */
	initChartJS() {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html

		this.chart = new Chart(this.chartElem.nativeElement, {
			type: 'bar',
			data: this.dailyActivityData,
			options: {
				title: {
					display: false
				},
				tooltips: {
					intersect: false,
					mode: 'nearest',
					xPadding: 10,
					yPadding: 10,
					caretPadding: 10
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				barRadius: 4,
				scales: {
					xAxes: [
						{
							display: false,
							gridLines: false,
							stacked: true
						}
					],
					yAxes: [
						{
							display: false,
							stacked: true,
							gridLines: false
						}
					]
				},
				layout: {
					padding: {
						left: 0,
						right: 0,
						top: 0,
						bottom: 0
					}
				}
			}
		});
	}
}
