import { POI_STATE } from './../../../core/point-of-interest/_consts/specification.dictionary';
import { MediaFile } from './../../../core/_base/crud/models/media-file.model';
import { selectedPointOfInterest } from './../../../core/auth/_selectors/auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from './../../../core/reducers/index';
import { PointOfInterest } from './../../../core/point-of-interest/_models/point-of-interest.model';
import { filter, takeWhile } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

export enum SERVICE_SUB_MENUS {
	PROFILE,
	GEOFENCE,
	GALLERY
}
const DEFAULT_MENUS = [
	{
		id: SERVICE_SUB_MENUS.PROFILE,
		title: 'MENU.SERVICE-PAGE.PROFILE',
		route: 'profile',
		iconSVG: `
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<rect x="0" y="0" width="24" height="24"/>
					<path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
					<path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
					<rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
					<rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
				</g>
			</svg>`
	},
	{
		id: SERVICE_SUB_MENUS.GEOFENCE,
		title: 'MENU.SERVICE-PAGE.GEOFENCE',
		route: 'geofence',
		iconSVG: `
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<rect x="0" y="0" width="24" height="24"/>
				<path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fill-rule="nonzero"/>
			</g>
		</svg>`
	},
	{
		id: SERVICE_SUB_MENUS.GALLERY,
		title: 'MENU.SERVICE-PAGE.GALLERY',
		route: 'gallery',
		iconSVG: `
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<polygon points="0 0 24 0 24 24 0 24"/>
					<rect fill="#000000" opacity="0.3" x="2" y="4" width="20" height="16" rx="2"/>
					<polygon fill="#000000" opacity="0.3" points="4 20 10.5 11 17 20"/>
					<polygon fill="#000000" points="11 20 15.5 14 20 20"/>
					<circle fill="#000000" opacity="0.3" cx="18.5" cy="8.5" r="1.5"/>
				</g>
			</svg>`
	}
];
@Component({
	selector: 'kt-service-page',
	templateUrl: './service-page.component.html',
	styleUrls: ['./service-page.component.scss']
})
export class ServicePageComponent implements OnInit, OnDestroy {
	subMenus = [];
	SERVICE_SUB_MENUS = SERVICE_SUB_MENUS;
	activeSubMenu: SERVICE_SUB_MENUS = null;
	pointOfInterest$: Observable<PointOfInterest>;
	pointOfInterestInfo: {
		title: string;
		description: string;
		picture: string;
		state?: { icon: string; tooltip: string };
	};

	inView = true;
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private store: Store<AppState>
	) {}

	ngOnInit() {
		this.activeSubMenu = this.getSelectedSubMenuFromRouteURL(this.router.url);
		// subscribe route changes
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event) => {
				this.activeSubMenu = this.getSelectedSubMenuFromRouteURL(this.router.url);
				this.cdr.markForCheck();
			});
		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterest$
			.pipe(takeWhile(() => this.inView))
			.subscribe(async (pointOfInterest) => {
				this.initMenuInfo(pointOfInterest);
			});
	}
	ngOnDestroy() {
		this.inView = false;
	}

	initMenuInfo(pointOfInterest: PointOfInterest) {
		if (!!pointOfInterest) {
			this.pointOfInterestInfo = {
				title: pointOfInterest.title as string,
				description: pointOfInterest.description as string,
				picture: MediaFile.getFilePath(pointOfInterest.mainImage),
				state: {
					icon:
						pointOfInterest.state === POI_STATE.Active
							? 'flaticon2-correct kt-font-primary'
							: 'fas fa-exclamation-circle kt-font-danger',
					tooltip:
						pointOfInterest.state === POI_STATE.Active
							? 'POINT_OF_INTEREST.STATE.ACTIVE.TOOLTIP'
							: 'POINT_OF_INTEREST.STATE.PENDING.TOOLTIP'
				}
			};
		} else {
			this.pointOfInterestInfo = null;
		}
		this.subMenus = DEFAULT_MENUS.map((obj) => ({ ...obj }));

		// Set the warning on Profile Info menu if missing the MainPicture info
		if (!pointOfInterest) {
			const profileMenu = this.subMenus.find(
				(menu) => menu.id === SERVICE_SUB_MENUS.PROFILE
			);
			if (!!profileMenu) {
				profileMenu.badge = {
					icon: 'fas fa-exclamation-circle kt-font-danger',
					tooltip: 'POINT_OF_INTEREST.STATE.PENDING.MENU_TOOLTIP'
				};
			}
		}

		// Set the warning on GeoFence menu if missing the geoFence info
		if (
			!pointOfInterest ||
			(!!pointOfInterest && !pointOfInterest.geofence) ||
			(!!pointOfInterest.geofence && pointOfInterest.geofence.length <= 0)
		) {
			const geofenceMenu = this.subMenus.find(
				(menu) => menu.id === SERVICE_SUB_MENUS.GEOFENCE
			);
			if (!!geofenceMenu) {
				geofenceMenu.disabled = !pointOfInterest;
				geofenceMenu.badge = {
					icon: 'fas fa-exclamation-circle kt-font-danger',
					tooltip: 'POINT_OF_INTEREST.STATE.PENDING.MENU_TOOLTIP'
				};
			}
		}
		// Set the warning on Gallery menu if missing the MainPicture info
		if (!pointOfInterest || !pointOfInterest.mainImage) {
			const mainPictureMenu = this.subMenus.find(
				(menu) => menu.id === SERVICE_SUB_MENUS.GALLERY
			);
			if (!!mainPictureMenu) {
				mainPictureMenu.disabled = !pointOfInterest;
				mainPictureMenu.badge = {
					icon: 'fas fa-exclamation-circle kt-font-danger',
					tooltip: 'POINT_OF_INTEREST.STATE.PENDING.MENU_TOOLTIP'
				};
			}
		}
		this.cdr.detectChanges();
	}

	getSelectedSubMenuFromRouteURL(url: string): SERVICE_SUB_MENUS {
		const activeMenu = this.subMenus.find((menu) => url.indexOf(menu.route) !== -1);
		return activeMenu ? activeMenu.id : null;
	}

	goTo($event) {
		const menu = this.subMenus.find((submenu) => submenu.id === $event);
		if (!!menu) {
			this.router.navigate([menu.route], { relativeTo: this.activatedRoute });
		}
	}
}
