import {
	selectShippingMethodsPageLoading,
	selectShippingMethodsActionLoading,
	selectShippingMethodsInStore,
	selectShippingMethodsShowInitWaitingMessage,
	selectShippingMethodsShowErrorMessage
} from './../_selectors/shipping-methods.selectors';

// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';

export class ShippingMethodsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(select(selectShippingMethodsPageLoading));
		this.actionLoading$ = this.store.pipe(
			select(selectShippingMethodsActionLoading)
		);
		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectShippingMethodsShowInitWaitingMessage)
		);

		this.showErrorMessage$ = this.store.pipe(
			select(selectShippingMethodsShowErrorMessage)
		);

		this.store
			.pipe(select(selectShippingMethodsInStore))
			.subscribe((response: QueryResultsModel) => {
				this.paginatorTotalSubject.next(response.totalCount);
				this.entitySubject.next(response.items);
			});
	}
}
