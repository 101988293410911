// PT - PT
export const locale = {
	lang: 'pt',
	data: {
		GENERIC: {
			ALL: 'Todos',
			SEARCH: 'Pesquisar',
			SEARCH_ALL: 'Pesquisar em todos os campos',
			UPDATE_STATE: 'Atualizar estado',
			STATE: 'Estado',
			CANCEL: 'Cancelar',
			SELECT: 'Escolher',
			CLEAR: 'Limpar',
			UNDO: 'Desfazer',
			DISCARD: 'Descartar',
			DELETE: 'Eliminar',
			DELETE_ALL: 'Eliminar Todos',
			FILTER_BY: 'Filtrar por {{key}}',
			ACTIONS: 'Ações',
			WAIT: 'Por favor, aguarde....',
			BACK: 'Voltar',
			OPEN: 'abre',
			CLOSE: 'Fechar',
			FROM: 'de',
			TO: 'até',
			SAVE: 'Guardar',
			CREATE: 'Criar',
			PUBLISH: 'Publicar',
			CONFIRM: 'Confirmar',
			CHANGE: 'Alterar',
			NO_DATA: 'Não há mais dados',
			EXIT: 'Sair',
			LAST_VALUE: 'Ultimo valor',
			ND: 'n/d',
			OPERATION_ERROR: 'Erro na operação',
			ITEMS: 'Itens',
			CONTACTS: 'Contactos',
			DATE: 'Data',
			ADDRESS: 'Morada',
			TOTAL: 'Total',
			CUSTOMER: 'Cliente',
			COST: 'Custo',
			LABEL: 'Descritivo',
			NAME: 'Nome',
			ENABLED: 'Ativo',
			DISABLED: 'Inativo',
			GROUP: 'Grupo',
			ENTER: 'Introduzir',
			ACCIONS: 'Ações',
			DELETING: 'A apagar...',
			UPS: 'Ups...',
			TYPE: 'Tipo',
			VISIBLE: 'Visível',
			NOT_VISIBLE: 'Não Visível',
			POSITION: 'Posição',
			ONLY_ACCEPT_NUMBERS:
				'Só aceita números inteiros positivos, ex.: 1, 2, 43, 1922, ...'
		},
		TIME: {
			WEEK_DAYS: {
				MONDAY: { SHORT: 'Seg', FULL: 'Segunda-feira' },
				TUESDAY: { SHORT: 'Ter', FULL: 'Terça-feira' },
				WEDNESDAY: { SHORT: 'Qua', FULL: 'Quarta-feira' },
				THURSDAY: { SHORT: 'Qui', FULL: 'Quinta-feira' },
				FRIDAY: { SHORT: 'Sex', FULL: 'Sexta-feira' },
				SATURDAY: { SHORT: 'Sab', FULL: 'Sabado' },
				SUNDAY: { SHORT: 'Dom', FULL: 'Domingo' },
				HOLIDAY: { FULL: 'Feriados' }
			}
		},
		MULTILANGUAGE: {
			ADD: 'Adicionar tradução',
			HIDE: 'Esconder tradução'
		},
		INPUT: {
			PT: {
				PLACEHOLDER: 'Digite o texto em português',
				LABEL: 'PT'
			},
			EN: {
				PLACEHOLDER: 'Digite o texto em inglês',
				LABEL: 'EN'
			},
			VALIDATIONS: {
				REQUIRED: 'Campo é obrigatório',
				PATTERN_INVALID: 'Campo não é válido',
				EMAIL_INVALID: 'Email inválido',
				POSTAL_CODE_INVALID: 'Código Postal inválido',
				MAX_LENGTH: 'Número maximo de caracteres atingido: {{value}} / {{maxValue}}',
				MIN_LENGTH: 'Introduza pelo menos {{value}} caracteres',
				DAY_INTERVAL: {
					MANDATORY: 'Dia de inicio é obrigatório.',
					END_AFTER_START: 'O dia de fim deve ser depois do dia de inicio.'
				},
				OPENING_HOURS: {
					MANDATORY: 'Intrevalo de horas é obrigatória.',
					CLOSE_AFTER_OPEN: 'Hora de fim deve ser depois da hora de inicio.'
				},
				SELECT_FIELD: 'Selecione um dos valores da lista',
				FILE_TOO_BIG_MB: 'O ficheiro é muito grande: {{value}} Mb / {{maxValue}} Mb',
				UPLOAD_FILES_HINT:
					'Só pode adicionar até {{maxCount}} fotos com um tamanho máximo de {{maxSize}} MB cada.',
				UPLOAD_FILES_ERROR: 'Foram descartados os ficheiros: {{ignoreFiles}}'
			}
		},
		DATATABLE: {
			SELECTED_ROWS: 'Número de registos selecionados:',
			NO_RECORDS: 'Nenhum registo encontrado'
		},
		TRANSLATOR: {
			SELECT: 'Escolha a sua língua'
		},
		UPDATE_DIALOG: {
			BUTTON_UPDATE: 'Atualizar {{key}}',
			SELECT_HINT: 'para atualizar as linhas'
		},
		CREATE_TAG_DIALOG: {
			FORM: {
				TITLE: 'Criar nova categoria de produto',
				NAME: 'Nome'
			}
		},
		GO_BACK_MODAL: {
			TITLE: 'Alterações não guardadas',
			MESSAGE: 'Tem alterações não guardadas. Pretende continuar?'
		},
		MENU: {
			NEW: 'novo',
			ACTIONS: 'Ações',
			CREATE_POST: 'Criar novo post',
			PAGES: 'Páginas',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			NOTIFICATIONS: 'Ofertas',
			'SERVICE-PAGE': {
				'SERVICE-PAGE': 'Perfil',
				PROFILE: 'Informação de Perfil',
				GEOFENCE: 'Informação de Localização',
				GALLERY: 'Galeria de fotos'
			},
			SHOP: {
				SHOP: 'Loja',
				PRODUCTS: 'Produtos',
				CATEGORIES: 'Categorias',
				ATTRIBUTES: 'Atributos',
				ORDERS: 'Encomendas',
				SETTINGS: 'Configurações'
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'Ou',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'Não tem uma conta?',
				SIGNUP_BUTTON: 'Registar',
				FORGOT_BUTTON: 'Esqueci a password',
				BACK_BUTTON: 'Voltar',
				PRIVACY: 'Privacidade',
				LEGAL: 'Legal',
				CONTACT: 'Contactos',
				ABOUT: 'Sobre',
				TEAM: 'Equipa'
			},
			LOGIN: {
				TITLE: 'Login',
				BUTTON: 'Entrar'
			},
			FORGOT: {
				TITLE: 'Esqueceu a password?',
				DESC: 'Introduza o seu email para redefinir a password',
				SUCCESS: 'A sua conta foi redefinida com sucesso.'
			},
			REGISTER: {
				TITLE: 'Registar',
				DESC: 'Introduza os seus detalhes para criar uma conta',
				SUCCESS: 'A sua conta foi criada com sucesso.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Nome completo',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirmar Password',
				USERNAME: 'Nome de utilizador'
			},
			VALIDATION: {
				INVALID: '{{name}} não é válido',
				REQUIRED: '{{name}} é obrigatório',
				MIN_LENGTH: '{{name}} tamanho mínimo é {{min}}',
				AGREEMENT_REQUIRED: 'É obrigatório aceitar os termos e condições',
				NOT_FOUND: 'O username pedido {{name}} não foi encontrado',
				INVALID_LOGIN: 'Os detalhes de login estão incorretos',
				NOT_CONFIRMED_USER:
					'A conta ainda não se encontra confirmada. Por favor, verifique o seu email.',
				BLOCKED_USER: 'A conta encontra-se bloqueada.',
				REQUIRED_FIELD: 'Campo obrigatório',
				MIN_LENGTH_FIELD: 'Tamanho mínimo:',
				MAX_LENGTH_FIELD: 'Tamanho máximo:',
				INVALID_FIELD: 'Campo não é válido'
			}
		},
		TOPBAR: {
			USER_PROFILE: {
				WELCOME: 'Olá',
				ADD_POINT_OF_INTEREST: 'Adicionar novo Ponto de interesse',
				TOOLTIP_DEMO_POINT_OF_INTEREST:
					'Para explorar a nossa aplicação criamos um ponto de interesse de demostração com dados gerados aleatóriamente',
				ADD_DEMO_POINT_OF_INTEREST: 'Adicionar o ponto de interesse de demonstração',
				DELETE_DEMO_POINT_OF_INTEREST:
					'Eliminar o ponto de interesse de demonstração',
				MY_POINT_OF_INTERESTS: 'Meus Pontos de Interesse',
				TRIAL_TOOLTIP:
					'Na versão de teste, você pode gerenciar apenas 1 ponto de interesse',
				TRIAL: 'Versão de teste',
				ALERTS: {
					DEMO_CREATED_ERROR: 'Erro ao criar ponto de interesse de demostração.',
					DEMO_CREATED_SUCCESS: 'Ponto de interesse de demostração criado.',
					DEMO_DELETED_ERROR: 'Erro ao eliminar ponto de interesse de demostração.',
					DEMO_DELETED_SUCCESS: 'Ponto de interesse de demostração eliminado.'
				}
			}
		},
		FOOTER: {
			CONTACT: 'Contactos',
			ABOUT: 'Sobre',
			TEAM: 'Equipa'
		},
		DASHBOARD: {
			STATISTICS: {
				TODAY: {
					TITLE: 'Utilizadores Ativos',
					SUB_TITLE: 'Hoje',
					OPENED: 'abriram o perfil da loja',
					LISTED: 'viram a loja listada'
				},
				LAST_MONTH: {
					SUB_TITLE: 'registos último mês',
					OPENED: {
						TITLE: 'abriram ofertas'
					},
					LISTED: {
						TITLE: 'viram ofertas listadas'
					}
				}
			},
			RANKING: {
				TITLE: 'Ofertas mais abertas'
			},
			ACTIVITY_CHART: {
				TITLE: 'Atividade de ofertas',
				LEGENDS: {
					SENT: 'Enviadas',
					RECEIVED: 'Recebidas',
					SAW: 'Vistas',
					OPENED: 'abretas',
					LISTED: 'listadas'
				}
			},
			TIME_MENU: {
				VALUES: {
					LAST_DAYS: 'Últimos {{days}} dias',
					LAST_DAY: 'Último dia',
					LAST_MONTHS: 'Últimos {{months}} meses',
					LAST_MONTH: 'Último mês'
				}
			}
		},
		PRODUCTS: {
			NAME: 'Nome',
			VARIANT: 'Variação',
			OPTION: 'Opção',
			QUANTITY: 'Quantidade',
			PRICE: 'Preço',
			SALE: 'Promoção',
			PUBLISH: 'Publicar',
			TITLES: {
				LIST: 'Produtos',
				CREATE: 'Criar produto',
				EDIT: 'Editar produto'
			},
			STATE: {
				INACTIVE: 'Inativo',
				ACTIVE: 'Ativo',
				PUBLISHED: 'Publicado',
				UNPUBLISHED: 'Não publicado'
			},
			ACTION: {
				ADD: 'Adicionar novo produto',
				EDIT: 'Editar produto',
				DELETE: 'Eliminar produto',
				GO_BACK_PRODUCTS: 'Voltar à lista de produtos',
				PUBLISH: 'Publicar produtos'
			},
			LIST: {
				MY_PRODUCTS: {
					TITLE: 'Meus produtos'
				},
				FIELDS: {
					SEARCH_BY_TITLE: 'Pesquisar pelo título do produto',
					CATEGORY: 'Categoria',
					SEARCH_BY_CATEGORY: 'Pesquisar por categoria',
					PRICE: 'Preço',
					SALE_PRICE: 'Preço promocional',
					PUBLISHED: 'Publicado',
					PUBLISHED_TOOLTIP:
						'O produto só estará disponível no catálogo se for publicado. Selecione os produtos para proceder a publicação'
				},
				UPDATE_STATE_DIALOG: {
					TITLE: 'Atualizar o estado para os produtos selecionados',
					MESSAGE: 'O estado foi atualizado em {{updatedCount}} produtos.'
				},
				DELETE_MODAL: {
					TITLE: 'Apagar produtos',
					DESCRIPTION:
						'Tem a certeza que deseja apagar o(s) produto(s) seleccionado(s)?',
					WAIT_DESCRIPTION: 'A apagar produto(s)...',
					DELETE_MESSAGE: 'Produto(s) selecionado(s) apagado(s)'
				}
			},
			FORM: {
				ERRORS: {
					ERROR_SAVING_IMAGES: 'Erro a salvar as imagens do produto',
					ERROR_SAVING_BASIC_INFO: 'Erro a salvar a informação básica',
					ERROR_CREATING_PRODUCT: 'Erro a criar o produto',
					PRICING_NOT_UPDATED_ERROR: 'Erro ao atualizar os preços',
					ERROR_UPDATING_PRODUCT: 'Erro ao atualizar o produto',
					ERROR_CREATING_VARIANT: 'Erro a criar atributo nível 1',
					ERROR_CREATING_OPTIONS: 'Erro a criar atributo nível 2',
					PRODUCT_VARIANT_NOT_ARCHIVED: 'O atributo nível 1 não foi eliminado ',
					PRODUCT_OPTION_NOT_ARCHIVED: 'O atributo nível 2 não foi eliminado '
				},
				STATE_SECTION: {
					TITLE: 'Estado'
				},
				BASE_INFO_SECTION: {
					TITLE: 'Informação base',
					NAME_FIELD: {
						TITLE: 'Nome'
					},
					DESCRIPTION_FIELD: {
						TITLE: 'Descrição'
					}
				},
				VARIANTS_AND_OPTIONS: {
					INFO:
						// tslint:disable-next-line:max-line-length
						'<p>Os atributos podem ter um ou dois n&iacute;veis. Por exemplo, uma camisola pode ser dividida por cores e tamanhos: </p> <p><strong>Nomes dos atributos</strong></p> <ul> <li>N&iacute;vel 1: Cor</li> <li>N&iacute;vel 2: Tamanho</li> </ul> <p>&nbsp;<strong>Atributos</strong></p> <ul> <li>N&iacute;vel 1: Azul, Vermelho</li> <li>N&iacute;vel 2: S, M, L, XL</li> </ul> <p>&nbsp;</p> <p>Assim, a mesma camisola tem duas varia&ccedil;&otilde;es, nas cores Azul e Vermelho, sendo que cada uma dessas cores tem dispon&iacute;veis os tamanhos S, M, L e XL</p>',
					ADD_ONE_MORE_LEVEL: 'Adicionar mais um nível de atributos',
					TITLE_ATTRS_LABELS: 'Nome dos atributos',
					VARIANTS_LABEL: 'Nível 1 (ex.: Cor ou Tamanho )',
					OPTIONS_LABEL: 'Nível 2',
					TITLE_ATTRS: 'Atributos',
					VARIANTS: 'Nível 1 (ex: Preto, Branco, .. ou S, M, ..  )',
					OPTIONS: 'Nível 2',
					ADD_VARIANT: 'Adicionar atributo nível 1',
					CREATE_OPTION: 'Criar atributo nível 2',
					REMOVE_VAR_OR_OPTION: 'Apagar atributo',
					REMOVING_VAR_OR_OPTION: 'A apagar atributo',
					DELTED_VAR_OR_OPTION: 'Atributo removido',
					EMPTY_VALUE: 'Sem valor',
					ALREADY_EXIST: 'Valor já existe'
				},
				PRICING: {
					TITLE: 'Preços e Descontos',
					UPDATING_PRICING: 'Atualização de preços',
					SAVED_SUCCESSFULLY: 'Preços atualizados com sucesso',
					THERE_IS_NO_PRICING_INFO:
						'Não existe informação sobre os preços. Crie atributos do produto para poder adicionar informação sobre os produtos.'
				},
				INVENTORY_INFO_SECTION: {
					TITLE: 'Inventário',
					MANAGER_FIELD: {
						TITLE: 'Gerir inventário',
						TOOLTIP:
							'Se ativo, os compradores poderão solicitar apenas a quantidade disponível, a menos que permita o pedido pendente'
					},
					BACKORDER_FIELD: {
						TITLE: 'Permitir pedido pendente',
						SUB_TITLE:
							'Permitir que os compradores façam pedidos após se esgotar a quantidade disponível'
					}
				},
				BUY_OPTIONS_SECTION: {
					TITLE: 'Opções de compra',
					LIST: {
						ATTRIBUTE: {
							FIELD: 'Característica',
							PLACEHOLDER: 'Introduzir a característica',
							TOOLTIP: 'Altere a característica na primeira variação',
							EDIT_TOOLTIP:
								'A alteração da característica aplica-se a todas as variações presentes no mesmo nível'
						},
						LABEL: {
							FIELD: 'Opção',
							PLACEHOLDER: 'Introduzir a opçao'
						},
						PRICE: {
							FIELD: 'Preço',
							PLACEHOLDER: 'Introduzir o preço',
							EDIT_TOOLTIP:
								'As alterações de preço aplicam-se a todas as opções-filho'
						},
						SALE_PRICE: {
							FIELD: 'Preço Promocional',
							PLACEHOLDER: 'Introduzir o preço promocional',
							EDIT_TOOLTIP:
								'As alterações de preço promocional aplicam-se a todas as opções-filho'
						},
						QUANTITY: {
							FIELD: 'Qtd. disponível',
							PLACEHOLDER: 'Introduzir a quantidade',
							EDIT_TOOLTIP:
								'As alterações de quantidade aplicam-se a todas as opções-filho',
							EDIT_BLOCKED_TOOLTIP:
								'Para o gerir o stock necessita de ativar a gestão de stock para este produto',
							RESERVED_TOOLTIP: 'Quantidade reservada: '
						},
						STATE: {
							FIELD: 'Estado'
						},
						TAG: {
							FIELD: 'Etiqueta',
							TOOLTIP: 'Valor apresentado no carrinho, checkout e pedidos'
						},

						ACTIONS: {
							FIELD: 'Ações',
							ADD_VARIANT: 'Criar nova variação',
							EDIT_VARIANT: 'Editar variação',
							DELETE_VARIANT: 'Eliminar variação',
							ADD_OPTION_TO: 'Criar nova opção para ',
							ADD_OPTION: 'Criar novo ',
							EDIT_OPTION: 'Editar opção',
							DELETE_OPTION: 'Eliminar opção'
						},
						ERRORS: {
							NO_OPTIONS: 'Ainda não criou nenhuma opção nesta variação',
							MISSING_NAMES: 'Falta definir alguns nomes nas variações/opções',
							MISSING_PRICES: 'Falta definir alguns preços nas variações/opções',
							MISSING_QUANTITIES:
								'Falta definir algumas quantidades nas variações/opções',
							REQUIRED: 'É obrigatorio definir pelo menos uma opção de compra'
						}
					}
				},
				MESSAGES: {
					SAVE_PRODUCT_ERROR:
						'Ocorrem alguns erros ao salvar o produto e as suas variantes.',
					SAVE_PRODUCT_SUCCESS: 'Produto guardado com sucesso',
					SAVE_VARIANT_SUCCESS: 'Variação foi guardada',
					SAVE_OPTION_SUCCESS: 'Opção foi guardada',
					PUBLISHED_SUCCESSFULLY: 'Produto publicado com sucesso'
				},
				DELETE_VARIANT_MODAL: {
					TITLE: 'Eliminar variação',
					DESCRIPTION:
						'Tem certeza que quer eliminar permanentemente a variação selecionada?',
					WAIT_DESCRIPTION: 'A eliminar variação ...',
					DELETE_MESSAGE: 'A variação selecionada foi eliminada.'
				},
				NOT_PROCESSED_IMG_MODAL: {
					TITLE: 'Algumas imagens não processadas!!',
					DESCRIPTION:
						'O produto possui algumas imagens ainda não processadas pelo servidor. Se continuar, algumas dessas imagens não estarão disponíveis no catálogo.',
					BUTTON_PUBLISH: 'Publicar',
					BUTTON_NOT_PUBLISH: 'Não publicar'
				}
			},
			TAGS: {
				LABEL_PLURAL: 'Categorias',
				TITLE: 'Categoria',
				PLACEHOLDER: 'Selecione uma categoria...',
				CREATE: 'Criar nova categoria',
				CREATE_ERROR: 'Não foi possível criar a categoria',
				CREATE_SUCCESS: 'Categoria criada com sucesso',
				DELETE_DESCRIPTION: 'Tem a certeza que deseja apagar a categoria?',
				DELETE_SUCCESS: 'Categoria eliminada com sucesso',
				DELETE_ERROR: 'Houve um erro ao eliminar a categoria.',
				ADD: 'Adicionar categoria'
			}
		},
		NOTIFICATIONS: {
			TITLES: {
				LIST: 'Ofertas',
				CREATE: 'Criar oferta',
				EDIT: 'Editar oferta'
			},
			FORM: {
				STATE_SECTION: {
					TITLE: 'Estado da oferta:'
				},
				DETAILS_SECTION: {
					TITLE: 'Detalhes da oferta:',
					PICTURE: {
						TITLE: 'Fotografia da oferta'
					},
					TITLE_FIELD: {
						TITLE: 'Oferta promocional:'
					},
					DESCRIPTION: {
						TITLE: 'Descrição:'
					},
					VALID_DATE: {
						TITLE: 'Início e fim:',
						PLACEHOLDER: 'Selecionar o período',
						MAX_RANGE: '(período máximo: {{max_value}} dias)'
					},
					BLOCKED_DAY: {
						MSG: 'Já atingiu o número máximo de ofertas ativas neste dia',
						MAX_VALUE: '(máximo: {{max_value}}/dia)'
					}
				},
				ALERTS: {
					FORM_ERROR: 'Alguns campos não estão válidos.',
					NOTHING_TO_SAVE: 'Não possui alterações para guardar',
					CREATE_ERROR: 'Não é possível criar a oferta.',
					CREATE_SUCCESS: 'Oferta criada com sucesso.',
					UPDATE_ERROR: 'Não é possível atualizar a oferta.',
					UPDATE_SUCCESS: 'Oferta atualizada com sucesso.',
					DATES_CONFLICT:
						'Já existe uma oferta que intercepta o intervalo de datas selecionado.',
					SERVER_ERROR: 'Erro no servidor',
					RANGE_IN_PAST:
						'Possui um intervalo de datas no passado. Para poder atualizar a informação deve começar por atualizar esse intervalo.',
					SERVER_ERRORS: {
						range_to_big:
							'O período é muito grande, o período máximo é de {{max_date_range}} dias.',
						active_notifications_per_day:
							'Já possui dias com {{max_active_per_day}} (ou mais) notificações ativas dentro deste período.'
					}
				}
			},
			FIELDS: {
				TITLE: 'Título',
				SEARCH_BY_TITLE: 'Pesquisar por título da notificação',
				DESCRIPTION: 'Descrição',
				START_DATE: 'Data início',
				END_DATE: 'Data fim'
			},
			ACTION: {
				ADD: 'Adicionar nova oferta',
				EDIT: 'Editar oferta',
				DELETE: 'Eliminar oferta',
				GO_BACK_NOTIFICATION: 'Voltar à lista de ofertas'
			},
			STATE: {
				INACTIVE: 'Inativa',
				SCHEDULED: 'Programada',
				ACTIVE: 'Ativa'
			},
			UPDATE_STATE_DIALOG: {
				TITLE: 'Atualizar o estado para as ofertas selecionadas',
				MESSAGE: 'O estado foi atualizado para as ofertas selecionadas'
			},
			DELETE_MODAL: {
				TITLE: 'Eliminar ofertas',
				DESCRIPTION:
					'Tem certeza que quer eliminar permanentemente as ofertas selecionadas?',
				WAIT_DESCRIPTION: 'A eliminar ofertas ...',
				DELETE_MESSAGE: 'As ofertas selecionadas foram eliminadas.'
			},
			CARD: {
				DAILY_ACTIVITY: {
					TITLE: 'Atividade diária',
					DESC: 'Veja cada coluna para mais detalhes'
				},
				ACTIVITY: {
					TITLE: 'Atividade',
					DESC: 'Atividade da oferta',
					NO_ACTIVITY: 'A oferta ainda não apresenta atividade...',
					LEGENDS: {
						RECEIVED: 'Receberam',
						SAW: 'Viram',
						OPENED: 'Abriram'
					},
					PERSONS: 'pessoas'
				},
				ACTIVE_NOTIFICATION: {
					TITLE: 'Oferta ativa',
					TO: 'a'
				},
				OTHER_NOTIFICATION: {
					TITLE: 'Minhas ofertas'
				}
			}
		},
		POINT_OF_INTEREST: {
			NO_POINT_OF_INTEREST:
				'Por favor, comece por preencher as informações de perfil do ponto de interesse...',
			STATE: {
				ACTIVE: {
					TOOLTIP: 'Ponto de interesse encontra se ativo'
				},
				PENDING: {
					MENU_TOOLTIP:
						'Preencha aqui as informações em falta para poder ativar o seu Ponto de interesse',
					TOOLTIP: 'Faltam informações para ativar o ponto de interesse'
				}
			},
			FORM: {
				GENERAL_SECTION: {
					TITLE: 'Informação geral:',
					TITLE_FIELD: {
						TITLE: 'Título:'
					},
					DESCRIPTION_FIELD: {
						TITLE: 'Descrição:'
					},
					CATEGORY_FIELD: {
						TITLE: 'Categoria:',
						SELECT_ONE: 'Selecione uma categoria...'
					},
					WEBSITE: {
						TITLE: 'Website'
					}
				},
				ADDRESS_SECTION: {
					TITLE: 'Morada:',
					STREET_FIELD: {
						TITLE: 'Rua:'
					},
					REGION_FIELD: {
						TITLE: 'Distrito:'
					},
					COUNTRY_FIELD: {
						TITLE: 'Pais:'
					},
					POSTAL_CODE_FIELD: {
						TITLE: 'Código Postal:'
					}
				},
				AVAILABLE_PAYMENTS_SECTION: {
					TITLE: 'Métodos de pagamento disponíveis:',
					TYPES: {
						money: 'Dinheiro',
						atm: 'Multibanco',
						atm_contactless: 'Multibanco contactless',
						mb_way: 'MBWay'
					}
				},
				EXTRA_FEATURES_SECTION: {
					TITLE: 'Serviços extra:'
				},
				OPENING_HOURS_SECTION: {
					TITLE: 'Horário de funcionamento:',
					SELECT_DAY: 'Escolha o dia',
					ACTION: {
						ADD: 'Adicionar horário',
						DELETE: 'Eliminar horário'
					}
				},
				CONTACTS_SECTION: {
					TITLE: 'Contactos:',
					EMAIL_FIELD: {
						TITLE: 'Email:'
					},
					PHONE_FIELD: {
						TITLE: 'Telefone:'
					}
				},
				SOCIAL_NETWORKS_SECTION: {
					TITLE: 'Redes sociais:'
				},
				GALLERY: {
					MAIN_PICTURE: 'Foto principal',
					ADD_PICTURE: 'Adicionar imagens',
					ADD_PICTURE_TOOLTIP:
						'Pode adicionar até 5 fotos com um tamanho máximo de 2 MB cada.'
				},
				GEOFENCE: {
					LOCATION: 'Localização',
					RADIUS:
						'Defina a área de geofence onde os utilizadores poderão receber ofertas',
					MAP_AJUST:
						'Ajuste a posição do ponto de interesse arrastando o ponto no mapa:'
				},
				ALERTS: {
					NOTHING_TO_SAVE: 'Não possui alterações para guardar',
					CREATE_ERROR:
						'Não é possível criar o Ponto de interesse, tente mais tarde!!',
					CREATE_SUCCESS: 'Ponto de interesse criado com sucesso.',
					UPDATE_ERROR:
						'Não é possível atualizar o ponto de interesse, tente mais tarde !!',
					UPDATE_SUCCESS: 'Ponto de interesse atualizado com sucesso.',
					UPLOAD_FILES_ERROR:
						'Só pode adicionar até 5 fotos com um tamanho máximo de 2 MB cada.Foram descartados os ficheiros: {{ignoreFiles}}'
				}
			}
		},
		ORDER: {
			LABEL: 'Encomenda',
			LABEL_PLURAL: 'Encomendas',
			REFERENCE: 'Referência',
			ORDER_STATUS: 'Estado da encomenda',
			ORDERS_STATUS: 'Estado das encomendas',
			ORDER_PAYMENT_STATUS: 'estado do pagamento da encomenda',
			ORDERS_PAYMENT_STATUS: 'estado do pagamento das encomendas',
			UPDATE_STATUS: 'Atualizar estado da(s) encomenda(s)',
			STATUS: {
				CANCELED: 'Cancelada',
				COMPLETED: 'Finalizada',
				NEW: 'Nova',
				PROCESSING: 'A processar'
			},
			PAYMENT: 'Pagamento',
			SUMMARY: 'Resumo',
			SHIPPING: 'Envio',
			TAXES: 'Taxas',
			PAYMENT_STATUS: {
				LABEL: 'Estado do Pagamento',
				COMPLETED: 'Efetuado',
				CREATED: 'Criando/ Não pago'
			},
			ERROR_UPDATING_ORDER: 'Erro a atualizar a encomenda',
			ERROR_REQUESTING: 'Erro ao descarregar as encomendas do servido.'
		},
		SETTINGS: {
			LABEL: 'Configurações'
		},
		SHIPPING: {
			METHODS: {
				DELETE: {
					TITLE: 'Eliminar método de pagamente',
					DESCRIPTION: 'Deseja eliminar este método de pagamento?',
					ERROR: 'Houve um problema a eliminar o método de pagamento.',
					SUCCESS: 'Método de pagamento eliminado com sucesso'
				},
				LABEL: 'Métodos de envio',
				ADD: 'Adicionar método de envio',
				NAME: 'Nome do método',
				FULFILLMENT_TYPE: 'Tipo de entrega',
				FULFILLMENT_SHIPPING: 'Entrega',
				FULFILLMENT_PICKUP: 'Ponto de Entrega'
			}
		}
	}
};
