// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { OrderModel } from '../_models/order.model';
import { ORDER_STATUS } from './../_models/order.model';

export enum OrderActionTypes {
	OrderRequest = '[Order Request] Order Request',
	OrderRequested = '[Order Requested] Order Requested',
	OrderUpdated = '[Edit Order Dialog] Order Updated',
	OrdersUpdateStatus = '[Order List Page] Orders Update status',
	OrdersStatusUpdated = '[Order List Page] Orders Status Updated',
	OrdersPageRequest = '[Orders List Page] Orders Page Request',
	OrdersPageLoaded = '[Orders API] Orders Page Loaded',
	OrdersPageCancelled = '[Orders API] Orders Page Cancelled',
	OrdersPageToggleLoading = '[Orders] Orders Page Toggle Loading',
	OrderActionToggleLoading = '[Orders] Orders Action Toggle Loading',
	OrderActionError = '[Orders] Orders Action Error'
}

export class OrderRequest implements Action {
	readonly type = OrderActionTypes.OrderRequest;

	constructor(
		public payload: {
			shopId: string;
			referenceId: string;
		}
	) {}
}
export class OrderRequested implements Action {
	readonly type = OrderActionTypes.OrderRequested;

	constructor(
		public payload: {
			orderFullInfo: Update<OrderModel>;
		}
	) {}
}

export class OrderUpdated implements Action {
	readonly type = OrderActionTypes.OrderUpdated;

	constructor(
		public payload: {
			partialOrder: Update<OrderModel>; // For State update
			order: OrderModel; // For Server update (through service)
		}
	) {}
}

export class OrdersUpdateStatus implements Action {
	readonly type = OrderActionTypes.OrdersUpdateStatus;

	constructor(
		public payload: {
			orders: OrderModel[];
			status: ORDER_STATUS;
		}
	) {}
}
export class OrdersStatusUpdated implements Action {
	readonly type = OrderActionTypes.OrdersStatusUpdated;

	constructor(
		public payload: {
			orders: { _id: string; status: ORDER_STATUS }[];
		}
	) {}
}

export class OrdersPageRequest implements Action {
	readonly type = OrderActionTypes.OrdersPageRequest;

	constructor(public payload: { page: QueryParamsModel; shopId: string }) {}
}

export class OrdersPageLoaded implements Action {
	readonly type = OrderActionTypes.OrdersPageLoaded;

	constructor(
		public payload: {
			orders: OrderModel[];
			totalCount: number;
			page: QueryParamsModel;
		}
	) {}
}

export class OrdersPageCancelled implements Action {
	readonly type = OrderActionTypes.OrdersPageCancelled;
}

export class OrdersPageToggleLoading implements Action {
	readonly type = OrderActionTypes.OrdersPageToggleLoading;

	constructor(public payload: { isLoading: boolean }) {}
}

export class OrderActionToggleLoading implements Action {
	readonly type = OrderActionTypes.OrderActionToggleLoading;

	constructor(public payload: { isLoading: boolean }) {}
}

export class OrderActionError implements Action {
	readonly type = OrderActionTypes.OrderActionError;

	constructor(public payload: { showErrorMessage: false | string }) {}
}

export type OrderActions =
	| OrderRequest
	| OrderRequested
	| OrderUpdated
	| OrdersStatusUpdated
	| OrdersUpdateStatus
	| OrdersPageRequest
	| OrdersPageLoaded
	| OrdersPageCancelled
	| OrdersPageToggleLoading
	| OrderActionToggleLoading
	| OrderActionError;
