import { HttpParams } from '@angular/common/http';
export class QueryParamsModel {
	// fields
	filter: any;
	sortOrder: string; // asc || desc
	sortField: string;
	start: number;
	limit: number;

	// constructor overrides
	constructor(
		_filter: any,
		_sortOrder: string = 'asc',
		_sortField: string = '',
		_start: number = 0,
		_limit: number = 10
	) {
		this.filter = _filter;
		this.sortOrder = _sortOrder;
		this.sortField = _sortField;
		this.start = _start;
		this.limit = _limit;
	}
}

export type HttpParamsOption = HttpParams | { [param: string]: string | string[] };
