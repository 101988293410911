import { PointOfInterest } from './../_models/point-of-interest.model';
import { environment } from './../../../../environments/environment';

// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import {
	HttpUtilsService,
	QueryParamsModel,
	QueryResultsModel
} from '../../_base/crud';
// Models
import { NotificationModel } from '../_models/notification.model';
import { HttpParamsOption } from '../../_base/crud/models/query-models/query-params.model';

const API_NOTIFICATIONS_URL = `${environment.apiEndpoint}/notifications`;
const API_USER_NOTIFICATIONS_URL = `${environment.apiEndpoint}/user-notifications`;

// Real REST API
@Injectable({
	providedIn: 'root'
})
export class NotificationsService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(
		new QueryParamsModel({}, 'asc', '', 0, 10)
	);

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {}

	// CREATE =>  POST: add a new notification to the server
	async createNotification(
		notification: NotificationModel,
		imageFile?: Blob
	): Promise<NotificationModel> {
		if (!notification) {
			return null;
		}
		const payload = await notification.getPayload(imageFile);
		// const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http
			.post<NotificationModel>(API_NOTIFICATIONS_URL, payload)
			.toPromise();
	}

	// READ
	getAllNotifications(): Observable<NotificationModel[]> {
		return this.http.get<NotificationModel[]>(API_NOTIFICATIONS_URL);
	}

	getNotificationById(
		notificationId: number,
		inCurrentLanguage = true
	): Observable<NotificationModel> {
		let requestOptions = {};
		if (!inCurrentLanguage) {
			const headerDict = {
				'Accept-Language': '*'
			};
			requestOptions = {
				headers: new HttpHeaders(headerDict)
			};
		}

		return this.http.get<NotificationModel>(
			API_NOTIFICATIONS_URL + `/${notificationId}`,
			requestOptions
		);
	}

	// Server should return filtered/sorted result
	findNotifications(queryParams: QueryParamsModel): Observable<NotificationModel[]> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = API_NOTIFICATIONS_URL;
		return this.http.get<NotificationModel[]>(url, {
			headers: httpHeaders,
			params: httpParams
		});
	}

	// UPDATE => PUT: update the notification on the server
	async updateNotification(
		notification: NotificationModel,
		imageFile?: Blob
	): Promise<any> {
		if (!notification) {
			return null;
		}
		const payload = await notification.getPayload(imageFile);
		const url = `${API_NOTIFICATIONS_URL}/${notification.id}`;
		return this.http.put(url, payload).toPromise();
	}

	// UPDATE Status
	updateStatusForNotification(
		notifications: NotificationModel[],
		state: string
	): Promise<boolean[]> {
		const body = {
			state
		};
		return Promise.all(
			notifications.map((notification) => {
				return this.http
					.put<QueryResultsModel>(
						`${API_NOTIFICATIONS_URL}/${notification.id}`,
						body
					)
					.toPromise()
					.then((res) => !!res)
					.catch(() => false);
			})
		);
	}

	// DELETE => delete the notification from the server
	deleteNotification(notificationId: number): Observable<NotificationModel> {
		const url = `${API_NOTIFICATIONS_URL}/${notificationId}`;
		return this.http.delete<NotificationModel>(url);
	}

	deleteNotifications(ids: number[] = []): Promise<boolean[]> {
		return Promise.all(
			ids.map((id) => {
				const url = `${API_NOTIFICATIONS_URL}/${id}`;
				return this.http
					.delete<QueryResultsModel>(url)
					.toPromise()
					.then((res) => !!res)
					.catch(() => false);
			})
		);
	}

	notificationsRanking(
		filterParams: object
	): Promise<{ notification: NotificationModel; count: number }[]> {
		const httpParams = this.httpUtils.getFilterHTTPParams(filterParams);
		const url = `${API_NOTIFICATIONS_URL}/ranking`;
		return this.http
			.get<{ notification: NotificationModel; count: number }[]>(url, {
				params: httpParams
			})
			.toPromise()
			.then((res) => res)
			.catch(() => []);
	}

	/**
	 *
	 * @param filterParams // {point_of_interest: 1, notification_nin: 12}
	 */
	notAvailableDays(filterParams: object): Promise<any> {
		const httpParams = this.httpUtils.getFilterHTTPParams(filterParams);
		const url = `${API_NOTIFICATIONS_URL}/days-blocked`;
		return this.http
			.get<any>(url, {
				params: httpParams
			})
			.toPromise()
			.then((res) => res)
			.catch(() => []);
	}

	/**
	 *
	 * @param filterParams
	 * received data example: {2020-03-27: {opened: 1, viewed: 2, received: 3, sent: 5},...}
	 */
	notificationsDailyActivity(filterParams: object): Promise<any> {
		const httpParams = this.httpUtils.getFilterHTTPParams(filterParams);
		const url = `${API_NOTIFICATIONS_URL}/daily-activity`;
		return this.http
			.get<any>(url, {
				params: httpParams
			})
			.toPromise()
			.then((res) => res)
			.catch(() => {});
	}

	/**
	 *
	 * @param filterParams
	 * received data example: {opened: 1, viewed: 2, received: 3, sent: 5}
	 */
	notificationActivity(filterParams: object): Promise<any> {
		const httpParams = this.httpUtils.getFilterHTTPParams(filterParams);
		const url = `${API_NOTIFICATIONS_URL}/activity`;
		return this.http
			.get<any>(url, {
				params: httpParams
			})
			.toPromise()
			.then((res) => res)
			.catch(() => {});
	}

	notificationsStatistics(
		point_of_interest: number
	): Promise<{ today: object; current_month: object; last_month: object }> {
		const httpParams = this.httpUtils.getFilterHTTPParams({ point_of_interest });
		const url = `${API_NOTIFICATIONS_URL}/statistics`;
		return this.http
			.get<{ today: object; current_month: object; last_month: object }>(url, {
				params: httpParams
			})
			.toPromise()
			.then((res) => res)
			.catch(() => null);
	}
}
