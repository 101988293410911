// NGRX
import { Action } from '@ngrx/store';

export enum ECommerceActionTypes {
	ECommerceInfoToggleLoading = '[ECommerce Info Loading] ECommerce info Toggle Loading',
	POIeCommerceInfoRequest = '[Request ECommerce Info] POI Ecommerce info',
	POIeCommerceInfoLoaded = '[Request ECommerce Info Loaded] POI Ecommerce info loaded'
}

export class ECommerceInfoToggleLoading implements Action {
	readonly type = ECommerceActionTypes.ECommerceInfoToggleLoading;

	constructor(public payload: { isLoading: boolean }) {}
}

export class POIeCommerceInfoRequest implements Action {
	readonly type = ECommerceActionTypes.POIeCommerceInfoRequest;

	constructor(public payload: { pointOfInterestId: number }) {}
}

export class POIeCommerceInfoLoaded implements Action {
	readonly type = ECommerceActionTypes.POIeCommerceInfoLoaded;

	constructor(public payload: { relations: { shopId: string; poiId: number }[] }) {}
}

export type ECommerceActions =
	| POIeCommerceInfoRequest
	| ECommerceInfoToggleLoading
	| POIeCommerceInfoLoaded;
