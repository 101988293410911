import { takeWhile } from 'rxjs/operators';
import { ECommerceConnectionService } from './../../../../../core/graphql/e-commerce/e-commerce.service';
import { TagsService } from './../../../../../core/point-of-interest/_services/tags.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from './../../../../../../environments/environment';
import { ImageInfoModel } from './../../../../../core/point-of-interest/_models/image-info.model';
import {
	MediaFile,
	ImageSizes
} from './../../../../../core/_base/crud/models/media-file.model';
import { GalleryPicture } from './../../../../partials/content/widgets/gallery-picture/gallery-picture.component';
import {
	LayoutUtilsService,
	MessageType,
	UI_INFO_TYPE
} from './../../../../../core/_base/crud/utils/layout-utils.service';
import { ProductsService } from './../../../../../core/point-of-interest/_services/products.service';
import { TagModel } from './../../../../../core/point-of-interest/_models/tag.model';
import { MIN_TITLE_LEN } from './../../../../../core/point-of-interest/_consts/general';
import { ProductModel } from './../../../../../core/point-of-interest/_models/product.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnDestroy
} from '@angular/core';
import {
	objectPathExists,
	runPromisesSequentially,
	isDefined
} from './../../../../../core/_base/crud/utils/helper.function';
import { FileRecord } from '@reactioncommerce/file-collections';

@Component({
	selector: 'kt-product-basic-info',
	templateUrl: './product-basic-info.component.html'
})
export class ProductBasicInfoComponent implements OnInit, OnDestroy {
	@Input() shopId: string;
	@Input() poiId: number;
	@Input() product: ProductModel;

	// Force product query since this is a legacy component and not use graphql directly
	@Output() productChanged = new EventEmitter<true>();

	productForm: FormGroup;

	shopTags: TagModel[] = [];

	allMediasAreProcessed = true;

	gallery: GalleryPicture[] = [];

	alertMessage: {
		message?: string;
		type?: UI_INFO_TYPE;
		duration?: number;
	} = {};

	actionLoading$ = new BehaviorSubject<boolean>(false);
	galleryChangeDetector$ = new BehaviorSubject<boolean>(false);

	inView = true;

	constructor(
		private formBuilder: FormBuilder,
		private productService: ProductsService,
		private layoutUtilsSrvc: LayoutUtilsService,
		private cd: ChangeDetectorRef,
		private translate: TranslateService,
		private tagSrvc: TagsService
	) {}

	async ngOnInit() {
		this.setActionLoadingState(true);

		this.allMediasAreProcessed = true;

		this.watchShopTags();

		// Load the gallery
		const medias: ImageInfoModel[] = objectPathExists(this.product, 'media', true);
		if (medias) {
			let i = 0;
			let hasMain = false;
			this.gallery = [];
			this.allMediasAreProcessed = ProductModel.allMediasProcessed(this.product);
			medias.forEach((img) => {
				const imgPath = MediaFile.getGQLImagePathSize(
					img.URLs,
					ImageSizes.original,
					environment.ecommerceEndpoint
				);
				const isMainPict = hasMain ? false : img.priority === 0;
				hasMain = isMainPict;
				this.gallery.push({
					id: i,
					url: imgPath,
					mainPicture: isMainPict,
					serverId: img._id
				});
				i++;
			});
		}

		this.productForm = this.formBuilder.group({
			id: [this.product._id],
			isVisible: [this.product.isVisible],
			title: [
				this.product.title,
				[
					Validators.required,
					Validators.minLength(MIN_TITLE_LEN),
					Validators.maxLength(75)
				]
			],
			description: [this.product.description, [Validators.maxLength(255)]],
			category: [
				ProductModel.getProductCategory(this.product)
					? ProductModel.getProductCategory(this.product)
					: null,
				Validators.required
			]
		});

		this.setActionLoadingState(false);

		this.cd.detectChanges();
	}

	setActionLoadingState(isLoading: boolean) {
		if (!!this.productForm) {
			if (isLoading) this.productForm.disable();
			else this.productForm.enable();
		}

		this.actionLoading$.next(isLoading);
	}

	/**
	 * Category autocomplete
	 */
	displayCategoryFn(c1: TagModel) {
		return (c1 && c1.displayTitle) || (c1 && c1.name) || '';
	}

	/**
	 * Load Shop Tags List
	 */
	watchShopTags(force: boolean = false) {
		const tags = this.tagSrvc
			.watchTags(this.shopId, force)
			.pipe(takeWhile(() => this.inView))
			.subscribe((result) => {
				const tagsResponse = ECommerceConnectionService.getEntitiesFromQuery<
					TagModel
				>('tags', result);
				this.shopTags = tagsResponse.items || [];
			});
	}

	/**
	 * Create category
	 */
	async createCategory() {
		const dialogRef = this.layoutUtilsSrvc.createOrEditTagModal();

		dialogRef.afterClosed().subscribe(async (res) => {
			// Dialog close
			if (!res || !res.name) {
				return;
			}

			const result = await this.tagSrvc.createTag(
				this.shopId,
				this.poiId,
				res.name,
				undefined,
				true,
				true
			);

			if (!!result)
				this.layoutUtilsSrvc.showActionNotification(
					'PRODUCTS.TAGS.CREATE_SUCCESS',
					MessageType.Create
				);
			else
				this.layoutUtilsSrvc.showActionNotification(
					'PRODUCTS.TAGS.CREATE_ERROR',
					MessageType.Create
				);
		});
	}

	async saveGallery(productId: string) {
		let result = true;
		// First check the deleted images and call the deleteMediaRecord
		const medias: ImageInfoModel[] = objectPathExists(this.product, 'media', true);
		if (medias) {
			const mediasToDelete = medias.filter((img) => {
				return (
					!!this.gallery &&
					!this.gallery.find((galleryImg) => galleryImg.serverId === img._id)
				);
			});
			if (mediasToDelete && mediasToDelete.length > 0) {
				// Delete the media records
				const requestsSequecially = mediasToDelete.map((media) => () =>
					this.productService.deleteMediaRecord(this.poiId, media._id)
				);

				result = await runPromisesSequentially(requestsSequecially).then((results) =>
					results.every((success) => !!success)
				);
			}
		}

		if (!!result) {
			// Upload the new images and update the priority of others
			result = await this.uploadFiles(productId);
		}

		return result;
	}

	priorityCount = 0;
	getGalleryImagePriority(index: number) {
		const galleryImg = this.gallery[index];
		if (!galleryImg) {
			return null;
		}
		// Main image is always the first priority;
		if (!galleryImg.mainPicture) {
			this.priorityCount++;
		}
		return galleryImg.mainPicture ? 0 : this.priorityCount;
	}

	getFileMetadata(productId: string, index: number) {
		const galleryImg = this.gallery[index];
		if (!galleryImg) {
			return {};
		}
		this.priorityCount++;
		return {
			productId,
			priority: this.getGalleryImagePriority(index)
		};
	}

	uploadFiles(productId: string) {
		this.priorityCount = 0;
		const promises = this.gallery.map(async (galleryFile, index) => {
			// File already exits in the server
			if (isDefined(galleryFile.serverId)) {
				// Update the priority of media record
				return this.productService.updatePriorityMediaRecord(
					this.poiId,
					galleryFile.serverId,
					this.getGalleryImagePriority(index)
				);
			} else if (isDefined(galleryFile.file)) {
				// If needs upload some image to the Serve this image isn't available yet
				this.allMediasAreProcessed = false;

				// New media record needs to be uploaded to the server
				const fileRecord = FileRecord.fromFile(galleryFile.file);
				fileRecord.metadata = this.getFileMetadata(productId, index);

				await fileRecord.upload();

				// We insert only AFTER the server has confirmed that all chunks were uploaded
				return this.productService.createMediaRecord(
					this.poiId,
					fileRecord.document
				);
			}
		});

		return Promise.all(promises)
			.then((responses) => {
				return responses.every((success) => !!success);
			})
			.catch((error) => {
				return false;
			});
	}

	uploadError(event: { message: string }) {
		this.alertMessage = {
			message: event.message,
			type: UI_INFO_TYPE.WARNING,
			duration: 10000
		};
	}

	onAlertClose() {
		this.alertMessage = null;
	}

	async onSumbit() {
		this.setActionLoadingState(true);
		let errorMessage = null;

		// Save the product gallery
		const imagesSaved = await this.saveGallery(this.product._id);

		if (!imagesSaved)
			errorMessage = this.translate.instant(
				'PRODUCTS.FORM.ERRORS.ERROR_SAVING_IMAGES'
			);

		const newData = {
			isVisible: !!this.productForm.get('isVisible').value,
			title: this.productForm.get('title').value,
			description: this.productForm.get('description').value,
			tagIds: this.productForm.get('category').value
				? [this.productForm.get('category').value._id]
				: []
		};

		let productSaved = false;
		productSaved = !!(await this.productService.updateProductThrougProxy(
			this.poiId,
			this.product._id,
			newData
		));

		if (!productSaved)
			errorMessage = `${errorMessage}, ${this.translate.instant(
				'PRODUCTS.FORM.ERRORS.ERROR_SAVING_BASIC_INFO'
			)}`;

		this.setActionLoadingState(false);

		if (!!errorMessage) {
			this.layoutUtilsSrvc.showActionNotification(errorMessage, MessageType.Create);
		} else {
			this.galleryChangeDetector$.next(false);
			this.productForm.markAsPristine();
			this.productChanged.next(true);
		}
	}

	get isFormPristine() {
		return !!this.productForm && !!this.productForm.pristine;
	}

	get isFormValid() {
		return !!this.productForm && !!this.productForm.valid;
	}

	get hasMediaChanges() {
		return false;
	}

	get galleryHasChanges() {
		return this.galleryChangeDetector$;
	}

	ngOnDestroy(): void {
		this.inView = false;
	}
}
