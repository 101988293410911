import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'kt-alert-dialog',
	templateUrl: './alert-dialog.component.html'
})
export class AlertDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<AlertDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	closeAlert() {
		this.dialogRef.close();
	}
}
