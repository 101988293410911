import { ShippingMethodModel } from './../_models/shipping-methods.model';
import {
	ShippingMethodsPageLoaded,
	ShippingMethodsListToggleLoading,
	ShippingMethodUpdated,
	ShippingMethodsActionMessage,
	ShippingMethodsOnServerCreated,
	ShippingMethodCreated
} from './../_actions/shipping-methods.actions';
import { ECommerceConnectionService } from './../../graphql/e-commerce/e-commerce.service';
import {
	ShippingMethodsPageRequest,
	ShippingMethodsActionTypes
} from '../_actions/shipping-methods.actions';
import { AppState } from '../../reducers/index';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ShippingMethodsActionToggleLoading } from '../_actions/shipping-methods.actions';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import { ShippingMethodsService } from './../_services/shipping-methods.service';

@Injectable()
export class ShippingMethodsEffects {
	showActionLoadingDistpatcher = new ShippingMethodsActionToggleLoading({
		isLoading: true
	});
	hideActionLoadingDistpatcher = new ShippingMethodsActionToggleLoading({
		isLoading: false
	});

	showListLoadingDistpatcher = new ShippingMethodsListToggleLoading({
		isLoading: true
	});
	hideListLoadingDistpatcher = new ShippingMethodsListToggleLoading({
		isLoading: false
	});

	@Effect()
	requestShippingMethods$ = this.actions$.pipe(
		ofType<ShippingMethodsPageRequest>(
			ShippingMethodsActionTypes.ShippingMethodsPageRequest
		),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showListLoadingDistpatcher);
			return this.shippingMethosSrvc.getAllShippingMethods(payload.shopId).pipe(
				catchError((error) => {
					console.error('Cannot find the shipping method!', error);
					return of(false);
				})
			);
		}),
		map((value) => {
			if (!!value) {
				const shippingMethodsResponse = ECommerceConnectionService.getEntitiesFromQuery<
					ShippingMethodModel
				>('flatRateFulfillmentMethods', value);

				if (!shippingMethodsResponse) return;

				return new ShippingMethodsPageLoaded({
					shippingMethods: shippingMethodsResponse.items,
					totalCount: shippingMethodsResponse.totalCount
				});
			}
			return this.hideListLoadingDistpatcher;
		})
	);

	@Effect()
	updateShippingMethod$ = this.actions$.pipe(
		ofType<ShippingMethodUpdated>(ShippingMethodsActionTypes.ShippingMethodUpdated),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showActionLoadingDistpatcher);
			return this.shippingMethosSrvc
				.updateFlatShippingMethod(payload.shippingMethod, payload.shopId)
				.pipe(
					catchError((error) => {
						console.error('Cannot update the shipping method!', error);
						return of(false);
					})
				);
		}),
		map((value) => {
			let actionMessage;
			if (!!value) {
				actionMessage = {
					type: 'success',
					message: 'SHIPPING.METHODS.SUCCESSFULLY_CREATED'
				};
			} else {
				actionMessage = {
					type: 'error',
					message: 'SHIPPING.METHODS.ERROR_ON_CREATION'
				};
			}

			this.store.dispatch(
				new ShippingMethodsActionMessage({
					actionMessage
				})
			);

			return this.hideActionLoadingDistpatcher;
		})
	);

	@Effect()
	createShippingMethod$ = this.actions$.pipe(
		ofType<ShippingMethodsOnServerCreated>(
			ShippingMethodsActionTypes.ShippingMethodsOnServerCreated
		),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showActionLoadingDistpatcher);
			return this.shippingMethosSrvc
				.createFlatShippingMethod(payload.shopId, payload.shippingMethod)
				.pipe(
					catchError((error) => {
						console.error('Cannot update the shipping method!', error);
						return of(false);
					})
				);
		}),
		map((value) => {
			let actionMessage;
			if (!!value) {
				actionMessage = {
					type: 'success',
					message: 'SHIPPING.METHODS.SUCCESSFULLY_CREATED'
				};

				const shippingMethod = ECommerceConnectionService.getEntityFromQuery<
					ShippingMethodModel
				>('createFlatRateFulfillmentMethod.method', value);

				this.store.dispatch(new ShippingMethodCreated({ shippingMethod }));
			} else {
				actionMessage = {
					type: 'error',
					message: 'SHIPPING.METHODS.ERROR_ON_CREATION'
				};
			}

			this.store.dispatch(
				new ShippingMethodsActionMessage({
					actionMessage
				})
			);

			return this.hideActionLoadingDistpatcher;
		})
	);

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private shippingMethosSrvc: ShippingMethodsService
	) {}
}
