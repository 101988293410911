import { selectRelations } from './../../../core/point-of-interest/_selectors/e-commerce.selectors';
import { selectedPointOfInterest } from './../../../core/auth/_selectors/auth.selectors';
import { PointOfInterest } from './../../../core/point-of-interest/_models/point-of-interest.model';
import { takeWhile } from 'rxjs/operators';
import { AppState } from './../../../core/reducers/index';
import { select, Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';

@Component({
	selector: 'kt-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
	inView = true;
	pointOfInterest$: Observable<PointOfInterest>;
	pointOfInterestRelations$: Observable<
		{
			shopId: string;
			poiId: number;
		}[]
	>;
	poiId: number;
	shopId: string | null | -1 = null;

	constructor(private store: Store<AppState>, private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterestRelations$ = this.store.pipe(select(selectRelations));

		combineLatest([this.pointOfInterest$, this.pointOfInterestRelations$])
			.pipe(takeWhile(() => this.inView))
			.subscribe(([pointOfInterest, poiRelations]) => {
				if (!pointOfInterest) return;

				this.poiId = pointOfInterest.id;
				if (this.poiId && poiRelations && poiRelations.length) {
					const relation = poiRelations.find((rel) => rel.poiId === this.poiId);
					if (relation) this.shopId = relation.shopId;
				}
				this.cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.inView = false;
	}
}
