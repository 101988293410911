import { SharedModule } from './../../../shared/shared.module';
import { OptionsListComponent } from './product-form/variant-list/options-list/options-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { VariantListComponent } from './product-form/variant-list/variant-list.component';
import { ProductsComponent } from './products.component';
import { ProductsListComponent } from './product-list/product-list.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { FileUploadModule } from './../../partials/content/general/file-upload/file-upload.module';
import { LayoutUtilsService } from './../../../core/_base/crud/utils/layout-utils.service';
import { TypesUtilsService } from './../../../core/_base/crud/utils/types-utils.service';
import { HttpUtilsService } from './../../../core/_base/crud/utils/http-utils.service';
// import { InterceptService } from './../../../core/_base/crud/';
import { productsReducer } from '../../../core/point-of-interest';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import {
	MatSelectModule,
	MatDialogModule,
	MatIconModule,
	MatSortModule,
	MatTableModule,
	MatCheckboxModule,
	MatMenuModule,
	MatProgressSpinnerModule,
	MatPaginatorModule,
	MatButtonModule,
	MatInputModule,
	MatAutocompleteModule,
	MatRadioModule,
	MatNativeDateModule,
	MatProgressBarModule,
	MatDatepickerModule,
	MatCardModule,
	MatSnackBarModule,
	MatTabsModule,
	MatTooltipModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatRippleModule,
	MatDividerModule,
	MatChipsModule
} from '@angular/material';
import { NgbProgressbarModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductEffects } from '../../../core/point-of-interest';
import { HttpClientModule } from '@angular/common/http';
import {
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent,
	ConfirmationDialogComponent,
	PublishProductDialogComponent,
	CreateTagDialogComponent
} from '../../partials/content/crud';
import { ProductGalleryComponent } from './product-form/product-gallery/product-gallery.component';
import { ProductBasicInfoComponent } from './product-form/product-basic-info/product-basic-info.component';
import { ProductVariantsComponent } from './product-form/product-variants/product-variants.component';
import { ProductStorePositionComponent } from './product-form/product-store-position/product-store-position.component';
import { ProductVariantsInfoComponent } from './product-form/shared/product-variants-info/product-variants-info.component';

// tslint:disable-next-line:class-name
const routes: Routes = [
	{
		path: '',
		component: ProductsComponent
	},
	{
		path: 'add',
		component: ProductFormComponent
	},
	{
		path: 'edit/:id',
		component: ProductFormComponent
	}
];

@NgModule({
	imports: [
		MatDialogModule,
		CommonModule,
		HttpClientModule,
		PartialsModule,
		NgbModule,
		RouterModule.forChild(routes),
		StoreModule.forFeature('products', productsReducer),
		EffectsModule.forFeature([ProductEffects]),
		CoreModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		NgbProgressbarModule,
		FileUploadModule,
		MatRippleModule,
		MatDividerModule,
		MatExpansionModule,
		MatChipsModule,
		SharedModule
	],
	providers: [
		// { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
	],
	entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		ConfirmationDialogComponent,
		PublishProductDialogComponent,
		VariantListComponent,
		OptionsListComponent,
		CreateTagDialogComponent
	],
	declarations: [
		ProductsComponent,
		ProductFormComponent,
		ProductsListComponent,
		ProductGalleryComponent,
		VariantListComponent,
		OptionsListComponent,
		ProductBasicInfoComponent,
		ProductVariantsComponent,
		ProductStorePositionComponent,
		ProductVariantsInfoComponent
	],
	bootstrap: [VariantListComponent]
})
export class ProductsModule {}
