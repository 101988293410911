import gql from 'graphql-tag';
import TagInfo from './../fragments/tag';

const updateTagMutation = gql`
	mutation updateTagMutation($input: UpdateTagInput!) {
		updateTag(input: $input) {
			tag {
				...TagInfo
			}
		}
	}
	${TagInfo}
`;

export default updateTagMutation;
