import { TranslateService } from '@ngx-translate/core';
import { OnInit, Output, EventEmitter, Input } from '@angular/core';
// Angular
import { Component } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

/**
 * Sample Time menu dropdown
 */
@Component({
	selector: 'kt-time-menu',
	templateUrl: './time-menu.component.html',
	styleUrls: ['./time-menu.component.scss']
})
export class TimeMenuComponent implements OnInit {
	@Input() timeOptions: {
		value: string;
		dateRange: {
			from: NgbDate;
			to: NgbDate;
		};
	}[] = [];
	selectedValue: string;
	@Output() dateRangeChanged = new EventEmitter<{ from: NgbDate; to: NgbDate }>();
	constructor(
		protected calendar: NgbCalendar,
		private translate: TranslateService
	) {}

	ngOnInit() {
		if (!this.timeOptions || this.timeOptions.length === 0) {
			const today = this.calendar.getToday();
			this.timeOptions = [
				{
					value: this.translate.instant('DASHBOARD.TIME_MENU.VALUES.LAST_DAYS', {
						days: 7
					}),
					dateRange: {
						from: this.calendar.getPrev(today, 'd', 7),
						to: today
					}
				},
				{
					value: this.translate.instant('DASHBOARD.TIME_MENU.VALUES.LAST_MONTHS', {
						months: 3
					}),
					dateRange: {
						from: this.calendar.getPrev(today, 'm', 3),
						to: today
					}
				},
				{
					value: this.translate.instant('DASHBOARD.TIME_MENU.VALUES.LAST_MONTHS', {
						months: 6
					}),
					dateRange: {
						from: this.calendar.getPrev(today, 'm', 6),
						to: today
					}
				}
			];
		}
		// By default select the first option
		this.selectionChange(0);
	}

	selectionChange(index: number) {
		if (index >= 0) {
			this.selectedValue = this.timeOptions[index].value;
			this.dateRangeChanged.next(this.timeOptions[index].dateRange);
		}
	}
}
