import { AppState } from './../../../reducers/index';
import { Logout } from './../../../auth/_actions/auth.actions';
import { catchError } from 'rxjs/operators';
// Angular
import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
// RxJS
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';

/**
 *
 */
@Injectable()
export class ErrorInterceptService implements HttpInterceptor {
	constructor(private store: Store<AppState>) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err.status === 401) {
					// auto logout if 401 response returned from api
					this.store.dispatch(new Logout());
					location.reload();
				}
				const error = err.error.message || err.statusText;
				console.error('ErrorInterceptService', error);
				return throwError(err);
			})
		);
	}
}
