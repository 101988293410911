import { JUST0TO9_REGEX } from './../../../../../core/shared/constants';
import { TagModel } from './../../../../../core/point-of-interest/_models/tag.model';
import { MIN_TAG_NAME_LEN } from './../../../../../core/point-of-interest/_consts/general';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
	isDefined,
	markAllFormAs,
	objectPathExists
} from './../../../../../core/_base/crud/utils/helper.function';

@Component({
	selector: 'kt-create-tag-dialog',
	templateUrl: './create-tag-dialog.component.html'
})
export class CreateTagDialogComponent implements OnInit {
	// Public properties
	viewLoading = false;

	form: FormGroup;
	displayPosition = true;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
		public dialogRef: MatDialogRef<CreateTagDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder
	) {}

	/**
	 * On init
	 */
	ngOnInit() {
		const tag: TagModel = this.data.tag || {};
		const position = TagModel.getPosition(tag);
		this.displayPosition = isDefined(this.data.displayPosition)
			? this.data.displayPosition
			: this.displayPosition;

		this.form = this.formBuilder.group({
			name: [
				tag.displayTitle || null,
				[
					Validators.required,
					Validators.minLength(MIN_TAG_NAME_LEN),
					Validators.maxLength(75)
				]
			],
			position: [position, Validators.pattern(JUST0TO9_REGEX)]
		});
	}

	onCancelClick(): void {
		this.dialogRef.close();
	}

	async onSaveClick() {
		if (this.form.invalid) {
			markAllFormAs(this.form, 'touched');
			return;
		}
		if (this.form.valid) {
			const name = this.form.get('name').value;
			const position = this.form.get('position').value;
			if (!!name) {
				this.dialogRef.close({ name, position });
			}
		}
	}

	/**
	 * Returns the errors of form field
	 */
	getErrors(field: string) {
		const control = this.form.get(field);
		if (!control) {
			return false;
		}
		return control.touched && control.errors;
	}
}
