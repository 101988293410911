// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// State
import { ShippingMethodsState } from '../_reducers/shipping-methods.reducers';
import { ShippingMethodModel } from '../_models/shipping-methods.model';

export const selectShippingMethodsState = createFeatureSelector<
	ShippingMethodsState
>('shippingMethods');

export const selectShippingMethodById = (smId: string) =>
	createSelector(
		selectShippingMethodsState,
		(shippingMethodsState) => shippingMethodsState.entities[smId]
	);

export const selectShippingMethodsPageLoading = createSelector(
	selectShippingMethodsState,
	(shippingMethodsState) => shippingMethodsState.listLoading
);

export const selectShippingMethodsActionLoading = createSelector(
	selectShippingMethodsState,
	(shippingMethodsState) => shippingMethodsState.actionsloading
);

export const selectShippingMethodsActionMessage = createSelector(
	selectShippingMethodsState,
	(shippingMethodsState) => shippingMethodsState.actionMessage
);

export const selectShippingMethodsInStore = createSelector(
	selectShippingMethodsState,
	(shippingMethodsState) => {
		const items: ShippingMethodModel[] = [];
		each(shippingMethodsState.entities, (element) => {
			items.push(element);
		});

		return new QueryResultsModel(items, shippingMethodsState.totalCount, '');
	}
);

export const selectShippingMethodsShowInitWaitingMessage = createSelector(
	selectShippingMethodsState,
	(shippingMethodsState) => shippingMethodsState.showInitWaitingMessage
);
export const selectShippingMethodsShowErrorMessage = createSelector(
	selectShippingMethodsState,
	(shippingMethodsState) => shippingMethodsState.showErrorMessage
);
