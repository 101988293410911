import {
	GQLProductVariant,
	GQLSimpleInventoryInfo
} from './../../../../../../generated/graphql';
import {
	forLoopMinMax,
	objectPathExists,
	isDefined
} from './../../../../../core/_base/crud/utils/helper.function';

export const LOW_INVENTORY_WARNING_THRESHOLD = 1;
export class VariantLine {
	productId?: string;
	_id?: string;
	attribute: string;
	price: number;
	saleprice: number;
	label: string;
	inventoryInfo?: GQLSimpleInventoryInfo;
	quantity?: number;
	state: boolean;
	options?: VariantLine[];
	currencySymbol: string;
	_parent?: VariantLine;
	_keyTag?: string;
	_isEditMode = false;
	_isNew = false;
	_isDeleted = false;

	constructor(item?: GQLProductVariant, productId?: string) {
		if (!!item) {
			this.productId = productId || null;
			this._id = item._id;
			this.attribute = item.attributeLabel;
			this.price = (item.pricing && item.pricing.price) || item.price || undefined;
			this.saleprice =
				(item.pricing &&
					item.pricing.compareAtPrice &&
					item.pricing.compareAtPrice.amount) ||
				undefined;
			this.quantity = undefined;
			this.label = item.optionTitle;
			this.state = item.isVisible;
			this.currencySymbol =
				objectPathExists(item, 'shop.currency.symbol', true) || '';
			this.options = !!item.options
				? item.options.map((opt) => {
						const newOpt = new VariantLine(opt, productId);
						newOpt._parent = this;
						return newOpt;
				  })
				: undefined;
		} else {
			this.clear();
		}
	}

	public static variantLinesValidator(data: VariantLine[]) {
		let result = {};
		if (!!data && data.some((variant) => !variant._isDeleted)) {
			// Names validator
			if (data.some((variant) => !variant.hasValidNames())) {
				result = {
					...result,
					variant_missing_name:
						'PRODUCTS.FORM.BUY_OPTIONS_SECTION.LIST.ERRORS.MISSING_NAMES'
				};
			}
			// Price validator
			if (data.some((variant) => !variant.hasValidPrice())) {
				result = {
					...result,
					variant_missing_price:
						'PRODUCTS.FORM.BUY_OPTIONS_SECTION.LIST.ERRORS.MISSING_PRICES'
				};
			}
			// Quantity validator
			if (data.some((variant) => !variant.hasValidQuantity())) {
				result = {
					...result,
					variant_missing_quantity:
						'PRODUCTS.FORM.BUY_OPTIONS_SECTION.LIST.ERRORS.MISSING_QUANTITIES'
				};
			}
		} else if (!data || !data.some((variant) => !variant._isDeleted)) {
			result = {
				...result,
				variant_required: 'PRODUCTS.FORM.BUY_OPTIONS_SECTION.LIST.ERRORS.REQUIRED'
			};
		}
		return result;
	}

	clear() {
		this.productId = undefined;
		this._id = undefined;
		this._isEditMode = false;
		this._isNew = false;
		this._isDeleted = false;
		this.attribute = undefined;
		this.price = undefined;
		this.label = undefined;
		this.inventoryInfo = undefined;
		this.quantity = undefined;
		this.state = true;
		this.options = undefined;
		this.currencySymbol = '';
		this._keyTag = undefined;
	}
	// Pricing functions
	getDisplayPrice(): string {
		if (!!this.options && this.options.length > 0) {
			const { min, max } = forLoopMinMax(this.options, 'price');
			return isDefined(min) && isDefined(max)
				? `${min} ${this.currencySymbol} ${
						min !== max ? `- ${max} ${this.currencySymbol}` : ''
				  } `
				: '-';
		} else {
			return isDefined(this.price) ? `${this.price} ${this.currencySymbol}` : '-';
		}
	}

	getDisplaySalePrice(): string {
		if (!!this.options && this.options.length > 0) {
			const { min, max } = forLoopMinMax(this.options, 'saleprice');
			return isDefined(min) && isDefined(max)
				? `${min} ${this.currencySymbol} ${
						min !== max ? `- ${max} ${this.currencySymbol}` : ''
				  } `
				: '-';
		} else {
			return isDefined(this.saleprice)
				? `${this.saleprice} ${this.currencySymbol}`
				: '-';
		}
	}

	setPrice(newprice: number) {
		if (!!this.options && this.options.length > 0) {
			this.price = null;
			this.options.forEach((optVar) => optVar.setPrice(newprice));
		} else {
			this.price = newprice;
		}
	}

	setSalePrice(newprice: number) {
		if (!!this.options && this.options.length > 0) {
			this.saleprice = null;
			this.options.forEach((optVar) => optVar.setSalePrice(newprice));
		} else {
			this.saleprice = newprice;
		}
	}

	// Inventory functions
	getDisplayQuantity(): string {
		if (!!this.options && this.options.length > 0) {
			const { min, max } = forLoopMinMax(this.options, 'quantity');
			return isDefined(min) && isDefined(max)
				? `${min} ${min !== max ? `- ${max}` : ''} `
				: '-';
		} else {
			return isDefined(this.quantity) ? `${this.quantity}` : '-';
		}
	}
	setQuantity(newQtd: number) {
		if (!!this.options && this.options.length > 0) {
			this.quantity = null;
			this.options.forEach((optVar) => optVar.setQuantity(newQtd));
		} else {
			this.quantity = newQtd;
		}
	}

	setInventoryFlags(newValue: { canBackorder?: boolean; isEnabled?: boolean }) {
		this.inventoryInfo = {
			...this.inventoryInfo,
			...newValue
		};
		if (!!this.options && this.options.length > 0) {
			this.options.forEach((optVar) => optVar.setInventoryFlags(newValue));
		}
	}

	setProductId(newValue: string) {
		this.productId = newValue;

		if (!!this.options && this.options.length > 0) {
			this.options.forEach((optVar) => optVar.setProductId(newValue));
		}
	}

	get hasOptions() {
		return !!objectPathExists(this, 'options.length', true);
	}

	// Description value displayed in cart, checkout and orders
	get displayTag() {
		if (!!this._parent && !!this.label) {
			return `${this._parent.label} - ${this.label}`;
		}
		return this.label || '';
	}

	hasValidPrice() {
		// Is valid if the line is marked as deleted
		if (this._isDeleted) {
			return true;
		}
		// Validate the options
		if (this.hasOptions) {
			return this.options.every((opt) => opt.hasValidPrice());
		} else {
			// If don't have any options need have price defined
			if (!isDefined(this.price)) {
				return false;
			}
		}
		return true;
	}

	hasValidQuantity() {
		// Is valid if the line is marked as deleted
		if (this._isDeleted) {
			return true;
		}
		// Validate the options
		if (this.hasOptions) {
			return this.options.every((opt) => opt.hasValidQuantity());
		} else {
			// If don't have any options need have the quantity defined
			if (
				objectPathExists(this.inventoryInfo, 'isEnabled', true) &&
				!isDefined(this.quantity)
			) {
				return false;
			}
		}
		return true;
	}

	hasValidNames() {
		// Is valid if the line is marked as deleted
		if (this._isDeleted) {
			return true;
		}
		if (!isDefined(this.attribute) || !isDefined(this.label)) {
			return false;
		}
		// Validate the options
		if (this.hasOptions) {
			return this.options.every((opt) => opt.hasValidNames());
		} else {
			// If don't have any options need have the attribute and the label defined
			if (!isDefined(this.attribute) || !isDefined(this.label)) {
				return false;
			}
		}
		return true;
	}
}
