// NGRX
import { createFeatureSelector } from '@ngrx/store';

// Actions
import {
	ECommerceActions,
	ECommerceActionTypes
} from '../_actions/e-commerce.actions';

export interface EcommerceInfoState {
	relations: {
		shopId: string;
		poiId: number;
	}[];
	isLoading: boolean;
}

export const initialEcommerceInfoState: EcommerceInfoState = {
	relations: null,
	isLoading: false
};

export function ecommerceInfoReducer(
	state = initialEcommerceInfoState,
	action: ECommerceActions
): EcommerceInfoState {
	switch (action.type) {
		case ECommerceActionTypes.ECommerceInfoToggleLoading: {
			return {
				...state,
				isLoading: action.payload.isLoading
			};
		}
		case ECommerceActionTypes.POIeCommerceInfoRequest: {
			return {
				...state,
				isLoading: true
			};
		}
		case ECommerceActionTypes.POIeCommerceInfoLoaded: {
			return {
				...state,
				relations: action.payload.relations,
				isLoading: false
			};
		}

		default:
			return state;
	}
}

export const getECommerceInfoState = createFeatureSelector<EcommerceInfoState>(
	'ecommerceInfo'
);
