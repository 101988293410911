import { MediaFile } from './../../_base/crud/models/media-file.model';
import { TranslationField } from './../../_base/crud/models/translation-field.model';
import { BaseModel } from '../../_base/crud';
import {
	NOTIFICATION_STATE,
	NOTIFICATION_TYPE
} from '../_consts/specification.dictionary';
import {
	dateToEnvironmentFormat,
	toFormData
} from '../../_base/crud/utils/helper.function';

export class NotificationModel extends BaseModel {
	title?: string | TranslationField;
	description: string | TranslationField;
	valid_from: Date;
	valid_to?: Date;
	type: NOTIFICATION_TYPE;
	state: NOTIFICATION_STATE;
	'point_of_interest'?: number;
	image: MediaFile;

	constructor() {
		super();
		this.clear();
	}

	public static validToString(notification: NotificationModel): string {
		return (
			(notification.valid_from
				? dateToEnvironmentFormat(notification.valid_from)
				: '') +
			' - ' +
			(notification.valid_to ? dateToEnvironmentFormat(notification.valid_to) : '')
		);
	}

	async getPayload(imageData) {
		let payload = {
			data: JSON.stringify(this)
		};

		if (!!imageData) {
			payload['files.image'] = imageData;
		}

		return toFormData(payload);
	}
	clear() {
		this.id = undefined;
		this.title = new TranslationField();
		this.title.clear();
		this.description = new TranslationField();
		this.description.clear();
		this.valid_from = undefined;
		this.valid_to = undefined;
		this.type = undefined;
		this.state = undefined;
		this.point_of_interest = undefined;
		this.image = undefined;
	}
}
