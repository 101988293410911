import { ORDER_STATUS } from './../_models/order.model';
import { ECommerceConnectionService } from '../../graphql/e-commerce/e-commerce.service';
import { ReactionQueryResultsModel } from './../../_base/crud/models/query-models/reaction-query-results.model';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
// RxJS
import { Observable, forkJoin } from 'rxjs';
// CRUD
import {
	HttpUtilsService,
	QueryParamsModel,
	QueryResultsModel
} from '../../_base/crud';
// Models
import { OrderModel, OrderReactionResponse } from '../_models/order.model';
import ordersQuery from '../../graphql/e-commerce/queries/getOrdersQuery';
import updateOrderMutation from '../../graphql/e-commerce/mutations/updateOrderMutation';
import orderByReferenceIdQuery from '../../graphql/e-commerce/queries/orderByReferenceId';

const API_CUSTOMERS_URL = 'api/orders';

@Injectable({
	providedIn: 'root'
})
export class OrdersService {
	constructor(
		private http: HttpClient,
		private httpUtils: HttpUtilsService,
		private apollo: Apollo
	) {}

	// CREATE =>  POST: add a new order to the server
	createOrder(order: OrderModel): Observable<OrderModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<OrderModel>(API_CUSTOMERS_URL, order, {
			headers: httpHeaders
		});
	}

	// READ
	getAllOrders(): Observable<OrderModel[]> {
		return this.http.get<OrderModel[]>(API_CUSTOMERS_URL);
	}

	getOrderById(
		shopId: string,
		lastSelectedOrderReferenceId: string
	): Observable<OrderReactionResponse> {
		return this.apollo.query<{ orders: ReactionQueryResultsModel<OrderModel> }>({
			query: orderByReferenceIdQuery,
			variables: {
				shopId,
				id: lastSelectedOrderReferenceId
			}
		});
	}

	findOrders(
		queryParams: QueryParamsModel,
		shopId: string
	): Observable<OrderReactionResponse> {
		const params = ECommerceConnectionService.queryParamsModelToGraphQlFilter(
			queryParams
		);

		return this.apollo.query<{ orders: ReactionQueryResultsModel<OrderModel> }>({
			query: ordersQuery,
			variables: {
				shopIds: [shopId],
				...params
			}
		});
	}

	// UPDATE => PUT: update the order on the server
	updateOrder(order: OrderModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_CUSTOMERS_URL, order, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForOrder(orders: OrderModel[], status: ORDER_STATUS): Observable<any> {
		const requests = orders.map((o) => {
			return this.apollo.mutate({
				mutation: updateOrderMutation,
				variables: { orderId: o._id, status }
			});
		});
		return forkJoin(requests);
	}
}
