import { Update } from '@ngrx/entity';
// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { OrderActions, OrderActionTypes } from '../_actions/order.actions';
// Models
import { OrderModel } from '../_models/order.model';
import { QueryParamsModel } from '../../_base/crud';

export interface OrdersState extends EntityState<OrderModel> {
	listLoading: boolean;
	actionsloading: boolean;
	totalCount: number;
	lastCreatedOrderId: number;
	lastQuery: QueryParamsModel;
	showInitWaitingMessage: boolean;
	showErrorMessage: false | string;
	lastSelectedOrderReferenceId: string;
}

export const adapter: EntityAdapter<OrderModel> = createEntityAdapter<OrderModel>({
	selectId: (publishedData: OrderModel) => publishedData._id
});

export const initialOrdersState: OrdersState = adapter.getInitialState({
	orderForEdit: null,
	listLoading: false,
	actionsloading: false,
	totalCount: 0,
	lastCreatedOrderId: undefined,
	lastQuery: new QueryParamsModel({}),
	showInitWaitingMessage: true,
	showErrorMessage: false,
	lastSelectedOrderReferenceId: null
});

export function ordersReducer(
	state = initialOrdersState,
	action: OrderActions
): OrdersState {
	switch (action.type) {
		case OrderActionTypes.OrderRequest: {
			return {
				...state,
				lastSelectedOrderReferenceId: action.payload.referenceId
			};
		}
		case OrderActionTypes.OrderRequested: {
			return adapter.updateOne(action.payload.orderFullInfo, state);
		}
		case OrderActionTypes.OrdersPageToggleLoading: {
			return {
				...state,
				listLoading: action.payload.isLoading,
				lastCreatedOrderId: undefined
			};
		}
		case OrderActionTypes.OrderActionToggleLoading: {
			return {
				...state,
				actionsloading: action.payload.isLoading
			};
		}

		case OrderActionTypes.OrderUpdated:
			return adapter.updateOne(action.payload.partialOrder, state);

		case OrderActionTypes.OrdersStatusUpdated: {
			// tslint:disable-next-line
			const partialOrders: Update<OrderModel>[] = action.payload.orders.map(
				(order) => ({
					id: order._id,
					changes: {
						status: order.status
					}
				})
			);

			return adapter.updateMany(partialOrders, state);
		}

		case OrderActionTypes.OrdersPageCancelled: {
			return {
				...state,
				listLoading: false,
				lastQuery: new QueryParamsModel({})
			};
		}
		case OrderActionTypes.OrdersPageLoaded: {
			return adapter.addMany(action.payload.orders, {
				...initialOrdersState,
				totalCount: action.payload.totalCount,
				listLoading: false,
				lastQuery: action.payload.page,
				showInitWaitingMessage: false
			});
		}

		case OrderActionTypes.OrderActionError: {
			return {
				...state,
				showErrorMessage: action.payload.showErrorMessage
			};
		}

		default:
			return state;
	}
}

export const getOrderState = createFeatureSelector<OrderModel>('orders');

export const {
	selectAll,
	selectEntities,
	selectIds,
	selectTotal
} = adapter.getSelectors();
