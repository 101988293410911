import gql from 'graphql-tag';

const updateOrderMutation = gql`
	mutation updateOrderMutation($orderId: ID!, $status: String) {
		updateOrder(input: { orderId: $orderId, status: $status }) {
			order {
				_id
				status
			}
		}
	}
`;

export default updateOrderMutation;
