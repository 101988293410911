import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputFeedbackComponent } from './form-input-feedback/form-input-feedback.component';

@NgModule({
	declarations: [FormInputFeedbackComponent],
	entryComponents: [FormInputFeedbackComponent],
	exports: [FormInputFeedbackComponent],
	imports: [CommonModule, TranslateModule.forChild()]
})
export class SharedModule {}
