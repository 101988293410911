export class Address {
	id: number;
	street: string;
	place: string;
	locality: string;
	city: string;
	region: string;
	country: string;
	postal_code: string;

	clear() {
		this.id = undefined;
		this.street = '';
		this.place = '';
		this.locality = '';
		this.city = '';
		this.region = '';
		this.country = '';
		this.postal_code = '';
	}
}
