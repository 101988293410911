import { NotificationModel } from './../../../../core/point-of-interest/_models/notification.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'kt-active-notification-card',
	templateUrl: './active-notification-card.component.html',
	styleUrls: ['./active-notification-card.component.scss']
})
export class ActiveNotificationCardComponent {
	@Input() activeNotification: NotificationModel = null;
	@Output() deleteActiveNotification = new EventEmitter<NotificationModel>();

	constructor() {}
}
