// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// TODO: Not being used. Use it when publish by selecting product in list

@Component({
	selector: 'kt-publish-product-dialog',
	templateUrl: './publish-product-dialog.component.html'
})
export class PublishProductDialogComponent implements OnInit {
	viewLoading = false;
	loadingAfterSubmit = false;
	constructor(
		public dialogRef: MatDialogRef<PublishProductDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit() {
		this.viewLoading = true;
		setTimeout(() => {
			this.viewLoading = false;
		}, 1000);
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	publish() {
		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		setTimeout(() => {
			this.dialogRef.close(true);
		}, 1000);
	}
}
