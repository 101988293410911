import { ECommerceConnectionService } from './../../../../core/graphql/e-commerce/e-commerce.service';
import { TagModel } from './../../../../core/point-of-interest/_models/tag.model';
import { TagsService } from './../../../../core/point-of-interest/_services/tags.service';
import { PointOfInterest } from './../../../../core/point-of-interest/_models/point-of-interest.model';
import { GQLTag } from './../../../../../generated/graphql';
import { TranslateService } from '@ngx-translate/core';
import { ProductsPageRequested } from './../../../../core/point-of-interest/_actions/product.actions';
import { selectProductsPageLastQuery } from './../../../../core/point-of-interest/_selectors/product.selectors';
import { ProductsDataSource } from './../../../../core/point-of-interest/_data-sources/products.datasource';
import { ProductsService } from './../../../../core/point-of-interest/_services/products.service';
import { ProductModel } from './../../../../core/point-of-interest/_models/product.model';
import {
	MediaFile,
	ImageSizes
} from './../../../../core/_base/crud/models/media-file.model';
import { environment } from './../../../../../environments/environment';
import { QueryParamsModel } from './../../../../core/_base/crud/models/query-models/query-params.model';
import {
	MessageType,
	LayoutUtilsService
} from './../../../../core/_base/crud/utils/layout-utils.service';
// Angular
import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectionStrategy,
	OnDestroy,
	Input
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { distinctUntilChanged, tap, skip, debounceTime } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// NGRX
import { AppState } from './../../../../core/reducers/index';
import { Store, select } from '@ngrx/store';

// Services and Models
import {
	dateToEnvironmentFormat,
	objectPathExists
} from './../../../../core/_base/crud/utils/helper.function';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-products-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsListComponent implements OnInit, OnDestroy {
	shopTags: GQLTag[] = [];

	@Input() poiId: number;
	@Input() shopId: string;

	dateFormat = environment.dateFormat;
	// Table fields
	dataSource: ProductsDataSource;
	displayedColumns = [
		'select',
		'title',
		'category',
		'price',
		'saleprice',
		'published',
		'state',
		'actions'
	];
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
	filterStatus = '';
	filterTag = '';
	lastQuery: QueryParamsModel;
	// Selection
	selection = new SelectionModel<ProductModel>(true, []);
	productsResult: ProductModel[] = [];
	private subscriptions: Subscription[] = [];
	MediaFile = MediaFile;
	dateToEnvironmentFormat = dateToEnvironmentFormat;
	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private layoutUtilsService: LayoutUtilsService,
		private store: Store<AppState>,
		private productsService: ProductsService,
		private tagService: TagsService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.loadShopTags();
		this.resetSort();
		this.loadProductsList();

		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => {
			this.paginator.pageIndex = 0;
			this.loadProductsList();
		});
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered:
		- when a pagination event occurs => this.paginator.page
		**/
		const paginatorSubscriptions = merge(this.paginator.page)
			.pipe(
				tap((pageEvent: PageEvent) => {
					// PAGINATOR BUG:
					// The paginator doesn't reset automatically the pageIndex when user changes the pageSize.
					// Need check with previous value if exists any change and reset the pageIndex
					if (this.lastQuery.limit !== pageEvent.pageSize) {
						this.paginator.pageIndex = 0;
					}
					this.loadProductsList();
				})
			)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
			.pipe(
				debounceTime(500),
				distinctUntilChanged(),
				tap(() => {
					this.paginator.pageIndex = 0;
					this.loadProductsList();
				})
			)
			.subscribe();
		this.subscriptions.push(searchSubscription);

		// Init DataSource
		this.dataSource = new ProductsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject
			.pipe(skip(1), distinctUntilChanged())
			.subscribe((res) => {
				this.productsResult = res as ProductModel[];
			});
		this.subscriptions.push(entitiesSubscription);
		const lastQuerySubscription = this.store
			.pipe(select(selectProductsPageLastQuery))
			.subscribe((res) => (this.lastQuery = res));
		// Load last query from store
		this.subscriptions.push(lastQuerySubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((el) => el.unsubscribe());
	}

	resetSort() {
		this.sort.active = '';
		this.sort.direction = '';
	}

	/**
	 * Load Shop Tags List
	 */
	async loadShopTags() {
		if (!this.poiId || !this.shopId) return;

		this.shopTags = await this.tagService
			.getTags(this.shopId)
			.toPromise()
			.then((result) => {
				const tagsResponse = ECommerceConnectionService.getEntitiesFromQuery<
					TagModel
				>('tags', result);
				return tagsResponse.items || [];
			});
	}

	/**
	 * Load Products List
	 */
	loadProductsList() {
		if (!this.poiId || !this.shopId) return;
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex * this.paginator.pageSize || 0,
			this.paginator.pageSize
		);
		// Call request from server
		this.store.dispatch(new ProductsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Returns object for filter
	 */
	filterConfiguration(): any {
		const filter: any = {};

		if (this.filterStatus && this.filterStatus.length > 0) {
			filter.isVisible = this.filterStatus;
		}
		if (!!this.poiId) {
			filter.point_of_interest_ids = this.poiId;
		}
		if (!!this.filterTag && this.filterTag.length > 0) {
			filter.tag_ids = this.filterTag;
		}

		const searchText: string = this.searchInput.nativeElement.value;
		filter._q = searchText;

		return filter;
	}

	/**
	 * Restore state
	 *
	 * @param queryParams: QueryParamsModel
	 * @param id: number
	 */
	restoreState(queryParams: QueryParamsModel, id: number) {
		if (!queryParams.filter) {
			return;
		}

		if ('status' in queryParams.filter) {
			this.filterStatus = queryParams.filter.status.toString();
		}

		if (queryParams.filter.model) {
			this.searchInput.nativeElement.value = queryParams.filter.model;
		}
	}

	/** ACTIONS */
	/**
	 * Delete products
	 */
	deleteProducts(item?: ProductModel) {
		const title = 'PRODUCTS.LIST.DELETE_MODAL.TITLE';
		const description = 'PRODUCTS.LIST.DELETE_MODAL.DESCRIPTION';
		const waitDescription = 'PRODUCTS.LIST.DELETE_MODAL.WAIT_DESCRIPTION';
		const deleteMessage = 'PRODUCTS.LIST.DELETE_MODAL.DELETE_MESSAGE';

		const dialogRef = this.layoutUtilsService.deleteElement(
			title,
			description,
			waitDescription
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}

			const idsForDeletion: string[] = [];
			if (!!item) {
				idsForDeletion.push(item._id);
			} else {
				// tslint:disable-next-line:prefer-for-of
				for (let i = 0; i < this.selection.selected.length; i++) {
					idsForDeletion.push(this.selection.selected[i]._id);
				}
			}

			this.productsService
				.deleteProducts(this.poiId, idsForDeletion)
				.then((result) => {
					if (!!result) {
						this.layoutUtilsService.showActionNotification(
							deleteMessage,
							MessageType.Delete
						);
					} else {
						this.layoutUtilsService.showActionNotification(
							"Can't delete the all products",
							MessageType.Delete
						);
						console.error("Can't delete the all products");
					}
					this.loadProductsList();
				})
				.catch((error) => {
					this.layoutUtilsService.showActionNotification(
						"Can't delete the products",
						MessageType.Delete
					);
					console.error("Can't delete the products", error);
				});
		});
	}

	/**
	 * Update status dialog
	 */
	updateStatusForProducts() {
		const title = 'PRODUCTS.LIST.UPDATE_STATE_DIALOG.TITLE';
		const updateMessage = 'PRODUCTS.LIST.UPDATE_STATE_DIALOG.MESSAGE';
		const key = 'GENERIC.STATE';
		const statuses = [
			{
				value: true,
				text: this.getItemStatusTranslateKey(true)
			},
			{
				value: false,
				text: this.getItemStatusTranslateKey(false)
			}
		];
		const messages = [];

		const idsForUpdate: string[] = [];
		this.selection.selected.forEach((elem) => {
			idsForUpdate.push(elem._id);
			messages.push({
				text: `${elem.title}`,
				id: elem._id,
				status: elem.isVisible,
				statusTitle: this.getItemStatusTranslateKey(elem.isVisible),
				statusCssClass: this.getItemCssClassByStatus(elem.isVisible)
			});
		});

		const dialogRef = this.layoutUtilsService.updateKeyForEntities(
			title,
			key,
			statuses,
			messages
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				this.selection.clear();
				return;
			}

			this.productsService
				.updateVisibilityForProducts(this.poiId, idsForUpdate, res)
				.then((result) => {
					if (!!result) {
						this.layoutUtilsService.showActionNotification(
							this.translate.instant(updateMessage, {
								updatedCount: result.updatedCount
							}),
							MessageType.Update
						);
					} else {
						this.layoutUtilsService.showActionNotification(
							"Can't upadte the all products",
							MessageType.Update
						);
						console.error("Can't upadte the all products");
					}
					this.loadProductsList();
				})
				.catch((error) => {
					this.layoutUtilsService.showActionNotification(
						"Can't upadte the all products",
						MessageType.Update
					);
					console.error("Can't update the products", error);
				});
		});
	}

	/**
	 * Redirect to edit page
	 *
	 * @param id: any
	 */
	editProduct(id) {
		this.router.navigate(['/products/edit', id], {
			relativeTo: this.activatedRoute
		});
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.productsResult.length;
		return numSelected === numRows;
	}

	/**
	 * Selects all rows if they are not all selected; otherwise clear selection
	 */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.productsResult.forEach((row) => this.selection.select(row));
		}
	}

	/* UI */
	/**
	 * Returns translate key for state
	 * @param isVisible: boolean
	 */
	getItemStatusTranslateKey(isVisible: boolean): string {
		return isVisible ? 'PRODUCTS.STATE.ACTIVE' : 'PRODUCTS.STATE.INACTIVE';
	}

	/**
	 * Returns translate key for state
	 * @param isVisible: boolean
	 */
	getItemPublishedTranslateKey(isPublished: boolean): string {
		return isPublished ? 'PRODUCTS.STATE.PUBLISHED' : 'PRODUCTS.STATE.UNPUBLISHED';
	}

	/**
	 * Returns CSS Class by state
	 *
	 * @param isVisible: boolean
	 */
	getItemCssClassByStatus(isVisible: boolean): string {
		return isVisible ? 'success' : 'danger';
	}

	getProductImagePath(product: ProductModel) {
		return ProductModel.getProductImagePath(product, ImageSizes.thumbnail);
	}

	productIsPublished(product: ProductModel) {
		return ProductModel.isPublished(product);
	}

	getProductCategory(product: ProductModel) {
		return ProductModel.getProductCategory(product)
			? ProductModel.getProductCategory(product).displayTitle
			: '-';
	}

	getProductDisplayPrice(product: ProductModel) {
		return ProductModel.getDisplayPrice(product);
	}

	getProductDisplaySalePrice(product: ProductModel) {
		return ProductModel.getDisplaySalePrice(product);
	}
}
