import gql from 'graphql-tag';

const orderCommonFragment = gql`
	fragment OrderCommon on Order {
		_id
		cartId
		createdAt
		email
		fulfillmentGroups {
			_id
			data {
				... on ShippingOrderFulfillmentGroupData {
					shippingAddress {
						_id
						address1
						address2
						city
						company
						country
						fullName
						isCommercial
						isShippingDefault
						phone
						postal
						region
						__typename
					}
					__typename
				}
				__typename
			}
			items {
				nodes {
					_id
					addedAt
					attributes {
						label
						value
					}
					createdAt
					imageURLs {
						large
						medium
						original
						small
						thumbnail
						__typename
					}
					isTaxable
					optionTitle
					parcel {
						containers
						distanceUnit
						height
						length
						massUnit
						weight
						width
						__typename
					}
					price {
						amount
						currency {
							code
							__typename
						}
						displayAmount
						__typename
					}
					productConfiguration {
						productId
						productVariantId
						__typename
					}
					productSlug
					productType
					productVendor
					productTags {
						nodes {
							name
							__typename
						}
						__typename
					}
					quantity
					shop {
						_id
						__typename
					}
					subtotal {
						amount
						currency {
							code
							__typename
						}
						displayAmount
						__typename
					}
					taxCode
					title
					updatedAt
					variantTitle
					__typename
				}
				__typename
			}
			selectedFulfillmentOption {
				fulfillmentMethod {
					_id
					carrier
					displayName
					fulfillmentTypes
					group
					name
					__typename
				}
				handlingPrice {
					amount
					currency {
						code
						__typename
					}
					displayAmount
					__typename
				}
				price {
					amount
					currency {
						code
						__typename
					}
					displayAmount
					__typename
				}
				__typename
			}
			shop {
				_id
				__typename
			}
			status
			summary {
				fulfillmentTotal {
					amount
					displayAmount
					__typename
				}
				itemTotal {
					amount
					displayAmount
					__typename
				}
				surchargeTotal {
					amount
					displayAmount
					__typename
				}
				taxTotal {
					amount
					displayAmount
					__typename
				}
				total {
					amount
					displayAmount
					__typename
				}
				__typename
			}
			tracking
			type
			__typename
		}
		payments {
			_id
			amount {
				amount
				displayAmount
				__typename
			}
			billingAddress {
				address1
				address2
				city
				company
				country
				fullName
				isCommercial
				phone
				postal
				region
				__typename
			}
			captureErrorMessage
			displayName
			method {
				canRefund
				displayName
				name
				__typename
			}
			mode
			processor
			refunds {
				amount {
					amount
					displayAmount
					__typename
				}
				createdAt
				paymentDisplayName
				reason
				__typename
			}
			riskLevel
			status
			transactionId
			__typename
		}
		referenceId
		refunds {
			amount {
				amount
				displayAmount
				__typename
			}
			createdAt
			paymentDisplayName
			reason
			__typename
		}
		shop {
			_id
			currency {
				code
				__typename
			}
			name
			__typename
		}
		status
		summary {
			fulfillmentTotal {
				amount
				displayAmount
				__typename
			}
			itemTotal {
				amount
				displayAmount
				__typename
			}
			surchargeTotal {
				amount
				displayAmount
				__typename
			}
			taxTotal {
				amount
				displayAmount
				__typename
			}
			total {
				amount
				displayAmount
				__typename
			}
			__typename
		}
		totalItemQuantity
		updatedAt
		__typename
	}
`;

export default orderCommonFragment;
