import { ECommerceService } from './../_services/e-commerce.service';
import { ECommerceActionTypes } from './../_actions/e-commerce.actions';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { map, mergeMap } from 'rxjs/operators';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// State
import { AppState } from '../../reducers';
// Actions
import {
	ECommerceInfoToggleLoading,
	POIeCommerceInfoRequest,
	POIeCommerceInfoLoaded
} from '../_actions/e-commerce.actions';

@Injectable()
export class ECommerceInfoEffects {
	showLoadingDistpatcher = new ECommerceInfoToggleLoading({ isLoading: true });
	hideActionLoadingDistpatcher = new ECommerceInfoToggleLoading({
		isLoading: false
	});

	@Effect()
	loadOrdersPage$ = this.actions$.pipe(
		ofType<POIeCommerceInfoRequest>(ECommerceActionTypes.POIeCommerceInfoRequest),
		mergeMap(({ payload }) => {
			this.store.dispatch(this.showLoadingDistpatcher);
			const requestToServer = this.ecommerceService.getEcommerceInfoOfPOI(
				payload.pointOfInterestId
			);

			return requestToServer;
		}),
		map((response) => {
			const { shop_id: shopId, poi_id: poiId } = response;
			return new POIeCommerceInfoLoaded({
				relations: [{ shopId, poiId }]
			});
		})
	);

	constructor(
		private actions$: Actions,
		private ecommerceService: ECommerceService,
		private store: Store<AppState>
	) {}
}
