import { TranslationField } from './../../_base/crud/models/translation-field.model';
import { ECommerceService } from './e-commerce.service';
import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS

// CRUD

// Models
import { NotificationModel } from '../_models/notification.model';
import { PointOfInterest } from '../_models/point-of-interest.model';

const API_POINT_OF_INTEREST_URL = `${environment.apiEndpoint}/point-of-interests`;
const API_DEMO_SEED_POINT_OF_INTEREST_URL = `${environment.apiEndpoint}/seed/demo`;

// Real REST API
@Injectable({
	providedIn: 'root'
})
export class PointOfInterestService {
	constructor(
		private http: HttpClient,
		private ecommerceService: ECommerceService
	) {}

	getPointOfInterestById(
		pointOfInterestId: number,
		inCurrentLanguage = true
	): Promise<PointOfInterest> {
		let requestOptions = {};
		if (!inCurrentLanguage) {
			const headerDict = {
				'Accept-Language': '*'
			};
			requestOptions = {
				headers: new HttpHeaders(headerDict)
			};
		}

		return this.http
			.get<PointOfInterest>(
				API_POINT_OF_INTEREST_URL + `/${pointOfInterestId}`,
				requestOptions
			)
			.toPromise()
			.then((res) => res)
			.catch(() => null);
	}

	// CREATE => POST: create the Point of Interest on the server
	async createPointOfInterest(payload: FormData): Promise<any> {
		if (!payload) {
			return null;
		}
		const url = `${API_POINT_OF_INTEREST_URL}`;
		return this.http.post(url, payload).toPromise();
	}

	// UPDATE => PUT: update the Point of Interest on the server
	/* `isGeneralInfo` is an indicator telling that there is general info being updated (email, contacts, name or description) */

	async updatePointOfInterest(
		id: number,
		payload: FormData,
		isGeneralInfo: boolean = false,
		data?: { poi: PointOfInterest; shopId: string }
	): Promise<any> {
		if (!payload) {
			return null;
		}
		const requests = [];

		const url = `${API_POINT_OF_INTEREST_URL}/${id}`;
		requests.push(this.http.put(url, payload).toPromise());

		// Update ECommerce Info: Shop General information;
		if (isGeneralInfo && !!data && !!data.poi && data.shopId) {
			const poi = data.poi;
			const name = (!!poi.title && (poi.title as TranslationField).pt) || null;
			const description =
				(!!poi.description && (poi.description as TranslationField).pt) || null;
			const email = (!!poi.contacts && poi.contacts.email) || null;
			requests.push(
				this.ecommerceService
					.updateShopInfo(data.shopId, name, description, email)
					.toPromise()
			);
		}

		return Promise.all(requests);
	}

	getActiveNotification(pointOfInterestId: number): Promise<NotificationModel> {
		if (!pointOfInterestId) {
			return Promise.reject();
		}
		return this.http
			.get<NotificationModel>(
				`${API_POINT_OF_INTEREST_URL}/${pointOfInterestId}/active-notification`
			)
			.toPromise()
			.then((res) => res)
			.catch(() => {
				return null;
			});
	}

	/**
	 * DEMO :: Point of interest
	 */
	// CREATE
	async createDemoPointOfInterest(): Promise<PointOfInterest> {
		return this.http
			.post<PointOfInterest>(API_DEMO_SEED_POINT_OF_INTEREST_URL, {})
			.toPromise();
	}

	// DELETE
	async deleteDemoPointOfInterest(id: number): Promise<PointOfInterest> {
		const url = `${API_DEMO_SEED_POINT_OF_INTEREST_URL}/${id}`;
		return this.http.delete<PointOfInterest>(url, {}).toPromise();
	}
}
