import { OrderViewDialogComponent } from './../order-view/order-view.dialog.component';
import {
	ORDER_STATUS,
	ORDER_PAYMENT_STATUS
} from './../../../../core/point-of-interest/_models/order.model';
// Angular
import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectionStrategy,
	OnDestroy,
	Input
} from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// CRUD
import { LayoutUtilsService, QueryParamsModel } from '../../../../core/_base/crud';
// Services and Models
import {
	OrderModel,
	OrdersDataSource,
	OrdersPageRequest,
	OrdersUpdateStatus
} from '../../../../core/point-of-interest';
import { OrderActionError } from '../../../../core/point-of-interest/_actions/order.actions';
import { OrderUtils } from '../share/orders.utils';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-orders-list',
	templateUrl: './orders-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersListComponent implements OnInit, OnDestroy {
	@Input() shopId: string;

	// Table fields
	dataSource: OrdersDataSource;
	displayedColumns = [
		'select',
		'status',
		'referenceId',
		'createdAt',
		'payments',
		'payments-state',
		'email',
		'summary.total.displayAmount',
		'actions'
	];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild('sort1', { static: true }) sort: MatSort;

	ORDER_PAYMENT_STATUS_VALUES = Object.values(ORDER_PAYMENT_STATUS);
	ORDER_STATUS_VALUES = Object.values(ORDER_STATUS);

	filterOrderStatus = '';
	filterPaymentStatus = '';

	ORDER_UTILS = OrderUtils;

	// Selection
	selection = new SelectionModel<OrderModel>(true, []);
	ordersResult: OrderModel[] = [];
	// Subscriptions
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param snackBar: MatSnackBar
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 */
	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
		private store: Store<AppState>
	) {}

	ngOnInit() {
		const sortSubscription = this.sort.sortChange.subscribe(
			() => (this.paginator.pageIndex = 0)
		);
		this.subscriptions.push(sortSubscription);

		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
			.pipe(tap(() => this.loadOrdersList()))
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Init DataSource
		this.dataSource = new OrdersDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject
			.pipe(skip(1), distinctUntilChanged())
			.subscribe((res) => {
				this.ordersResult = res;
			});
		this.subscriptions.push(entitiesSubscription);

		this.dataSource.showErrorMessage$.subscribe((error) => {
			if (error === false) return;

			const title = this.translate.instant('GENERIC.OPERATION_ERROR');
			const message = this.translate.instant(error);

			this.layoutUtilsService.showSimpleAlertDialog('warning', title, message, true);
			this.store.dispatch(new OrderActionError({ showErrorMessage: false }));
		});

		// First load
		of(undefined)
			.pipe(take(1), delay(1000))
			.subscribe(() => {
				this.loadOrdersList();
			});
	}

	ngOnDestroy() {
		this.subscriptions.forEach((el) => el.unsubscribe());
	}

	loadOrdersList() {
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize
		);

		this.store.dispatch(
			new OrdersPageRequest({ page: queryParams, shopId: this.shopId })
		);
	}

	filterConfiguration(): any {
		const filter: any = {};

		if (this.filterOrderStatus && this.filterOrderStatus.length > 0) {
			filter.status = this.filterOrderStatus;
		}

		if (this.filterPaymentStatus && this.filterPaymentStatus.length > 0) {
			filter.payment = this.filterPaymentStatus;
		}

		return filter;
	}

	/**
	 * Show UpdateStatuDialog for selected orders
	 */
	updateStatusForOrders() {
		const title = this.translate.instant('ORDER.UPDATE_STATUS');
		const messages = [];
		const statuses = Object.values(ORDER_STATUS).map((statusValue) => ({
			value: statusValue,
			text: `ORDER.STATUS.${statusValue.toUpperCase()}`
		}));

		this.selection.selected.forEach((elem) => {
			messages.push({
				text: `${elem.referenceId} - ${elem.email}`,
				id: elem._id.toString(),
				statusTitle: this.ORDER_UTILS.getItemStatusString(elem.status),
				statusCssClass: this.ORDER_UTILS.getItemCssClassByStatus(elem.status)
			});
		});

		const dialogRef = this.layoutUtilsService.updateKeyForEntities(
			title,
			'ORDER.ORDERS_STATUS',
			statuses,
			messages,
			{ width: '50%', placeholder: 'GENERIC.STATE' }
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				this.selection.clear();
				return;
			}
			this.store.dispatch(
				new OrdersUpdateStatus({
					status: res,
					orders: this.selection.selected
				})
			);
			this.selection.clear();
		});
	}

	viewOrder(order: OrderModel) {
		const dialogRef = this.dialog.open(OrderViewDialogComponent, {
			data: { shopId: this.shopId, orderId: order._id }
		});
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			}
		});
	}

	/**
	 * Check all rows are selected
	 */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.ordersResult.length;
		return numSelected === numRows;
	}

	/**
	 * Toggle all selections
	 */
	masterToggle() {
		if (this.selection.selected.length === this.ordersResult.length) {
			this.selection.clear();
		} else {
			this.ordersResult.forEach((row) => this.selection.select(row));
		}
	}
}
