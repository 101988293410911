import {toISODate} from './../utils/helper.function';
/**
 * Model used to represent an interval between two dates.
 */

export class DateInterval {
	id: number;
	from: Date;
	to?: Date;

	clear() {
		this.id = undefined;
		this.from = undefined;
		this.to = undefined;
	}

	toString() {
		return toISODate(this.from) + ' - ' + this.to ? toISODate(this.to) : '';
	}
}
