import { URL_REGEX } from './../../../../core/shared/constants';
import { selectRelations } from './../../../../core/point-of-interest/_selectors/e-commerce.selectors';
import {
	Feature,
	DIGITAL_FEATURE_ID
} from './../../../../core/point-of-interest/_models/feature.model';
import { User } from './../../../../core/auth/_models/user.model';
import { UserRequested } from './../../../../core/auth/_actions/auth.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { WEEK_DAY_OPTIONS } from './../../../../core/_base/crud/models/day-interval.model';
import { TranslationField } from './../../../../core/_base/crud/models/translation-field.model';
import {
	PointOfInterest,
	Category,
	OpeningHours,
	Payment,
	SocialNetworks,
	Address,
	Contacts
} from './../../../../core/point-of-interest';
import { CategoryService } from './../../../../core/point-of-interest/_services/category.service';
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormArray,
	FormControl,
	ValidationErrors,
	AbstractControl
} from '@angular/forms';
import {
	POI_STATE,
	PAYMENT_TYPE
} from './../../../../core/point-of-interest/_consts/specification.dictionary';
import { takeWhile, map, startWith } from 'rxjs/operators';
import {
	selectedPointOfInterest,
	currentUser
} from './../../../../core/auth/_selectors/auth.selectors';
import {
	LayoutUtilsService,
	MessageType
} from './../../../../core/_base/crud/utils/layout-utils.service';
import { PointOfInterestService } from './../../../../core/point-of-interest/_services/point-of-interest.service';
import { Store, select } from '@ngrx/store';
import { AppState } from './../../../../core/reducers/index';
import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	ChangeDetectorRef,
	OnDestroy
} from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import {
	markAllFormAs,
	toFormData
} from './../../../../core/_base/crud/utils/helper.function';
import { FeaturesService } from './../../../../core/point-of-interest/_services/features.service';
import { PostalCodeService } from './../../../../core/point-of-interest/_services/postal-code.service';

@Component({
	selector: 'kt-service-information',
	templateUrl: './service-information.component.html',
	styleUrls: ['./service-information.component.scss']
})
export class ServiceInformationComponent implements OnInit, OnDestroy {
	inView = true;
	editMode = false;
	multiLanguage = false;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	paymentTypes = Object.values(PAYMENT_TYPE);
	weekDaysOptions = WEEK_DAY_OPTIONS;
	PHONE_PREFIX = '+351';
	// Alert panel
	@ViewChild('alert', { static: false }) set content(content: ElementRef) {
		if (!!content && !!content.nativeElement) {
			content.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		this.alertElement = content;
	}
	alertElement: ElementRef;
	showAlertMessage = false;
	alertMessage: {
		message?: string;
		type?: 'primary' | 'accent' | 'warn';
	} = {};

	constructor(
		private cdr: ChangeDetectorRef,
		private store: Store<AppState>,
		private pointOfInterestService: PointOfInterestService,
		private layoutUtilsService: LayoutUtilsService,
		private formBuilder: FormBuilder,
		private categoryService: CategoryService,
		private postalCodeService: PostalCodeService,
		private featuresService: FeaturesService
	) {}

	pointOfInterest$: Observable<PointOfInterest>;

	pointOfInterest: PointOfInterest = null;
	pointOfInterestForm: FormGroup;
	oldPointOfInterest: PointOfInterest = null;
	categories: Category[] = [];
	features: Feature[] = [];

	// ECommerce Info
	shopId: string;
	pointOfInterestRelations$: Observable<
		{
			shopId: string;
			poiId: number;
		}[]
	>;

	// Category autocomplete
	categoryGroupOptions$: Observable<any[]>;
	categoryAutocomplete: FormControl = new FormControl();

	// Current User
	user$: Observable<User>;
	currentUser: User;

	async ngOnInit() {
		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.user$ = this.store.pipe(select(currentUser));
		this.user$.pipe(takeWhile(() => this.inView)).subscribe((user) => {
			if (!!user) {
				this.currentUser = user;
			}
		});

		this.categories = await this.categoryService.findCategories({ active: true });
		this.features = await this.featuresService.findFeatures({ _sort: 'order:asc' });
		this.pointOfInterest$ = this.store.pipe(select(selectedPointOfInterest));
		this.pointOfInterestRelations$ = this.store.pipe(select(selectRelations));

		combineLatest([this.pointOfInterest$, this.pointOfInterestRelations$])
			.pipe(takeWhile(() => this.inView))
			.subscribe(async ([pointOfInterest, poiRelations]) => {
				if (!!pointOfInterest) {
					// Get the most recent info from server
					const serverPointOfInterest = await this.pointOfInterestService.getPointOfInterestById(
						pointOfInterest.id,
						false
					);
					this.editMode = true;
					this.loadPointOfInterest(serverPointOfInterest);

					// ECommerce Info
					if (pointOfInterest.id && poiRelations && poiRelations.length) {
						const relation = poiRelations.find(
							(rel) => rel.poiId === pointOfInterest.id
						);
						if (relation) this.shopId = relation.shopId;
					}
				} else {
					this.editMode = false;
					const newPointOfInterest = new PointOfInterest();
					newPointOfInterest.clear();
					// When create an new Point of interest the initial state is pending until have all required info added;
					newPointOfInterest.state = POI_STATE.Pending;
					this.loadPointOfInterest(newPointOfInterest);
				}

				// Category autocomplete
				this.categoryGroupOptions$ = this.categoryAutocomplete.valueChanges
					.pipe(takeWhile(() => this.inView))
					.pipe(
						map((category: Category | any) => {
							this.pointOfInterestForm.get('category').markAsTouched();
							this.pointOfInterestForm.get('category').markAsDirty();
							if (category && typeof category === 'object') {
								this.pointOfInterestForm.get('category').setValue(category);
							} else {
								this.pointOfInterestForm.get('category').setValue('');
								this.pointOfInterestForm
									.get('category')
									.setErrors({ select: 'INPUT.VALIDATIONS.SELECT_FIELD' });
							}
							return category && typeof category === 'object'
								? category.name
								: category;
						}),
						startWith(''),
						map((value) => {
							return this.initCategoryGroups(this.categories, value);
						})
					);
			});
	}

	/**
	 *
	 * Category autocomplete
	 */
	displayCategoryFn(c1: Category) {
		return (c1 && c1.name) || '';
	}

	initCategoryGroups(categories: Category[], search = '') {
		let result = [];
		let filteredCategories = categories;
		if (!!search && search !== '') {
			filteredCategories = categories.filter((category) => {
				const name = category.name && (category.name as string).toLowerCase();
				return name.includes('' + search.toLowerCase());
			});
		}
		const totree = (branches, node) => {
			// if we don't have the parent yet
			if (!branches[node.parent]) {
				// create a dummy placeholder for now
				branches[node.parent] = {
					childrens: []
				};
			}
			// store our node in its parent
			branches[node.parent].childrens.push(node);
			// store our node in the full list
			// copy all added branches on possible placeholder
			branches[node.id] = Object.assign(node, {
				...branches[node.id],
				childrens: []
			});
			return branches;
		};

		// Categories that don't have the parent in the list of Categories
		const rootCategoriesIds = filteredCategories
			.filter(
				(category) => !filteredCategories.find((c) => c.id === category.parent)
			)
			.map((c) => c.id);

		// Create the full category tree
		const tree = filteredCategories.reduce(totree, {});

		// Reduce the tree result to the root categories
		result = rootCategoriesIds.reduce((acc, id) => {
			acc.push(tree[id]);
			return acc;
		}, []);

		return result;
	}

	ngOnDestroy() {
		this.inView = false;
	}

	/**
	 * Close alert
	 *
	 */
	onAlertClose($event) {
		this.showAlertMessage = false;
		this.alertMessage = {};
	}
	/**
	 * Show alert
	 *
	 */
	showAlert() {
		this.showAlertMessage = true;
		this.cdr.detectChanges();
	}

	/**
	 * Returns the error of form field
	 */
	hasError(field: string, error: string) {
		const control = this.pointOfInterestForm.get(field);
		if (!control) {
			return false;
		}
		return control.touched && control.hasError(error);
	}
	/**
	 * Returns the errors of form field
	 */
	getErrors(field: string) {
		const control = this.pointOfInterestForm.get(field);
		if (!control) {
			return false;
		}
		return control.touched && control.errors;
	}

	multiLanguageToggle() {
		this.multiLanguage = !this.multiLanguage;
	}

	/**
	 * Load the Point of Interest
	 */
	loadPointOfInterest(pointOfInterest: PointOfInterest) {
		this.pointOfInterest = pointOfInterest;
		this.oldPointOfInterest = Object.assign({}, pointOfInterest);
		this.initPointOfInterest();
		this.cdr.detectChanges();

		// Only activate the Postal Code validator after all changes applyed to reduce the number of verifications
		const postalCodeControl =
			!!this.pointOfInterestForm &&
			this.pointOfInterestForm.get('address.postal_code');
		if (!!postalCodeControl) {
			postalCodeControl.setAsyncValidators(this.autoCompleteAddress.bind(this));
			postalCodeControl.updateValueAndValidity();
		}
	}

	autoCompleteAddress(control: AbstractControl): Promise<ValidationErrors | null> {
		return this.getPostalCodeByValue(control.value)
			.then((postalCodes) => {
				let res = null;
				let newValues = {
					place: '',
					locality: '',
					city: '',
					region: '',
					country: ''
				};
				if (postalCodes && postalCodes.length > 0) {
					newValues = {
						place: postalCodes[0].place,
						locality: postalCodes[0].locality,
						city: postalCodes[0].city,
						region: postalCodes[0].region,
						country: postalCodes[0].country.country
					};
				} else {
					res = { invalid_postal_code: true };
				}
				this.pointOfInterestForm.get('address.place').setValue(newValues.place);
				this.pointOfInterestForm
					.get('address.locality')
					.setValue(newValues.locality);
				this.pointOfInterestForm.get('address.city').setValue(newValues.city);
				this.pointOfInterestForm.get('address.region').setValue(newValues.region);
				this.pointOfInterestForm.get('address.country').setValue(newValues.country);
				return res;
			})
			.catch((error) => {
				this.pointOfInterestForm.get('address.place').setValue('');
				this.pointOfInterestForm.get('address.locality').setValue('');
				this.pointOfInterestForm.get('address.city').setValue('');
				this.pointOfInterestForm.get('address.region').setValue('');
				this.pointOfInterestForm.get('address.country').setValue('');
				return { invalid_postal_code: error };
			})
			.then((res) => {
				this.pointOfInterestForm.get('address').markAllAsTouched();
				this.cdr.detectChanges();
				return res;
			});
	}

	/**
	 * Get the address components from postal code
	 * @param address
	 */
	async getPostalCodeByValue(postal_code: string) {
		console.log('Search by: ', postal_code);
		if (!postal_code) {
			return;
		}
		return await this.postalCodeService.findPostalCodes({ postal_code, _limit: 1 });
	}

	/**
	 * Init Point of Interest
	 */
	initPointOfInterest() {
		this.createForm();
		this.loadingSubject.next(false);
	}

	/**
	 * Create form
	 */
	createForm() {
		if (!this.pointOfInterest) {
			return;
		}

		const refinedCategory = this.moreRefinedCategory(
			this.pointOfInterest.categories
		);
		// Set the autocomplete control value
		if (!!refinedCategory) {
			this.categoryAutocomplete.setValue(refinedCategory);
		}

		this.pointOfInterestForm = this.formBuilder.group({
			id: [this.pointOfInterest.id],
			demo: [this.pointOfInterest.demo],
			title: this.formBuilder.group({
				id: [
					this.pointOfInterest.title
						? (this.pointOfInterest.title as TranslationField).id
						: null
				],
				pt: [
					this.pointOfInterest.title
						? (this.pointOfInterest.title as TranslationField).pt
						: '',
					[Validators.required, Validators.minLength(5), Validators.maxLength(75)]
				],
				en: [
					this.pointOfInterest.title
						? (this.pointOfInterest.title as TranslationField).en
						: '',
					[Validators.minLength(5), Validators.maxLength(75)]
				]
			}),
			description: this.formBuilder.group({
				id: [
					this.pointOfInterest.description
						? (this.pointOfInterest.description as TranslationField).id
						: null
				],
				pt: [
					this.pointOfInterest.description
						? (this.pointOfInterest.description as TranslationField).pt
						: '',
					[Validators.required, Validators.maxLength(255)]
				],
				en: [
					this.pointOfInterest.description
						? (this.pointOfInterest.description as TranslationField).en
						: '',
					[Validators.maxLength(255)]
				]
			}),
			category: [refinedCategory, Validators.required],
			website: [
				this.pointOfInterest.web_site || null,
				Validators.pattern(URL_REGEX)
			],
			state: [this.pointOfInterest.state, Validators.required],
			address: this.formBuilder.group({
				id: [this.pointOfInterest.address ? this.pointOfInterest.address.id : null],
				street: [
					this.pointOfInterest.address ? this.pointOfInterest.address.street : '',
					Validators.maxLength(100)
				],
				place: [
					{
						value: this.pointOfInterest.address
							? this.pointOfInterest.address.place
							: '',
						disabled: true
					},
					Validators.maxLength(50)
				],
				locality: [
					{
						value: this.pointOfInterest.address
							? this.pointOfInterest.address.locality
							: '',
						disabled: true
					},
					Validators.maxLength(50)
				],
				city: [
					{
						value: this.pointOfInterest.address
							? this.pointOfInterest.address.city
							: '',
						disabled: true
					},
					Validators.maxLength(50)
				],
				region: [
					{
						value: this.pointOfInterest.address
							? this.pointOfInterest.address.region
							: '',
						disabled: true
					},
					Validators.maxLength(50)
				],
				country: [
					{
						value: this.pointOfInterest.address
							? this.pointOfInterest.address.country
							: '',
						disabled: true
					},
					Validators.maxLength(50)
				],
				postal_code: [
					this.pointOfInterest.address
						? this.pointOfInterest.address.postal_code
						: '',
					[Validators.required]
				]
			}),
			opening_hours: this.formBuilder.array(
				this.pointOfInterest.opening_hours
					? this.pointOfInterest.opening_hours.map((hour: OpeningHours) =>
							this.createOpeningHoursForm(hour)
					  )
					: []
			),
			contacts: this.formBuilder.group({
				id: [
					this.pointOfInterest.contacts ? this.pointOfInterest.contacts.id : null
				],
				email: [
					this.pointOfInterest.contacts ? this.pointOfInterest.contacts.email : '',
					Validators.compose([
						Validators.email,
						Validators.required,
						Validators.minLength(3),
						// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
						Validators.maxLength(320)
					])
				],
				phone_number: [
					this.pointOfInterest.contacts
						? this.pointOfInterest.contacts.phone_number
						: '',
					Validators.required
				]
			}),
			features: this.createFeaturesForm(this.pointOfInterest.features as Feature[]),
			available_payments: this.createAvailablePaymentsForm(
				this.pointOfInterest.available_payments
			),
			social_networks: this.formBuilder.group({
				id: [
					this.pointOfInterest.social_networks
						? this.pointOfInterest.social_networks.id
						: null
				],
				linkedIn: [
					this.pointOfInterest.social_networks
						? this.pointOfInterest.social_networks.linkedIn
						: ''
				],
				facebook: [
					this.pointOfInterest.social_networks
						? this.pointOfInterest.social_networks.facebook
						: ''
				],
				twitter: [
					this.pointOfInterest.social_networks
						? this.pointOfInterest.social_networks.twitter
						: ''
				],
				instagram: [
					this.pointOfInterest.social_networks
						? this.pointOfInterest.social_networks.instagram
						: ''
				]
			})
		});
	}

	get opening_hours(): FormArray {
		return (
			!!this.pointOfInterestForm &&
			(this.pointOfInterestForm.get('opening_hours') as FormArray)
		);
	}

	get featuresArrayControl(): FormArray {
		return (
			!!this.pointOfInterestForm &&
			(this.pointOfInterestForm.get('features') as FormArray)
		);
	}
	/**
	 * Get the more refined category
	 * based in the factual taxonomy the more high id value is the more refined category in the Category Tree
	 */
	private moreRefinedCategory(categories: number[] | Category[]) {
		if (!categories || (!!categories && categories.length <= 0)) {
			return null;
		}
		let maxId;
		if (typeof categories[0] !== 'number') {
			maxId = (categories as Category[]).reduce(
				(max, node) => Math.max(max, node.id),
				0
			);
			// (categories as Category[]).map((c) => c.id) as number[];
		} else {
			maxId = Math.max(...(categories as number[]));
		}
		return this.categories.find((c) => c.id === maxId);
	}

	addClearOpeningHour() {
		const openingHours = this.pointOfInterestForm.get('opening_hours') as FormArray;
		openingHours.push(this.createOpeningHoursForm());
	}

	removeOpeningHourForm(index: number) {
		const openingHours = this.pointOfInterestForm.get('opening_hours') as FormArray;
		openingHours.removeAt(index);
		openingHours.markAsDirty();
	}

	getOpeningHourFromForm(hour: FormControl): OpeningHours {
		const openingHour = new OpeningHours();
		openingHour.clear();
		openingHour.id = hour.get('id').value || undefined;
		openingHour.opening_hours = hour.get('opening_hours').value;
		openingHour.closing_hours = hour.get('closing_hours').value;
		openingHour.day_interval.from = hour.get('day_interval.from').value;
		openingHour.day_interval.to = hour.get('day_interval.to').value;
		return openingHour;
	}

	createOpeningHoursForm(openingHour?: OpeningHours): FormGroup {
		// Create a clear opening hour
		if (!openingHour) {
			openingHour = new OpeningHours();
			openingHour.clear();
		}

		return this.formBuilder.group(
			{
				id: [openingHour.id],
				opening_hours: [openingHour.opening_hours, Validators.required],
				closing_hours: [openingHour.closing_hours, Validators.required],
				day_interval: this.formBuilder.group({
					id: [openingHour.day_interval ? openingHour.day_interval.id : null],
					from: [
						openingHour.day_interval ? openingHour.day_interval.from : '',
						Validators.required
					],
					to: [openingHour.day_interval ? openingHour.day_interval.to : '']
				})
			},
			{ validator: (data) => OpeningHours.hoursValidator(data.value) }
		);
	}

	createAvailablePaymentsForm(payments: Payment[]) {
		const paymentGroup = {};
		Object.keys(PAYMENT_TYPE).forEach((type) => {
			paymentGroup[PAYMENT_TYPE[type]] = new FormControl(
				!!payments && payments.findIndex((p) => PAYMENT_TYPE[type] === p.type) !== -1
			);
		});
		return this.formBuilder.group(paymentGroup);
	}

	createFeaturesForm(features: Feature[]) {
		if (!this.features || this.features.length <= 0) {
			return;
		}
		const featuresControlArray = [];
		this.features
			.filter((f) => !!f.active)
			.forEach((feature) => {
				featuresControlArray.push(
					this.formBuilder.group({
						id: [feature.id],
						icon: [feature.icon],
						name: [feature.name],
						description: [feature.description],
						selected: new FormControl(
							!!features && features.findIndex((f) => f.id === feature.id) !== -1
						)
					})
				);
			});
		return this.formBuilder.array(featuresControlArray);
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit() {
		/** check form */
		if (this.pointOfInterestForm.invalid) {
			markAllFormAs(this.pointOfInterestForm, 'touched');
			return;
		}
		if (this.pointOfInterestForm.pristine) {
			this.alertMessage = {
				message: 'NOTIFICATIONS.FORM.ALERTS.NOTHING_TO_SAVE',
				type: 'primary'
			};
			this.showAlert();
			return;
		}

		const editedPointOfInterest: PointOfInterest = this.preparePointOfInterest();
		if (!editedPointOfInterest) {
			console.log('Missing the information to create or update the notification!!');
			return;
		}

		if (editedPointOfInterest.id > 0) {
			this.updatePointOfInterest(editedPointOfInterest);
			return;
		}

		// Set the owner user of new Point Of interest
		editedPointOfInterest.user =
			(!!this.currentUser && this.currentUser.id) || undefined;
		this.addPointOfInterest(editedPointOfInterest);
	}

	/**
	 *
	 *  Returns object for saving
	 *
	 */
	preparePointOfInterest(): PointOfInterest {
		if (!this.pointOfInterest || this.pointOfInterestForm.pristine) {
			return null;
		}

		const _poi = new PointOfInterest();
		// ID
		_poi.id = this.pointOfInterestForm.get('id').value || undefined;
		// Is Demo
		_poi.demo = this.pointOfInterestForm.get('demo').value || false;

		// Type
		_poi.state = this.pointOfInterestForm.get('state').value;

		// Title
		_poi.title = new TranslationField();
		_poi.title.clear();
		_poi.title.id = this.pointOfInterestForm.get('title.id').value || undefined;
		_poi.title.pt = this.pointOfInterestForm.get('title.pt').value;
		_poi.title.en = this.pointOfInterestForm.get('title.en').value;

		// Description
		_poi.description = new TranslationField();
		_poi.description.clear();
		_poi.description.id =
			this.pointOfInterestForm.get('description.id').value || undefined;
		_poi.description.pt = this.pointOfInterestForm.get('description.pt').value;
		_poi.description.en = this.pointOfInterestForm.get('description.en').value;

		// Categories
		_poi.categories = this.getCategoryTree(
			this.pointOfInterestForm.get('category').value
		);

		// Website
		_poi.web_site = this.pointOfInterestForm.get('website').value;

		// Address
		_poi.address = new Address();
		_poi.address.clear();
		_poi.address.id = this.pointOfInterestForm.get('address.id').value || undefined;
		_poi.address.street = this.pointOfInterestForm.get('address.street').value;
		_poi.address.place = this.pointOfInterestForm.get('address.place').value;
		_poi.address.locality = this.pointOfInterestForm.get('address.locality').value;
		_poi.address.city = this.pointOfInterestForm.get('address.city').value;
		_poi.address.region = this.pointOfInterestForm.get('address.region').value;
		_poi.address.country = this.pointOfInterestForm.get('address.country').value;
		_poi.address.postal_code = this.pointOfInterestForm.get(
			'address.postal_code'
		).value;

		// Opening Hours
		_poi.opening_hours = [];
		const openingHours = this.pointOfInterestForm.get('opening_hours') as FormArray;
		if (
			!!openingHours &&
			openingHours.controls &&
			openingHours.controls.length > 0
		) {
			openingHours.controls.forEach((openingHour: FormControl) => {
				_poi.opening_hours.push(this.getOpeningHourFromForm(openingHour));
			});
		}

		// Contacts
		_poi.contacts = new Contacts();
		_poi.contacts.clear();
		_poi.contacts.id =
			this.pointOfInterestForm.get('contacts.id').value || undefined;
		_poi.contacts.email = this.pointOfInterestForm.get('contacts.email').value;
		_poi.contacts.phone_number = undefined;
		const phoneNumberControl = this.pointOfInterestForm.get('contacts.phone_number');
		_poi.contacts.phone_number =
			!!phoneNumberControl.dirty && phoneNumberControl.value
				? this.PHONE_PREFIX + phoneNumberControl.value
				: phoneNumberControl.value || undefined;

		// Available Payments
		_poi.available_payments = [];
		const payments = this.pointOfInterestForm.get('available_payments') as FormGroup;
		if (!!payments && payments.controls) {
			Object.keys(payments.controls)
				.filter((key) => !!payments.get(key).value)
				.forEach((key) => {
					const payment = new Payment();
					payment.clear();
					payment.type = key as PAYMENT_TYPE;
					_poi.available_payments.push(payment);
				});
		}

		// Features
		_poi.features = [];
		let activeFeatures: number[] = [];
		const featuresArrayControl = this.pointOfInterestForm.get(
			'features'
		) as FormArray;
		if (!!featuresArrayControl && featuresArrayControl.controls) {
			activeFeatures = featuresArrayControl.controls
				.filter((control) => !!control.get('selected').value)
				.map((control) => control.get('id').value);
		}

		// Need add the digital payment feature if have selected any digital payment
		if (!!Payment.haveAnyDigitalPayment(_poi.available_payments)) {
			activeFeatures.push(DIGITAL_FEATURE_ID);
		}
		_poi.features = activeFeatures;

		// Social Networks
		_poi.social_networks = new SocialNetworks();
		_poi.social_networks.clear();
		_poi.social_networks.id =
			this.pointOfInterestForm.get('social_networks.id').value || undefined;
		_poi.social_networks.linkedIn = this.pointOfInterestForm.get(
			'social_networks.linkedIn'
		).value;
		_poi.social_networks.facebook = this.pointOfInterestForm.get(
			'social_networks.facebook'
		).value;
		_poi.social_networks.twitter = this.pointOfInterestForm.get(
			'social_networks.twitter'
		).value;
		_poi.social_networks.instagram = this.pointOfInterestForm.get(
			'social_networks.instagram'
		).value;

		return _poi;
	}

	private getCategoryTree(category: Category): number[] {
		if (!category) {
			return [];
		}
		let result = [];
		result.push(category.id);
		if (!!category && !!category.parent) {
			const parentCategory = this.categories.find((c) => c.id === category.parent);
			result = result.concat(this.getCategoryTree(parentCategory));
		}
		return result;
	}

	/**
	 * Refresh PointOfInterest
	 *
	 * @param pointOfInterest: PointOfInterest
	 */
	refreshPointOfInterest(pointOfInterest: PointOfInterest) {
		this.loadingSubject.next(false);
		if (!!pointOfInterest) {
			this.store.dispatch(new UserRequested());
			this.pointOfInterest = null;
		}
	}

	/**
	 * Add Point of Interest
	 *
	 * @param _pointOfInterest: PointOfInterest
	 * @param withBack: boolean
	 */
	async addPointOfInterest(pointOfInterest: PointOfInterest) {
		if (!pointOfInterest) {
			return null;
		}

		const payload = {
			data: JSON.stringify(pointOfInterest)
		};
		this.loadingSubject.next(true);

		try {
			const res = await this.pointOfInterestService.createPointOfInterest(
				toFormData(payload)
			);

			this.loadingSubject.next(false);
			const message = 'POINT_OF_INTEREST.FORM.ALERTS.CREATE_SUCCESS';
			this.layoutUtilsService.showActionNotification(message, MessageType.Create);
			this.refreshPointOfInterest(res[0]);
		} catch (error) {
			this.loadingSubject.next(false);
			if (error instanceof HttpErrorResponse) {
				this.alertMessage = {
					message: 'POINT_OF_INTEREST.FORM.ALERTS.CREATE_ERROR',
					type: 'warn'
				};
				this.showAlert();
			}
		}
	}

	/**
	 * Update Point of Interest
	 *
	 * @param _notification: NotificationModel
	 * @param withBack: boolean
	 */
	async updatePointOfInterest(_pointOfInterest: PointOfInterest) {
		if (!_pointOfInterest) {
			return null;
		}
		const payload = {
			data: JSON.stringify(_pointOfInterest)
		};
		this.loadingSubject.next(true);

		try {
			const res = await this.pointOfInterestService.updatePointOfInterest(
				_pointOfInterest.id,
				toFormData(payload),
				true,
				{ poi: _pointOfInterest, shopId: this.shopId }
			);

			this.loadingSubject.next(false);
			const message = 'POINT_OF_INTEREST.FORM.ALERTS.UPDATE_SUCCESS';
			this.layoutUtilsService.showActionNotification(message, MessageType.Update);
			this.refreshPointOfInterest(res[0]);
		} catch (error) {
			this.loadingSubject.next(false);
			if (error instanceof HttpErrorResponse) {
				this.alertMessage = {
					message: 'POINT_OF_INTEREST.FORM.ALERTS.UPDATE_ERROR',
					type: 'warn'
				};
				this.showAlert();
			}
		}
	}

	get isPointOfInterestReady() {
		if (this.editMode) return !!this.pointOfInterest && !!this.shopId;

		return !!this.pointOfInterest;
	}
}
