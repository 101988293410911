export class PageConfig {
	public defaults: any = {
		// dashboard: {
		// 	page: {
		// 		title: 'Dashboard',
		// 		desc: 'Latest updates and statistic charts'
		// 	},
		// },
		// 'my-page': { // <= add page URL
        //     page: { title: 'My page', desc: 'My page desc' } // <= Page name and description
        // },
	};

	public get configs(): any {
		return this.defaults;
	}
}
