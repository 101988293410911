// USA
export const locale = {
	lang: 'en',
	data: {
		GENERIC: {
			ALL: 'All',
			SEARCH: 'Search',
			SEARCH_ALL: 'Search in all fields',
			UPDATE_STATE: 'Update state',
			STATE: 'State',
			CANCEL: 'Cancel',
			SELECT: 'Select',
			CLEAR: 'Clear',
			UNDO: 'Undo',
			DISCARD: 'Discard',
			DELETE: 'Delete',
			DELETE_ALL: 'Delete All',
			FILTER_BY: 'Filter by {{key}}',
			ACTIONS: 'Actions',
			WAIT: 'Please wait....',
			BACK: 'Back',
			OPEN: 'open',
			CLOSE: 'Close',
			FROM: 'from',
			TO: 'to',
			SAVE: 'Save',
			CREATE: 'Create',
			PUBLISH: 'Publish',
			CONFIRM: 'Confirm',
			CHANGE: 'Change',
			NO_DATA: 'No more data',
			EXIT: 'Exit',
			LAST_VALUE: 'Last value',
			ND: 'n/d',
			OPERATION_ERROR: 'The operation failed',
			ITEMS: 'Items',
			CONTACTS: 'Contacts',
			DATE: 'Date',
			ADDRESS: 'Address',
			TOTAL: 'Total',
			CUSTOMER: 'Customer',
			COST: 'COST',
			LABEL: 'Label',
			NAME: 'Name',
			ENABLED: 'Enabled',
			DISABLED: 'Disabled',
			GROUP: 'Group',
			ENTER: 'Enter',
			ACCTIONS: 'Acctions',
			DELETING: 'Deleting...',
			UPS: 'Ups...',
			TYPE: 'Type',
			VISIBLE: 'Visible',
			NOT_VISIBLE: 'Not visible',
			POSITION: 'Position',
			ONLY_ACCEPT_NUMBERS: 'Only accept positive integers, e.g.: 1, 2, 43, 1922, ...'
		},
		TIME: {
			WEEK_DAYS: {
				MONDAY: { SHORT: 'Mon', FULL: 'Monday' },
				TUESDAY: { SHORT: 'Tue', FULL: 'Tuesday' },
				WEDNESDAY: { SHORT: 'Wed', FULL: 'Wednesday' },
				THURSDAY: { SHORT: 'Thu', FULL: 'Tuesday' },
				FRIDAY: { SHORT: 'Fri', FULL: 'Friday' },
				SATURDAY: { SHORT: 'Sat', FULL: 'Saturday' },
				SUNDAY: { SHORT: 'Sun', FULL: 'Sunday' },
				HOLIDAY: { FULL: 'Holidays' }
			}
		},
		MULTILANGUAGE: {
			ADD: 'Add translation',
			HIDE: 'Hide translation'
		},
		INPUT: {
			PT: {
				PLACEHOLDER: 'Type the Portuguese text',
				LABEL: 'PT'
			},
			EN: {
				PLACEHOLDER: 'Type the English text',
				LABEL: 'EN'
			},
			VALIDATIONS: {
				REQUIRED: 'This field is required',
				PATTERN_INVALID: 'Invalid value',
				EMAIL_INVALID: 'Invalid email',
				POSTAL_CODE_INVALID: 'Invalid postal code',
				MAX_LENGTH: 'Maximum number of characters reached: {{value}} / {{maxValue}}',
				MIN_LENGTH: 'Enter at least {{value}} characters',
				DAY_INTERVAL: {
					MANDATORY: 'Start day is mandatory.',
					END_AFTER_START: 'The end day should be after start day.'
				},
				OPENING_HOURS: {
					MANDATORY: 'Opening interval is mandatory.',
					CLOSE_AFTER_OPEN: 'The close hour should be after open hour.'
				},
				SELECT_FIELD: 'Select one of the list values.',
				FILE_TOO_BIG_MB: 'The file is too big: {{value}} Mb / {{maxValue}} Mb',
				UPLOAD_FILES_HINT:
					'You can only add up to {{maxCount}} photos with a maximum size of {{maxSize}} MB each.',
				UPLOAD_FILES_ERROR: 'The files were discarded: {{ignoreFiles}}'
			}
		},
		DATATABLE: {
			SELECTED_ROWS: 'Selected records count:',
			NO_RECORDS: 'No records found'
		},
		TRANSLATOR: {
			SELECT: 'Select your language'
		},
		UPDATE_DIALOG: {
			BUTTON_UPDATE: 'Update {{key}}',
			SELECT_HINT: 'for rows updating'
		},
		CREATE_TAG_DIALOG: {
			FORM: {
				TITLE: 'Create new product category',
				NAME: 'Name'
			}
		},
		GO_BACK_MODAL: {
			TITLE: 'Unsaved changes',
			MESSAGE: 'You have unsaved changes. Do you want to continue?'
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			PAGES: 'Pages',
			FEATURES: 'Features',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			NOTIFICATIONS: 'Offers',
			'SERVICE-PAGE': {
				'SERVICE-PAGE': 'Profile',
				PROFILE: 'Profile information',
				GEOFENCE: 'Geofence information',
				GALLERY: 'Photo gallery'
			},
			SHOP: {
				SHOP: 'Shop',
				PRODUCTS: 'Products',
				CATEGORIES: 'Categories',
				ATTRIBUTES: 'Attributes',
				ORDERS: 'Orders',
				SETTINGS: 'Settings'
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: "Don't have an account?",
				SIGNUP_BUTTON: 'Sign Up',
				FORGOT_BUTTON: 'Forgot Password',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact'
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Sign In'
			},
			FORGOT: {
				TITLE: 'Forgotten Password?',
				DESC: 'Enter your email to reset your password',
				SUCCESS: 'Your account has been successfully reset.'
			},
			REGISTER: {
				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				CONFIRM_PASSWORD: 'Confirm Password',
				USERNAME: 'Username'
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				NOT_CONFIRMED_USER: 'The account is not confirmed. Please check your email.',
				BLOCKED_USER: 'The account is blocked.',
				REQUIRED_FIELD: 'Required field',
				MIN_LENGTH_FIELD: 'Minimum field length:',
				MAX_LENGTH_FIELD: 'Maximum field length:',
				INVALID_FIELD: 'Field is not valid'
			}
		},
		TOPBAR: {
			USER_PROFILE: {
				WELCOME: 'Hi',
				ADD_DEMO_POINT_OF_INTEREST: 'Add the demonstration Point of interest',
				TOOLTIP_DEMO_POINT_OF_INTEREST:
					'To explore our application we created a point of interest of demonstration with randomly generated data',
				DELETE_DEMO_POINT_OF_INTEREST: 'Delete the demonstration Point of interest',
				ADD_POINT_OF_INTEREST: 'Add new Point of interest',
				MY_POINT_OF_INTERESTS: 'My Points of Interest',
				TRIAL_TOOLTIP:
					'In the trial version you can only manage 1 Point of interest',
				TRIAL: 'Trial version',
				ALERTS: {
					DEMO_CREATED_ERROR:
						'Error when create the demonstration Point of interest',
					DEMO_CREATED_SUCCESS: 'Demonstration Point of interest created',
					DEMO_DELETED_ERROR:
						'Error when delete the demonstration Point of interest',
					DEMO_DELETED_SUCCESS: 'Demonstration Point of interest deleted'
				}
			}
		},
		FOOTER: {
			CONTACT: 'Contact',
			ABOUT: 'About',
			TEAM: 'Team'
		},
		DASHBOARD: {
			STATISTICS: {
				TODAY: {
					TITLE: 'Active users',
					SUB_TITLE: 'Today',
					OPENED: 'opened store profile',
					LISTED: 'saw the store listed'
				},
				LAST_MONTH: {
					SUB_TITLE: 'records last month',
					OPENED: {
						TITLE: 'opened offers'
					},
					LISTED: {
						TITLE: 'saw offers listed'
					}
				}
			},
			RANKING: {
				TITLE: 'Most open offers'
			},
			ACTIVITY_CHART: {
				TITLE: 'Offers activity',
				LEGENDS: {
					SENT: 'Sent',
					RECEIVED: 'Received',
					SAW: 'Saw',
					OPENED: 'opened',
					LISTED: 'listed'
				}
			},
			TIME_MENU: {
				VALUES: {
					LAST_DAYS: 'Last {{days}} days',
					LAST_DAY: 'Last day',
					LAST_MONTHS: 'Last {{months}} months',
					LAST_MONTH: 'Last month'
				}
			}
		},
		PRODUCTS: {
			NAME: 'Name',
			VARIANT: 'Variant',
			OPTION: 'Option',
			QUANTITY: 'Quantity',
			PRICE: 'Price',
			SALE: 'Sale price',
			PUBLISH: 'Publish',
			TITLES: {
				LIST: 'Products',
				CREATE: 'Create product',
				EDIT: 'Edit product'
			},
			STATE: {
				INACTIVE: 'Inactive',
				ACTIVE: 'Active',
				PUBLISHED: 'Published',
				UNPUBLISHED: 'Unpublished'
			},
			ACTION: {
				ADD: 'Add new product',
				EDIT: 'Edit product',
				DELETE: 'Delete product',
				GO_BACK_PRODUCTS: 'Back to the products list',
				PUBLISH: 'Publish products'
			},
			LIST: {
				MY_PRODUCTS: {
					TITLE: 'My products'
				},
				FIELDS: {
					SEARCH_BY_TITLE: 'Search by product title',
					CATEGORY: 'Category',
					SEARCH_BY_CATEGORY: 'Search by category',
					PRICE: 'Price',
					SALE_PRICE: 'Sale Price',
					PUBLISHED: 'Published',
					PUBLISHED_TOOLTIP:
						'The product only be available in the catalog if is published. Select products to proceed with publication.'
				},
				UPDATE_STATE_DIALOG: {
					TITLE: 'Update state for selected offers',
					MESSAGE: 'State has been updated in {{updatedCount}} products'
				},
				DELETE_MODAL: {
					TITLE: 'Products Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected products?',
					WAIT_DESCRIPTION: 'Products are deleting...',
					DELETE_MESSAGE: 'Selected products have been deleted'
				}
			},
			FORM: {
				ERRORS: {
					ERROR_SAVING_IMAGES: 'Error saving images',
					ERROR_SAVING_BASIC_INFO: 'Error saving basic info',
					ERROR_CREATING_PRODUCT: 'Error creating product',
					PRICING_NOT_UPDATED_ERROR: 'Error updating pricing',
					ERROR_UPDATING_PRODUCT: 'Error updating product',
					ERROR_CREATING_VARIANT: 'Error updating attribute level 1',
					ERROR_CREATING_OPTIONS: 'Error updating attribute level 2',
					PRODUCT_VARIANT_NOT_ARCHIVED: 'Attribute level 1 was not removed',
					PRODUCT_OPTION_NOT_ARCHIVED: 'Attribute level 2 was not removed'
				},
				STATE_SECTION: {
					TITLE: 'State'
				},
				BASE_INFO_SECTION: {
					TITLE: 'Basic information',
					NAME_FIELD: {
						TITLE: 'Name'
					},
					DESCRIPTION_FIELD: {
						TITLE: 'Description'
					}
				},
				VARIANTS_AND_OPTIONS: {
					INFO:
						// tslint:disable-next-line:max-line-length
						'<p>Attributes can have one or two levels. For example, a sweater with colors and sizes:</p> <p><strong>Attributes labels</strong></p> <ul> <li>Level 1: Color</li> <li>Level 2: Size</li> </ul> <p>Attributes</p> <ul> <li>Level 1: Back, White</li> <li>Level 2: S, M, L, XL</li> </ul> <p>&nbsp;</p> <p>Thus the same sweater has two variants - Black and white, having for each color the sizes&nbsp;S, M, L and XL.</p>',
					ADD_ONE_MORE_LEVEL: 'Add one more level of attributes',
					TITLE_ATTRS_LABELS: 'Attributes names',
					VARIANTS_LABEL: 'Level 1 (ex.: Color or Size)',
					OPTIONS_LABEL: 'Level 2',
					TITLE_ATTRS: 'Attributes',
					VARIANTS: 'Level 1 (ex: Black, White, .. or S, M, .. )',
					OPTIONS: 'Level 2',
					ADD_VARIANT: 'Add attribute level 1',
					CREATE_OPTION: 'Create attribute level 2',
					REMOVE_VAR_OR_OPTION: 'Remove attribute',
					REMOVING_VAR_OR_OPTION: 'Removing attribute',
					DELTED_VAR_OR_OPTION: 'Attribute removed',
					EMPTY_VALUE: 'There is no value',
					ALREADY_EXIST: 'Value already exists'
				},
				PRICING: {
					TITLE: 'Price and Sale prices',
					UPDATING_PRICING: 'Pricing update',
					SAVED_SUCCESSFULLY: 'Pricing saved successfully',
					THERE_IS_NO_PRICING_INFO:
						'There is no pricing information. Please, add attributes and start to add pricing to your product'
				},
				INVENTORY_INFO_SECTION: {
					TITLE: 'Inventory',
					MANAGER_FIELD: {
						TITLE: 'Manager inventory',
						TOOLTIP:
							'If is active the shoppers can only order up to the available quantity, unless you enable back-ordering'
					},
					BACKORDER_FIELD: {
						TITLE: 'Allow backorder',
						SUB_TITLE: 'Allow the shoppers order up to the available quantity'
					}
				},
				BUY_OPTIONS_SECTION: {
					TITLE: 'Purchasing options',
					LIST: {
						ATTRIBUTE: {
							FIELD: 'Attribute',
							PLACEHOLDER: 'Enter the attribute',
							TOOLTIP: 'Change the attribute value on the first variant',
							EDIT_TOOLTIP:
								'The value changes affect the attribute of all variants at the same level'
						},
						LABEL: {
							FIELD: 'Label',
							PLACEHOLDER: 'Enter the label'
						},
						PRICE: {
							FIELD: 'Price',
							PLACEHOLDER: 'Enter the price',
							EDIT_TOOLTIP: 'The value changes affect the price of all child options'
						},
						SALE_PRICE: {
							FIELD: 'Sale Price',
							PLACEHOLDER: 'Enter the sale price',
							EDIT_TOOLTIP:
								'The value changes affect the sale price of all child options'
						},
						QUANTITY: {
							FIELD: 'Qty available',
							PLACEHOLDER: 'Enter the quantity',
							EDIT_TOOLTIP:
								'The value changes affect the quantity of all child options',
							EDIT_BLOCKED_TOOLTIP:
								'To manager the stock quantity need to enable the manager inventory on this product',
							RESERVED_TOOLTIP: 'Reserved inventory: '
						},
						STATE: {
							FIELD: 'State'
						},
						TAG: {
							FIELD: 'Tag',
							TOOLTIP: 'Value displayed in cart, checkout and orders'
						},
						ACTIONS: {
							FIELD: 'Actions',
							ADD_VARIANT: 'Create new variant',
							EDIT_VARIANT: 'Edit the variant',
							DELETE_VARIANT: 'Delete the variant',
							ADD_OPTION_TO: 'Create new option to ',
							ADD_OPTION: 'Create new ',
							EDIT_OPTION: 'Edit the option',
							DELETE_OPTION: 'Delete the option'
						},
						ERRORS: {
							NO_OPTIONS: "You don't have any options in this variant",
							MISSING_NAMES: 'Missing set some variant/option names!!',
							MISSING_PRICES: 'Missing set some variant/option prices!!',
							MISSING_QUANTITIES:
								'Missing set some variant/option available quantities!!',
							REQUIRED: 'At least one purchase option is required'
						}
					}
				},
				MESSAGES: {
					SAVE_PRODUCT_ERROR: 'Occur some errors when save the product and variants',
					SAVE_PRODUCT_SUCCESS: 'Product saved with success',
					SAVE_VARIANT_SUCCESS: 'Variant has been saved',
					SAVE_OPTION_SUCCESS: 'Option has been saved',
					PUBLISHED_SUCCESSFULLY: 'Product published successfully'
				},
				DELETE_VARIANT_MODAL: {
					TITLE: 'Variant Delete',
					DESCRIPTION: 'Are you sure to permanently delete the selected variant?',
					WAIT_DESCRIPTION: 'Variant is deleting...',
					DELETE_MESSAGE: 'Selected variant has been deleted'
				},
				NOT_PROCESSED_IMG_MODAL: {
					TITLE: 'Some images not processed!!',
					DESCRIPTION:
						'The product have some images not processed by the Serve yet. If you continue some of these images are not available in the catalog.',
					BUTTON_PUBLISH: 'Publish',
					BUTTON_NOT_PUBLISH: 'Not publish'
				}
			},
			TAGS: {
				LABEL_PLURAL: 'Tags',
				TITLE: 'Category',
				PLACEHOLDER: 'Select one category...',
				CREATE: 'Create new category',
				CREATE_ERROR: "Can't create the Category",
				CREATE_SUCCESS: 'Category created with success',
				DELETE_DESCRIPTION: 'Are you sure to permanently delete the category?',
				DELETE_SUCCESS: 'Category successfully deleted',
				DELETE_ERROR: 'There was an error deleting the category',
				ADD: 'Add category'
			}
		},
		NOTIFICATIONS: {
			TITLES: {
				LIST: 'Offers',
				CREATE: 'Create offer',
				EDIT: 'Edit offer'
			},
			FORM: {
				STATE_SECTION: {
					TITLE: 'Offer Status:'
				},
				DETAILS_SECTION: {
					TITLE: 'Offer Details:',
					PICTURE: {
						TITLE: 'Offer picture'
					},
					TITLE_FIELD: {
						TITLE: 'Promotional offer:'
					},
					DESCRIPTION: {
						TITLE: 'Description:'
					},
					VALID_DATE: {
						TITLE: 'Start and end:',
						PLACEHOLDER: 'Select date range',
						MAX_RANGE: '(max range: {{max_value}} days)'
					},
					BLOCKED_DAY: {
						MSG: 'Already reached the maximum number of active offers on this day',
						MAX_VALUE: ' (max: {{max_value}}/day)'
					}
				},
				ALERTS: {
					FORM_ERROR: 'Some fields are not valid.',
					NOTHING_TO_SAVE: 'Nothing to save',
					CREATE_ERROR: "Can't create the offer.",
					CREATE_SUCCESS: 'Offer created successfully.',
					UPDATE_ERROR: "Can't update the offer.",
					UPDATE_SUCCESS: 'Offer updated successfully.',
					DATES_CONFLICT:
						'Already exists a offer that intercept the range of dates selected.',
					SERVER_ERROR: 'Server error',
					RANGE_IN_PAST:
						'Has a date range in the past. To be able to update the information you must start by updating this interval.',
					SERVER_ERRORS: {
						range_to_big:
							'The dates range is too big. The max date range is {{max_date_range}} days.',
						active_notifications_per_day:
							'Already have days with {{max_active_per_day}} (or more) active notifications inside this date range.'
					}
				}
			},
			FIELDS: {
				TITLE: 'Title',
				SEARCH_BY_TITLE: 'Search by notification title',
				DESCRIPTION: 'Description',
				START_DATE: 'Start date',
				END_DATE: 'End date'
			},
			ACTION: {
				ADD: 'Add new offer',
				EDIT: 'Edit offer',
				DELETE: 'Delete offer',
				GO_BACK_NOTIFICATION: 'Back to the offers list'
			},
			STATE: {
				INACTIVE: 'Inactive',
				SCHEDULED: 'Scheduled',
				ACTIVE: 'Active'
			},
			UPDATE_STATE_DIALOG: {
				TITLE: 'Update state for selected offers',
				MESSAGE: 'State has been updated for selected offers'
			},
			DELETE_MODAL: {
				TITLE: 'Offers Delete',
				DESCRIPTION: 'Are you sure to permanently delete selected offers?',
				WAIT_DESCRIPTION: 'Offers are deleting...',
				DELETE_MESSAGE: 'Selected offers have been deleted'
			},
			CARD: {
				DAILY_ACTIVITY: {
					TITLE: 'Daily activity',
					DESC: 'See each column for more details'
				},
				ACTIVITY: {
					TITLE: 'Activity',
					DESC: 'Offer activity',
					NO_ACTIVITY: 'Offer still has no activity...',
					LEGENDS: {
						RECEIVED: 'Received',
						SAW: 'Saw',
						OPENED: 'Opened'
					},
					PERSONS: 'person'
				},
				ACTIVE_NOTIFICATION: {
					TITLE: 'Active offer',
					TO: 'to'
				},
				OTHER_NOTIFICATION: {
					TITLE: 'Other offers'
				}
			}
		},
		POINT_OF_INTEREST: {
			NO_POINT_OF_INTEREST:
				'Please start by filling in the point of interest profile information...',
			STATE: {
				ACTIVE: {
					TOOLTIP: 'Point of interest is active'
				},
				PENDING: {
					MENU_TOOLTIP:
						'Fill in the missing information here in order to activate your Point of Interest',
					TOOLTIP: 'Missing information to activate the point of interest'
				}
			},
			FORM: {
				GENERAL_SECTION: {
					TITLE: 'General information:',
					TITLE_FIELD: {
						TITLE: 'Title:'
					},
					DESCRIPTION_FIELD: {
						TITLE: 'Description:'
					},
					CATEGORY_FIELD: {
						TITLE: 'Category:',
						SELECT_ONE: 'Select one category...'
					},
					WEBSITE: {
						TITLE: 'Website'
					}
				},
				ADDRESS_SECTION: {
					TITLE: 'Address:',
					STREET_FIELD: {
						TITLE: 'Street:'
					},
					REGION_FIELD: {
						TITLE: 'Region:'
					},
					COUNTRY_FIELD: {
						TITLE: 'Country:'
					},
					POSTAL_CODE_FIELD: {
						TITLE: 'Postal code:'
					}
				},
				AVAILABLE_PAYMENTS_SECTION: {
					TITLE: 'Available payment methods:',
					TYPES: {
						money: 'Money',
						atm: 'ATM',
						atm_contactless: 'ATM contactless',
						mb_way: 'MBWay'
					}
				},
				EXTRA_FEATURES_SECTION: {
					TITLE: 'Extra services:'
				},
				OPENING_HOURS_SECTION: {
					TITLE: 'Opening hours:',
					SELECT_DAY: 'Select the day',
					ACTION: {
						ADD: 'Add schedule',
						DELETE: 'Delete schedule'
					}
				},
				CONTACTS_SECTION: {
					TITLE: 'Contacts:',
					EMAIL_FIELD: {
						TITLE: 'Email:'
					},
					PHONE_FIELD: {
						TITLE: 'Phone:'
					}
				},
				SOCIAL_NETWORKS_SECTION: {
					TITLE: 'Social networks:'
				},
				GALLERY: {
					MAIN_PICTURE: 'Main picture',
					ADD_PICTURE: 'Add pictures',
					ADD_PICTURE_TOOLTIP:
						'You can add up to 5 photos with a maximum size of 2 MB each.'
				},
				GEOFENCE: {
					LOCATION: 'Location',
					RADIUS: 'Define the geofence area where users can receive offers',
					MAP_AJUST:
						'Adjust the position of the point of interest by dragging the point on the map:'
				},
				ALERTS: {
					NOTHING_TO_SAVE: 'Nothing to save',
					CREATE_ERROR: "Can't create the point-of-interest, please try later!!",
					CREATE_SUCCESS: 'Point-of-interest created successfully.',
					UPDATE_ERROR: "Can't update the Point of interest, please try later!!",
					UPDATE_SUCCESS: 'Point of interest updated successfully.',
					UPLOAD_FILES_ERROR:
						'You can only add up to 5 photos with a maximum size of 2 MB each. The files were discarded: {{ignoreFiles}}'
				}
			}
		},
		ORDER: {
			LABEL: 'Order',
			LABEL_PLURAL: 'Orders',
			REFERENCE: 'Reference',
			ORDER_STATUS: 'Order status',
			ORDER_PAYMENT_STATUS: 'order payment status',
			ORDERS_PAYMENT_STATUS: 'orders payment status',
			UPDATE_STATUS: 'Order status update',
			UPDATES_STATUS: 'Order(s) status update',
			STATUS: {
				CANCELED: 'Canceled',
				COMPLETED: 'Completed',
				NEW: 'New',
				PROCESSING: 'Processing'
			},
			PAYMENT: 'Payment',
			SUMMARY: 'Summary',
			SHIPPING: 'Shipping',
			TAXES: 'Taxes',
			PAYMENT_STATUS: {
				LABEL: 'Payment Status',
				COMPLETED: 'Completed',
				CREATED: 'Created/ Not paid'
			},
			ERROR_UPDATING_ORDER: 'Error updating order',
			ERROR_REQUESTING: 'Error requesting order(s) from server.'
		},
		SETTINGS: {
			LABEL: 'Settings'
		},
		SHIPPING: {
			METHODS: {
				DELETE: {
					TITLE: 'Delete shipping method',
					DESCRIPTION: 'Are you sure to permanently delete this shipping method?',
					ERROR: 'There was a problem deleting this shipping method',
					SUCCESS: 'Shipping method successfully deleted'
				},
				LABEL: 'Shipping methods',
				ADD: 'Add shipping method',
				NAME: 'Method name',
				FULFILLMENT_TYPE: 'Fullfillment type',
				FULFILLMENT_SHIPPING: 'Shipping',
				FULFILLMENT_PICKUP: 'Pickup'
			}
		}
	}
};
