import { LatLng } from './lat-lng.model';

/**
 * Model used to represent an circular geofence.
 */

export const CIRCULAR_FENCE_TYPE = 'maps.circular-fence';
export const DEFAULT_FENCE_RADIUS = 30;
export class CircularFence {
	__component: string;
	id?: number;
	radius: number;
	center: LatLng;

    clear() {
		this.id = undefined;
		this.__component = CIRCULAR_FENCE_TYPE;
		this.radius = DEFAULT_FENCE_RADIUS;
		this.center = new LatLng();
		this.center.clear();
    }
}
