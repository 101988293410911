import { ShippingMethodModel } from './../_models/shipping-methods.model';
// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export enum ShippingMethodsActionTypes {
	ShippingMethodsPageRequest = '[ShippingM ethods List Page] Shipping Methods Page Request',
	ShippingMethodsPageLoaded = '[Shipping Methods API] Shipping Methods Page Loaded',
	ShippingMethodsActionToggleLoading = '[Shipping Methods] Shipping Methods Action Toggle Loading',
	ShippingMethodsActionMessage = '[Shipping Methods] Shipping Methods Action Message',
	ShippingMethodsListToggleLoading = '[Shipping Methods] Shipping Methods List Toggle Loading',
	ShippingMethodsActionError = '[Shipping Methods] Shipping Methods Action Error',
	ShippingMethodsOnServerCreated = '[Shipping Methods] Shipping Methods Action on server created',
	ShippingMethodCreated = '[Shipping Methods] Shipping Methods Action on creation',
	ShippingMethodUpdated = '[Shipping Methods] Shipping Methods Action on updated',
	OneShippingMethodDeleted = '[Shipping Methods] Shipping Methods Action on delete'
}

export class ShippingMethodsOnServerCreated implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodsOnServerCreated;
	constructor(
		public payload: { shopId: string; shippingMethod: ShippingMethodModel }
	) {}
}

export class ShippingMethodCreated implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodCreated;
	constructor(public payload: { shippingMethod: ShippingMethodModel }) {}
}

export class ShippingMethodUpdated implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodUpdated;
	constructor(
		public payload: {
			shopId: string;
			partialShippingMethod: Update<ShippingMethodModel>; // For State update
			shippingMethod: ShippingMethodModel; // For Server update (through service)
		}
	) {}
}
export class OneShippingMethodDeleted implements Action {
	readonly type = ShippingMethodsActionTypes.OneShippingMethodDeleted;
	constructor(public payload: { id: string }) {}
}

export class ShippingMethodsPageRequest implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodsPageRequest;

	constructor(
		public payload: {
			shopId: string;
		}
	) {}
}

export class ShippingMethodsPageLoaded implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodsPageLoaded;

	constructor(
		public payload: {
			shippingMethods: ShippingMethodModel[];
			totalCount: number;
		}
	) {}
}

export class ShippingMethodsActionToggleLoading implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodsActionToggleLoading;

	constructor(public payload: { isLoading: boolean }) {}
}

export class ShippingMethodsActionMessage implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodsActionMessage;

	constructor(
		public payload: {
			actionMessage: { type: 'error' | 'info' | 'success'; message: string };
		}
	) {}
}

export class ShippingMethodsListToggleLoading implements Action {
	readonly type = ShippingMethodsActionTypes.ShippingMethodsListToggleLoading;

	constructor(public payload: { isLoading: boolean }) {}
}

export type ShippingMethodsActions =
	| ShippingMethodsPageRequest
	| ShippingMethodsPageLoaded
	| ShippingMethodsActionToggleLoading
	| ShippingMethodsActionMessage
	| ShippingMethodsListToggleLoading
	| ShippingMethodsOnServerCreated
	| ShippingMethodCreated
	| ShippingMethodUpdated
	| OneShippingMethodDeleted;
