import { MediaFile } from './../../_base/crud/models/media-file.model';
import { TranslationField } from './../../_base/crud/models/translation-field.model';
import { BaseModel } from './../../_base/crud/models/_base.model';

export class Category extends BaseModel {
	name: string | TranslationField;
	active: boolean;
	image?: MediaFile;
	parent?: number; // <Category> id of parent

    clear() {
		this.id = null;
		this.name = '';
		this.image = null;
		this.parent = null;
    }
}
