import { GQLOrder, GQLAddress, GQLOrderItem } from './../../../../generated/graphql';
import { ApolloQueryResult } from 'apollo-client';
import { ReactionQueryResultsModel } from '../../_base/crud/models/query-models/reaction-query-results.model';

export class OrderModel extends GQLOrder {
	status: ORDER_STATUS;
}

export class AddressModel extends GQLAddress {}
export class OrderItemModel extends GQLOrderItem {}

export interface OrderReactionResponse
	extends ApolloQueryResult<{
		orders: ReactionQueryResultsModel<OrderModel>;
	}> {}

export enum ORDER_STATUS {
	CANCELED = 'canceled',
	COMPLETED = 'completed',
	NEW = 'new',
	PROCESSING = 'processing'
}
export enum ORDER_PAYMENT_STATUS {
	CREATED = 'created',
	COMPLETED = 'completed'
}
