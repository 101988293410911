// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { ProductModel } from '../_models/product.model';
import { Update } from '@ngrx/entity';

export enum ProductActionTypes {
	ProductsPageRequested = '[Products List Page] Products Page Requested',
	ProductsPageLoaded = '[Products API] Products Page Loaded',
	ProductsPageCancelled = '[Products API] Products Page Cancelled',
	ProductsPageToggleLoading = '[Products] Products Page Toggle Loading',
	ProductsActionToggleLoading = '[Products] Products Action Toggle Loading'
}

export class ProductsPageRequested implements Action {
	readonly type = ProductActionTypes.ProductsPageRequested;
	constructor(public payload: { page: QueryParamsModel }) {}
}

export class ProductsPageLoaded implements Action {
	readonly type = ProductActionTypes.ProductsPageLoaded;
	constructor(
		public payload: {
			products: ProductModel[];
			totalCount: number;
			page: QueryParamsModel;
		}
	) {}
}

export class ProductsPageCancelled implements Action {
	readonly type = ProductActionTypes.ProductsPageCancelled;
}

export class ProductsPageToggleLoading implements Action {
	readonly type = ProductActionTypes.ProductsPageToggleLoading;
	constructor(public payload: { isLoading: boolean }) {}
}

export class ProductsActionToggleLoading implements Action {
	readonly type = ProductActionTypes.ProductsActionToggleLoading;
	constructor(public payload: { isLoading: boolean }) {}
}

export type ProductActions =
	| ProductsPageRequested
	| ProductsPageLoaded
	| ProductsPageCancelled
	| ProductsPageToggleLoading
	| ProductsActionToggleLoading;
