import { TranslationService } from './../../layout/services/translation.service';
import { environment } from './../../../../../environments/environment';
// Angular
import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	constructor(private translationService: TranslationService) {}
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// modify headers request
		const headers = {};
		// Set the authentication token
		const token = localStorage.getItem(environment.authTokenKey);
		if (!!token) {
			// tslint:disable-next-line:no-string-literal
			headers['Authorization'] = `Bearer ${token}`;
		}
		// Set the current selected language
		const lang = this.translationService.getSelectedLanguage();
		// Only change the header to current language if the request don't have yet the Accept-Language
		if (!!lang && !request.headers.get('Accept-Language')) {
			headers['Accept-Language'] = lang;
		}

		request = request.clone({
			setHeaders: {
				...headers
			}
		});

		return next.handle(request);
	}
}
