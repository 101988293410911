import gql from 'graphql-tag';

const createFlatRateFulfillmentMethodMutation = gql`
	mutation createFlatRateFulfillmentMethod(
		$input: CreateFlatRateFulfillmentMethodInput!
	) {
		createFlatRateFulfillmentMethod(input: $input) {
			method {
				_id
				name
				label
				fulfillmentTypes
				cost
				rate
				isEnabled
			}
		}
	}
`;

export default createFlatRateFulfillmentMethodMutation;
