import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { environment } from './../../../../../environments/environment';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, formatDate } from '@angular/common';

/**
 * Returns truthy if the path to the object exists
 *
 * @example
 * const Hello = {World: { Foo: { Bar: 'Baz'}}};
 * console.log(objectPathExists(Hello, 'World.Foo.Bar')) // true
 * console.log(objectPathExists(Hello, 'World.Foo.Bar', true)) // Baz
 * console.log(objectPathExists(Hello, 'World.Foo', true)) // {Bar: 'Baz'}
 */
export function objectPathExists(
	o: object,
	path: string,
	getValue?: boolean,
	defaultValueIfNotExist: any = false
): boolean | any {
	if (!o || !Object.keys(o).length) return false;
	const keys = path.split('.');
	const loop = () => keys.every((k) => ((o = o[k]), isDefined(o)));
	const passFail = loop();

	if (!getValue) return passFail;

	// Whants the value (getValue === true)
	return (passFail && o) || defaultValueIfNotExist;
}

/**
 * Return a Date object without the _current_ time-zone
 * @param ofDate
 */
export function removeTimeZone(ofDate: Date) {
	const offset = ofDate.getTimezoneOffset() * 60000;
	return new Date(ofDate.getTime() - offset);
}

export function toDate(date: Date | string | number | NgbDateStruct) {
	if (!date) {
		return false;
	}

	if (date instanceof Date === true) {
		return date as Date;
	}
	if (typeof date === 'string' || typeof date === 'number') {
		return new Date(`${date}`);
	}

	const { day, month, year } = date as NgbDateStruct;
	return !!day && !!month && !!year && new Date(Date.UTC(year, month - 1, day));
}

export function toNgbDateStruct(date: Date | string | number | NgbDateStruct) {
	if (!date) {
		return null;
	}

	if (date instanceof Date === true) {
		date = date as Date;
		return {
			day: date.getUTCDate(),
			month: date.getUTCMonth() + 1,
			year: date.getUTCFullYear()
		};
	}

	if (typeof date === 'string' || typeof date === 'number') {
		const resDate = new Date(`${date}`);
		return {
			day: resDate.getUTCDate(),
			month: resDate.getUTCMonth() + 1,
			year: resDate.getUTCFullYear()
		};
	}

	return date as NgbDateStruct;
}

/**
 * Transforms provided date into ISOString date. if the date param is a
 * string, you _must_ provide it as 2121-12-21 (year-month-day).
 *
 * @param {Date | string | NgbDateStruct} date
 * @param separator by default is '/'
 * @returns {boolean | string} false || new Date(date).toISOString();
 */
export function toISODate(
	date: Date | string | number | NgbDateStruct,
	isToRemoveTimezone: boolean = true
) {
	const parsedDate = toDate(date);
	if (!parsedDate) {
		return false;
	}

	if (!isToRemoveTimezone) {
		return parsedDate.toISOString();
	}
	return removeTimeZone(parsedDate as Date).toISOString();
}

export function dateToEnvironmentFormat(
	date: Date | string | number | NgbDateStruct,
	isToRemoveTimezone: boolean = false
) {
	let c_date = toISODate(date, isToRemoveTimezone);
	if (!c_date) {
		return;
	}
	return dateCustomFormat(c_date);
}

/**
 * Convert Date to string with custom format 'dd/MM/yyyy'
 *
 * @param date: any
 */
function dateCustomFormat(date: string): string {
	let stringDate = '';
	if (date && date.length > 0) {
		const dateParts = date.trim().split('-');
		const year = toInteger(dateParts[0]);
		const month = toInteger(dateParts[1]);
		const day = toInteger(dateParts[2]);
		stringDate += isNumber(day) ? padNumber(day) + '/' : '';
		stringDate += isNumber(month) ? padNumber(month) + '/' : '';
		stringDate += year;
	}
	return stringDate;
}
/**
 * Convert number to string and addinng '0' before
 *
 * @param value: number
 */
function padNumber(value: number) {
	if (isNumber(value)) {
		return `0${value}`.slice(-2);
	} else {
		return '';
	}
}

/**
 * Checking value type equals to Number
 *
 * @param value: any
 */
export function isNumber(value: any): boolean {
	return !isNaN(toInteger(value));
}

/**
 * Covert value to number
 *
 * @param value: any
 */
export function toInteger(value: any): number {
	return parseInt(`${value}`, 10);
}

export function dateEquals(one: NgbDateStruct, two: NgbDateStruct) {
	return (
		one &&
		two &&
		two.year === one.year &&
		two.month === one.month &&
		two.day === one.day
	);
}

export function dateBefore(one: NgbDateStruct, two: NgbDateStruct) {
	return !one || !two
		? false
		: one.year === two.year
		? one.month === two.month
			? one.day === two.day
				? false
				: one.day < two.day
			: one.month < two.month
		: one.year < two.year;
}

export function dateAfter(one: NgbDateStruct, two: NgbDateStruct) {
	return !one || !two
		? false
		: one.year === two.year
		? one.month === two.month
			? one.day === two.day
				? false
				: one.day > two.day
			: one.month > two.month
		: one.year > two.year;
}

/**
 * Marks the control and all its descendant controls as
 *
 * @param markAs 'touched' | 'dirty' | 'pristine' | 'untouched'
 *
 */
export function markAllFormAs(
	form,
	markAs: 'touched' | 'dirty' | 'pristine' | 'untouched'
) {
	if (markAs === 'touched') {
		form.markAllAsTouched();
		return;
	}
	markFormGroupAs(form, markAs);
}
const markFormGroupAs = (
	formGroup: FormGroup,
	markAs: 'touched' | 'dirty' | 'pristine' | 'untouched'
) => {
	Object.keys(formGroup.controls).forEach((key) => {
		switch (formGroup.get(key).constructor.name) {
			case 'FormGroup':
				markFormGroupAs(formGroup.get(key) as FormGroup, markAs);
				break;
			case 'FormArray':
				markFormArrayAs(formGroup.get(key) as FormArray, markAs);
				break;
			case 'FormControl':
				markFormControlAs(formGroup.get(key) as FormControl, markAs);
				break;
		}
	});
};
const markFormArrayAs = (
	formArray: FormArray,
	markAs: 'touched' | 'dirty' | 'pristine' | 'untouched'
) => {
	formArray.controls.forEach((control) => {
		switch (control.constructor.name) {
			case 'FormGroup':
				markFormGroupAs(control as FormGroup, markAs);
				break;
			case 'FormArray':
				markFormArrayAs(control as FormArray, markAs);
				break;
			case 'FormControl':
				markFormControlAs(control as FormControl, markAs);
				break;
		}
	});
};
const markFormControlAs = (
	formControl: FormControl,
	markAs: 'touched' | 'dirty' | 'pristine' | 'untouched'
) => {
	switch (markAs) {
		case 'touched':
			formControl.markAsTouched();
			break;
		case 'dirty':
			formControl.markAsDirty();
			break;
		case 'pristine':
			formControl.markAsPristine();
			break;
		case 'untouched':
			formControl.markAsUntouched();
			break;
	}
};

/**
 * Function which return a base64 of file
 * @param file
 * @returns data:base64 string
 */
export function getBase64(file) {
	if (!file) {
		return null;
	}
	const reader = new FileReader();
	return new Promise((resolve) => {
		reader.onload = (ev) => {
			resolve(reader.result);
		};
		reader.readAsDataURL(file);
	});
}

export function isDefined(value: any, toIgnore?: any[]): boolean {
	const isNotDefinedDefaultTest = value !== undefined && value !== null;
	const isNotDefinedCustomTest = toIgnore
		? toIgnore.find((ti) => value !== ti)
		: true;

	return isNotDefinedDefaultTest && isNotDefinedCustomTest;
}

export function toFormData<T>(formValue: T) {
	const formData = new FormData();

	for (const key of Object.keys(formValue)) {
		const value = formValue[key];
		if (isDefined(value)) {
			formData.append(key, value);
		}
	}

	return formData;
}
/**
 * Function that use the for loop to get the min and max value in an array
 * @param array array of values or object array.
 * @param key the key to get the value in the array of objects.
 * @returns data:base64 string
 */
export function forLoopMinMax(
	array: any,
	key?: string
): { min: number; max: number } {
	let min = (key ? array[0][key] : array[0]) || 0;
	let max = min;

	for (let i = 1; i < array.length; i++) {
		const value = key ? array[i][key] : array[i];
		min = value < min ? value : min;
		max = value > max ? value : max;
	}

	return { min, max };
}
/** Handle requests sequentially (second request is only executed after the first finished)
 *  This avoid requests that happen at the same time and eventually causing error in the DataOrder
 *
 * @example
 * const requestsSequecially = this.addonsToDeleteIds.map(addonId => () => this.service.removeAddon(addonId));
 * runPromisesSequentially(requestsSequecially)
 *
 * @param {() => Promise<any>)[]} promisesWithDeps - an array of functions that return Promises
 *        (like in the example an array of UpdateShoppingCartItem requests)
 */
export function runPromisesSequentially<T>(promisesWithDeps: (() => Promise<T>)[]) {
	return promisesWithDeps.reduce(async (promise: Promise<T[]>, func) => {
		const result = await promise;
		const r = await func();
		return result.concat(r);
	}, Promise.resolve([] as T[]));
}

/** Get image URI - host resolver */
export function getImageUri(
	imageRelPath: string,
	source: 'proxyserve' | 'commerce' = 'proxyserve'
) {
	if (!imageRelPath || imageRelPath.startsWith('http')) return imageRelPath;

	return `${environment.ecommerceEndpoint}${imageRelPath}`;
}

// Is FormControl Invalid and Dirty
export function isControlInvalidAndDirty(control: AbstractControl) {
	return control.dirty && control.invalid;
}
